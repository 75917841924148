import React, { useEffect, useRef, useState } from "react"
import * as m from "@mui/material"
import newIcons from "../assets/newIcons"
import { useNavigate } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import _ from "lodash"
import { appLayout, emulatorStatus } from "../state/projectState"
import useStyles from "./card_style"
import { convertToUppercase } from "../state/StatusState"
import { ReactComponent as HeartIcon } from "../assets/newIcons/Heart.svg"
import { ReactComponent as SettingsIcon } from "../assets/newIcons/settings.svg"
import { ReactComponent as OptFrameIcon } from "../assets/newIcons/Frame 2608986.svg"
import { ReactComponent as DMTBadge } from "../assets/newIcons/badge-check.svg"
import * as requests from "../state/requests"
import {
  EmulationLab,
  ShareIconButton,
  useWindowDimensions,
} from "../GlobalFileContainer"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { alertStates } from "../state/vizState"
import { testJWTToken } from "../state/axios"

const ClampedTypography = m.styled(m.Typography)(({ theme }) => ({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
}))

const availableModes = {
  Calculator: "ready_for_calcs",
  Emulator: "ready_for_emulate",
  Lookup: "ready_for_lookup",
  Optimizer: "ready_for_optimization",
}

const getFavoriteDefault = (emulator, mongoProfile) => {
  try {
    return mongoProfile.library.consumer.includes(emulator.id)
  } catch {
    return false
  }
}

const EmulatorSelectionCardConsumer = ({
  emulator,
  mongoProfile,
  setMongoProfile,
  searchResultKeys,
  fetchData,
}) => {
  const contentRef = useRef(null)
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const setEmulatorStatusState = useSetRecoilState(emulatorStatus)
  const setAlertState = useSetRecoilState(alertStates)
  const layout = useRecoilValue(appLayout)

  const [favorite, setFavorite] = useState(
    getFavoriteDefault(emulator, mongoProfile)
  )
  const [imageHeight, setImageHeight] = useState("100%")
  const iconHeight = {
    height: width < 680 ? "20px" : "27px",
    width: width < 680 ? "20px" : "27px",
  }
  const iconPadding = {
    padding: width < 680 ? "3px" : "8px",
  }

  useEffect(() => {
    const updateImageHeight = () => {
      if (contentRef.current) {
        const containerHeight = contentRef.current.offsetHeight
        const fixedHeight = layout === "grid" ? 75 : 0
        setImageHeight(`${containerHeight - fixedHeight}px`)
      }
    }

    updateImageHeight()
    window.addEventListener("resize", updateImageHeight)

    return () => {
      window.removeEventListener("resize", updateImageHeight)
    }
  }, [layout])

  const investigateRouteChange = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let path = `/em/${emulator.id}/summary`
    setEmulatorStatusState(emulator.status)
    navigate(path)
    return false
  }

  const setFavoriteState = (response) => {
    const direction = favorite ? "removed from" : "added to"
    if (response.status === 200) {
      setAlertState({
        boolState: true,
        message: `Emulator ${emulator.name} ${direction} library`,
        severityState: "success",
      })
      setFavorite((val) => !val)
    } else {
      setAlertState({
        boolState: true,
        message: "Emulator unable to modify favorites",
        severityState: "error",
      })
    }
  }

  const clickFavoriteHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let body = { id: emulator.id }
    let url = requests.fetchUserLibraryFavorites
    if (!favorite) {
      EmulationLab.put(url, body, {
        headers: {
          severity: SeverityLevel.Warning,
        },
      }).then((response) => {
        setFavoriteState(response)
        testJWTToken(setMongoProfile)
      })
    } else {
      EmulationLab.delete(url, {
        data: body,
        headers: {
          severity: SeverityLevel.Warning,
        },
      }).then((response) => {
        setFavoriteState(response)
        testJWTToken(setMongoProfile)
      })
    }
  }

  const tryItRouteChange = (e, mode) => {
    e.stopPropagation()
    e.preventDefault()
    let path = mode === "lookup" ? `/em/${emulator.id}/lookup` : `/em/${emulator.id}/tryit?mode=${mode}`
    navigate(path)
    setEmulatorStatusState(emulator.status)
    return false
  }

  const getModeIcon = (mode) => {
    switch (mode) {
      case "calculate":
        return (
          <img
            style={{ ...iconHeight }}
            src={newIcons["calculate"]}
            alt={mode}
          />
        )
      case "emulate":
        return (
          <img style={{ ...iconHeight }} src={newIcons["emulate"]} alt={mode} />
        )
      case "lookup":
        return (
          <img
            style={{ ...iconHeight }}
            src={newIcons["LookupIcon"]}
            alt={mode}
          />
        )
      case "optimization":
        return (
          <div>
            <SettingsIcon style={{ ...iconHeight }} />
            <OptFrameIcon
              style={{
                position: "absolute",
                right: "5px",
                bottom: "6px",
                ...iconHeight,
              }}
            />
          </div>
        )
    }
  }

  return (
    <>
      {_.some(searchResultKeys, (value) => _.includes(value, emulator?.id)) &&
        (layout === "grid" ? (
          <m.Card
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
            }}
            className="emu-row__card cursor-point"
            onClick={investigateRouteChange}
          >
            <m.CardHeader
              sx={{ padding: "16px 12px" }}
              title={
                <>
                  <m.Grid
                    container
                    alignItems={"baseline"}
                    justifyContent={"space-between"}
                  >
                    <m.Grid item xs={9}>
                      <ClampedTypography
                        variant={"cardHeading"}
                        fontFamily={"Roboto"}
                        fontWeight={700}
                      >
                        {emulator.name}
                      </ClampedTypography>
                    </m.Grid>
                    <m.Grid item xs={3}>
                      <m.Box alignItems={"center"}>
                        <m.IconButton
                          onClick={clickFavoriteHandler}
                          size="small"
                          sx={{ padding: "8px" }}
                          className="zoom-hover-10"
                        >
                          <HeartIcon
                            height={"20px"}
                            fill={favorite ? "black" : "none"}
                          />
                        </m.IconButton>
                        <ShareIconButton id={emulator?.id} />
                      </m.Box>
                    </m.Grid>
                  </m.Grid>
                  <m.Grid sx={{ pt: "16px" }} container spacing={1}>
                    <m.Grid item xs={12}>
                      <ClampedTypography variant="body2">
                        {emulator.description}
                      </ClampedTypography>
                    </m.Grid>
                  </m.Grid>
                </>
              }
            />
            <div style={{ height: "100%" }} ref={contentRef}>
              <img
                height={imageHeight}
                width="100%"
                src={emulator.image}
                alt={emulator.name}
                onError={({ target }) => {
                  target.src = "/assets/images/default_em_image.jpg"
                }}
              />
              {emulator?.validations.length !== 0 && (
                <m.Box
                  sx={{
                    padding: "8px 12px",
                    backgroundColor: "#389950",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    width: "100%",
                    bottom: "75px",
                  }}
                >
                  <DMTBadge stroke="#FAFAFA" />
                  &nbsp;
                  <m.Typography
                    fontFamily={"'Inter', Helvetica"}
                    fontSize={16}
                    fontWeight={500}
                    color={"#ffffff"}
                    textTransform={"uppercase"}
                  >
                    {" "}
                    {emulator?.validations[0]}
                  </m.Typography>
                </m.Box>
              )}

              <m.CardActions
                sx={{
                  padding: "16px 8px",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                }}
              >
                <m.Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <m.Grid item>
                    {emulator.modes.map((mode) => (
                      <m.IconButton
                        key={mode}
                        title={mode}
                        size="small"
                        sx={{ ...iconPadding }}
                        className="zoom-hover-10"
                        onClick={(e) => tryItRouteChange(e, mode)}
                      >
                        {getModeIcon(mode)}
                      </m.IconButton>
                    ))}
                  </m.Grid>
                  <m.Grid item>
                    <m.Chip
                      sx={{ backgroundColor: "#000000" }}
                      label={
                        <m.Typography
                          variant="body1"
                          fontFamily={"Roboto"}
                          fontWeight={500}
                          color={"#ffffff"}
                        >
                          {convertToUppercase(emulator.category)}
                        </m.Typography>
                      }
                      variant="filled"
                    />
                  </m.Grid>
                </m.Grid>
              </m.CardActions>
            </div>
          </m.Card>
        ) : (
          <m.Card
            sx={{ display: "flex", flexDirection: "row" }}
            className="emu-col__card cursor-point"
            onClick={investigateRouteChange}
          >
            <m.Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                padding: "5px",
                width: "100%",
              }}
            >
              <m.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%" }} ref={contentRef}>
                  <img
                    style={{ borderRadius: 6 }}
                    height={imageHeight}
                    width="80px"
                    src={emulator.image}
                    alt={emulator.name}
                    onError={({ target }) => {
                      target.src = "/assets/images/default_em_image.jpg"
                    }}
                  />
                </div>
                <m.Box
                  sx={{
                    display: "inline-flex",
                    flexDirection: "column",
                  }}
                >
                  <ClampedTypography
                    variant="body1"
                    fontFamily={"Roboto"}
                    fontWeight={700}
                  >
                    {emulator.name}{" "}
                    {emulator?.validations.length !== 0 && (
                      <m.Chip
                        size="small"
                        icon={
                          <DMTBadge
                            height={"15px"}
                            width={"15px"}
                            stroke="#389950"
                          />
                        }
                        label={
                          <m.Typography
                            fontFamily={"'Inter', Helvetica"}
                            fontSize={8}
                            fontWeight={500}
                            color="success"
                            textTransform={"uppercase"}
                          >
                            {emulator?.validations[0]}
                          </m.Typography>
                        }
                        variant="outlined"
                        color="success"
                      />
                    )}
                  </ClampedTypography>
                  <ClampedTypography
                    variant="subHeading"
                    fontFamily={"Helvetica"}
                    fontWeight={500}
                  >
                    {emulator.description}
                  </ClampedTypography>
                </m.Box>
              </m.Box>
              <m.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: width < 680 ? 1 : 2,
                }}
              >
                {emulator.modes.map((mode) => (
                  <m.IconButton
                    key={mode}
                    title={mode}
                    size="small"
                    sx={{ ...iconPadding }}
                    className="zoom-hover-10"
                    onClick={(e) => tryItRouteChange(e, mode)}
                  >
                    {getModeIcon(mode)}
                  </m.IconButton>
                ))}
                <m.Divider orientation="vertical" flexItem />
                <m.Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <m.IconButton
                    onClick={clickFavoriteHandler}
                    size="small"
                    sx={{ ...iconPadding }}
                    className="zoom-hover-10"
                  >
                    <HeartIcon
                      height={"20px"}
                      fill={favorite ? "black" : "none"}
                    />
                  </m.IconButton>
                  <ShareIconButton
                    iconPadding={iconPadding}
                    id={emulator?.id}
                  />
                </m.Box>
              </m.Box>
            </m.Box>
          </m.Card>
        ))}
    </>
  )
}

export default EmulatorSelectionCardConsumer
