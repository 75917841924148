import { useState, useEffect, useRef, Suspense } from "react"
import { useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import { debounce } from "lodash"
import { Typography } from "@material-ui/core"
import RefreshIcon from "@mui/icons-material/Refresh"
import * as file from "../../GlobalFileContainer"
import { ChipIcon, OutputsCard } from "../project/CalculatorCard"
import * as projState from "../../state/projectState"
import * as statusSatate from "../../state/StatusState"
import "./OptimizationMain.css"
import useStyles from "../card_style"
import { lookupRequest, optimizeUrl } from "../../state/requests"
import OutputsForm from "../testing-calculator/OutputsForm"
import { handleValidation } from "../testing-calculator/AddTestForm"
import RefreshButtonStyling from "../shared components/RefreshStyling/RefreshButtonStyling"
import { EvaluateTooltip } from "../MLEvaluation/MLIcons"
import { Grid } from "@mui/material"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { alertStates } from "../../state/vizState"

const getSteps = (lookup) =>
  lookup
    ? [
        {
          label: "Select Output",
          description: "Pick a sort value for lookup calculation.",
        },
        {
          label: "Add Filters",
          description: "Add limiting factors for input/output values",
        },
        {
          label: "View Results",
        },
      ]
    : [
        {
          label: "Select Outputs",
          description:
            "Pick an output that's going to define good vs bad in the optimization.",
        },
        {
          label: "Add Constraints",
          description: "Add limiting factors for output values if needed.",
        },
        {
          label: "Set Variables",
          description: "Click on the inputs you want to make flexible.",
        },
        {
          label: "Choose Inputs",
          description: "Enter values for known parameters.",
        },
        {
          label: "View Results",
        },
      ]

const POLLING_INTERVAL = 10000

const StyledStepConnector = m.styled(m.StepConnector)(({ theme }) => ({
  paddingTop: "12px",
}))

const OptimizationStepper = ({ getAllHistory, lookup }) => {
  let { emulatorId } = useParams()
  const theme = m.useTheme()
  const classes = useStyles()
  const buttonRef = useRef(null)
  const boxStyle = {
    display: "flex",
    flexDirection: "row",
    pt: 2,
    justifyContent: "space-between",
    alignItems: "end",
  }
  const emConfig = useRecoilValue(projState.getEmulatorData)

  const emptySubTestObject = {
    label: "",
    mode: "",
    value: "",
    valueMin: "",
    valueMax: "",
    tolerance: "",
    status: "",
  }

  const setErrorFields = useSetRecoilState(projState.errorFieldsState)
  const finalTestObjectState = useRecoilValue(projState.finalTestObject)
  const [selectedOutputs, setSelectedOutputs] = useRecoilState(
    projState.outputsParametersAndVariables
  )
  const [graph, setGraph] = useRecoilState(projState.getGraphResult)
  const [jobId, setJobId] = useRecoilState(projState.resultJobIdState)
  const emulatorConfig = useRecoilValue(projState.getEmulatorData)
  const setAlertState = useSetRecoilState(alertStates)

  const [selectedLabel, setSelectedLabel] = useState(false)
  const [testList, setTestList] = useState(selectedOutputs?.constraints)
  const [index, setIndex] = useState()
  const [updateTest, setUpdateTest] = useState(false)
  const [addForm, setAddForm] = useState(true)
  const [testDetails, setTestDetails] = useState(emptySubTestObject)
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const [timer, setTimer] = useState(10)
  const [isPolling, setIspolling] = useState(false)
  const [generateRandomInputs, setGenerateRandomInputs] = useState(false)
  const [progress, setProgress] = useState(0)
  const [validateOptimizeButton, setValidateOptimizeButton] = useState(false)
  const [validationErrors, setValidationErrors] = useState({
    labelError: false,
    ruleError: false,
    expOutError: false,
    maxOutErr: false,
  })
  const [seeData, setSeeData] = useState(false)
  const [newRecord, setNewRecord] = useState(false)
  const [outputObjective, setOutputObjective] = useState(
    statusSatate.OUTPUT_OBJECTIVES[0]
  )
  const [limitforLookup, setLimitForLookup] = useState(1)
  const [lookupResults, setLookupResults] = useState([])
  const [loader, setLoader] = useState(false)
  const [lookupBody, setLookupBody] = useState({})

  const disableRandomiseButton =
    !selectedOutputs?.objectives?.length ||
    statusSatate.compareLengths(selectedOutputs, emConfig) ||
    validateOptimizeButton
  const findEmptyVariable =
    selectedOutputs?.variables.find((v) => v?.domain.values?.length < 1) ??
    false

  const steps = getSteps(lookup)
  const NUM_STEPS = steps.length

  useEffect(() => {
    if (jobId?.length > 0) {
      setActiveStep(NUM_STEPS - 1)
      setCompleted(
        Object.fromEntries(
          Array.from({ length: NUM_STEPS - 1 }, (_, i) => [i, true])
        )
      )
    }
  }, [])

  useEffect(() => {
    if (jobId) {
      const searchObject = {
        jobId: jobId,
      }
      projState.updateURLSearchParams(searchObject)
    }
  }, [jobId])

  useEffect(() => {
    setErrorFields(false)
    if (updateTest) {
      let selectedTestObject = finalTestObjectState?.tests[index]
      let selectedObjSubTests = selectedTestObject?.subTests

      setTestList(selectedObjSubTests)
      setTestDetails(selectedObjSubTests[selectedObjSubTests.length - 1])
    }
  }, [updateTest])

  useEffect(() => {
    if (!disableRandomiseButton && selectedOutputs?.parameters) {
      const debouncedFindBestResult = debounce(() => {
        findBestResult()
      }, 1000)

      debouncedFindBestResult()

      return () => {
        debouncedFindBestResult.cancel()
      }
    } else {
      setSelectedOutputs((prev) => ({
        ...prev,
        bestResult: "",
      }))
    }
  }, [disableRandomiseButton, selectedOutputs?.parameters])

  useEffect(() => {
    if (isPolling) {
      const timerInterval = setInterval(() => {
        setTimer((prevTime) => (prevTime > 1 ? prevTime - 250 / 1000 : 10))
      }, 250)
      return () => clearInterval(timerInterval)
    }
  }, [isPolling])

  useEffect(() => {
    if (isPolling) {
      const percentComplete = ((10 - timer) * 100) / 10
      setProgress(percentComplete)
    } else setProgress(0)
  }, [timer])

  useEffect(() => {
    if (jobId && activeStep === NUM_STEPS - 2 && !lookup) {
      getResults(true)
    }
  }, [jobId])

  useEffect(() => {
    if (activeStep === NUM_STEPS - 1 && !lookup) {
      getResults()
    }
  }, [activeStep])

  useEffect(() => {
    if (activeStep === NUM_STEPS - 1) {
      if (jobId) {
        const pollingID = setInterval(async () => {
          if (!graph || statusSatate.passFailedArray?.includes(graph?.status)) {
            clearInterval(pollingID)
            setTimer(false)
            return
          }
          await getResults()
        }, POLLING_INTERVAL)

        return () => {
          clearInterval(pollingID)
        }
      }
    }
  }, [graph])

  const handleTestDialogClose = () => {
    setUpdateTest(false)
    setValidationErrors({
      labelError: false,
      ruleError: false,
      expOutError: false,
      maxOutErr: false,
    })
    setTestDetails(emptySubTestObject)
    setTestList([])
    setSelectedLabel(false)
    setErrorFields(false)
  }

  const getButtonStyle = (refreshButton) => {
    if (refreshButton) {
      return {
        position: "absolute",
        mr: 0,
        boxShadow: "0px 0px 1px 1px #0000001a",
      }
    } else {
      return {
        position: "relative",
        mr: 1,
      }
    }
  }

  const getResults = async (complete) => {
    const resultUrl = `/calc/job/${jobId}/results2`
    try {
      const result = await file.EmulationLab.get(resultUrl)
      const results = result.data
      const isComplete = statusSatate.passFailedArray?.includes(results?.status)
      const hasResult = results.ranking.length
        ? !selectedOutputs.bestResult ||
          selectedOutputs.bestResult === "Infinity" ||
          selectedOutputs.bestResult > results?.ranking[0]?.value
        : false
      setGraph(results)
      if (hasResult && isPolling) {
        setSelectedOutputs((prev) => ({
          ...prev,
          bestResult: results.ranking[0].value,
        }))
        setNewRecord(true)
      }
      setIspolling(!isComplete)
      setTimer(isComplete ? false : 10)
      if (complete) {
        handleComplete()
      }
    } catch (err) {
      console.error(err)
      alert("Failed to view results, please try after some time.")
    }
  }

  const clickAndStoreOutput = (variableData, value) => {
    const newOutputObject = {
      label: variableData?.ColumnLabel ?? selectedOutputs?.objectives[0]?.label,
      mode: value ?? outputObjective,
      value: "",
    }
    setSelectedOutputs((prev) => ({
      ...prev,
      objectives: [newOutputObject],
    }))
  }

  const handleNext = () => {
    const newActiveStep =
      activeStep !== NUM_STEPS - 1 ? activeStep + 1 : activeStep
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setNewRecord(false)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  const commonButton = (
    isDisabled,
    callBackFunc,
    btnName,
    title,
    refreshButton,
    style
  ) => {
    const btnStyle = getButtonStyle(refreshButton && isPolling)
    return (
      <m.Button
        ref={refreshButton ? buttonRef : null}
        variant="contained"
        className={
          refreshButton && isPolling && (timer < 2 || timer === 30)
            ? "pulse"
            : ""
        }
        style={style ? { pointerEvents: "none" } : undefined}
        sx={btnStyle}
        disabled={isDisabled}
        onClick={callBackFunc}
        title={title}
      >
        <m.Typography variant="caption" color={theme.palette.common.white}>
          {btnName}
        </m.Typography>
      </m.Button>
    )
  }

  const handleResetOutput = () => {
    setSelectedOutputs((prev) => ({
      ...prev,
      objectives: [],
    }))
    setCompleted({})
  }

  const commonIconBtn = (callBAckFunc) => (
    <m.IconButton onClick={callBAckFunc}>
      <RefreshIcon
        titleAccess="Click here to reset the output"
        color={"primary"}
      />
    </m.IconButton>
  )

  const findBestResult = async () => {
    setGenerateRandomInputs(false)
    const historicalBestUrl = `/calc/${emulatorId}/optimization/history/best`
    const wholeOptimizationObject = statusSatate.optimisationObject(
      emulatorId,
      selectedOutputs,
      emulatorId === "64d11e7c572b89d39f6b2aa9"
    )

    try {
      const historicalBest = await file.EmulationLab.post(
        historicalBestUrl,
        wholeOptimizationObject
      )
      if (historicalBest.status === 200) {
        setSelectedOutputs((prev) => ({
          ...prev,
          bestResult: historicalBest?.data.best,
        }))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleOptimization = async () => {
    const wholeOptimizationObject = statusSatate.optimisationObject(
      emulatorId,
      selectedOutputs,
      emulatorId === "64d11e7c572b89d39f6b2aa9"
    )
    try {
      const response = await file.EmulationLab.post(
        optimizeUrl,
        wholeOptimizationObject
      )

      if (response.status === 200) {
        getAllHistory()
        const newJobId = response?.data
        setJobId(newJobId)
        setTestList(selectedOutputs?.constraints)
        setTestDetails(emptySubTestObject)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const convertConstraints = async (constraints, limit) => {
    if (limit) {
      return constraints.map((constraint) => {
        return {
          Label: constraint.label,
          Data: [
            `${statusSatate.getOperator(constraint.mode)} ${constraint.value}`,
          ],
        }
      })
    } else {
      return constraints.map((constraint) => {
        return {
          label: constraint.label,
          operator: constraint.mode,
          value: constraint.value,
        }
      })
    }
  }

  const convertOutputVal = (res) => {
    return res.map((obj) => {
      const result = Object.entries(obj)
        .map(([key, value]) => ({
          name: key,
          value: value,
        }))
        .filter((data) => data.name !== "version")

      return { Result: result }
    })
  }

  const handleLookupResult = async (constraint) => {
    const constraints = await convertConstraints(constraint, false)
    const featuresData = await convertConstraints(constraint, true)
    const body = {
      emulator_id: emulatorId,
      description: emulatorConfig.description,
      instructions: {
        limit: limitforLookup,
        sort: { ...selectedOutputs.objectives[0] },
        filters: constraints,
      },
    }
    setLookupBody(body)
    try {
      const result = await file.EmulationLab.post(lookupRequest, body, {
        headers: {
          severity: SeverityLevel.Error,
        },
        params: { client: "TryIt", version: "beta" },
      })

      if (!result.data) {
        setLookupResults([])
        setLoader(false)
      }

      const outputvalues = await convertOutputVal(result.data)
      if (outputvalues) {
        const outputbody = outputvalues.map((val) => ({
          emulatorId: emulatorId,
          jobId: "",
          inputValues: featuresData,
          outputValues: val.Result,
          resultMode: "lookup",
          resultVersion: result.data[0].version,
          resultStatus: "complete",
        }))
        setLookupResults(outputbody)
      }
      setLoader(false)
    } catch (error) {
      setLookupResults([])
      setLoader(false)
      console.log(error)
    }
  }

  const handleAddConstraint = async () => {
    const ifDisabled = getDisableStatus()
    let constraints = []
    if (ifDisabled) {
      const validationError = handleValidation(
        testDetails,
        validationErrors,
        setValidationErrors
      )
      if (validationError) return validationError
      return
    }

    if (testDetails.mode !== "" && testDetails.value !== "") {
      let allConstraints = [...testList, testDetails]
      setTestList([...testList, testDetails])
      setTestDetails(emptySubTestObject)
      setSelectedOutputs((prev) => ({
        ...prev,
        constraints: allConstraints,
      }))
      constraints = allConstraints
    } else if (
      JSON.stringify(testList) !== JSON.stringify(selectedOutputs.constraints)
    ) {
      const updatedTest = [...testList]
      const findEmptyTest = updatedTest.find(
        (test) => test.mode === "" || test.value === ""
      )
      if (findEmptyTest) {
        const validationError = handleValidation(
          findEmptyTest,
          validationErrors,
          setValidationErrors
        )
        if (validationError) return validationError
      }
      setSelectedOutputs((prev) => ({
        ...prev,
        constraints: updatedTest,
      }))
      constraints = updatedTest
    }

    lookup && handleComplete()
    setLoader(true)
    lookup
      ? handleLookupResult(
          constraints.length > 0 ? constraints : selectedOutputs.constraints
        )
      : handleComplete()
  }

  const handleRandoms = () => {
    setGenerateRandomInputs(true)
  }

  const handleCloseViewData = () => {
    setSeeData(false)
  }

  const getDisableStatus = () => {
    const { label, value, mode } = testDetails
    console.log("disable2", testDetails)

    const allEmpty =
      (label === "" && value === "" && mode === "") ||
      (label !== "" && value !== "" && mode !== "")
    const anyFilled = label !== "" || value !== "" || mode !== ""

    const boolToFind = anyFilled && !allEmpty

    if (boolToFind) {
      let objToFind = testDetails
      const foundObject =
        selectedOutputs?.constraints?.length > 0
          ? selectedOutputs?.constraints.find((item) =>
              Object.keys(objToFind).every(
                (key) => item[key] === objToFind[key]
              )
            )
          : false
      return !foundObject
    } else return boolToFind
  }

  const showConstraintsOnHover = () => {
    return (
      <m.Grid xs>
        {selectedOutputs?.constraints?.map((obj, i) => {
          return (
            <Typography variant="body2" key={`${obj}+${i}`}>{`${i + 1}) ${
              obj?.label
            } with ${obj?.mode} expected value ${obj.value}`}</Typography>
          )
        })}
      </m.Grid>
    )
  }

  const handleOutputObjectiveChange = (e) => {
    let value = e.target.value
    setOutputObjective(value)
    clickAndStoreOutput("", value)
  }

  const returnInformationText = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            <m.Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {selectedOutputs?.objectives?.length > 0 && (
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <m.Box>
                    <m.Typography noWrap className="ed-small">
                      Objectives:
                    </m.Typography>
                  </m.Box>
                  &nbsp;
                  <m.Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                    xs
                  >
                    <m.FormControl size="small">
                      <m.Select
                        disabled={activeStep > 0}
                        variant="outlined"
                        SelectDisplayProps={{
                          style: { padding: "6px 14px" },
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        style={{ minWidth: "120px" }}
                        value={outputObjective}
                        onChange={handleOutputObjectiveChange}
                      >
                        {statusSatate.OUTPUT_OBJECTIVES.map((outp, index) => (
                          <m.MenuItem key={`${outp}-${index}`} value={outp}>
                            {outp.charAt(0).toUpperCase() + outp.slice(1)}
                          </m.MenuItem>
                        ))}
                      </m.Select>
                    </m.FormControl>
                    &nbsp;
                    {selectedOutputs?.objectives?.map((obj) => {
                      const findMatchingOutput =
                        emConfig?.calculator?.OutputVariables?.find(
                          (otp) => otp?.ColumnLabel === obj.label
                        )

                      return (
                        <m.Chip
                          size={"medium"}
                          key={obj?.label}
                          className={classes.chipStyle}
                          label={findMatchingOutput?.NickName}
                          icon={ChipIcon(findMatchingOutput?.Type)}
                          color={"secondary"}
                          style={{
                            fontFamily: theme.palette.typography.fontFamily,
                          }}
                        />
                      )
                    })}
                  </m.Grid>
                  {activeStep === 0 && (
                    <m.Box>{commonIconBtn(handleResetOutput)}</m.Box>
                  )}
                </Grid>
              )}
            </m.Grid>
          </>
        )
      case 1:
        return (
          <m.Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: "min-content",
            }}
          >
            {selectedOutputs?.constraints?.length > 0 && activeStep > 0 && (
              <m.Grid
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <EvaluateTooltip
                  placement="right"
                  arrow
                  title={<>{showConstraintsOnHover()}</>}
                >
                  <m.Box sx={{ cursor: "pointer" }}>
                    <m.Typography noWrap className="ed-small">
                      Constraints: {selectedOutputs?.constraints.length}
                    </m.Typography>
                  </m.Box>
                </EvaluateTooltip>
              </m.Grid>
            )}
          </m.Grid>
        )

      case 2:
        return (
          <m.Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {selectedOutputs?.variables?.length > 0 && activeStep > 1 && (
              <>
                <m.Box>
                  <m.Typography noWrap className="ed-small">
                    Variables:
                  </m.Typography>
                </m.Box>
                &nbsp;
                <m.Grid xs>
                  {selectedOutputs?.variables?.map((obj) => {
                    const findMatchingOutput =
                      emConfig?.calculator?.InputVariables?.find(
                        (otp) => otp?.ColumnLabel === obj.label
                      )

                    return (
                      <m.Chip
                        size={"medium"}
                        key={obj?.label}
                        className={classes.chipStyle}
                        label={findMatchingOutput?.NickName}
                        icon={ChipIcon(findMatchingOutput?.Type)}
                        color={"primary"}
                        style={{
                          fontFamily: theme.palette.typography.fontFamily,
                        }}
                      />
                    )
                  })}
                </m.Grid>
              </>
            )}
          </m.Grid>
        )

      case 3:
        return (
          <m.Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {selectedOutputs?.parameters?.length > 0 && activeStep > 2 && (
              <>
                <m.Box>
                  <m.Typography noWrap className="ed-small padding-top-5">
                    Constants:
                  </m.Typography>
                </m.Box>
                &nbsp;
                <m.Grid container style={{ whiteSpace: "nowrap" }}>
                  {selectedOutputs?.parameters?.map((obj, index) => {
                    const findMatchingOutput =
                      emConfig?.calculator?.InputVariables?.find(
                        (otp) => otp?.ColumnLabel === obj.label
                      )
                    const unit = statusSatate.EMPTY_STATES.includes(
                      findMatchingOutput?.Units
                    )
                      ? ""
                      : findMatchingOutput.Units
                    return (
                      <m.Typography
                        key={`${obj.label}-${obj.value}-${unit}`}
                        noWrap
                        variant="body2"
                      >{`${statusSatate.roundValues(obj.value)} ${unit}${
                        selectedOutputs?.parameters?.length - 1 === index
                          ? ""
                          : ","
                      }`}</m.Typography>
                    )
                  })}
                </m.Grid>
              </>
            )}
          </m.Grid>
        )

      default:
        return
    }
  }

  const limitButton = (label) => {
    return (
      <div className="ml-display-flex ml-flex-dir-col">
        <div>
          <m.Typography variant="subtitle2">{label}</m.Typography>
        </div>
        <m.TextField
          sx={{ maxWidth: "100px" }}
          autoComplete="off"
          type="number"
          size="small"
          inputProps={{
            style: {
              padding: "2.5px 10px",
            },
          }}
          value={limitforLookup}
          onChange={(e) => setLimitForLookup(e.target.value)}
        />
      </div>
    )
  }

  const isFirstThreeSteps = lookup
    ? activeStep === 0
    : [0, 1, 2].includes(activeStep)
  const anyTypeContinous =
    emConfig?.calculator?.InputVariables?.find(
      (inpVar) => inpVar?.Domain?.Type === "continuous"
    ) ?? false

  return (
    <m.Box sx={{ width: "100%" }}>
      <m.Stepper
        nonLinear
        sx={{ alignItems: "flex-start" }}
        connector={<StyledStepConnector />}
        activeStep={activeStep}
      >
        {steps.map((step, index) => (
          <m.Step key={step} completed={completed[index]} id="mui-stepper">
            <m.StepLabel sx={{ alignItems: "flex-start" }} color="inherit">
              <m.Typography variant="body1">{step.label}</m.Typography>
            </m.StepLabel>
            {step.description && (
              <m.StepContent>
                <m.Typography variant="body2" className="ed-small">
                  {step.description}
                </m.Typography>
              </m.StepContent>
            )}
            {returnInformationText(index)}
          </m.Step>
        ))}
      </m.Stepper>
      <div>
        <m.Box sx={{ ml: "10px", mt: 2, p: 0 }}>
          {(steps[activeStep].label === steps[3]?.label && !lookup && (
            <>
              <file.InputComponent
                setAsVariable={false}
                generateRandomInputs={generateRandomInputs}
                setGenerateRandomInputs={setGenerateRandomInputs}
                setValidateOptimizeButton={setValidateOptimizeButton}
              />
            </>
          )) ||
            (steps[activeStep].label === steps[2]?.label &&
              (!lookup ? (
                <>
                  <file.InputComponent
                    setAsVariable={true}
                    generateRandomInputs={generateRandomInputs}
                    setGenerateRandomInputs={setGenerateRandomInputs}
                  />
                </>
              ) : (
                <m.Box>
                  <m.Box sx={{ display: "flex", justifyContent: "end" }}>
                    {commonButton(
                      false,
                      () =>
                        statusSatate.handleContextCopy(
                          JSON.stringify(lookupBody),
                          setAlertState,
                          "Instructions"
                        ),
                      "Copy to clipboard",
                      "Copy instruction object to clipboard"
                    )}
                  </m.Box>
                  <>
                    {loader ? (
                      <file.Loader />
                    ) : lookupResults.length > 0 ? (
                      <file.OutputsCard
                        finalRes={lookupResults}
                        fullView={"Exit"}
                        lookup={lookup}
                      />
                    ) : (
                      <Typography variant="body1">
                        No results for this request.
                      </Typography>
                    )}
                  </>
                </m.Box>
              ))) ||
            (steps[activeStep].label === steps[1]?.label && (
              <>
                <Suspense fallback={<file.Loader />}>
                  <OutputsForm
                    setErrorFields={setErrorFields}
                    setTestDetails={setTestDetails}
                    testDetails={testDetails}
                    testList={testList}
                    setTestList={setTestList}
                    updateTest={updateTest}
                    handleTestDialogClose={handleTestDialogClose}
                    addForm={addForm}
                    emptySubTestObject={emptySubTestObject}
                    setAddForm={setAddForm}
                    selectedLabel={selectedLabel}
                    index={index}
                    validationError={validationErrors}
                    setValidationErrors={setValidationErrors}
                    setSelectedLabel={setSelectedLabel}
                    isConstraints={true}
                    lookup={lookup}
                  />
                </Suspense>
              </>
            )) ||
            (steps[activeStep].label === steps[0]?.label && (
              <m.Grid
                item
                xs={12}
                md={6}
                className="ml-p-5 margin-top-20 ml-display-flex ml-space-between"
                sx={{ margin: "5px", alignItems: "start" }}
              >
                <OutputsCard
                  emulatorConfig={emConfig}
                  showDescription={true}
                  clickFunction={clickAndStoreOutput}
                />
              </m.Grid>
            )) ||
            (steps[activeStep].label === steps[4]?.label && (
              <file.ErrorBoundary>
                <file.ScatterGraph
                  activeStep={activeStep}
                  newRecord={newRecord}
                />
              </file.ErrorBoundary>
            ))}

          <m.Box sx={boxStyle}>
            <m.Box>
              {commonButton(
                activeStep === 0 ||
                  (activeStep === 4 && !selectedOutputs.objectives.length),
                handleBack,
                "Back"
              )}
            </m.Box>
            {activeStep === 4 ? (
              <m.Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: 0,
                  p: 0,
                }}
              >
                <m.Typography variant="h6">
                  {statusSatate.convertToUppercase(
                    graph?.scatterplot?.title,
                    "multiple"
                  )}
                </m.Typography>
              </m.Box>
            ) : (
              <m.Box sx={{ flex: "1 1 auto" }} />
            )}
            {activeStep === 1 && lookup && (
              <>
                <div className="ml-display-flex ml-flex-dir-col ml-align-center padding-right-10">
                  {limitButton("Limit")}
                  {(limitforLookup > 10 || limitforLookup < 1) && (
                    <m.Typography variant="body2" className="ed-small">
                      {`Limit can be <=10 and >=1`}
                    </m.Typography>
                  )}
                </div>
                {commonButton(
                  ((testDetails.mode === "" || testDetails.value === "") &&
                    testList.length === 0) ||
                    limitforLookup > 10 ||
                    limitforLookup < 1,
                  handleAddConstraint,
                  "Calculate Lookup Result",
                  "Click to view results"
                )}
              </>
            )}

            {isFirstThreeSteps && (
              <div className="ml-display-flex ml-flex-dir-col ml-align-center">
                {commonButton(
                  (activeStep === 0 && !selectedOutputs?.objectives?.length) ||
                    (activeStep === 2 &&
                      (!selectedOutputs?.variables?.length ||
                        findEmptyVariable)) ||
                    false,
                  activeStep === 1 ? handleAddConstraint : handleComplete,
                  "Next"
                )}
                {activeStep === 2 && !selectedOutputs?.variables?.length && (
                  <m.Typography variant="body2" className="ed-small">
                    {anyTypeContinous
                      ? "Please select at least one"
                      : "No Continuous Parameter is Available to Select as"}{" "}
                    Variable!
                  </m.Typography>
                )}
              </div>
            )}
            {/* {activeStep === 3 &&
              commonButton(
                disableRandomiseButton,
                handleViewData,
                "View existing data",
                "Get random set of inputs."
              )} */}
            {activeStep === 3 &&
              selectedOutputs?.variables?.length !==
                emConfig?.calculator?.InputVariables.length &&
              commonButton(
                false,
                handleRandoms,
                "Randomize Inputs",
                "Get random set of inputs."
              )}
            {activeStep === 3 &&
              commonButton(
                disableRandomiseButton,
                handleOptimization,
                "Optimize Output",
                "Click to view results"
              )}
            {activeStep === 4 && (
              <RefreshButtonStyling
                progress={progress}
                strokeDasharray={
                  buttonRef?.current?.getBoundingClientRect()?.width + 340 ||
                  350
                }
                height={
                  buttonRef?.current?.getBoundingClientRect()?.height + 16 || 40
                }
                width={
                  buttonRef?.current?.getBoundingClientRect()?.width + 16 || 200
                }
                isPolling={isPolling}
              >
                {commonButton(
                  false,
                  getResults,
                  `${
                    statusSatate.passFailedArray?.includes(graph?.status)
                      ? `Optimization Job ${graph?.status}`
                      : `Refreshing (${
                          graph?.status?.includes("%")
                            ? graph?.status + "complete"
                            : graph?.status
                        })`
                  }`,
                  graph?.status_message,
                  true,
                  statusSatate.passFailedArray?.includes(graph?.status) ||
                    graph?.status === "incomplete"
                )}
              </RefreshButtonStyling>
            )}
          </m.Box>
        </m.Box>
      </div>
      <file.ShowResult
        ViewResult={seeData}
        closeResult={handleCloseViewData}
        title={"View Filtered Data"}
        optimise={true}
      />
    </m.Box>
  )
}

export default OptimizationStepper
