import { useState } from "react"
import * as core from "@material-ui/core"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  authorization,
  editAlertBoxState,
  emulatorSpecificRole,
  handleKeyDown,
  siteWideRole,
} from "../../../state/projectState"
import { AlertBox } from "../../../GlobalFileContainer"

const Authorization = (props) => {
  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const siteWideRoleState = useRecoilValue(siteWideRole)

  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)
  const [openWarnDialog, setOpenWarnDialog] = useState(false)
  const processNameVar = props.processName ? props.processName : ""

  const checkAuthorization = () => {
    const checkSiteWide =
      processNameVar &&
      authorization &&
      siteWideRoleState &&
      authorization[processNameVar]?.includes(siteWideRoleState)
    const checkEmulatorSpecificRole =
      processNameVar &&
      authorization &&
      emulatorSpecificRoleState &&
      authorization[processNameVar]?.includes(emulatorSpecificRoleState)
    if (siteWideRoleState === "admin") {
      //if admin show all
      return true
    } else if (siteWideRoleState === "consumer") {
      //if new user with no role sitewide or emulatorwide
      return false
    } else if (
      siteWideRoleState !== "admin" &&
      emulatorSpecificRoleState !== "em"
    ) {
      //if user do not have sitewide role but have emulator role
      return checkEmulatorSpecificRole
    } else if (emulatorSpecificRoleState === "em") {
      //if user do not have emulator role but have sitewide role
      return checkSiteWide
    } else if (checkSiteWide === checkEmulatorSpecificRole) {
      //both conditions true then dont show icons and vice versa
      return checkSiteWide
    } else {
      //either of both conditions is false
      return true
    }
  }

  const style = {
    display:
      processNameVar === ""
        ? "inline"
        : checkAuthorization()
        ? "inline"
        : "none",
  }

  const openAlert = () => {
    setEditAlertBox({
      heading: "Alert!",
      content: `This emulator is currently ${props.role}. Are you sure you want to
          make changes here?`,
      multipleChoice: false,
    })
    setOpenWarnDialog(true)
  }

  const closeDialog = () => {
    setOpenWarnDialog(false)
  }

  if (props.callFunction) {
    return (
      <>
        <div
          onClick={
            props.forbidden.includes(props.role)
              ? openAlert
              : props.callFunction
          }
          style={style}
        >
          {props.children}
        </div>
        <core.Dialog
          onKeyDown={(e) => handleKeyDown(e, closeDialog)}
          open={openWarnDialog}
          maxWidth="md"
          onClose={closeDialog}
        >
          <AlertBox
            heading={editAlertBox.heading}
            content={editAlertBox.content}
            multipleChoice={editAlertBox.multipleChoice}
            closeDialog={closeDialog}
            callFunction={props.callFunction}
          />
        </core.Dialog>
      </>
    )
  }
  return <div style={style}>{props.children}</div>
}

export default Authorization
