import { Dialog } from "@material-ui/core"
import "./CalculatorConfig.css"
import { handleKeyDown } from "../../../state/projectState"

export default function VariableDialog(props) {
  if (!props.visible) {
    return <></>
  } else {
    return (
      <Dialog
        onKeyDown={(e) => handleKeyDown(e, props.setVisible)}
        className={`${props.givClass}`}
        open={true}
        maxWidth={"xl"}
        onClose={() => props.setVisible(false)}
      >
        {props.children}
      </Dialog>
    )
  }
}
