const useErrorPage = (page) => {
  const pageMessages = {
    "ML Evaluation": "at least one output",
    Calculator: "at least one input and one output",
    Tests: "at least one input and one output",
  }

  const defaultMessage = "variables under manage config page"

  const message = pageMessages[page] || defaultMessage

  return (
    <>
      There was no data returned to show the {page}, or there was an issue
      matching the data with the configs,
      <br /> Please add {message} to see the page.
    </>
  )
}

export default useErrorPage
