import { useMsal } from "@azure/msal-react"
import * as m from "@mui/material"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import "../App.css"

export const SignOutButton = () => {
  const { instance } = useMsal()
  const theme = m.useTheme()
  const buttonText = "Sign Out"

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    })
  }

  const signOutIcons = () => {
    return (
      <>
        <m.Button
          title={buttonText}
          sx={{
            color: theme.palette.common.black,
            textTransform: "none",
            display: { xs: "none", md: "flex" },
          }}
          onClick={handleLogout}
          startIcon={<ExitToAppIcon />}
        >
          <m.Typography variant="body2">{buttonText}</m.Typography>
        </m.Button>
        <m.MenuItem
          sx={{ display: { xs: "flex", md: "none" } }}
          onClick={handleLogout}
        >
          <m.Typography variant="body2">{buttonText}</m.Typography>
        </m.MenuItem>
      </>
    )
  }

  return <>{signOutIcons()}</>
}
