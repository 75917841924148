import { Typography } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import CircularProgress from "@mui/material/CircularProgress"
import { Stack } from "@mui/system"

function Loader(props) {
  if (props.linear) {
    return (
      <Stack marginTop={props.margin} sx={{ width: "100%" }}>
        <Typography textAlign={"center"}>{props.message}</Typography>
        <LinearProgress variant="indeterminate" />
      </Stack>
    )
  } else {
    return (
      <Stack
        alignContent={"center"}
        alignItems="center"
        justifyContent={"center"}
        marginTop={props.margin ? props.margin : "20%"}
        marginBottom={props.marginBot ? props.marginBot : ""}
        sx={{ height: "100%", width: "100%" }}
      >
        <CircularProgress
          sx={{ position: props.threed ? "absolute" : undefined }}
        />
      </Stack>
    )
  }
}

export default Loader
