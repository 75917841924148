import React, { useEffect } from "react"
import * as m from "@mui/material"
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import {
  findTrueModes,
  getEmulatorData,
  updateURLSearchParams,
} from "../../../state/projectState"
import { useSearchParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { RUN_TYPES, iconMappings } from "../../../state/StatusState"
import useStyles from "../../card_style"

export const getCustomIcon = (buttonType, mode) => {
  const iconSrc = iconMappings[buttonType]?.[mode]

  if (iconSrc) {
    return <img height={"45px"} src={iconSrc} alt={buttonType} />
  } else {
    return null
  }
}

export const ModeButton = ({ tryitMode, setTryItMode, pageOptimize }) => {
  const classes = useStyles()
  const [modeSearchParam] = useSearchParams()
  const emulatorConfig = useRecoilValue(getEmulatorData)
  const trueModes = useRecoilValue(findTrueModes)

  useEffect(() => {
    if (!pageOptimize) {
      if (
        emulatorConfig.modes?.length !== 0 &&
        (modeSearchParam?.size === 0 ||
          !emulatorConfig.modes?.includes(tryitMode))
      ) {
        const searchParams = Object.fromEntries(modeSearchParam.entries())

        trueModes?.includes(RUN_TYPES[0])
          ? (searchParams.mode = RUN_TYPES[0])
          : (searchParams.mode = emulatorConfig.modes[0])

        setTryItMode(searchParams.mode)
        updateURLSearchParams(searchParams)
      } else {
        if (trueModes?.includes(RUN_TYPES[0])) {
          setTryItMode(RUN_TYPES[0])
          updateURLSearchParams({ mode: RUN_TYPES[0] })
        } else {
          setTryItMode(emulatorConfig.modes[0])
          updateURLSearchParams({ mode: emulatorConfig.modes[0] })
        }
      }
    } else {
      if (trueModes?.includes(RUN_TYPES[0])) {
        setTryItMode(RUN_TYPES[0])
      } else setTryItMode(RUN_TYPES[2])
    }
  }, [trueModes])

  const getToggleIcon = (buttonType) => {
    if (emulatorConfig.modes?.includes(buttonType)) {
      return getCustomIcon(
        buttonType,
        tryitMode === buttonType ? "selected" : "inactive"
      )
    } else {
      return getCustomIcon(buttonType, "unavailable")
    }
  }

  const handleButtonName = (name) => {
    const params = new URLSearchParams(modeSearchParam)
    params.mode = name

    !pageOptimize && updateURLSearchParams(params)
    setTryItMode(name)
  }

  const getDisabledStatus = (type) => {
    if (pageOptimize) {
      return !trueModes?.includes(type)
    } else {
      return !emulatorConfig.modes?.includes(type)
    }
  }

  return (
    <m.Grid
      sx={{ minWidth: "120px", display: "flex", flexDirection: "column" }}
    >
      <ToggleButtonGroup
        color="primary"
        value={tryitMode}
        exclusive
        aria-label="text alignment"
      >
        {RUN_TYPES.map(
          (type) =>
            type !== "lookup" && (
              <ToggleButton
                key={type}
                value={type}
                className={classes.calculatorToggleBtn}
                disabled={() => getDisabledStatus(type)}
                title={`Select mode ${type}`}
                onClick={() => handleButtonName(type)}
              >
                {getToggleIcon(type)}
              </ToggleButton>
            )
        )}
      </ToggleButtonGroup>
      <m.Typography variant="body2" className="ed-small gutter-top">
        <b>Mode: </b>
        {tryitMode}
      </m.Typography>
    </m.Grid>
  )
}
