import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  card: {
    backgroundColor: "white",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  matrixCard: {
    backgroundColor: "white",
    width: "100%",
    height: "2000px",
    overflow: "auto",
  },
  chartCard: {
    height: "1200px",
  },
  cardPaddingTop: {
    paddingTop: "56.25%", // 16:9
  },
  cardMedia: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  cardContent: {
    // height: "1000px",
    maxHeight: "2000px",
    overflow: "auto",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  scatterplot: {
    height: "300px",
    width: "300px",
  },
  calculatorBtnGroup: {
    display: "flex",
    marginTop: "10px",
  },
  calculatorTypo: {
    color: "grey",
    minHeight: "40px",
    display: "flex",
    alignContent: "center",
    flexWrap: "wrap",
  },
  calcCardMargin: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "0.1rem",
    width: "100%",
  },
  // buttonLabel: {
  //   [theme.breakpoints.down("xs")]: {
  //     fontSize: "10px",
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "0.8rem",
  //   },
  // },
  menuStyling: {
    fontSize: "12px",
    padding: "0px 20px 2px 0px !important",
    "& .MuiMenuItem-root": {
      padding: "2px",
    },
    "& .MuiList-root": {
      padding: "2px",
    },
  },
  calcMainCard: {
    padding: "8px 16px !important",
  },
  inputBadge: {
    backgroundColor: "rgb(57, 194, 215)",
  },
  outputBadge: {
    backgroundColor: "rgb(235, 206, 48)",
  },
  chipStyle: {
    height: "auto !important",
    marginBottom: "2px",
    "& .MuiChip-label": {
      padding: "0 7px 0 5px",
      display: "block",
      whiteSpace: "normal",
    },
  },
  calculatorToggleBtn: {
    padding: "0px !important",
  },
  calcGalleryDialog: {
    overflowY: "hidden !important",
  },
  themeTypo: {
    fontFamily: theme.typography.fontFamily,
  },
  backgroundPrimaryMain: {
    backgroundColor: theme.palette.primary.main,
  },
  backgroundPrimaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  backgroundWhite: {
    backgroundColor: theme.palette.common.white,
  },
  bgDangerIcon: {
    backgroundColor: theme.palette.status.danger,
  },
  colorPrimaryMain: {
    color: theme.palette.primary.main,
  },
  bgSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  colorSecondaryLight: {
    color: theme.palette.secondary.light,
  },
  colorGraySec: {
    color: theme.palette.grey.secondaryLight,
  },
  colorGrayLight: {
    color: theme.palette.grey.light,
  },
  colorWhiteIcon: {
    color: theme.palette.common.white,
  },
  colorDangerIcon: {
    color: theme.palette.status.danger,
  },
  colorLightDangerIcon: {
    color: theme.palette.common.pink,
  },
  bgLightDangerIcon: {
    backgroundColor: theme.palette.common.pink,
  },
  bgPastelIcon: {
    backgroundColor: theme.palette.common.pastel,
  },
  bgLightPink: {
    backgroundColor: theme.palette.common.lightPink,
  },
  colorSuccessIcon: {
    color: theme.palette.status.success,
  },
  colorBlack: {
    color: theme.palette.common.black,
  },
  colorAnnotation: {
    backgroundColor: "#e0e0e0",
  },
  algoVertical: {
    writingMode: "vertical-rl",
    color: theme.palette.grey.secondaryLight,
    fontStyle: "italic",
    textAlign: "left",
    transform: "scale(-1)",
    "-moz-transform": "scale(-1)",
    "-webkit-transform": "scale(-1)",
    "-o-transform": "scale(-1)",
    "-ms-transform": "scale(-1)",
    transition: "color 0.3s",
    "&:hover": {
      color: theme.palette.common.black,
    },
  },
  leftsidebox: {
    margin: "5px",
    width: "30px",
    height: "200px",
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.common.black}`,
  },
  uploadButton: {
    padding: "10px 0",
    border: `2px dashed ${theme.palette.grey.secondaryLight}`,
    marginBottom: "20px",
    width: "100%",
    backgroundColor: theme.palette.common.lightGrey,
  },
  highlight: {
    backgroundColor: theme.palette.common.lightGrey,
  },
  progressCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))

export default useStyles
