import { NavLink, useNavigate } from "react-router-dom"
import * as m from "@mui/material"
import "./navContent.css"
import { useWindowDimensions } from "../../GlobalFileContainer"
import useStyles from "../card_style"
import { classNameString } from "./ExploreNav"

const CommonMobileNav = ({ navOptions, menuItem, setMenuItem }) => {
  const { width } = useWindowDimensions()
  const navigate = useNavigate()
  const classes = useStyles()

  const handleMenuChange = (e) => {
    setMenuItem(e.target.value)
  }

  const handleRouteChange = (route) => {
    navigate(route)
  }

  return (
    <ul className="gl-display-flex gl-flex-grow-1 gl-border-none nav gl-tabs-nav">
      {width > 650 ? (
        navOptions.map((navOpt, i) => (
          <m.Typography
            variant="body1"
            key={`${navOpt.to}-${navOpt.group}-${i}`}
          >
            <li className="nav-item">
              <NavLink
                className={(isActive) => classNameString(isActive)}
                onClick={() => setMenuItem(navOpt?.buttonText ?? navOpt.title)}
                to={navOpt.to}
              >
                {navOpt?.buttonText ?? navOpt.title}
              </NavLink>
            </li>
          </m.Typography>
        ))
      ) : (
        <li className="nav-item" key={`only-one-list-element`}>
          <m.FormControl
            size="small"
            fullWidth
            sx={{
              fontSize: "0.75rem",
            }}
          >
            <m.Select
              sx={{
                fontSize: "small",
                textAlign: "center",
                width: "97%",
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              value={menuItem}
              onChange={handleMenuChange}
            >
              {navOptions.map((navOpt, i) => (
                <m.MenuItem
                  className={classes.themeTypo}
                  key={`${navOpt.to}-${navOpt.group}-${i}`}
                  value={navOpt?.buttonText ?? navOpt.title}
                  onClick={() => handleRouteChange(navOpt.to)}
                >
                  {navOpt?.buttonText ?? navOpt.title}
                </m.MenuItem>
              ))}
            </m.Select>
          </m.FormControl>
        </li>
      )}
    </ul>
  )
}

export default CommonMobileNav
