import { Card, CardContent } from "@material-ui/core"
import { Results } from "../../../GlobalFileContainer"
import useStyles from "../../card_style"
import { Box } from "@mui/material"

function CalculatorOutput(props) {
  const classes = useStyles()
  const returnResult = () => {
    return (
      <Results
        outputResponse={props.outputResponse}
        inputResponse={props.inputResponse}
        loader={props.loader}
        jobResponses={props.jobResponses}
        setLoader={props.setLoader}
        currentIndex={props.currentIndex}
        setCurrentIndex={props.setCurrentIndex}
        threeRes={props.threeRes}
        isModalObject={props.isModalObject}
        setDisableKeys={props.setDisableKeys}
        disableKeys={props.disableKeys}
      />
    )
  }

  return props.threeRes ? (
    <Box sx={{ padding: "10px" }}>{returnResult()}</Box>
  ) : (
    <Card>
      <CardContent className={classes.calcMainCard}>
        {returnResult()}
      </CardContent>
    </Card>
  )
}

export default CalculatorOutput
