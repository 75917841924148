import { useRecoilValue } from "recoil"
import {
  getGraphResult,
  outputsParametersAndVariables,
} from "../../state/projectState"
import { getTransformedData, roundValues } from "../../state/StatusState"

function LineLayer({ xScale, yScale }) {
  const graph = useRecoilValue(getGraphResult)

  const lineColor = "steelblue"
  const chartData = graph?.stream?.data
  const transformedData = getTransformedData(chartData)

  const dataForLine = transformedData.map((data, i) => {
    return { x: i, value: data.minimum }
  })

  const pathData = dataForLine
    .map((point, index, array) => {
      if (index < array.length - 1) {
        return `L ${xScale(point.x)} ${yScale(point.value)} L ${xScale(
          point.x + 1
        )} ${yScale(point.value)}`
      }
    })
    .join(" ")

  return (
    <>
      <path
        d={
          dataForLine?.length
            ? `M ${xScale(dataForLine[0].x)} ${yScale(
                dataForLine[0].value
              )} ${pathData}`
            : undefined
        }
        fill="none"
        stroke={lineColor}
        strokeWidth={4}
      />
    </>
  )
}

function BestLayer({ xScale, yScale }) {
  const graph = useRecoilValue(getGraphResult)
  const outputsParametersAndVariablesState = useRecoilValue(
    outputsParametersAndVariables
  )

  const lineColor = "green"
  const chartData = graph?.stream?.data
  const transformedData = getTransformedData(chartData)
  const bestResultValue = outputsParametersAndVariablesState?.bestResult

  const dataForLine = transformedData.map((data, i) => {
    return { x: i, value: bestResultValue }
  })

  return (
    bestResultValue &&
    dataForLine?.length && (
      <>
        <text x="-40" y={yScale(bestResultValue) + 5} fill="red" fontSize="15">
          {roundValues(bestResultValue)}
        </text>
        <path
          d={
            dataForLine?.length
              ? `M ${xScale(dataForLine[0].x)} ${yScale(
                  dataForLine[0].value
                )} L ${xScale(dataForLine[0].x)} ${yScale(
                  dataForLine[0].value
                )} L ${xScale(dataForLine[dataForLine.length - 1].x)} ${yScale(
                  dataForLine[dataForLine.length - 1].value
                )}`
              : undefined
          }
          fill="none"
          stroke={lineColor}
          strokeWidth={2}
        />
      </>
    )
  )
}

export { LineLayer, BestLayer }
