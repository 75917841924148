import React, { Component } from "react"
import Histoslider from "histoslider"

export default class HistosliderContainer extends Component {
  state = {
    selection: [0, this.props.currentMax],
  }
  setSelection = (selection) => {
    this.setState({ selection })
    this.props.stateSetter(Math.round(selection[1], 0))
  }
  render = () => (
    <Histoslider
      // An array of data to show on the slider
      data={this.props.data}
      // A function to handle a change in the selection
      selection={this.state.selection}
      onChange={this.setSelection}
      selectedColor="#39c2d7"
      {...this.props}
    />
  )
}
