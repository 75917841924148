import { useState } from "react"
import * as core from "@material-ui/core"
import { Stack, Typography } from "@mui/material"
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone"
import "./navContent.css"
import { CreateNewEmulator } from "../../GlobalFileContainer"
import useStyles from "../card_style"

export default function NewEmulatorButton() {
  const classes = useStyles()
  const [handleCreate, setHandleCreate] = useState(false)

  return (
    <core.Card className="emu-row__card">
      <core.Button
        className="addEmulatorbtnstyle"
        onClick={() => setHandleCreate(true)}
      >
        <Stack alignItems="center" justify="center" direction="column">
          <core.Typography>
            <AddCircleTwoToneIcon
              color="primary"
              style={{ fontSize: "3rem" }}
            />
          </core.Typography>
          <Typography variant="caption" className={classes.themeTypo}>
            CREATE NEW EMULATOR
          </Typography>
        </Stack>
      </core.Button>
      {handleCreate && (
        <CreateNewEmulator
          closeCreate={setHandleCreate}
          trigger={handleCreate}
        />
      )}
    </core.Card>
  )
}
