import { useEffect, useRef, useState, Suspense } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useNavigate } from "react-router-dom"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { Typography } from "@mui/material"
import _ from "lodash"
import {
  Loader,
  EmulationLab,
  ErrorBoundary,
  useNavigatePath,
  PosterGridSkeleton,
  EmulatorSelectionCard,
} from "../GlobalFileContainer"
import { cleanReturnJson } from "../state/axios"
import {
  cardFilterSearchStringState,
  emulatorStatus,
  searchBlobData,
  siteWideRole,
} from "../state/projectState"
import "./Row.css"
import { mongoProfileState } from "../state/userState"
import NewEmulatorButton from "./navContent/NewEmulatorButton"
import { fetchSearchBlobAPI } from "../state/requests"
import EmulatorSelectionCardConsumer from "./EmulatorSelectionCardConsumer"

const initialSorter = (a, b) => {
  const nameA = a.numUses
  const nameB = b.numUses
  return nameA < nameB ? 1 : nameA > nameB ? -1 : 0
}

function PosterGrid({
  fetchUrl,
  appliedFilters,
  cards,
  setCards,
  isLoading,
  setIsLoading,
}) {
  const ref = useRef(null)
  const navigate = useNavigate()
  const path = localStorage.getItem("path")

  const [searchBlob, setSearchBlob] = useRecoilState(searchBlobData)
  const [mongoProfile, setMongoProfile] = useRecoilState(mongoProfileState)
  const cardsNameFilter = useRecoilValue(cardFilterSearchStringState)
  const setEmulatorStatusState = useSetRecoilState(emulatorStatus)
  const siteWideRoleState = useRecoilValue(siteWideRole)

  const [searchResultKeys, setSearchResultKeys] = useState([])
  const [serachedCards, setSearchedCards] = useState(cards)

  useEffect(() => {
    if (path) {
      const redesignedPath = useNavigatePath(path)
      localStorage.removeItem("path")
      redesignedPath !== null && navigate(redesignedPath)
    }
    setEmulatorStatusState("")
  }, [])

  useEffect(() => {
    setIsLoading(true)

    fetchData()
  }, [fetchUrl])

  useEffect(() => {
    searchBlob.length === 0 && fetchSearchBlob()
  }, [])

  useEffect(() => {
    const filteredData = cards?.filter((item) => {
      const isInSearchResults = searchResultKeys.includes(item.id)

      const isInCategory =
        appliedFilters.categoryList.length === 0 ||
        appliedFilters.categoryList.includes(item.category)

      const isInMode =
        appliedFilters.modeList.length === 0 ||
        appliedFilters.modeList.every((mode) =>
          item.modes.includes(mode?.toLowerCase())
        )

      const isInTag =
        appliedFilters.tagList.length === 0 ||
        appliedFilters.tagList.every((tag) => item.tags.includes(tag))

      const isInStatus =
        appliedFilters.statusList.length === 0 ||
        appliedFilters.statusList.includes(item.status)

      const isInMember =
        appliedFilters.memberList.length === 0 ||
        appliedFilters.memberList.includes(item.team.director.name) ||
        item.team.members.some((mb) =>
          appliedFilters.memberList.includes(mb.name)
        )

      return (
        isInSearchResults &&
        isInCategory &&
        isInMode &&
        isInTag &&
        isInStatus &&
        isInMember
      )
    })

    setSearchedCards(filteredData)
  }, [appliedFilters, searchResultKeys, cards])

  useEffect(() => {
    const { others } = appliedFilters

    let filteredKeys =
      others.length > 0
        ? _.filter(Object.keys(searchBlob), (key) => {
            const searchValue = _.toLower(searchBlob[key])
            return others.every((item) =>
              _.includes(searchValue, _.toLower(item))
            )
          })
        : Object.keys(searchBlob)

    filteredKeys = cardsNameFilter
      ? _.filter(filteredKeys, (key) =>
          _.includes(_.toLower(searchBlob[key]), _.toLower(cardsNameFilter))
        )
      : filteredKeys

    setSearchResultKeys(filteredKeys)
  }, [searchBlob, cardsNameFilter, JSON.stringify(appliedFilters.others)])

  // useEffect(() => {
  //   let t0 = performance.now()
  //   cards
  //     ? setFilteredCards(
  //         _.filter(cards, (object) =>
  //           _.some(
  //             object,
  //             (value) =>
  //               _.isString(value) &&
  //               _.includes(_.toLower(value), _.toLower(cardsNameFilter))
  //           )
  //         )
  //       )
  //     : setFilteredCards([])
  //   let t1 = performance.now()
  //   console.log(
  //     "Time taken to execute search function:" + (t1 - t0) + " milliseconds"
  //   )
  // }, [cards, cardsNameFilter])

  //for setting filtered cards when user clicks on checkbox or lands on solution mode page

  const commonNoteforNoEmulator = (emulator) => (
    <div>
      <Typography variant="h5" className="Loader-heading">
        There are no {emulator} in the requested group. Please select a
        different group or change search criteria.
      </Typography>
    </div>
  )

  const fetchData = async () => {
    const request = await EmulationLab.get(fetchUrl, {
      headers: {
        severity: SeverityLevel.Warning,
      },
    })
    const requestData = request?.data || []
    const cleanedData = cleanReturnJson(requestData)
    const sortedData = Array.isArray(cleanedData)
      ? cleanedData.sort(initialSorter)
      : []
    setCards(sortedData)
    setIsLoading(false)
    return request
  }

  const fetchSearchBlob = async () => {
    try {
      const request = await EmulationLab.get(fetchSearchBlobAPI)
      setSearchBlob(request.data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Suspense fallback={<Loader />}>
        <div>
          <div className="ml-display-flex grid__posters" ref={ref}>
            <>
              {siteWideRoleState !== "consumer" && <NewEmulatorButton />}
              {isLoading
                ? [...Array(20)].map((e, i) => (
                    <span className="skleton-span" key={`${e}_${i}`}>
                      <PosterGridSkeleton />
                    </span>
                  ))
                : serachedCards?.length > 0
                ? serachedCards.map((card) => {
                    return (
                      <ErrorBoundary key={card.id} sideComp>
                        {siteWideRoleState === "consumer" ? (
                          <EmulatorSelectionCardConsumer
                            key={card.id}
                            emulator={card}
                            mongoProfile={mongoProfile}
                            setMongoProfile={setMongoProfile}
                            searchResultKeys={searchResultKeys}
                            fetchData={fetchData}
                          />
                        ) : (
                          <EmulatorSelectionCard
                            key={card.id}
                            emulator={card}
                            mongoProfile={mongoProfile}
                            searchResultKeys={searchResultKeys}
                            fetchData={fetchData}
                          />
                        )}
                      </ErrorBoundary>
                    )
                  })
                : commonNoteforNoEmulator("emulators")}
            </>
          </div>
        </div>
      </Suspense>
    </>
  )
}

export default PosterGrid
