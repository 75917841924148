import { useEffect, useMemo, useRef, useState } from "react"
import * as core from "@material-ui/core"
import { Stage, Image, Layer } from "react-konva"
import { useRecoilState, useRecoilValue } from "recoil"
import { useParams } from "react-router-dom"
import { ChipRenderer, convertPosition } from "./ImageViewer"
import {
  chipPositionState,
  fetchAnnotationsState,
  getEmulatorData,
  imageIndex,
  imageLoader,
  imagesState,
} from "../../state/projectState"
import { EmulationLab, Loader } from "../../GlobalFileContainer"
import { useTheme } from "@mui/material"

const KonvaImageStage = (props) => {
  const containerRef = useRef(null)
  const stageRef = useRef(null)
  const imageRef = useRef(null)
  const { emulatorId } = useParams()
  const theme = useTheme()

  const [isFetchingAnnotations, setIsFetchingAnnotations] = useRecoilState(
    fetchAnnotationsState
  )
  const [isLoading, setIsLoading] = useRecoilState(imageLoader)
  const index = useRecoilValue(imageIndex)
  const images = useRecoilValue(imagesState)
  const emulatorConfig = useRecoilValue(getEmulatorData)
  const [chipPosition, setChipPosition] = useRecoilState(chipPositionState)

  const [containerSize, setContainerSize] = useState({})
  const [konvaImageElement, setKonvaImageElement] = useState()
  const [imageScaleFactor, setImageScaleFactor] = useState()
  const [renderedSize, setRenderedSize] = useState({})
  const saveAnnotationURL = `/emulators/${emulatorId}/images/annotations`

  useEffect(() => {
    if (isFetchingAnnotations && chipPosition.length === 0 && index !== -1) {
      getAnnotations()
    }
  }, [chipPosition, index, images])

  const getAnnotations = async () => {
    try {
      setIsLoading(true)
      const res = await EmulationLab.get(saveAnnotationURL)

      if (res && index !== -1) {
        var imageSpecificResponse = res?.data.filter(
          (item) => item?.image_id === images[index]?.id
        )
        var modifiedArray = imageSpecificResponse?.map((annotation) => {
          return {
            id: annotation.id,
            content: annotation.content,
            type: annotation.type,
            position: { u: annotation.u, v: annotation.v },
            color:
              annotation.type === "input"
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
          }
        })
        setIsFetchingAnnotations(false)
        setChipPosition(modifiedArray)
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [containerRef.current?.offsetWidth])

  useEffect(() => {
    if (isLoading) {
      handleResize()
    }
  }, [containerSize])

  useEffect(() => {
    findSize()
  }, [containerSize, index, images])

  const handleResize = () => {
    setContainerSize({
      width: containerRef.current.offsetWidth,
      height: containerRef.current.offsetHeight,
    })
  }

  const imageElement = useMemo(() => {
    const element = new window.Image()
    element.width = renderedSize?.width
    element.height = renderedSize?.height
    element.scaleX = imageScaleFactor
    element.scaleY = imageScaleFactor
    element.src = images[index]?.image
    return element
  }, [renderedSize, images?.length])

  useEffect(() => {
    setKonvaImageElement(imageElement)
    imageRef.current = imageElement
  }, [imageElement])

  const findSize = async () => {
    const imageSize = {
      height: images[index]?.height,
      width: images[index]?.width,
    }
    const widthScale = containerSize.width / imageSize.width
    const heightScale = containerSize.height / imageSize.height

    const scale = Math.min(widthScale, heightScale)
    if (!Number.isNaN(scale)) {
      setImageScaleFactor(scale)
      setRenderedSize({
        height: imageSize.height * scale,
        width: imageSize.width * scale,
      })
    }
  }

  const saveAnnotations = async (data) => {
    try {
      const res = await EmulationLab.put(saveAnnotationURL, data)
      return res.data
    } catch (error) {
      console.error(error)
    }
  }

  const handleDrop = async (e) => {
    e.preventDefault()
    stageRef.current.setPointersPositions(e)
    const pointerPosition = stageRef.current.getPointerPosition()
    const type = emulatorConfig.calculator.InputVariables.find(
      (vars) => vars.Name === props.chiplabel
    )
      ? "input"
      : "output"
    const pointer = convertPosition(
      pointerPosition?.x,
      pointerPosition?.y,
      renderedSize
    )
    const dataUpload = {
      image_id: images[index].id,
      image_url: images[index].image,
      type: type,
      content: props.chiplabel,
      u: pointer.u,
      v: pointer.v,
    }

    if (props.chiplabel) {
      try {
        const response = await saveAnnotations(dataUpload)

        if (response && pointerPosition) {
          setChipPosition((prev) => [
            ...prev,
            {
              id: response,
              content: props.chiplabel,
              type: type,
              position: pointer,
              color:
                type === "input"
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
            },
          ])
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <core.Box
        ref={containerRef}
        className={`ml-display-flex  ml-align-center ml-justify-center width-100 ${
          props.displayIn === "calc"
            ? "img-prevsize-calc "
            : "img-prevsize-gall "
        } ${isLoading ? "opaque" : ""}`}
      >
        {!props.mainLoader ? (
          <core.Box>
            {Object.keys(renderedSize).length !== 0 && (
              <core.Box
                sx={{ height: renderedSize.height, width: renderedSize.width }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <Stage
                  ref={stageRef}
                  width={renderedSize.width}
                  height={renderedSize.height}
                >
                  <Layer>
                    <Image ref={imageRef} image={konvaImageElement} />
                    {chipPosition?.map((item, id) => {
                      return (
                        <ChipRenderer
                          key={`${item}-${id}`}
                          id={id}
                          item={item}
                          size={renderedSize}
                          setChipPosition={setChipPosition}
                          disableComponent={props.disableComponent}
                          displayIn={props.displayIn}
                        />
                      )
                    })}
                  </Layer>
                </Stage>
              </core.Box>
            )}
          </core.Box>
        ) : (
          <Loader />
        )}
      </core.Box>
    </>
  )
}

export default KonvaImageStage
