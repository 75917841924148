import { useDropzone } from "react-dropzone"

const useCustomDropzone = (callback, allowedFileExtensions) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      (allowedFileExtensions === "csv" && { "text/csv": [".csv"] }) ||
      (allowedFileExtensions === "xlsx,xlsm,xls,mcdx,gh,ghx,py" && {
        "text/xlsx": [".xlsx", ".xlsm", ".mcdx", ".gh", ".ghx", ".xls", ".py"],
      }) ||
      (allowedFileExtensions === "csv,PIPELINE" && {
        "text/csv": [".PIPELINE", ".csv"],
      }),
    onDrop: callback,
  })

  return { getRootProps, getInputProps }
}

export default useCustomDropzone
