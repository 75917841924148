import { useEffect, useState } from "react"
import * as m from "@mui/material"
import { ResponsiveParallelCoordinates } from "@nivo/parallel-coordinates"
import { useRecoilValue } from "recoil"
import { useEmulatorVariablesByColumnLabel } from "../../GlobalFileContainer"
import { tabulatorFilteredDataState } from "../../state/dataState"
import { nivoTheme } from "../../charts/nivoTheme"
import { showNameWithUnits } from "./Tabulator"
import { getUnitList } from "../../state/projectState"

const ParallelCoordinatesNivoFiltered = ({
  setSelectedColumn,
  calculator,
  setLoader,
}) => {
  const dataPromises = useRecoilValue(tabulatorFilteredDataState)
  const uniqueList = useRecoilValue(getUnitList)

  const [dataSlice, setDataSlice] = useState([])
  const [domainStructure, setDomainStructure] = useState({})
  const [selectedCols, setSelectedCols] = useState([])
  const [colList, setColList] = useState([])
  const [selectCol, setSelectCol] = useState(false)

  const [emVarsDict] = useEmulatorVariablesByColumnLabel(calculator)
  const sliceLength = 100

  useEffect(() => {
    if (selectCol || !dataPromises || dataPromises.length === 0) {
      setDataSlice([])
      setDomainStructure({})
      return
    } else {
      getRandomSlice(dataPromises?.length > 0 ? dataPromises : [], sliceLength)
      Promise.resolve(dataPromises[0]).then((datarow) => {
        let tempDomainStructure = Object.keys(datarow)
          .filter((dtrow) => dtrow !== "id")
          .map((colLabel) => {
            try {
              emVarsDict[colLabel].Type
            } catch (e) {
              console.log(`Configs doesn't include ${colLabel}`, {
                emVarsDict,
              })
              return
            }
            if (emVarsDict[colLabel]) {
              const isLinear = Number(datarow[colLabel])
              return {
                key: colLabel,
                type: isLinear ? "linear" : "point",
                legend: showNameWithUnits(
                  emVarsDict,
                  colLabel,
                  false,
                  uniqueList
                ),
                legendPosition: "middle",
                legendOffset: -20,
              }
            }
          })
        const filteredTemp = tempDomainStructure.filter((x) => x)

        const labelPositions = {}
        const inputLabels = calculator.InputVariables.map(
          (input) => input.ColumnLabel
        )
        const outputLabels = calculator.OutputVariables.map(
          (output) => output.ColumnLabel
        )

        inputLabels.forEach((label, index) => {
          labelPositions[label] = index
        })

        outputLabels.forEach((label, index) => {
          labelPositions[label] = inputLabels.length + index
        })

        filteredTemp.sort((a, b) => {
          const posA = labelPositions[a.key]
          const posB = labelPositions[b.key]

          if (posA === undefined || posB === undefined) {
            console.error(
              `Undefined position: ${a.key} -> ${posA}, ${b.key} -> ${posB}`
            )
            return 0
          }

          return posA - posB
        })
        setDomainStructure(filteredTemp)
      })
    }
  }, [dataPromises, sliceLength])

  useEffect(() => {
    if (Array.isArray(domainStructure)) {
      if (selectedCols.length === 0) {
        setSelectedCols(domainStructure.map((x) => x.legend))
      }
      setColList(domainStructure.map((x) => x.legend))
    }
  }, [domainStructure])

  useEffect(() => {
    if (Array.isArray(selectedCols)) {
      setLoader(true)
      setSelectedColumn(selectedCols)
    }
  }, [selectedCols])

  const getRandomSlice = (states, sliceLength) => {
    const indexes = states?.map((el, i) => i)
    const shuffledIndexes = indexes.sort(() => 0.5 - Math.random())
    const selectedIndexes = shuffledIndexes.slice(0, sliceLength)
    const dataPromisesSlice = selectedIndexes.map((i) => states[i])

    Promise.all(dataPromisesSlice).then((values) => {
      Object.keys(emVarsDict).forEach((key) => {
        if (["categorical", "text"].includes(emVarsDict[key].Type)) {
          values.forEach((val, index) => {
            values = values
              .sort((a, b) => {
                const domainOptions = emVarsDict[key].Domain?.Options
                const indexA = domainOptions && domainOptions?.indexOf(a[key])
                const indexB = domainOptions && domainOptions?.indexOf(b[key])
                return indexA - indexB
              })
              ?.reverse()
          })
        }
      })
      setDataSlice(values)
    })
  }

  const handleColumnSelectionChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedCols(typeof value === "string" ? value.split(",") : value)
  }

  if (dataSlice.length === 0) return <></>

  return (
    <div className="nivofilterwrapper">
      <div className="showhidecolumn">
        <m.Button
          onClick={() => {
            getRandomSlice(dataPromises, sliceLength)
          }}
        >
          <m.Typography variant="caption">
            Get New Random Slice of Data
          </m.Typography>
        </m.Button>
        {!selectCol && (
          <m.Button
            onClick={() => {
              setSelectCol(true)
            }}
          >
            <m.Typography variant="caption">Show/Hide Columns</m.Typography>
          </m.Button>
        )}
        {selectCol && (
          <div className="userform-main-grid">
            <m.FormControl sx={{ m: 0, width: 300 }} size="small">
              <m.Select
                multiple
                displayEmpty
                value={selectedCols}
                onChange={handleColumnSelectionChange}
                input={<m.OutlinedInput />}
                renderValue={() => {
                  return <em>Show/Hide Columns</em>
                }}
                open={true}
                onClose={() => setSelectCol(false)}
              >
                {colList.map((name) => (
                  <m.MenuItem key={name} value={name}>
                    <m.Checkbox checked={selectedCols.indexOf(name) > -1} />
                    <m.ListItemText primary={name} />
                  </m.MenuItem>
                ))}
              </m.Select>
            </m.FormControl>
          </div>
        )}
      </div>
      <ResponsiveParallelCoordinates
        data={dataSlice}
        theme={nivoTheme}
        variables={domainStructure.filter((ds) =>
          selectedCols.includes(ds.legend)
        )}
        margin={{ top: 20, right: 60, bottom: 80, left: 60 }}
        colors={{ scheme: "purple_blue" }}
        strokeWidth={1}
        lineOpacity={0.3}
        pixelRatio={2}
        animate={false}
      />
    </div>
  )
}
export default ParallelCoordinatesNivoFiltered
