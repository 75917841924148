import * as core from "@material-ui/core"
import rank1 from "../../assets/rank1.png"
import rank2 from "../../assets/rank2.png"
import rank3 from "../../assets/rank3.png"
import rank4 from "../../assets/rank4.png"
import useStyles from "../card_style"
import { Typography } from "@mui/material"
const Rankhelp = ({ height, color, thickness, dashWidth, dashGap }) => {
  const style = {
    margin: "5px",
  }
  const classes = useStyles()
  return (
    <core.Grid className="ml-display-flex ml-flex-dir-row">
      <core.Box className="margin-right-10 ml-display-flex ml-flex-dir-col ml-align-center">
        <div className="rankheading">
          <Typography variant="h6">Rank</Typography>
        </div>
        <div style={style}>1</div>
        <div style={style}>2</div>
        <div style={style}>3</div>
        <svg height={height} width={thickness}>
          <path
            d={`M ${thickness / 2} 0 v ${height}`}
            stroke={color}
            strokeWidth={thickness}
            strokeDasharray={`${dashWidth}, ${dashGap}`}
          />
        </svg>
        <div style={style}>n</div>
      </core.Box>
      <core.Box className="rankbox ml-display-flex ml-flex-dir-col ml-space-between">
        <core.Box
          className={`${classes.leftsidebox} ${classes.backgroundPrimaryMain} margin-top-20`}
        >
          <core.Typography variant="body2" id="inclinedheadings">
            No severe errors during testing.
          </core.Typography>
        </core.Box>
        <core.Box>
          <core.Typography variant="body2" id="mui-typo">
            acceptable
          </core.Typography>
        </core.Box>
        <core.Box
          className={`${classes.leftsidebox} ${classes.bgLightDangerIcon} margin-top-20`}
        >
          <core.Typography variant="body2" id="inclinedheadings">
            Severe errors during testing.
          </core.Typography>
        </core.Box>
        <core.Box>
          <core.Typography
            variant="body2"
            id="mui-typo"
            style={{ marginTop: "15px" }}
          >
            most severe
          </core.Typography>
        </core.Box>
      </core.Box>
      <core.Box className="ml-display-flex ml-align-center ml-flex-dir-col">
        <div style={{ marginTop: "10px" }}>
          <Typography variant="body2">best</Typography>
        </div>
        <div className="labelrank">
          <Typography variant="body2">severe</Typography>
        </div>
      </core.Box>
      <core.Box className="ml-display-flex ml-align-center ml-flex-dir-col">
        <core.Box className="arrowwrap margin-right-10">
          <div className="line-with-arrows"></div>
        </core.Box>
        <core.Box className="newranklabel margin-right-10">
          <div className="line-with-arrows"></div>
        </core.Box>
      </core.Box>
      <core.Box className="ml-display-flex ml-align-center ml-flex-dir-col">
        <core.Grid
          container
          sx={{ flexDirection: { xs: "row", md: "column" } }}
        >
          <img src={rank1} alt="rank1" className="rankimg" />
          <core.Box className="typogwrap">
            <core.Typography variant="body1" id="mui-typo">
              High concentration near zero error, and most errors in comfort
              zone.
              <br /> <b>No severe errors.</b>
            </core.Typography>
          </core.Box>
        </core.Grid>
        <core.Grid
          container
          sx={{ flexDirection: { xs: "row", md: "column" } }}
        >
          <img src={rank2} alt="rank1" className="rankimg" />
          <core.Box className="typogwrap">
            <core.Typography variant="body1" id="mui-typo">
              High proportion of errors in comfort zone.
              <br /> <b>No severe errors.</b>
            </core.Typography>
          </core.Box>
        </core.Grid>
        <core.Grid
          container
          sx={{ flexDirection: { xs: "row", md: "column" } }}
        >
          <img src={rank3} alt="rank1" className="rankimg" />
          <core.Box className="typogwrap">
            <core.Typography variant="body1" id="mui-typo">
              Presence of at least <b>one severe error</b> during testing.
            </core.Typography>
          </core.Box>
        </core.Grid>
        <core.Grid
          container
          sx={{ flexDirection: { xs: "row", md: "column" } }}
        >
          <img src={rank4} alt="rank1" className="rankimg" />
          <core.Box className="typogwrap">
            <core.Typography variant="body1" id="mui-typo">
              <b>Most severe errors </b>in magnitude and/or likelihood.
            </core.Typography>
          </core.Box>
        </core.Grid>
      </core.Box>
    </core.Grid>
  )
}

export default Rankhelp
