import imageNone from "./0.png"
import imageNoneBr from "./0bo.png"
import imageNoneBu from "./0bu.png"
import imageNoneI from "./0i.png"
import imageNoneOf from "./0f.png"
import imageWpm from "./1.png"
import imageWpmBr from "./1bo.png"
import imageWpmBu from "./1bu.png"
import imageWpmI from "./1i.png"
import imageWpmOf from "./1f.png"
import imageNull from "./2.png"
import imageNullBr from "./2bo.png"
import imageNullBu from "./2bu.png"
import imageNullI from "./2i.png"
import imageNullOf from "./2f.png"
import imageOther from "./3.png"
import imageOtherBr from "./3bo.png"
import imageOtherBu from "./3bu.png"
import imageOtherI from "./3i.png"
import imageOtherOf from "./3f.png"

const pythonImages = {
  "0no": imageNone,
  "0br": imageNoneBr,
  "0bu": imageNoneBu,
  "0id": imageNoneI,
  "0of": imageNoneOf,
  "1no": imageWpm,
  "1br": imageWpmBr,
  "1bu": imageWpmBu,
  "1id": imageWpmI,
  "1of": imageWpmOf,
  "2no": imageNull,
  "2br": imageNullBr,
  "2bu": imageNullBu,
  "2id": imageNullI,
  "2of": imageNullOf,
  "3no": imageOther,
  "3br": imageOtherBr,
  "3bu": imageOtherBu,
  "3id": imageOtherI,
  "3of": imageOtherOf,
}

export default pythonImages
