export const NewStatusInfo = {
  "Idea Initiated": {
    info: [
      `The Emulator has been defined and is gathering Support. To advance to next step, all inputs/outputs need to be defined.`,
    ],
  },
  "Function Defined": {
    info: [
      `Configuration of this emulator has started. To advance to next step, Either a calculator needs to be uploaded or data need to be updated.`,
    ],
  },
  "Calculator Testing": {
    info: [
      `Calculator has been uploaded with active file reference. User has proceeded to test the calculator. To advance to next step, complete the testing and mark this calculator as deployed.`,
    ],
  },
  "Calculator Released": {
    info: [
      `This Calculator has completed testing and marked by a team member as deployed, unless the user Initiates additional training, or updates any of the Configurations. This Calculator is available for use.`,
    ],
  },
  "Data Upload": {
    info: [
      `For emulator to work, data files have been uploaded under manage data.`,
    ],
  },
  "Data Reference": {
    info: [
      `For emulator to work, the released calculator was used to generate data files for this emulator.`,
    ],
  },
  "Data Synthesis": {
    info: [
      `For emulator to work, Data file references have been set for this emulator.`,
    ],
  },
  "Data Available": {
    info: [
      `Data uploading/generating/referencing step has been completed successfully. If all previous steps are complete then the Emulator has all the required information to start training.`,
    ],
  },
  "Lookup Released": {
    info: [`Lookup feature is deployed successfully.`],
  },
  "Training in Progress": {
    info: [
      `The Emulator has started the Machine Learning Training phase. The Machine Learning Training will be completed based on the acceptance criteria. This model may be available for use as a deployed app during this phase, but will not move to next phase until the acceptance criteria have met.`,
    ],
  },
  "Emulator Testing": {
    info: [
      `ML training is complete we have an active algorithm to start testing our emulator. To advance to next step, complete the testing and mark this emulator as deployed.`,
    ],
  },
  "Emulator Released": {
    info: [
      `This Emulator has completed training and marked by a team member as deployed, unless the user Initiates additional training, or updates any of the Configurations. This Emulator is available for use as a deployed app.`,
    ],
  },
}
