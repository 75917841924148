import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useRecoilState } from "recoil"
import { Rhino3dmLoader } from "three/addons/loaders/3DMLoader.js"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader"
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry"
import * as THREE from "three"
import { Box, Divider, Grid, Button, Typography, useTheme } from "@mui/material"
import * as ThreeDHelper from "./ThreedHelper"
import * as myFont from "./font.json"
import {
  extractModelPosition,
  getEmulatorData,
  sendModelWithRotation,
} from "../../../state/projectState"
import {
  Authorization,
  EmulationLab,
  Loader,
} from "../../../GlobalFileContainer"
import useStyles from "../../card_style"

const loader = new Rhino3dmLoader()
loader.setLibraryPath("https://unpkg.com/rhino3dm@8.0.0-beta2/")
const pivot = new THREE.Object3D()
const raycaster = new THREE.Raycaster()
let scene,
  cubeScene,
  camera,
  renderer,
  cubeCamera,
  cubeRenderer,
  cube,
  controlScene,
  controlCamera,
  controlRenderer

const LIGHT_COLOR = 0xffffff // a pure white color
const BG_COLOR = 0xf0f5fc
const LIGHT_INTENSITY = 1
export const cubeSides = ["Front", "Back", "Top", "Bottom", "Left", "Right"]
const cubeSidesMesh = []

const getModalObjectFromResult = (result, currentIndex, setDisableKeys) => {
  if (result?.length > 0) {
    const refData = result[currentIndex]?.outputValues
    if (refData && refData?.length > 0) {
      const lastItem = refData[refData.length - 1]

      const lastDataValue =
        lastItem && lastItem.Data && lastItem.Data.length > 0
          ? lastItem.Data[lastItem.Data.length - 1]
          : null

      if (lastDataValue) {
        const updateModalObject = lastItem?.Data
        return updateModalObject
      } else {
        setDisableKeys(false)
        console.log("No last data value found.")
      }
    } else {
      setDisableKeys(false)
      console.log("No data found.")
    }
  }
  return null
}

const commonCamera = (canvas, fov) => {
  const camera = new THREE.PerspectiveCamera(
    fov,
    canvas.clientWidth / canvas.clientHeight,
    0.1,
    1000
  )

  return camera
}
const commonWebGl = (canvas) => {
  const webGl = new THREE.WebGLRenderer({
    antialias: true,
    canvas: canvas,
    alpha: true,
  })
  return webGl
}

const commonScene = () => {
  return new THREE.Scene()
}
const commonVector = new THREE.Vector3(1, 1, 1)
const commonVector2 = new THREE.Vector3(0, 0, 1)
const bgColor = new THREE.Color(BG_COLOR)

const initializeControlledCanvasEnvironment = (canvas) => {
  controlScene = commonScene()
  let color = LIGHT_COLOR
  controlScene.background = new THREE.Color(color)

  controlCamera = commonCamera(canvas, 45)
  controlCamera.lookAt(commonVector)

  controlCamera.up = commonVector2
  controlRenderer = commonWebGl(canvas)
  controlRenderer.setPixelRatio(window.devicePixelRatio)
  controlRenderer.setSize(canvas.clientWidth, canvas.clientHeight)
}

const initializeCanvasEnvironment = (canvas) => {
  scene = commonScene()
  scene.background = bgColor

  camera = commonCamera(canvas, 25)
  camera.lookAt(commonVector)

  camera.up = commonVector2
  renderer = commonWebGl(canvas)
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setSize(canvas.clientWidth, canvas.clientHeight)
}

function animate() {
  requestAnimationFrame(animate)
  renderer.render(scene, camera)
  cubeScene && cubeRenderer.render(cubeScene, cubeCamera)
}

function animateControled() {
  requestAnimationFrame(animateControled)
  controlRenderer.render(controlScene, controlCamera)
}

const objectMaterial = () => {
  const material = new THREE.MeshStandardMaterial()
  material.vertexColors = true
  material.opacity = 0
  material.roughness = 1
  material.metalness = 0.8
  return material
}

const decodeAndLoadRhino = (updateModalObject, emData, setDisableKeys) => {
  window.rhino3dm().then((m) => {
    const rhino = m
    let doc
    if (doc !== undefined) doc.delete()
    doc = new rhino.File3dm()

    for (let i = 0; i < updateModalObject.length; i++) {
      const parsedData = JSON.parse(updateModalObject[i])
      for (let j = 0; j < parsedData.length; j++) {
        const rhinoObject = decodeItem(parsedData[j])
        if (rhinoObject !== null) {
          doc.objects().add(rhinoObject, null)
        }
      }
    }

    if (doc.objects().count < 1) {
      setDisableKeys(false)
      console.error("No rhino objects to load!")
      return
    }
    const buffer = new Uint8Array(doc.toByteArray()).buffer
    function decodeItem(item) {
      const parsedData = JSON.parse(item)
      return rhino.CommonObject.decode(parsedData)
    }
    loader.parse(buffer, function (object) {
      object.traverse((child) => {
        if (child?.isMesh) {
          const material = objectMaterial()
          child.material = material
        }
      }, false)

      //bounding box for rhino object
      const boundingBox = new THREE.Box3().setFromObject(object)
      if (
        !isFinite(boundingBox.min.x) ||
        !isFinite(boundingBox.min.y) ||
        !isFinite(boundingBox.min.z) ||
        !isFinite(boundingBox.max.x) ||
        !isFinite(boundingBox.max.y) ||
        !isFinite(boundingBox.max.z)
      ) {
        console.error("Invalid bounding box.")
        return
      }

      const boundingBoxCenter = new THREE.Vector3()
      boundingBox.getCenter(boundingBoxCenter)

      // Manually calculate the size of the bounding box
      const sizeX = boundingBox.max.x - boundingBox.min.x
      const sizeY = boundingBox.max.y - boundingBox.min.y
      const sizeZ = boundingBox.max.z - boundingBox.min.z

      // Calculate the distance to fit the bounding box within the canvas
      const maxBoundingBoxSize = Math.max(sizeX, sizeY, sizeZ)
      const cameraDistance = maxBoundingBoxSize * 1.5

      // Position the camera to center and fully visualize the loaded object
      camera.position.set(
        boundingBoxCenter.x,
        boundingBoxCenter.y + cameraDistance,
        boundingBoxCenter.z
      )
      camera.lookAt(boundingBoxCenter)

      scene.traverse((child) => {
        if (!child.isLight && child.name !== "context") {
          scene.remove(child)
        }
      })

      // adding and setting pivot position in center,for rotating object using cube
      pivot.position.set(
        boundingBoxCenter.x,
        boundingBoxCenter.y,
        boundingBoxCenter.z
      )
      boundingBoxCenter.y === 0 &&
        object.position.set(
          boundingBoxCenter.x - boundingBox.max.x,
          boundingBoxCenter.y,
          boundingBoxCenter.z - boundingBox.max.z
        )
      const directionalLight = new THREE.DirectionalLight(
        LIGHT_COLOR,
        LIGHT_INTENSITY
      )
      directionalLight.castShadow = true
      directionalLight.position.set(0, 8, 0)
      scene.add(directionalLight)
      scene.add(pivot)
      pivot.add(object)
      pivot.rotation.set(0, 0, 0)
      if (emData) {
        const extractCordinates = extractModelPosition(emData)
        if (extractCordinates) {
          pivot.rotation.set(
            extractCordinates.x,
            extractCordinates.y,
            extractCordinates.z
          )
        }
      }
      animate()
      setDisableKeys(false)
    })
  })
}
const decodeAndLoadControledRhino = (
  updateModalObject,
  emData,
  setDisableKeys
) => {
  window.rhino3dm().then((m) => {
    const rhino = m
    let doc
    if (doc !== undefined) doc.delete()
    doc = new rhino.File3dm()

    for (let i = 0; i < updateModalObject.length; i++) {
      const parsedData = JSON.parse(updateModalObject[i])
      for (let j = 0; j < parsedData.length; j++) {
        const rhinoObject = decodeItem(parsedData[j])
        if (rhinoObject !== null) {
          doc.objects().add(rhinoObject, null)
        }
      }
    }

    if (doc.objects().count < 1) {
      setDisableKeys(false)
      console.error("No rhino objects to load!")
      return
    }
    const buffer = new Uint8Array(doc.toByteArray()).buffer
    function decodeItem(item) {
      const parsedData = JSON.parse(item)
      return rhino.CommonObject.decode(parsedData)
    }

    loader.parse(buffer, function (object) {
      object.traverse((child) => {
        if (child?.isMesh) {
          const material = objectMaterial()
          child.material = material
        }
      }, false)

      //bounding box for rhino object
      const boundingBox = new THREE.Box3().setFromObject(object)
      if (
        !isFinite(boundingBox.min.x) ||
        !isFinite(boundingBox.min.y) ||
        !isFinite(boundingBox.min.z) ||
        !isFinite(boundingBox.max.x) ||
        !isFinite(boundingBox.max.y) ||
        !isFinite(boundingBox.max.z)
      ) {
        console.error("Invalid bounding box.")
        return
      }

      // Calculate the center of the bounding box
      const boundingBoxCenter = new THREE.Vector3()
      boundingBox.getCenter(boundingBoxCenter)

      // Manually calculate the size of the bounding box
      const sizeX = boundingBox.max.x - boundingBox.min.x
      const sizeY = boundingBox.max.y - boundingBox.min.y
      const sizeZ = boundingBox.max.z - boundingBox.min.z

      // Calculate the distance to fit the bounding box within the canvas
      const maxBoundingBoxSize = Math.max(sizeX, sizeY, sizeZ)
      const cameraDistance = maxBoundingBoxSize * 1.5

      // Position the camera to center and fully visualize the loaded object
      controlCamera.position.set(
        boundingBoxCenter.x,
        boundingBoxCenter.y + cameraDistance,
        boundingBoxCenter.z
      )
      controlCamera.lookAt(boundingBoxCenter)

      controlScene.traverse((child) => {
        if (!child.isLight && child.name !== "context") {
          controlScene.remove(child)
        }
      })

      const directionalLight = new THREE.DirectionalLight(
        LIGHT_COLOR,
        LIGHT_INTENSITY
      )
      directionalLight.position.set(3, 3, 3)
      controlScene.add(directionalLight)
      // const axesHelper = new THREE.AxesHelper(15);
      controlScene.add(object)
      object.rotation.set(0, 0, 0)
      if (emData) {
        const extractCordinates = extractModelPosition(emData)
        if (extractCordinates) {
          object.rotation.set(
            extractCordinates.x,
            extractCordinates.y,
            extractCordinates.z
          )
        }
      }
      animateControled()
      setDisableKeys(false)
    })
  })
}

const initializeCubeEnvironment = (cubeContainer) => {
  cubeScene = commonScene()
  cubeScene.background = bgColor
  cubeCamera = commonCamera(cubeContainer, 25)

  cubeCamera.lookAt(commonVector)
  cubeCamera.up = commonVector2

  cubeRenderer = commonWebGl(cubeContainer)
  cubeRenderer.setSize(cubeContainer.clientWidth, cubeContainer.clientHeight)
}

const addCube = (emData) => {
  const geometry = new THREE.BoxGeometry(3, 3, 3)
  const material = new THREE.MeshStandardMaterial({
    color: 0x39c2d7,
    metalness: 1,
    roughness: 0.6,
  })
  cube = new THREE.Mesh(geometry, material)
  const position = extractModelPosition(emData)
  if (position) {
    cube.rotation.set(position.x, position.y, position.z)
  }
  //creating bounding box for cube
  const boundingBox = new THREE.Box3().setFromObject(cube)
  if (
    !isFinite(boundingBox.min.x) ||
    !isFinite(boundingBox.min.y) ||
    !isFinite(boundingBox.min.z) ||
    !isFinite(boundingBox.max.x) ||
    !isFinite(boundingBox.max.y) ||
    !isFinite(boundingBox.max.z)
  ) {
    console.error("Invalid bounding box.")
    return
  }

  // Calculate the center of the bounding box
  const boundingBoxCenter = new THREE.Vector3()
  boundingBox.getCenter(boundingBoxCenter)

  // Position the camera to center and fully visualize the loaded object
  cubeCamera.position.set(
    boundingBoxCenter.x,
    boundingBoxCenter.y + 12,
    boundingBoxCenter.z
  )
  cubeCamera.lookAt(boundingBoxCenter)

  //adding light for visual textures
  const light1 = new THREE.DirectionalLight(LIGHT_COLOR, LIGHT_INTENSITY)
  light1.position.set(3, 3, 3)
  cubeScene.add(light1)
  const light2 = new THREE.DirectionalLight(LIGHT_COLOR, LIGHT_INTENSITY)
  light2.position.set(0, 5, 0)
  cubeScene.add(light2)

  // Load the font file
  const fontLoader = new FontLoader().parse(myFont)
  cubeSides.forEach((labelText, index) => {
    const planeGeometry = new THREE.PlaneGeometry(3, 3)
    const squareMesh = new THREE.Mesh(
      planeGeometry,
      new THREE.MeshStandardMaterial({
        color: 0xff0000,
        transparent: true,
        opacity: 0,
      })
    )

    squareMesh.position.copy(ThreeDHelper.getSidePosition(index))
    squareMesh.userData.sideName = cubeSides[index] // Attach side name as userData
    const isExisting = cubeSidesMesh.some(
      (item) => item.userData.sideName === cubeSides[index]
    )
    if (!isExisting) {
      cubeSidesMesh.push(squareMesh)
    }

    const labelGeometry = new TextGeometry(labelText?.toUpperCase(), {
      font: fontLoader,
      size: 0.4,
      height: 0.02,
    })
    const labelMaterials = new THREE.MeshBasicMaterial({
      color: LIGHT_COLOR,
    })
    const labelMesh = new THREE.Mesh(labelGeometry, labelMaterials)
    labelMesh.position.copy(ThreeDHelper.getLabelPosition(index))

    // Adjust the rotation of the label meshes
    if (index === 0) {
      // Front
      labelMesh.rotation.x = -Math.PI / 2
      squareMesh.rotation.x = -Math.PI / 2
      labelMesh.rotation.z = Math.PI
      squareMesh.rotation.z = Math.PI
    } else if (index === 2) {
      //Top
      labelMesh.rotation.z = Math.PI
      squareMesh.rotation.z = Math.PI
    } else if (index === 3) {
      // Bottom
      labelMesh.rotation.y = Math.PI
      squareMesh.rotation.y = Math.PI
    } else if (index === 4) {
      // Left
      labelMesh.rotation.y = Math.PI / 2
      squareMesh.rotation.y = Math.PI / 2
      labelMesh.rotation.x = Math.PI
      squareMesh.rotation.x = Math.PI
    } else if (index === 5) {
      // Right
      labelMesh.rotation.y = -Math.PI / 2
      squareMesh.rotation.y = -Math.PI / 2
      labelMesh.rotation.z = -Math.PI
      squareMesh.rotation.z = -Math.PI
    } else if (index === 1) {
      // Back
      labelMesh.rotation.y = Math.PI
      squareMesh.rotation.y = Math.PI
      labelMesh.rotation.x = -Math.PI / 2
      squareMesh.rotation.x = -Math.PI / 2
    }
    cube.add(labelMesh)
    cube.add(squareMesh)
  })

  cubeScene.add(cube)
  cubeRenderer.render(cubeScene, cubeCamera)
  pivot.rotation.copy(cube.rotation)
  animate()
}

export const ThreedCanvasControlled = ({
  currentIndex,
  result,
  setOpenThreeModal,
  openThreeModal,
  emData,
  setDisableKeys,
  disableKeys,
  loader,
}) => {
  const updateModalObject = getModalObjectFromResult(
    result,
    currentIndex,
    setDisableKeys
  )

  useEffect(() => {
    const canvas = document.getElementById("canvas")
    cleanup()
    if (updateModalObject) {
      initializeControlledCanvasEnvironment(
        canvas,
        controlScene,
        controlCamera,
        controlRenderer
      )
      decodeAndLoadControledRhino(updateModalObject, emData, setDisableKeys)

      return () => {
        cleanup()
      }
    } else {
      setDisableKeys(false)
      console.log("No last data value found.")
    }

    function cleanup() {
      if (controlScene) {
        controlScene.traverse((child) => {
          if (!child.isLight && child.name !== "context") {
            controlScene.remove(child)
          }
        })
      }
      if (controlRenderer) {
        controlRenderer.dispose()
      }
    }
    return cleanup
  }, [currentIndex, result, openThreeModal])

  const openThreeDDialogue = () => {
    setOpenThreeModal(true)
    setDisableKeys(true)
  }

  return (
    <Box
      sx={{
        height: "80%",
      }}
      className="hover-styling"
      title={"Click here to view 3D Model"}
      onClick={openThreeDDialogue}
    >
      {(disableKeys || loader) && <Loader margin={"0px"} threed={true} />}
      {updateModalObject?.includes("[]") && !disableKeys && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No rhino objects to load!
        </Box>
      )}
      <canvas id="canvas" className="width-100 ml-height"></canvas>
    </Box>
  )
}

export function ThreeDCanvas({
  currentIndex,
  result,
  setDisableKeys,
  disableKeys,
  loader,
}) {
  const { emulatorId } = useParams()
  const classes = useStyles()
  const theme = useTheme()
  const [emData, setEmData] = useRecoilState(getEmulatorData)
  const updateModalObject = getModalObjectFromResult(
    result,
    currentIndex,
    setDisableKeys
  )

  useEffect(() => {
    const canvas = document.getElementById("controledCanvas")
    const cubeContainer = document.getElementById("cubeControlledCanvas")

    cleanup()
    if (updateModalObject) {
      initializeCanvasEnvironment(canvas)
      decodeAndLoadRhino(updateModalObject, emData, setDisableKeys)
      initializeCubeEnvironment(cubeContainer)
      addCube(emData)

      const onCubeClick = (event) => {
        // Calculate the mouse position in normalized device coordinates (NDC)
        const mouse = ThreeDHelper.getMouseXY(event, cubeContainer)
        raycaster.setFromCamera(mouse, cubeCamera)
        const intersects = raycaster.intersectObjects(cubeSidesMesh)
        if (intersects.length > 0 && intersects[0].object.userData) {
          rotateCubeTo(intersects[0].object.userData.sideName)
          pivot.rotation.copy(cube.rotation)
        }
      }

      const rotateCubeTo = (side) => {
        // Get the index of the desired side in the faceNames array
        const index = cubeSides.indexOf(side)
        switch (index) {
          case 0: // Front
            cube.rotation.set(0, 0, 0)
            break
          case 1: // Back
            cube.rotation.set(-3, 0, 0)
            break
          case 2: // Top
            cube.rotation.set(-1.5, 0, 0)
            break
          case 3: // Bottom
            cube.rotation.set(1.5, 0, 0)
            break
          case 4: // Left
            cube.rotation.set(-1.57, -1.57, 0)
            break
          case 5: // Right
            cube.rotation.set(-1.57, 1.57, 0)
            break
        }
      }

      let isMiddleMouseDown = false
      let isRightMouseDown = false
      let isShiftkeyPressed = false
      let initialMouseX = 0
      let initialMouseY = 0
      let initialRotationX = 0
      let initialRotationY = 0
      let initialModelPositionX = 0
      let initialModelPositionY = 0
      let buttonType = 0
      let panSpeed = 3
      let cubeIsMouseDown = false
      let cubeInitialMouseX = 0
      let cubeInitialMouseY = 0
      let cubeInitialRotationX = 0
      let cubeInitialRotationY = 0

      const cubeMouseDown = (event) => {
        event.preventDefault()
        if (event.button === 0) {
          cubeIsMouseDown = true
          cubeInitialMouseX = event.clientX
          cubeInitialMouseY = event.clientY
          cubeInitialRotationX = -pivot.rotation.x
          cubeInitialRotationY = -pivot.rotation.y
        }
      }

      const cubeMouseMove = (event) => {
        event.stopPropagation()
        event.preventDefault()
        if (cubeIsMouseDown) {
          // Calculate the rotation offsets based on the initial mouse position
          const offsetX =
            ((event.clientX - cubeInitialMouseX) / cubeContainer.clientWidth) *
            Math.PI *
            2
          const offsetY =
            ((event.clientY - cubeInitialMouseY) / cubeContainer.clientHeight) *
            Math.PI *
            2

          // Apply the rotation offsets to the initial rotation
          const newRotationX = -(cubeInitialRotationX + offsetY)
          const newRotationY = -(cubeInitialRotationY + offsetX)

          cube.rotation.set(newRotationX, newRotationY, cube.rotation.z)
          pivot.rotation.set(newRotationX, newRotationY, cube.rotation.z)
        }
      }

      const cubeMouseUp = () => (cubeIsMouseDown = false)

      const onMouseDown = (event) => {
        event.preventDefault()
        if (event.button) {
          buttonType = event.button
          initialMouseX = event.clientX
          initialMouseY = event.clientY

          if (event.button === 1) {
            isMiddleMouseDown = true
            if (isShiftkeyPressed) {
              initialRotationX = -pivot.rotation.x
              initialRotationY = -pivot.rotation.y
            } else {
              initialModelPositionX = pivot.position.x
              initialModelPositionY = pivot.position.z
            }
          } else if (event.button === 2) {
            isRightMouseDown = true
            initialRotationX = -pivot.rotation.x
            initialRotationY = -pivot.rotation.y
          }
        }
      }

      const onMouseMove = (event) => {
        event.preventDefault()
        const getModelSize = () => {
          const boundingBox = new THREE.Box3().setFromObject(pivot)
          const size = new THREE.Vector3()
          boundingBox.getSize(size)
          const avgSize = (size.x + size.y + size.z) / 3

          return avgSize
        }

        const calculateSpeed = (modelSize) => {
          const scaleFactor = 0.003
          const speed = modelSize * scaleFactor

          const maxSpeed = 0.4
          return Math.min(speed, maxSpeed)
        }

        const handleRotation = () => {
          // Calculate the rotation offsets based on the initial mouse position isMiddleMouseDown && buttonType === 1 && isShiftkeyPressed
          const offsetX =
            ((event.clientX - initialMouseX) / canvas.clientWidth) * Math.PI * 2
          const offsetY =
            ((event.clientY - initialMouseY) / canvas.clientHeight) *
            Math.PI *
            2

          // Apply the rotation offsets to the initial rotation
          const newRotationX = -(initialRotationX + offsetY)
          const newRotationY = -(initialRotationY + offsetX)

          cube.rotation.set(newRotationX, newRotationY, 0)
          pivot.rotation.set(newRotationX, newRotationY, 0)
        }

        if (isMiddleMouseDown && buttonType === 1) {
          if (isShiftkeyPressed) {
            handleRotation()
          } else {
            const modelSize = getModelSize(pivot)
            const speed = calculateSpeed(modelSize)
            const deltaX = (event.clientX - initialMouseX) * speed
            const deltaY = (event.clientY - initialMouseY) * speed
            const newPositionX = initialModelPositionX - deltaX
            const newPositionY = initialModelPositionY - deltaY
            pivot.position.set(newPositionX, pivot.position.y, newPositionY)
          }
        } else if (isRightMouseDown && buttonType === 2) {
          handleRotation()
        }
      }

      const onMouseUp = () => {
        isMiddleMouseDown = false
        isRightMouseDown = false
        buttonType = 0
      }

      const onRightClick = (event) => {
        event.preventDefault()
      }

      const onMouseWheel = (event) => {
        event.preventDefault()
        const delta = Math.sign(event.deltaY)
        camera.position.y += delta * panSpeed
        camera.updateProjectionMatrix()
      }

      const onKeyPress = (event) => {
        event.preventDefault()
        isShiftkeyPressed = event.shiftKey
      }

      const onKeyRelease = (event) => {
        event.preventDefault()
        isShiftkeyPressed = false
      }

      const addEventListeners = () => {
        canvas.addEventListener("contextmenu", onRightClick)
        canvas.addEventListener("mousedown", onMouseDown)
        canvas.addEventListener("mousemove", onMouseMove)
        canvas.addEventListener("mouseup", onMouseUp)
        canvas.addEventListener("wheel", onMouseWheel)
        document.addEventListener("keydown", onKeyPress)
        document.addEventListener("keyup", onKeyRelease)
        cubeContainer.addEventListener("mousedown", cubeMouseDown)
        cubeContainer.addEventListener("mousemove", cubeMouseMove)
        cubeContainer.addEventListener("mouseup", cubeMouseUp)
        cubeContainer.addEventListener("dblclick", onCubeClick)
      }

      const removeEventListeners = () => {
        canvas.addEventListener("contextmenu", onRightClick)
        canvas.removeEventListener("mousedown", onMouseDown)
        canvas.removeEventListener("mousemove", onMouseMove)
        canvas.removeEventListener("mouseup", onMouseUp)
        canvas.removeEventListener("wheel", onMouseWheel)
        document.removeEventListener("keydown", onKeyPress)
        document.removeEventListener("keyup", onKeyRelease)
        cubeContainer.removeEventListener("mousedown", cubeMouseDown)
        cubeContainer.removeEventListener("mousemove", cubeMouseMove)
        cubeContainer.removeEventListener("mouseup", cubeMouseUp)
        cubeContainer.removeEventListener("dblclick", onCubeClick)
      }

      handleResize()
      handleCubeResize()
      window.addEventListener("resize", handleResize)
      window.addEventListener("resize", handleCubeResize)
      addEventListeners()

      return () => {
        window.removeEventListener("resize", handleResize)
        window.removeEventListener("resize", handleCubeResize)
        removeEventListeners()
        cleanup()
      }
    } else {
      setDisableKeys(false)
      console.log("No last data value found.")
    }

    function handleResize() {
      const width = canvas.clientWidth
      const height = canvas.clientHeight

      camera.aspect = width / height
      camera.updateProjectionMatrix()

      renderer.setSize(width, height)
    }

    function handleCubeResize() {
      const width = cubeContainer.clientWidth
      const height = cubeContainer.clientHeight

      cubeCamera.aspect = width / height
      cubeCamera.updateProjectionMatrix()

      cubeRenderer.setSize(width, height)
    }

    function cleanup() {
      if (scene) {
        scene.remove(pivot)
        while (pivot.children.length) {
          pivot.remove(pivot.children[0])
        }
      }
      scene?.traverse((child) => {
        if (!child.isLight && child.name !== "context") {
          scene?.remove(child)
        }
      })
      if (renderer) {
        renderer.dispose()
      }
      if (cubeRenderer) {
        cubeRenderer.dispose()
      }
    }
    return cleanup
  }, [result, currentIndex])

  const saveModalPosition = async () => {
    const updatedObject = sendModelWithRotation(undefined, {
      x: pivot.rotation._x,
      y: pivot.rotation._y,
      z: pivot.rotation._z,
    })
    const objectIndex = emData?.calculator?.OutputVariables?.findIndex(
      (item) => item.ModelVisual
    )
    const temp = JSON.parse(JSON.stringify(emData))
    const url = `/emulators/${emulatorId}/variables/output/${objectIndex}`
    try {
      await EmulationLab.post(url, updatedObject)

      temp.calculator.OutputVariables[objectIndex] = updatedObject

      setEmData(temp)
    } catch (error) {
      alert("Failed to update, please try again..")
    }
  }

  return (
    <div className="width-100 ml-height">
      <Grid
        container
        direction={"column"}
        sx={{ backgroundColor: "white", height: "100%" }}
      >
        <Grid item sx={{ height: "100%" }}>
          <Box
            sx={{
              position: "absolute",
              top: "60px",
              right: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              userSelect: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                viewBox="0 0 1080 1050"
                height="53px"
                width="4em"
                enableBackground="new 0 0 1080 1080"
                className={classes.colorGraySec}
              >
                <path d="M539,203.646c-117.475,0-213,95.572-213,213.047v246.613c0,117.475,95.525,213.047,213,213.047s213-95.572,213-213.047  V416.693C752,299.219,656.475,203.646,539,203.646z M730,663.307c0,105.388-85.613,191.126-191,191.126s-191-85.738-191-191.126V533  h382V663.307z M730,511H549V225.846c101,5.357,181,88.908,181,190.848V511z" />
              </svg>
              <Typography className={classes.colorGraySec}>
                Rotate Cube
              </Typography>
            </Box>
            <Divider
              sx={{ margin: "5px", height: "70px" }}
              orientation="vertical"
              className={classes.colorGraySec}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <svg
                fill="currentColor"
                viewBox="0 0 16 16"
                height="35px"
                width="3em"
                style={{ margin: "10px 0 8px" }}
                className={classes.colorGraySec}
              >
                <path d="M8 3a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 3zm4 8a4 4 0 01-8 0V5a4 4 0 118 0v6zM8 0a5 5 0 00-5 5v6a5 5 0 0010 0V5a5 5 0 00-5-5z" />
              </svg>
              <Typography className={classes.colorGraySec}>Pan/Zoom</Typography>
            </Box>
            <Divider
              sx={{ margin: "5px", height: "70px" }}
              orientation="vertical"
              className={classes.colorGraySec}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  viewBox="0 0 1080 1050"
                  height="53px"
                  width="4em"
                  enableBackground="new 0 0 1080 1080"
                  className={classes.colorGraySec}
                >
                  <path d="M326,416.693v246.613c0,117.475,95.525,213.047,213,213.047s213-95.572,213-213.047V416.693  c0-117.475-95.525-213.047-213-213.047S326,299.219,326,416.693z M348,533h382v130.307c0,105.388-85.613,191.126-191,191.126  s-191-85.738-191-191.126V533z M348,416.693c0-101.939,80-185.49,181-190.848V511H348V416.693z" />
                </svg>
                <Typography className={classes.colorGraySec}>or</Typography>
                <svg
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  height="35px"
                  width="3em"
                  style={{ margin: "10px 0 8px" }}
                  className={classes.colorGraySec}
                >
                  <path d="M8 3a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 018 3zm4 8a4 4 0 01-8 0V5a4 4 0 118 0v6zM8 0a5 5 0 00-5 5v6a5 5 0 0010 0V5a5 5 0 00-5-5z" />
                </svg>
                <Typography className={classes.colorGraySec}>+</Typography>
                <Box
                  sx={{
                    marginLeft: "10px",
                    width: "50px",
                    height: "25px",
                    border: `1px solid ${theme.palette.grey.secondaryLight}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    className: classes.colorGraySec,
                  }}
                >
                  <Typography className={classes.colorGraySec}>
                    Shift
                  </Typography>
                </Box>
              </Box>
              <Typography className={classes.colorGraySec}>
                Rotate Model
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: "100%" }}>
            {(disableKeys || loader) && <Loader margin={"0px"} threed={true} />}
            {updateModalObject?.includes("[]") && !disableKeys && (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No rhino objects to load!
              </Box>
            )}
            <canvas
              id="controledCanvas"
              className="width-100 ml-height"
            ></canvas>
          </Box>
        </Grid>
        <Grid
          item
          className="ml-display-flex ml-space-between ml-align-center"
          sx={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "60%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <canvas
              id="cubeControlledCanvas"
              className="ml-height"
              style={{ width: "100%" }}
            ></canvas>
          </Box>
          <Authorization processName="manageIO">
            <Box>
              <Button
                id="tryitbtn"
                color="primary"
                variant="contained"
                onClick={saveModalPosition}
              >
                <Typography variant="caption" margin="4px">
                  Set as default orientation
                </Typography>
              </Button>
            </Box>
          </Authorization>
        </Grid>
      </Grid>
    </div>
  )
}
