import { useEffect, useState } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { useParams } from "react-router-dom"
import { Stack, IconButton, Typography, Box, Dialog } from "@mui/material"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import HistoryIcon from "@mui/icons-material/History"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import useStyles from "../../card_style"
import {
  AlertBox,
  Loader,
  OutputsCard,
  useWindowDimensions,
} from "../../../GlobalFileContainer"
import {
  calculatorResult,
  editAlertBoxState,
  handleKeyDown,
  selectedVersion,
} from "../../../state/projectState"
import { confirmDelete } from "../../../state/StatusState"

const FULL_VIEW_OPEN_TEXT = "Open"
const FULL_VIEW_EXIT_TEXT = "Exit"

function Results(props) {
  const classes = useStyles()
  let { emulatorId } = useParams()
  const { width } = useWindowDimensions()
  const searchParams = new URLSearchParams(window.location.search)
  const tryItMode = searchParams.get("mode")

  const selectedVersions = useRecoilValue(selectedVersion)
  const [result, setResult] = useRecoilState(calculatorResult)
  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)

  const [fullView, setFullView] = useState(FULL_VIEW_OPEN_TEXT)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const storedData = localStorage.getItem("jobObject")
  const parsedData = storedData ? JSON.parse(storedData) : []
  const updatedParsedData = parsedData?.filter(
    (parse) => parse.emulatorId === emulatorId
  )

  useEffect(() => {
    openDeleteDialog &&
      setEditAlertBox({
        heading: confirmDelete,
        content: `Are you sure you want to delete whole result history?`,
      })
  }, [openDeleteDialog])

  useEffect(() => {
    if (result?.length > 0) {
      props.setLoader(false)
      props.isModalObject && props.setDisableKeys(true)
    }
  }, [result])

  useEffect(() => {
    const length = Object.keys(props.jobResponses).length
    if (length <= 0) {
      return
    }

    const resultArray = updatedParsedData
      ?.map((jobObj) => {
        const presentInResult = result?.some(
          (result) => result.jobId === jobObj.jobid
        )
        if (!presentInResult) {
          const singleResult = {
            emulatorId: jobObj.emulatorId,
            jobId: jobObj.jobid,
            inputValues: jobObj.inputs,
            outputValues: props.jobResponses[jobObj.jobid]?.estimate,
            resultMode: jobObj.resultType,
            resultVersion: jobObj.version,
            resultStatus: props.jobResponses[jobObj.jobid]?.status,
          }

          return singleResult
        }

        return null
      })
      ?.filter(Boolean)
      .reverse()

    if (resultArray.length === 0) {
      //to update card when status updates
      const updatedResult = result?.map((resultItem) => {
        if (Object.keys(props.jobResponses).includes(resultItem.jobId)) {
          return {
            ...resultItem,
            outputValues: props.jobResponses[resultItem.jobId]?.estimate,
            resultStatus: props.jobResponses[resultItem.jobId]?.status,
          }
        } else {
          return resultItem
        }
      })

      setResult(updatedResult)
    }

    if (resultArray.length > 0) {
      setResult([...resultArray, ...result])
    }

    props.setLoader(false)
  }, [props.jobResponses])

  useEffect(() => {
    if (props.outputResponse.length > 0) {
      const singleResult = {
        emulatorId: props.inputResponse?.emulator_id,
        jobId: length,
        inputValues: props.inputResponse?.FeaturesData,
        outputValues: props.outputResponse,
        resultMode: tryItMode,
        resultVersion: selectedVersions,
        resultStatus: "complete",
      }

      setResult([singleResult, ...result])
    }
  }, [props.outputResponse])

  const handlePrev = () => {
    props.isModalObject && props.setDisableKeys(true)
    props.setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + result.length) % result.length
    )
  }

  const handleNext = () => {
    props.isModalObject && props.setDisableKeys(true)
    props.setCurrentIndex((prevIndex) => (prevIndex + 1) % result.length)
  }

  const handleFullViewResult = () => {
    setFullView(
      fullView === FULL_VIEW_OPEN_TEXT
        ? FULL_VIEW_EXIT_TEXT
        : FULL_VIEW_OPEN_TEXT
    )
  }

  const handleDialogClose = () => setOpenDeleteDialog(false)

  const handleDeleteHistory = () => {
    setResult([])
    localStorage.removeItem("jobObject")
  }

  const renderCommonResultCard = (data) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: data ? "end" : "center",
      }}
    >
      {!props.loader && (
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={handlePrev}
            disabled={
              fullView === FULL_VIEW_EXIT_TEXT ||
              props.currentIndex === 0 ||
              props.disableKeys
            }
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Box mx={2}>
            <Typography variant="body2">
              {fullView === FULL_VIEW_OPEN_TEXT
                ? `${String(props.currentIndex + 1).padStart(
                    2,
                    "0"
                  )} of ${String(result.length).padStart(2, "0")}`
                : "all-results"}
            </Typography>
          </Box>
          <IconButton
            onClick={handleNext}
            disabled={
              fullView === FULL_VIEW_EXIT_TEXT ||
              props.currentIndex === result.length - 1 ||
              props.disableKeys
            }
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      )}
      {!data && (
        <>
          <Box
            display="flex"
            alignItems="center"
            title={"Clear result history"}
          >
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            title={`${fullView} list-view`}
          >
            <IconButton>
              <HistoryIcon onClick={handleFullViewResult} />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  )

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" className={classes.calculatorTypo}>
          Results
        </Typography>
        {updatedParsedData.length > 0 &&
          !props.threeRes &&
          renderCommonResultCard()}
      </Stack>
      <div
        className={
          fullView === FULL_VIEW_OPEN_TEXT || width < 480 ? "" : "results-block"
        }
      >
        {props.loader ? (
          <Loader margin="0px" />
        ) : result.length > 0 ? (
          fullView === FULL_VIEW_OPEN_TEXT ? (
            <Box>
              <OutputsCard
                finalRes={result}
                fullView={fullView}
                currentIndex={props.currentIndex}
                threeRes={props.threeRes}
                isModalObject={props.isModalObject}
              />
            </Box>
          ) : (
            <Stack>
              <OutputsCard
                finalRes={result}
                fullView={fullView}
                threeRes={props.threeRes}
                isModalObject={props.isModalObject}
              />
            </Stack>
          )
        ) : (
          <Typography variant="body2">
            Run the emulator to view results.
          </Typography>
        )}
      </div>
      <Dialog
        onKeyDown={(e) => handleKeyDown(e, handleDialogClose)}
        open={openDeleteDialog}
      >
        <AlertBox
          heading={editAlertBox.heading}
          content={editAlertBox.content}
          closeDialog={handleDialogClose}
          callFunction={handleDeleteHistory}
        />
      </Dialog>
      {result.length > 0 && props.threeRes && (
        <Box marginTop={"5px"}>{renderCommonResultCard(true)}</Box>
      )}
    </>
  )
}

export default Results
