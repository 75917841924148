// import React from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { getAuthEmail } from "../state/axios"
export var appInsightsReactPlugin = new ReactPlugin()
export const locationUrl = window.location.href
export const isStaging = locationUrl?.startsWith(
  "https://emulationlab-staging.azureedge.net/"
)
export const isProd = locationUrl?.startsWith("https://emulationlab.ai/")
export var appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=475733d6-4b40-4317-8e14-a13b1f72ca63;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/",
    enableAutoRouteTracking: true,
    extensions: [appInsightsReactPlugin],
  },
})

appInsights.addTelemetryInitializer((telemetry) => {
  if (telemetry.data) {
    telemetry.data.userEmail = getAuthEmail()
  }
})

if (isStaging || isProd) {
  appInsights.loadAppInsights()
}
