import React, { useEffect } from "react"
import * as core from "@material-ui/core"
import { ImageViewer } from "../../images/ImageViewer"
import AddDialogTitle from "../DialogTitle/AddDialogTitle"
import EmulatorImages from "../../images/EmulatorImages"
import { useRecoilState } from "recoil"
import {
  galleryDialogModeTitle,
  handleKeyDown,
} from "../../../state/projectState"
import useStyles from "../../card_style"
import { Grid } from "@mui/material"

const GalleryDialogBox = ({
  setOpenImageDialog,
  disable,
  openImageDialog,
  setAssociatedStates,
}) => {
  const classes = useStyles()
  const [calcModeTitle, setCalcModeTitle] = useRecoilState(
    galleryDialogModeTitle
  )

  useEffect(() => {
    if (disable) {
      setCalcModeTitle("Preview mode")
    } else {
      setCalcModeTitle("")
    }
  }, [openImageDialog])

  return (
    <>
      <core.Dialog
        onKeyDown={(e) => handleKeyDown(e, setAssociatedStates)}
        fullWidth={true}
        maxWidth={"lg"}
        open={openImageDialog}
      >
        <Grid
          className="gallery-dialog"
          sx={{ minWidth: { xs: "180px", md: "500px" } }}
        >
          <AddDialogTitle
            calcModeTitle={calcModeTitle}
            callFunction={setAssociatedStates}
            title="Emulator Images"
          />
          <core.DialogContent className={classes.calcGalleryDialog}>
            {disable ? (
              <ImageViewer
                setOpenImageDialog={setOpenImageDialog}
                disable={disable}
              />
            ) : (
              <EmulatorImages />
            )}
          </core.DialogContent>
        </Grid>
      </core.Dialog>
    </>
  )
}

export default GalleryDialogBox
