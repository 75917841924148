import * as React from "react"
import * as m from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { Grid } from "@material-ui/core"
import { AddDialogTitle } from "../GlobalFileContainer"

const BootstrapDialog = m.styled(m.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "980px"
  }
}))

export default function PageHelper({ info, displayIcon, callBackFunc }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    callBackFunc && callBackFunc()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const removeHtmlTags = (text) => {
    return text.replace(/<[^>]*>?/gm, "")
  }

  return (
    <div>
      {displayIcon === false ? (
        <m.MenuItem onClick={handleClickOpen}>Help?</m.MenuItem>
      ) : (
        <Grid item xs={1} lg={2}>
          <m.IconButton
            sx={{ padding: "0px" }}
            variant="outlined"
            onClick={handleClickOpen}
          >
            <HelpOutlineIcon color="primary" fontSize="large" />
          </m.IconButton>
        </Grid>
      )}
      <BootstrapDialog
        onClose={handleClose}
        open={open}
      >
        <AddDialogTitle closeCreate={setOpen} title={info.header} />
        <m.DialogContent dividers>
          {info.bullets
            ? info.bullets.map((bullet, key) => (
                <m.Typography
                  variant={bullet?.includes("<b>") ? "h5" : "body1"}
                  gutterBottom
                  key={key + bullet}
                >
                  {removeHtmlTags(bullet)}
                </m.Typography>
              ))
            : null}
        </m.DialogContent>
        <m.DialogActions>
          <m.Button autoFocus onClick={handleClose}>
            <m.Typography variant="caption">Thanks for the help</m.Typography>
          </m.Button>
        </m.DialogActions>
      </BootstrapDialog>
    </div>
  )
}
