export const pageHelpers = {
  manageConfigs: {
    header: `Please add the inputs and outputs to train your emulator`,
    bullets: [
      `Regardless of size and number of input columns in the original file, you can provide just the inputs that you want to be used as part of emulator training. You can investigate the ‘feature selection’ page for choose your inputs wisely!`,
      `Please follow the format provided for defining each input and output. Make sure you select variable types correctly.`,
      `You can re-edit all the data in this page whenever you want!`,
    ],
  },
  uploadData: {
    header: `You can upload your raw data here.`,
    bullets: [
      `It will give you the opportunity to investigate data here or in other sections of the website. Keep in mind that`,
      `You can add different batches of data separately. It will merge internally for training the emulator.`,
      `Adding more data throughout the time usually results in better emulator for your purposes.`,
    ],
  },
  exploreTable: {
    header: `A preview of your uploaded file(s) is shown here`,
    bullets: [
      `The file’s preview is based on original file regardless of the inputs and outputs you defined. The complete data table is shown here. `,
      `You can sort your data based on different columns to find about possible flaws in your original file. The Emulation Lab will clean your file eventually for ML purposes but its always good to understand your data!`,
      `You can also get a random slice of your data because it’s fun to explore!`,
    ],
  },
  featureSelection: {
    header: `Here is a measure of the linear relationship between your variables`,
    bullets: [
      `Overall, less correlation seen here will result in better emulator training purposes.`,
      `Variables not related to each other are better for training the emulators.`,
      `Imagine you are working as a member of a group on a specific project. After a couple of months, your minds start thinking in same way because you are getting familiar with the project (related variables). Now its time to add new members to the group! The new insight will challenge the group members typical thinking trend (no correlation), find flaws in current stage of project, and help improving the expected results (better emulator).`,
      "So, find the variables not related to each other for training emulators.",
      "<b>What if most of my variables are correlated to each other?</b>",
      "Try to train the emulator based on current combination of inputs. If the result is satisfactory, you are good to go. Adding more batches of data in future steps might increase the score.",
      "<b>What if the result is not good?</b>",
      "See the problem in different scope. Can you add more variables to generate new data? Doing an initial thinking, which variables might have significant effect on final output? Try to add more variables!",
    ],
  },

  generateData: {
    header: ``,
    bullets: [``],
  },

  exploreMatrix: {
    header: `Here is a Matrix-based exploratory data analysis focusing just on Inputs and Outputs you defined in ‘Manage Config’.`,
    bullets: [
      "Similar to ‘Feature Selection’, less correlation seen here between ‘Inputs’ will result in better emulator training purposes because the inputs will refine various aspects of surrogate model. So if the plot comparing two variables has random and funny look, don’t be afraid; be happy!",
      "Linear (semi-linear, any kind of!) correlation between each input and defined output is the best thing that can happen to you! It will reduce the dimensionality of surrogate models and usually results in better trained emulators. So, if you detect some kind of relation between most of the inputs and outputs you can be happier than before!",
      "<b>What if most of my variables are correlated to each other?</b>",
      "Try to train the emulator based on current combination of inputs. If the result is satisfactory, you are good to go. Adding more batches of data in future steps might increase the score.",
      "<b>What if the result is not good?</b>",
      "See the problem in different scope. Can you add more variables to generate new data? Doing an initial thinking, which variables might have significant effect on final output? Try to add more variables! ",
    ],
  },
  MLEvaluation: {
    header: `Let's train an emulator!`,
    bullets: [
      "You can train an emulator to predict each individual output you listed before. The training process will be listed accordingly. ",
      "When you are ready just add the defined emulator training to the ‘queue’ by clicking on the button.",
      "Now, you can start the training process. You can monitor the status to realize when it’s completely done.",
      "For each training process, different methodologies will be tested, and the output will be executed. Just Google each one of the regression or classification approaches to learn more!",
      "The executed outputs will be ranked based on specific criteria. You can learn more by expanding each training row by clicking on ‘More’. There is plenty of useful data there as well. ",
      "<b> What if the training failed? </b>",
      "1.	Try training the emulator for different selected ’outputs’. If the training works there, the problem might be related to this specifically selected output. ",
      "2.	If training for all individual outputs failed, the problem might be related to the data structure or the way you defined your inputs and outputs. Revisit ‘Manage Configs’ and make sure all the cells are defined in a proper format. Focus on the data type and make sure everything is ok. The ‘Explore Table’ tab can be a secondary good option for you to double-check the data structure. ",
      "3.	I tried everything but it’s not working. So, Better Call Saul! Contact us. one of our data scientists will help you to train your emulator. ",
    ],
  },
  EmulatorFileManager: {
    header: `You can find the emulators history here. `,
    bullets: [
      "Feel free to download each emulator pipeline or CSV file for further use or modification.  ",
      "You can also upload your own trained emulator or modified version of already trianed emulators. It will be listed below and will be there for further investigation",
    ],
  },
  tryIt: {
    header: `Let's use use emulators is custom web application we provided!`,
    bullets: [
      `try to change the inputs to see the selected output as calculated based on defined emulator`,
    ],
  },
}

export const title = (domain, actualDomain) => {
  return {
    discr: "There is domain mismatch found while validating.",
    good: "The defined input domain match the data set.",
    err: "Domain mismatch! The defined input domains are larger than the data ranges, which creates a dangerous situation.User can make predictions in untrained portions of the problem space, which is an antipattern.",
    avg: "Domain Mismatch! The defined input domains are smaller than the data ranges, which means you are restricting the user to only part of the trained problem space. Safely ignore if this was intended.",
    round: `There's a rounding error that may introduce errors, your domain is ${domain} the actual domain is ${actualDomain}`,
  }
}
