import { useEffect } from "react"
import * as core from "@material-ui/core"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { useRecoilState, useSetRecoilState } from "recoil"
import EmulationLab from "../../state/axios"
import "./UploadFiles.css"
import {
  editAlertBoxState,
  featureSelectionReload,
  filesFromDropzone,
  handleKeyDown,
} from "../../state/projectState"
import { AlertBox } from "../../GlobalFileContainer"
import { confirmDelete } from "../../state/StatusState"

const DeleteConfirmation = ({
  close,
  deleteEmulatorFiles,
  deleteData,
  open,
  deleteConfirm,
  setUpdate,
  updateEmulatorState,
  fileType,
  submitData,
  removeActiveSupp,
}) => {
  const setReloadProject = useSetRecoilState(featureSelectionReload)
  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)

  useEffect(() => {
    open &&
      setEditAlertBox({
        heading: confirmDelete,
        content: `Are you sure you want to delete ${deleteData.name} file?`,
      })
  }, [open, deleteData])

  const handleClose = () => {
    close(false)
  }

  const handleDelete = async () => {
    handleClose()
    setUpdate((prevState) => ({
      ...prevState,
      loaderState: true,
      loaderMsg: "Processing...",
    }))
    try {
      await EmulationLab.delete(deleteEmulatorFiles, {
        data: deleteData,
        headers: {
          severity: SeverityLevel.Error,
        },
      })
      removeActiveSupp &&
        fileType === "supporting" &&
        removeActiveSupp(deleteData?.name)
      fileType === "calculator" &&
        deleteData?.ref &&
        (await submitData(undefined, "delete", null))
      deleteConfirm()
      updateEmulatorState &&
        updateEmulatorState(fileType === "calculator" ? "data" : null)
      setUpdate((prevState) => ({
        ...prevState,
        updateState: false,
      }))
      setReloadProject(true)
    } catch (error) {
      setUpdate((prevState) => ({
        ...prevState,
        loaderState: false,
        loaderMsg: "",
      }))
      alert("Unable to delete file!")
      console.error(error)
    }
  }

  return (
    <core.Dialog onKeyDown={(e) => handleKeyDown(e, handleClose)} open={open}>
      <AlertBox
        heading={editAlertBox.heading}
        content={editAlertBox.content}
        closeDialog={handleClose}
        callFunction={handleDelete}
      />
    </core.Dialog>
  )
}

export default DeleteConfirmation
