import { useEffect, useMemo, useState } from "react"
import * as m from "@mui/material"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { DataGrid } from "@mui/x-data-grid"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import GradingIcon from "@mui/icons-material/Grading"
import * as projState from "../../state/projectState"
import {
  boldTitleHeader,
  renderIdenticalIcon,
  stringOperators,
  uniqueFiltersOwner,
} from "./AgentHealthMain"
import useStyles from "../card_style"
import {
  AdvanceSearchFilter,
  CommonHeader,
  ErrorBoundary,
  EmulationLab,
  Loader,
} from "../../GlobalFileContainer"
import * as statusState from "../../state/StatusState"
import { alertStates } from "../../state/vizState"
import { isAppliedFilter } from "../result-optimization/OptimizationHistory"
import { renderFilterButton } from "../navContent/ExploreNav"
import { EvaluateTooltip } from "../MLEvaluation/MLIcons"
import { runDefaultTest } from "../project/CalculatorCard"
import { getIconBasedOnStatus } from "../testing-calculator/MainTest"
import { updateCalcMode, updateSynMode } from "../../state/requests"
import { mongoProfileState } from "../../state/userState"

export const commonRowStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}

export const handleReadyForCalcToggle = async (
  e,
  emuId,
  updateCalcMode,
  type,
  setAlertState,
  agentDataState,
  setAgentDataState,
  capability
) => {
  const toggleValue = e.target.checked
  const flagId = type === "mix" ? "agent_id" : "emulator_id"
  const flagValue =
    type === "calc"
      ? "ready_for_calcs"
      : type === "syn"
      ? "ready_for_synthesis"
      : "mixed_mode_on"
  const mixModeBody = {
    [`${flagValue}`]: toggleValue,
  }
  const readyForCalcBody =
    type === "mix"
      ? mixModeBody
      : {
          [`${flagId}`]: emuId,
          ...mixModeBody,
        }

  const updateTableValues = (testPass) => {
    const updateTableData = (tableData) => {
      if (tableData[`${flagId}`] !== emuId) {
        return tableData
      }

      const baseUpdate = {
        ...tableData,
        [`${flagValue}`]: testPass === toggleValue,
      }

      if (type === "calc") {
        if ((!testPass || !toggleValue) && tableData.ready_for_synthesis) {
          return { ...baseUpdate, ready_for_synthesis: false }
        }
        return baseUpdate
      }

      if (type === "mix" && tableData.capability !== capability) {
        return tableData
      }

      return baseUpdate
    }

    const updatedTableArr = agentDataState.table.map(updateTableData)

    setAgentDataState({
      ...agentDataState,
      table: updatedTableArr,
    })
  }

  updateTableValues(true)

  try {
    const res = await EmulationLab.post(updateCalcMode, readyForCalcBody)
    if (res) {
      updateTableValues(true)
      setAlertState({
        boolState: true,
        message: `Updated ${flagValue} flag as ${toggleValue}`,
        severityState: "success",
      })
    }
  } catch (error) {
    updateTableValues(false)
    setAlertState({
      boolState: true,
      message: `Could'nt update value`,
      severityState: "error",
    })
  }
}

export const insertObjectAtIndex = (array, index, column) => {
  const updatedArray = [...array.slice(0, index), column, ...array.slice(index)]
  return updatedArray
}

const CapabilityMain = () => {
  const theme = m.useTheme()
  const classes = useStyles()

  const agentHealthDataState = useRecoilValue(projState.agentHealthData)
  const [agentCapabilityDataState, setAgentCapabilityDataState] =
    useRecoilState(projState.agentCapabilityData)
  const [selectedFilters, setSelectedFilter] = useRecoilState(
    projState.agentDataFilter
  )
  const capabilityDependencyDataState = useRecoilValue(
    projState.capabilityDependencyData
  )
  const mongoProfile = useRecoilValue(mongoProfileState)
  const setAlertState = useSetRecoilState(alertStates)
  const setDefaultTestStatusState = useSetRecoilState(
    projState.defaultTestStatus
  )
  const estimateData = useRecoilValue(projState.estimatedTimeState)

  const [filteredColumns, setFilteredColumns] = useState([])
  const [key, setKey] = useState(0)
  const [openFilter, setOpenFilter] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [allStatus, setAllStatus] = useState([])

  const isAdmin = mongoProfile.useModes.includes("admin")
  const [filterModel, setFilterModel] = useState({
    items: [{ field: "status", operator: "is", value: "true" }],
  })
  const [segregatedDataBasedOnStatus, setSegregatedDataBasedOnStatus] =
    useState({
      busy: [],
      off: [],
      idle: [],
      broken: [],
      others: [],
    })

  const dataRows =
    Object.keys(agentCapabilityDataState).length > 0 &&
    agentCapabilityDataState.table.map((tableData, index) => {
      return Object.entries(tableData).reduce(
        (acc, [key, value]) => {
          if (key.length === 24) {
            acc[key] = value
          }
          return acc
        },
        {
          id: `${tableData.name}-${index}`,
          emu_id: tableData?.emulator_id,
          emu_name: tableData?.name,
          software_dependency: `${tableData?.software}: ${tableData?.dependency_set}`,
          status: tableData?.ready_for_calcs,
          synthesis_status: tableData?.ready_for_synthesis
            ? tableData?.ready_for_synthesis !== "UNKNOWN"
            : false,
          modes: tableData?.modes,
          current_potential: `${tableData?.current_agent_count}/${tableData?.potential_agent_count}`,
        }
      )
    })

  const filteredRows =
    dataRows &&
    dataRows
      ?.filter((row) => row.modes.includes("calculate"))
      ?.filter((calcRow) => calcRow.status === true)

  const disableDefaultTest =
    currentIndex < filteredRows?.length && currentIndex > 0

  const getCapabilityAgentFilter = (id) => {
    const result = dataRows
      ?.map((obj) => obj[id])
      ?.filter((rslt) => rslt !== "")
    return Array.from(new Set(result))
  }

  const filteredTable =
    Object.keys(agentHealthDataState).length > 0 &&
    agentHealthDataState?.table?.reduce((acc, curr) => {
      const isExistingAgent = acc.some(
        (item) => item.agent_id === curr.agent_id
      )
      if (!isExistingAgent) {
        acc.push(curr)
      }

      return acc
    }, [])

  useEffect(() => {
    if (filteredTable?.length > 0) {
      filteredTable?.map((tableData) => {
        Object.keys(segregatedDataBasedOnStatus).includes(tableData?.status)
          ? setSegregatedDataBasedOnStatus((prevState) => {
              const newState = { ...prevState }

              !newState[tableData?.status]?.includes(tableData?.agent_id) &&
                newState[tableData?.status]?.push(tableData?.agent_id)

              return newState
            })
          : setSegregatedDataBasedOnStatus((prevState) => {
              const newState = { ...prevState }

              !newState["others"]?.includes(tableData?.agent_id) &&
                newState["others"].push(tableData?.agent_id)

              return newState
            })
      })
    }
  }, [agentCapabilityDataState])

  useEffect(() => {
    if (selectedFilters.capabilityData.length === 0) {
      const allColumns = Object.values(segregatedDataBasedOnStatus).flat()
      setFilteredColumns(allColumns)
    } else {
      const filteredColumnsArray = selectedFilters.capabilityData.flatMap(
        (filterValue) => {
          return segregatedDataBasedOnStatus[filterValue] || []
        }
      )
      setFilteredColumns(filteredColumnsArray)
    }
  }, [selectedFilters.capabilityData, segregatedDataBasedOnStatus])

  const allValueOption = () => {
    return (
      Array.from(
        new Set(dataRows?.map((tableData) => tableData?.software_dependency))
      )?.filter((tbData) => tbData?.split(" ")[1] !== "unknown") ?? []
    )
  }

  useEffect(() => {
    if (currentIndex > 0) {
      runAllEmulatorTests()
    }
    if (currentIndex === filteredRows.length) {
      setCurrentIndex(0)
    }
  }, [currentIndex])

  const iterator = () => {
    if (currentIndex < filteredRows.length) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const runAllEmulatorTests = async () => {
    try {
      const emuConfig = {
        name: filteredRows[currentIndex].emu_name,
        id: filteredRows[currentIndex].emu_id,
        ready_for_calcs: filteredRows[currentIndex].status,
      }

      const res = await runDefaultTest(
        emuConfig,
        setAlertState,
        setDefaultTestStatusState,
        "all",
        estimateData,
        setAllStatus
      )

      res && iterator()
    } catch (error) {
      console.error("Error-", error)
    }
  }

  const agentColumns =
    filteredTable &&
    dataRows &&
    filteredTable?.map((tableItems, index) => {
      return {
        field: tableItems.agent_id,
        align: "center",
        headerAlign: "center",
        headerClassName: "custom-header",
        width: 100,
        sortable: false,
        renderHeader: (params) => {
          const agentStatus =
            filteredTable &&
            filteredTable?.reduce((acc, tableItem) => {
              acc[tableItem.agent_id] = tableItem.status
              return acc
            }, {})

          const agentOwner =
            filteredTable &&
            filteredTable?.reduce((acc, tableItem) => {
              acc[tableItem.agent_id] = tableItem.owner
              return acc
            }, {})

          const agentName =
            filteredTable &&
            filteredTable?.reduce((acc, tableItem) => {
              acc[tableItem.agent_id] = tableItem.device
              return acc
            }, {})

          const statusIcon = statusState.returnAgentStatusIcon(
            agentStatus[tableItems?.agent_id],
            theme,
            classes,
            "small"
          )

          const renderTooltipInfo = () => {
            return (
              <div>
                <m.Typography variant="body2">
                  <b>Agent_id:</b>
                  <br />
                  {tableItems.agent_id}
                </m.Typography>
                <m.Typography variant="body2">
                  <b>Owner:</b>
                  <br />
                  {agentOwner[tableItems.agent_id]}
                </m.Typography>
                <m.Typography variant="body2">
                  <b>Device name:</b>
                  <br />
                  {agentName[tableItems.agent_id]}
                </m.Typography>
                <m.Typography
                  className="ml-display-flex ml-align-center"
                  variant="body2"
                >
                  <b>Capabilities:</b> &nbsp;
                  {capabilityDependencyDataState[tableItems.agent_id]?.map(
                    (dependency) => {
                      const [
                        dependencyType,
                        dependencySubType,
                        dependencyStatus,
                      ] = statusState.seperateSoftwareAndSubType(dependency)
                      return statusState.pickStatusWithSoftwareIcons(
                        dependencyType,
                        dependencySubType,
                        dependencyStatus,
                        "20px",
                        dependency
                      )
                    }
                  )}
                </m.Typography>
              </div>
            )
          }

          return (
            <m.Box key={`${tableItems.agent_id}`}>
              <EvaluateTooltip
                title={<>{renderTooltipInfo()}</>}
                arrow
                placement="top"
              >
                <div>
                  {renderIdenticalIcon(
                    tableItems.agent_id,
                    setAlertState,
                    "Agent ID",
                    true,
                    params,
                    statusIcon
                  )}
                </div>
              </EvaluateTooltip>
            </m.Box>
          )
        },

        type: "singleSelect",
        valueOptions: getCapabilityAgentFilter(tableItems.agent_id),
        filterOperators: stringOperators,
        renderCell: (params) => {
          const getValue = params.row[tableItems.agent_id]
          const [softwareType, softwareSubType, softwareStatus] =
            statusState.seperateSoftwareAndSubType(getValue)

          return getValue ? (
            <div style={commonRowStyle}>
              {statusState.pickStatusWithSoftwareIcons(
                softwareType,
                softwareSubType,
                softwareStatus,
                "35px",
                getValue
              )}
            </div>
          ) : (
            ""
          )
        },
      }
    })

  const columnsForNonAdmins = agentColumns && [
    {
      field: "emu_name",
      headerName: "Name",
      width: 300,
      headerClassName: "bold-header",
      sortable: false,
    },
    {
      field: "software_dependency",
      headerName: "Dependencies",
      width: 60,
      description: "software_dependency",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header cursor-point",
      sortable: false,
      filterable: true,
      type: "singleSelect",
      valueOptions: allValueOption(),
      filterOperators: stringOperators,
      renderCell: (params) => {
        const [softwareType, softwareSubType, softwareStatus] =
          statusState.seperateSoftwareAndSubType(params.row.software_dependency)

        return params.row.software_dependency ? (
          <div style={commonRowStyle}>
            {statusState.pickStatusWithSoftwareIcons(
              softwareType,
              softwareSubType,
              softwareStatus,
              "35px",
              params.row.software_dependency
            )}
          </div>
        ) : (
          ""
        )
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "bold-header",
      align: "center",
      width: 100,
      headerAlign: "center",
      sortable: false,
      type: "boolean",

      valueOptions: uniqueFiltersOwner(dataRows, "status"),
      renderCell: (params) => {
        const status = params.row.status
        const name = params.row.emu_name
        const emuId = params.row.emu_id
        const modes = params.row.modes
        const findDefaultTestStatus = allStatus.find(
          (status) => status.name === name
        )?.status

        return (
          <div style={commonRowStyle} title={`ready_for_calcs: ${status}`}>
            {status !== "" ? (
              findDefaultTestStatus && findDefaultTestStatus !== "pass" ? (
                getIconBasedOnStatus(
                  "test",
                  findDefaultTestStatus,
                  false,
                  classes
                )
              ) : status ? (
                <CheckCircleIcon className={classes.colorSuccessIcon} />
              ) : (
                <CancelIcon className={classes.colorDangerIcon} />
              )
            ) : (
              ""
            )}
            {isAdmin && modes.includes("calculate") && (
              <m.Switch
                checked={status}
                onChange={(e) =>
                  handleReadyForCalcToggle(
                    e,
                    emuId,
                    updateCalcMode,
                    "calc",
                    setAlertState,
                    agentCapabilityDataState,
                    setAgentCapabilityDataState
                  )
                }
              />
            )}
          </div>
        )
      },
    },
    {
      field: "current_potential",
      headerName: "C/P",
      width: 60,
      description: "current_agent_count vs potential_agent_count",
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header cursor-point",
      sortable: false,
      type: "singleSelect",
      valueOptions: uniqueFiltersOwner(dataRows, "current_potential"),
      filterOperators: stringOperators,
      renderCell: (params) => {
        const rowValue = params.row.current_potential
        const current = String(rowValue?.split("/")[0])
        const potential = String(rowValue?.split("/")[1])

        return rowValue ? (
          <div
            style={commonRowStyle}
            title={`current_agent_count: ${current}\npotential_agent_count: ${potential}`}
          >
            {rowValue}
          </div>
        ) : (
          ""
        )
      },
    },
    ...agentColumns,
  ]

  const SynColumn = dataRows && {
    field: "synthesis_status",
    headerName: "Syn. Status",
    headerClassName: "bold-header",
    align: "center",
    width: 100,
    headerAlign: "center",
    sortable: false,
    type: "boolean",
    valueOptions: isAdmin && uniqueFiltersOwner(dataRows, "synthesis_status"),
    renderCell: (params) => {
      const status = params.row.status
      const emuId = params.row.emu_id
      const synStatus = params.row.synthesis_status
      const modes = params.row.modes

      return (
        modes.includes("calculate") && (
          <div
            style={commonRowStyle}
            title={
              !status ? "ready_for_calc should be true for synthesis" : false
            }
          >
            <m.Switch
              disabled={!status}
              checked={synStatus}
              onChange={(e) =>
                handleReadyForCalcToggle(
                  e,
                  emuId,
                  updateSynMode,
                  "syn",
                  setAlertState,
                  agentCapabilityDataState,
                  setAgentCapabilityDataState
                )
              }
            />
          </div>
        )
      )
    },
  }
  const columns = isAdmin
    ? columnsForNonAdmins &&
      SynColumn &&
      insertObjectAtIndex(columnsForNonAdmins, 3, SynColumn)
    : columnsForNonAdmins

  const displayedColumns = useMemo(() => {
    if (!columns || !filteredColumns) {
      return false
    }

    return columns.filter(
      (column) =>
        column.field.length !== 24 || filteredColumns.includes(column.field)
    )
  }, [columns, filteredColumns])

  const handleOpenFilters = (e) => {
    setAnchorEl(e.currentTarget)
    setOpenFilter(true)
  }

  const resetFilter = () => {
    setSelectedFilter({
      ...selectedFilters,
      capabilityData: [],
    })
  }

  return (
    <m.Paper className="para-1">
      <ErrorBoundary>
        <CommonHeader title={"Agent Capability"}>
          <m.Box>
            {isAdmin && (
              <m.Button
                variant="contained"
                sx={{
                  margin: "5px",
                  height: "35px",
                  color: theme.palette.common.white,
                }}
                disabled={disableDefaultTest}
                size="small"
                color="primary"
                startIcon={
                  disableDefaultTest ? (
                    getIconBasedOnStatus("test", "running", false, classes)
                  ) : (
                    <GradingIcon fontSize="25px" />
                  )
                }
                onClick={runAllEmulatorTests}
              >
                <m.Typography variant="caption">Run Default Tests</m.Typography>
              </m.Button>
            )}
            {renderFilterButton(
              handleOpenFilters,
              isAppliedFilter({
                ...selectedFilters,
                status: [],
                capability: [],
              })
            )}
          </m.Box>
        </CommonHeader>
      </ErrorBoundary>
      <div className="adminmuigridtable margin-0-10">
        {displayedColumns && dataRows ? (
          <DataGrid
            key={key}
            rows={dataRows}
            columns={displayedColumns || []}
            density="compact"
            autoHeight
            className="capability-table"
            disableSelectionOnClick
            disableRowSelectionOnClick
            hideFooterPagination
            sx={boldTitleHeader}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) =>
              setFilterModel(newFilterModel)
            }
            hideFooter
          />
        ) : (
          <Loader />
        )}
      </div>
      <AdvanceSearchFilter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        appliedFilters={selectedFilters}
        setAppliedFilters={setSelectedFilter}
        anchorEl={anchorEl}
        isAppliedFilter={isAppliedFilter({
          ...selectedFilters,
          status: [],
          capability: [],
        })}
        resetFilter={resetFilter}
        filterOptions={["Status"]}
        capabilityData={true}
        setKey={setKey}
      />
    </m.Paper>
  )
}

export default CapabilityMain
