import * as core from "@material-ui/core"
import CloseIcon from "@mui/icons-material/Close"
import { Typography } from "@mui/material"

const FileProgressHeader = ({ file, onDelete }) => {
  return (
    <core.Grid container justifyContent="space-between" alignItems="center">
      <Typography variant="body1">{file.name}</Typography>
      <core.Grid item>
        <core.Button size="small" onClick={() => onDelete(file)}>
          <CloseIcon />
        </core.Button>
      </core.Grid>
    </core.Grid>
  )
}

export default FileProgressHeader
