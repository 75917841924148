import { useState, useEffect } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { useParams } from "react-router-dom"
import { Grid } from "@material-ui/core"
import { appInsightsReactPlugin } from "../../../errorManagement/AppInsights"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import {
  CalculatorInputCard,
  CalculatorOutput,
  EmulationLab,
  CommonerrorPageHandle,
  CalculatorGallery,
  ThreeDCanvas,
  AddDialogTitle,
  ErrorBoundary,
} from "../../../GlobalFileContainer"
import {
  calculatorResult,
  estimatedTimeState,
  getBeta,
  getEmulatorData,
  handleKeyDown,
  imagesState,
  jobResponsesState,
  releaseIOState,
  selectedVersion,
  versionName,
} from "../../../state/projectState"
import {
  allEmulatorStatus,
  areObjectsEqual,
  betaVersion,
  breadCrumbsStrings,
  emulatorMode,
  latestVersion,
} from "../../../state/StatusState"
import { Dialog, DialogContent } from "@mui/material"
import useCalculatorPolling from "../../../hooks/useCalculatorPolling"

function TryIt() {
  let { emulatorId } = useParams()
  let searchParams = window.location.search
  const tryitMode = searchParams?.split("?")[1].split("&")[0].split("=")[1]

  const emData = useRecoilValue(getEmulatorData)
  const betaVers = useRecoilValue(getBeta)
  const images = useRecoilValue(imagesState)
  const [jobResponses, setJobResponses] = useRecoilState(jobResponsesState)
  const [versName, setVersName] = useRecoilState(versionName)
  const [selectedVersions, setSelectedVersions] =
    useRecoilState(selectedVersion)
  const result = useRecoilValue(calculatorResult)
  const estimateData = useRecoilValue(estimatedTimeState)
  const releaseWithIo = useRecoilValue(releaseIOState)

  const isModalObject = result?.some((ress) =>
    ress?.outputValues?.some((outp) => {
      try {
        const parsedJson = JSON.parse(outp.Data)
        return typeof parsedJson === "object"
      } catch (error) {
        return false // If parsing fails, it's not an object
      }
    })
  )

  const [jobData, setJobData] = useState([])
  const [emulatorConfigs, setEmulatorConfig] = useState([
    emData?.calculator,
    emData,
  ])
  const [outputResponse, setOutput] = useState([])
  const [postBody, setPostBody1] = useState({})
  const [loader, setLoader] = useState(false)
  const [mainLoader, setMainLoader] = useState(true)
  const [checkLocalStorage, setCheckLocalStorage] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [showModal, setShowModal] = useState(isModalObject)
  const [openThreeModal, setOpenThreeModal] = useState(false)
  const [checkButtonClick, setCheckButtonClick] = useState(false)
  const [generateRandomInputs, setGenerateRandomInputs] = useState(false)
  const [disableKeys, setDisableKeys] = useState(false)

  const emulatorConfig = emulatorConfigs[0]
  const emulatorConfig1 = emulatorConfigs[1]
  const latest = latestVersion(emData)
  const beta = betaVersion(emData)
  const select = latest === breadCrumbsStrings.none ? beta : latest

  useEffect(() => {
    setShowModal(isModalObject)
    setDisableKeys(isModalObject)
  }, [isModalObject])

  useEffect(() => {
    setSelectedVersions(select)
    setVersName(
      latest === breadCrumbsStrings.none
        ? breadCrumbsStrings.beta
        : breadCrumbsStrings.released
    )
    setTimeout(() => {
      setMainLoader(false)
    }, [1000])
  }, [])

  useEffect(() => {
    if (!mainLoader) {
      let calci
      if (
        selectedVersions === betaVers &&
        versName === breadCrumbsStrings.beta
      ) {
        calci = emulatorConfig1?.calculator
      } else {
        calci = releaseWithIo ?? []
      }

      setEmulatorConfig([calci, emulatorConfig1])
    }
  }, [selectedVersions])

  useEffect(() => {
    if (Object.keys(postBody).length !== 0) {
      if (
        tryitMode === emulatorMode.emulate ||
        tryitMode === emulatorMode.calculate
      ) {
        fetchData(postBody, setOutput)
      } else {
        setPostBody1({})
      }
    }
  }, [postBody])

  useEffect(() => {
    if (!checkLocalStorage) {
      return
    }
    const storedData = localStorage.getItem("jobObject")
    const parsedData = JSON.parse(storedData)

    if (parsedData?.length > 0) {
      const modifiedData = parsedData.filter(
        (item) => item.emulatorId === emulatorId
      )
      if (modifiedData?.length > 0) {
        setJobData(modifiedData)
        result.length === 0 && setLoader(true)
      }
    }
  }, [checkLocalStorage])

  useEffect(() => {
    // main logic to start polling if jobObject present in local store
    if (jobData.length > 0) {
      const pollingInterval = setInterval(async () => {
        const threeDStatus = getThreeModalStatus()
        const { updatedResponses, allJobsCompleteorFailed } =
          await useCalculatorPolling(
            jobResponses,
            jobData,
            "calculate",
            estimateData
          )

        if (
          Object.keys(updatedResponses).length === 0 &&
          allJobsCompleteorFailed
        ) {
          alert("Failed to process previous request!")
          localStorage.removeItem("jobObject")
          setJobData([])
          setJobResponses({})
          clearInterval(pollingInterval)
          setCheckLocalStorage(false)
          setCheckButtonClick(false)
        } else {
          let lastKey = Object.keys(updatedResponses)?.pop()
          let lastKeyStatus = updatedResponses[lastKey]?.status || ""

          setJobResponses((prev) => {
            if (!areObjectsEqual(prev, updatedResponses)) {
              return {
                ...prev,
                ...updatedResponses,
              }
            }
            return prev
          })

          if (allJobsCompleteorFailed) {
            if (lastKeyStatus === "complete" && threeDStatus) {
              setDisableKeys(true)
              setOpenThreeModal(true)
            }
            clearInterval(pollingInterval)
            setCheckLocalStorage(false)
            setCheckButtonClick(false)
          }
        }
      }, 1000)

      return () => {
        clearInterval(pollingInterval)
      }
    }
  }, [jobData, jobResponses])

  const getThreeModalStatus = () => {
    if (isModalObject && showModal && checkButtonClick) {
      return true
    } else return false
  }

  const saveLocally = (data) => {
    // save job data in local storage
    setCheckLocalStorage(false)
    const newJobObject = {
      emulatorId: postBody.emulator_id,
      jobid: data,
      inputs: postBody.FeaturesData,
      version: selectedVersions,
      resultType: tryitMode,
    }

    const storedData = localStorage.getItem("jobObject")
    const parsedData = JSON.parse(storedData) || []
    try {
      if (Array.isArray(parsedData)) {
        parsedData.push(newJobObject)
        localStorage.setItem("jobObject", JSON.stringify(parsedData))
        setCheckLocalStorage(true)
      }
    } catch (error) {
      console.error("Error parsing or updating existing data:", error)
    }
  }

  async function fetchData(postBody, callbackFunction) {
    try {
      setLoader(true)

      const finalEndPoint =
        tryitMode === emulatorMode.emulate
          ? allEmulatorStatus.predict
          : emulatorMode.calculate
      const api_url = `/engine/${finalEndPoint}`

      const response = await EmulationLab.post(api_url, postBody, {
        headers: {
          severity: SeverityLevel.Error,
        },
        params: { client: "TryIt", version: "beta" },
      })

      const data = response.data

      if (typeof data === "string") {
        saveLocally(data, "string")
        setPostBody1({})
        setCheckButtonClick(true)
      } else {
        callbackFunction(data)
        setLoader(false)
      }
    } catch (err) {
      console.error("Error fetching data:", err)

      callbackFunction({ error: `Unable to fetch ${err}` })
      setLoader(false)
      setPostBody1({})
    }
  }
  const closeDialog = () => {
    setDisableKeys(true)
    setOpenThreeModal(false)
    setShowModal(true)
  }

  if (Object.keys(emulatorConfig).length === 0) {
    return <div></div>
  } else {
    return (
      <div>
        <CommonerrorPageHandle title="Calculator">
          <Grid container>
            <Grid item xs={12}>
              <ErrorBoundary>
                <CalculatorInputCard
                  activeEmulatorId={emulatorId}
                  calcConfig={emulatorConfig}
                  setPostBody1={setPostBody1}
                  loader={loader}
                  generateRandomInputs={generateRandomInputs}
                  setGenerateRandomInputs={setGenerateRandomInputs}
                  page="tryit"
                  setAsVariable={false}
                  setCurrentIndex={setCurrentIndex}
                />
              </ErrorBoundary>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={3}>
              <ErrorBoundary sideComp>
                {(images.length !== 0 || (isModalObject && showModal)) && (
                  <CalculatorGallery
                    mainLoader={mainLoader}
                    isModalObject={isModalObject}
                    showModal={showModal}
                    result={result}
                    currentIndex={currentIndex}
                    setOpenThreeModal={setOpenThreeModal}
                    openThreeModal={openThreeModal}
                    emData={emData}
                    imageLength={images.length}
                    setDisableKeys={setDisableKeys}
                    disableKeys={disableKeys}
                    loader={loader}
                  />
                )}
              </ErrorBoundary>
            </Grid>
            <Grid
              item
              xs={12}
              md={images.length !== 0 || isModalObject ? 9 : 12}
            >
              <ErrorBoundary>
                <CalculatorOutput
                  outputResponse={outputResponse}
                  inputResponse={postBody}
                  loader={loader}
                  jobResponses={jobResponses}
                  setLoader={setLoader}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  isModalObject={isModalObject}
                  setDisableKeys={setDisableKeys}
                  disableKeys={disableKeys}
                />
              </ErrorBoundary>
            </Grid>
          </Grid>
        </CommonerrorPageHandle>
        <Dialog
          onKeyDown={(e) => handleKeyDown(e, closeDialog)}
          open={openThreeModal}
          fullWidth
          maxWidth={"100%"}
        >
          <AddDialogTitle title={"3D Model"} closeCreate={closeDialog} />
          <DialogContent dividers sx={{ padding: "0px" }}>
            <Grid container className="gallery-dialog">
              <Grid item xs={12} md={3}>
                <ErrorBoundary>
                  <CalculatorOutput
                    outputResponse={outputResponse}
                    inputResponse={postBody}
                    loader={loader}
                    jobResponses={jobResponses}
                    setLoader={setLoader}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    threeRes={true}
                    isModalObject={isModalObject}
                    setDisableKeys={setDisableKeys}
                    disableKeys={disableKeys}
                  />
                </ErrorBoundary>
              </Grid>
              <Grid item xs={12} md={9}>
                <ErrorBoundary>
                  <ThreeDCanvas
                    result={result}
                    currentIndex={currentIndex}
                    setDisableKeys={setDisableKeys}
                    disableKeys={disableKeys}
                    loader={loader}
                  />
                </ErrorBoundary>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default withAITracking(appInsightsReactPlugin, TryIt)
