import { IconButton } from "@mui/material"
import * as core from "@material-ui/core"
import CloseIcon from "@mui/icons-material/Close"
import useStyles from "../../card_style"
import ShareIconButton from "../IconButtons/ShareIconButton"

const AddDialogTitle = ({
  calcModeTitle,
  callFunction,
  closeCreate,
  title,
  shareButton,
}) => {
  const classes = useStyles()

  const handleClose = () => {
    if (callFunction) {
      callFunction()
    } else {
      closeCreate(false)
    }
  }

  return (
    <core.DialogTitle
      className={`${classes.backgroundPrimaryMain} ${classes.colorWhiteIcon} img-title-block`}
    >
      <div className="ml-space-between ml-display-flex ml-align-center">
        <div className="critheading">
          <core.Typography variant="h5">{title}</core.Typography>
        </div>
        <div className="ml-display-flex ml-flex-dir-row">
          {calcModeTitle && (
            <div className="critheading">
              <core.Typography variant="h6">{calcModeTitle}</core.Typography>
            </div>
          )}
          {shareButton && (
            <div className="createEm-close-btn">
              <ShareIconButton />
            </div>
          )}
          <IconButton
            aria-label="close"
            id="commonbtncss"
            className={`${classes.colorPrimaryMain}`}
            onClick={handleClose}
          >
            <CloseIcon className={classes.colorWhiteIcon} />
          </IconButton>
        </div>
      </div>
    </core.DialogTitle>
  )
}

export default AddDialogTitle
