import { NavLink, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import * as core from "@material-ui/core"
import * as fa from "react-icons/fa"
import { FaMagnifyingGlass } from "react-icons/fa6"
import GradingIcon from "@mui/icons-material/Grading"
import { RiTableFill } from "react-icons/ri"
import {
  emulatorSpecificRole,
  emulatorStatus,
  siteWideRole,
  dataSet,
  getEmulatorData,
  sidebarWidth,
  resultJobIdState,
} from "../../state/projectState"
import { useWindowDimensions, Loader } from "../../GlobalFileContainer"
import {
  PREPARING_ARRAY,
  emulatorMode,
  getDisableState,
  getParamString,
} from "../../state/StatusState"
import "./EmulatorSidebar.css"
import useStyles from "../card_style"
import { useTheme } from "@mui/material"
import {
  useGetCalcShowHideStatus,
  useGetEmulatorUsageStatus,
} from "../../hooks/useGetCalcShowHideStatus"
import { configInpOut } from "../../hooks/useCalculatorConfig"

export const links = (
  emulatorId,
  status,
  isCountZero,
  siteWideRoleState,
  emulatorSpecificRoleState,
  disableCalcTest,
  disableTesting,
  readyForCalc,
  searchParamsString,
  optimizationString
) => {
  const haveSearchParamString = searchParamsString
    ? `&${searchParamsString}`
    : ""
  const isSiteWideEm = siteWideRoleState !== "admin"
  const isPreparing = PREPARING_ARRAY.includes(status)
  const disabledExplore = isPreparing || isCountZero

  return [
    {
      heading: <h4>Summary:</h4>,
      children: [
        {
          title: "Emulator Summary",
          url: `/em/${emulatorId}/summary`,
          icon: <fa.FaHome />,
          disable: false,
        },
        {
          title: "Event Logs",
          url: `/em/${emulatorId}/timeline`,
          icon: <fa.FaCalendarAlt />,
          disable: false,
        },
        {
          title: "Emulator Members",
          url: `/em/${emulatorId}/members`,
          icon: <fa.FaUsers />,
          disable: false,
        },
      ],
    },
    {
      heading: <h4>Define Emulator:</h4>,
      children: [
        {
          title: "Manage Configs",
          url: `/em/${emulatorId}/manage_configs`,
          icon: <fa.FaPen />,
          disable: false,
        },
        {
          title: "Manage Data",
          url: `/em/${emulatorId}/uploaddata`,
          icon: <fa.FaFileUpload />,
          disable: false,
        },
        {
          title: "Generate Data",
          url: `/em/${emulatorId}/generate/data?index=0`,
          icon: <fa.FaDatabase />,
          disable:
            disableTesting || !readyForCalc || siteWideRoleState === "consumer",
        },
      ],
    },
    {
      heading: <h4>Explore Data:</h4>,
      children: [
        {
          title: "Explore Table",
          url: `/em/${emulatorId}/explore/table`,
          icon: <RiTableFill />,
          disable: disabledExplore,
        },
        {
          title: "Feature Selection",
          url: `/em/${emulatorId}/feature-selection`,
          icon: <fa.FaBalanceScaleLeft />,
          disable: disabledExplore,
        },
        {
          title: "Explore Matrix",
          url: `/em/${emulatorId}/explore/matrix`,
          icon: <fa.FaMicroscope />,
          disable: disabledExplore,
        },
      ],
    },
    {
      heading: <h4>Training:</h4>,
      children: [
        {
          title: "ML Evaluation",
          url: `/em/${emulatorId}/mlevaluation`,
          icon: <fa.FaRegChartBar />,
          disable: disabledExplore,
        },
        {
          title: "Emulator File Manager",
          url: `/em/${emulatorId}/emulator/filemanager`,
          icon: <fa.FaFile />,
          disable: isSiteWideEm || disabledExplore,
        },
      ],
    },
    {
      heading: <h4>Use:</h4>,
      children: [
        {
          title: "Calculator",
          url: `/em/${emulatorId}/tryit?mode=calculate${haveSearchParamString}`,
          icon: <fa.FaCalculator />,
          disable: disableCalcTest,
        },
        {
          title: "Calculator Tests",
          url: `/em/${emulatorId}/tests`,
          icon: <GradingIcon fontSize="small" />,
          disable: disableTesting,
        },
        {
          title: "Optimization",
          url: `/em/${emulatorId}/optimization?${optimizationString}`,
          icon: <fa.FaHatWizard fontSize="small" />,
          disable: disableCalcTest,
        },
        {
          title: "Lookup Results",
          url: `/em/${emulatorId}/lookup`,
          icon: <FaMagnifyingGlass fontSize="small" />,
          disable: false,
        },
      ],
    },
  ]
}

export const returnDisableTestingVal = (
  noConfigs,
  isCalculateMode,
  emulatorData,
  showCalculator
) => {
  return (
    noConfigs ||
    !isCalculateMode ||
    !emulatorData?.source?.ref ||
    !showCalculator
  )
}

export function EmulatorSidebar() {
  const { width } = useWindowDimensions()
  let { emulatorId } = useParams()
  const { inpVar, outVar } = configInpOut()
  const classes = useStyles()
  const theme = useTheme()

  const emulatorData = useRecoilValue(getEmulatorData)
  const emulatorStatusState = useRecoilValue(emulatorStatus)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const dataSetState = useRecoilValue(dataSet)
  const [large, setLarge] = useRecoilState(sidebarWidth)
  const optimizeJobId = useRecoilValue(resultJobIdState)

  const emulatorInputVarConfig = emulatorData?.calculator?.InputVariables
  const optimizationString = `${optimizeJobId ? `jobId=${optimizeJobId}` : ""}`
  const searchParamsString = getParamString(emulatorInputVarConfig)
  const location = window.location.href
  const noConfigs = inpVar === 0 || outVar === 0
  const noOfReleases = Object.keys(emulatorData.versions.releases).length
  const showCalculator = useGetCalcShowHideStatus(emulatorData, noOfReleases)
  const isCalculateMode = emulatorData?.modes?.includes(emulatorMode.calculate)
  const isCountZero = dataSetState === 0
  const readyForCalc = emulatorData?.ready_for_calcs
  const useEmulator = useGetEmulatorUsageStatus(emulatorData)
  const disableCalcTest = noConfigs || !showCalculator || useEmulator
  const disableTesting =
    returnDisableTestingVal(
      noConfigs,
      isCalculateMode,
      emulatorData,
      showCalculator
    ) || !emulatorData?.ready_for_calcs

  const [showArrow, setShowArrow] = useState(!large)

  useEffect(() => {
    setLarge(width > 960)
  }, [location, width])

  useEffect(() => {
    width > 960 ? setShowArrow(false) : setShowArrow(true)
    setLarge(!showArrow)
  }, [width])

  const disableHandler = (e) => {
    e.preventDefault()
  }

  const classNameString = ({ isActive }) => {
    return `EmulatorSidebar-navlink ${
      !isActive ? "" : "active active_menu_link"
    } ${large ? "" : "width-50"}`
  }

  const drawerHandler = (e) => {
    e.preventDefault()
    setLarge(!large)
  }

  const drawer = () => (
    <div>
      <core.Toolbar />
      <core.Divider />
      <core.List>
        {emulatorStatusState === "" && <Loader />}
        {emulatorStatusState !== "" && showArrow && (
          <core.ListItem>
            <NavLink
              className="EmulatorSidebar-navlink"
              onClick={drawerHandler}
            >
              {large ? (
                <fa.FaCaretLeft fontSize="20px" />
              ) : (
                <fa.FaCaretRight fontSize="20px" />
              )}
            </NavLink>
          </core.ListItem>
        )}
        {emulatorStatusState !== "" &&
          siteWideRoleState &&
          emulatorSpecificRoleState &&
          links(
            emulatorId,
            emulatorStatusState,
            isCountZero,
            siteWideRoleState,
            emulatorSpecificRoleState,
            disableCalcTest,
            disableTesting,
            readyForCalc,
            searchParamsString,
            optimizationString
          ).map((link, index) => {
            let linkHeading = link.heading.props.children.replace(":", "")
            return (
              <div key={`parent_${link}` + index}>
                {large && (
                  <core.ListItem>
                    <core.ListItemText
                      className="EmulatorSidebar-listtext heading"
                      primary={link.heading}
                    />
                  </core.ListItem>
                )}
                {link.children.map((child, index) => (
                  <core.ListItem
                    title={
                      child.disable
                        ? linkHeading === "Explore Data"
                          ? getDisableState(linkHeading, emulatorData?.modes)
                          : getDisableState(child.title, emulatorData?.modes)
                        : ""
                    }
                    key={`child_${child}` + index}
                  >
                    <NavLink
                      onClick={child.disable ? disableHandler : ""}
                      key={index}
                      className={(isActive) => classNameString(isActive)}
                      to={child.disable ? "/" : child.url}
                    >
                      {large ? (
                        <>
                          <core.ListItemIcon
                            className="navlink-child"
                            style={{
                              color: child.disable
                                ? theme.palette.grey.secondaryLight
                                : undefined,
                            }}
                          >
                            {child.icon}
                          </core.ListItemIcon>
                          <core.ListItemText
                            className={
                              child.disable
                                ? classes.colorGraySec
                                : classes.colorBlack
                            }
                            primary={
                              <core.Typography>{child.title}</core.Typography>
                            }
                          />
                        </>
                      ) : (
                        <core.ListItemIcon
                          style={{
                            color: child.disable
                              ? theme.palette.grey.secondaryLight
                              : undefined,
                          }}
                          className={child.disable ? classes.colorGraySec : ""}
                        >
                          {child.icon}
                        </core.ListItemIcon>
                      )}
                    </NavLink>
                  </core.ListItem>
                ))}
              </div>
            )
          })}
      </core.List>
    </div>
  )

  return (
    <>
      <core.Box className="EmulatorSidebar-Box">
        <core.Box>
          <core.Drawer variant="persistent" open>
            {drawer(large)}
          </core.Drawer>
        </core.Box>
      </core.Box>
    </>
  )
}
