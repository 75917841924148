import { useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import Edit from "@mui/icons-material/Edit"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import "./CalculatorConfig.css"
import * as storedSatus from "../../../state/StatusState"
import useStyles from "../../card_style"
import { modelCheckbox } from "../../CreateEmulator"

function MetaDataSection({ fileList, openFiles, hasModel, emSoftware }) {
  const fieldFlexDirection = { flexDirection: { xs: "column", md: "row" } }
  const fieldFlexDirection2 = {
    flexDirection: { xs: "column", sm: "row", md: "column", lg: "row" },
  }
  const fieldBreadCss = { wordBreak: "break-all", maxWidth: "100%" }
  const commonFieldWidth = { xs: "120px" }

  const theme = m.useTheme()
  const classes = useStyles()
  const { width } = file.useWindowDimensions()

  const emData = useRecoilValue(storedSates.getEmulatorData)
  const fileType = useRecoilValue(storedSates.dataSourceType)
  const setEditMeta = useSetRecoilState(storedSates.isEditMeta)
  const emulatorStatusState = useRecoilValue(storedSates.emulatorStatus)

  const sourceTypes = storedSatus.sourceType(emData)
  const isHostedCalc = fileType === storedSatus.emulatorSoftwareType.hosted

  const uploadCalcButton = (calc) => {
    return (
      <m.Button
        onClick={() => openFiles()}
        sx={{ padding: "2px", color: theme.palette.common.white }}
        variant="contained"
        color="primary"
      >
        <core.Typography variant="caption">
          {calc === "set"
            ? "click here to set active file"
            : "Upload Calc File"}
        </core.Typography>
      </m.Button>
    )
  }

  const commonMetaRender = (key, value, tag) => {
    const ShowValue = (value) => (
      <m.Typography variant="body2">{value}</m.Typography>
    )

    return (
      <core.Box
        sx={{ alignItems: { sm: "baseline" } }}
        className="ml-display-flex boxmainwrap"
      >
        <core.Box
          sx={{
            width: commonFieldWidth,
            minWidth: commonFieldWidth,
          }}
        >
          <m.Typography
            variant="body2"
            fontWeight={storedSatus.fontWeight650}
            className={`${classes.colorBlack} boldLabel`}
          >
            {key}:
          </m.Typography>
        </core.Box>
        {tag
          ? (tag === "tag" && (
              <core.Box className="ml-display-flex ml-align-center">
                <core.Typography variant="body2">
                  {value &&
                    Object?.entries(value)?.map(([id, tag]) => (
                      <core.Chip
                        id="chipmui"
                        key={`${id}_${tag}`}
                        size="small"
                        label={
                          <m.Typography variant="body2">{tag}</m.Typography>
                        }
                        variant="filled"
                        style={{
                          fontSize:
                            (width < 600 && "0.7rem") ||
                            (width < 980 && width >= 600 && "0.8rem") ||
                            "1rem",
                          fontFamily: theme.palette.typography.fontFamily,
                        }}
                      />
                    ))}
                </core.Typography>
              </core.Box>
            )) ||
            ShowValue(value)
          : ShowValue(value)}
      </core.Box>
    )
  }

  return (
    <core.Box
      className="hoverGrey"
      sx={{
        width: "auto",
        minWidth: "200px",
        textWrap: "true",
        textAlign: "left",
        contentAlign: "left",
        backgroundColor: theme.palette.common.white,
        borderBottom: "3px solid #39c2d759",
      }}
      elevation={1}
    >
      <m.Grid container sx={fieldFlexDirection}>
        <m.Grid
          alignItems="center"
          xs={12}
          md={11}
          container
          sx={fieldFlexDirection}
        >
          <m.Grid
            sx={{ background: theme.palette.primary.light }}
            className="ml-display-flex ml-align-center ml-height ml-justify-center"
            item
            xs={12}
            md={4}
            lg={3}
          >
            <img src={emData.image} alt="emulator image" className="img-main" />
          </m.Grid>
          <m.Grid
            className="config-name"
            sx={{ margin: "10px 0" }}
            item
            xs={12}
            md={8}
            lg={9}
          >
            <m.Stack className="ml-p-5">
              <m.Typography variant="body1">
                <b>{emData?.name}</b>
              </m.Typography>
            </m.Stack>
            <m.Stack className="ml-p-5">
              <m.Typography variant="body2">{emData?.description}</m.Typography>
            </m.Stack>
            <m.Stack sx={fieldFlexDirection2}>
              <m.Grid item xs={12} lg={8}>
                {commonMetaRender("Tags", emData?.tags, "tag")}
              </m.Grid>
              <m.Grid item xs={12} lg={4}>
                {commonMetaRender(
                  "Category",
                  storedSatus.convertToUppercase(emData?.category)
                )}
              </m.Grid>
            </m.Stack>
            <m.Stack sx={fieldFlexDirection2}>
              <m.Grid item xs={12} lg={8}>
                {commonMetaRender(
                  "Modes",
                  emData.modes
                    ?.map((m) => storedSatus.convertToUppercase(m))
                    ?.join(", ")
                )}
              </m.Grid>
              <m.Grid item xs={12} lg={4}>
                {commonMetaRender(
                  "Data Source",
                  isHostedCalc
                    ? "Hosted Calculator"
                    : storedSatus.convertToUppercase(fileType),
                  "ds"
                )}
              </m.Grid>
            </m.Stack>
            {fileType !== storedSatus.emulatorSoftwareType.upload && (
              <m.Stack sx={fieldFlexDirection2}>
                <m.Grid item xs={12} lg={8}>
                  <file.Authorization
                    role={emulatorStatusState}
                    forbidden={storedSatus.trainingDeployed}
                    processName="manageIO"
                  >
                    <core.Box className="ml-display-flex boxmainwrap">
                      <core.Box
                        sx={{
                          width: commonFieldWidth,
                          minWidth: commonFieldWidth,
                        }}
                      >
                        <m.Typography
                          variant="body2"
                          fontWeight={storedSatus.fontWeight650}
                          className={`${classes.colorBlack} boldLabel`}
                        >
                          {sourceTypes ===
                          storedSatus.emulatorSoftwareType.hosted
                            ? "Ref. URL"
                            : "Active Calc File"}
                          :
                        </m.Typography>
                      </core.Box>
                      <core.Box className="ml-display-flex ml-align-center">
                        {sourceTypes !==
                        storedSatus.emulatorSoftwareType.hosted ? (
                          fileList.length > 0 ? (
                            (emData?.source?.ref && (
                              <m.Typography variant="body2" sx={fieldBreadCss}>
                                {emData?.source?.ref?.split("/")[2]}
                              </m.Typography>
                            )) ||
                            uploadCalcButton("set")
                          ) : (
                            uploadCalcButton("calc")
                          )
                        ) : (
                          <m.Typography variant="body2" sx={fieldBreadCss}>
                            {emData?.source?.ref}
                          </m.Typography>
                        )}
                      </core.Box>
                    </core.Box>
                  </file.Authorization>
                </m.Grid>
                {!isHostedCalc && (
                  <m.Grid item xs={12} lg={4}>
                    {commonMetaRender(
                      "Uploaded Calc Files",
                      fileList?.length,
                      "box"
                    )}
                  </m.Grid>
                )}
              </m.Stack>
            )}
            {isHostedCalc && (
              <m.Grid item xs={12}>
                {commonMetaRender("Methods", emData?.source?.method, "box")}
              </m.Grid>
            )}
            {emSoftware === storedSatus.emulatorSoftwareType.grasshopper && (
              <m.Grid item xs={12}>
                {modelCheckbox(hasModel)}
              </m.Grid>
            )}
          </m.Grid>
        </m.Grid>
        <m.Grid
          item
          xs={12}
          md={1}
          display={"flex"}
          sx={{ justifyContent: { xs: "center", md: "right" } }}
        >
          <m.Stack alignItems="center">
            <file.Authorization
              role={emulatorStatusState}
              forbidden={storedSatus.trainingDeployed}
              callFunction={() => setEditMeta(true)}
              processName="editMetadataOrManageCollaborator"
            >
              <core.Button className="hover-styling" color="primary">
                <m.Typography variant="caption">
                  {width > 800 ? <Edit /> : "Edit"}
                </m.Typography>
              </core.Button>
            </file.Authorization>
          </m.Stack>
        </m.Grid>
      </m.Grid>
    </core.Box>
  )
}

export default MetaDataSection
