import { Suspense, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Dialog, DialogContent, Button, Grid, Box } from "@material-ui/core"
import { createEmulator, fetchSearchBlobAPI } from "../state/requests"
import {
  EmulationLab,
  CreateEmulator,
  Loader,
  AddDialogTitle,
} from "../GlobalFileContainer"
import { testJWTToken } from "../state/axios"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { JWTTokenState, mongoProfileState } from "../state/userState"
import {
  commonCategoryChangeHandler,
  dataSourceType,
  handleKeyDown,
  searchBlobData,
} from "../state/projectState"
import "./CreateNewEmulator.css"
import { alertStates } from "../state/vizState"
import { Typography, useTheme } from "@mui/material"
import {
  convertToUppercase,
  emulatorMode,
  emulatorSoftwareType,
} from "../state/StatusState"
import { base64Regex, urlRegex } from "./project/Define/CalculatorConfig"

export const blobData = async (setSearchBlob) => {
  try {
    const request = await EmulationLab.get(fetchSearchBlobAPI)
    setSearchBlob(request.data)
  } catch (error) {
    console.error(error)
  }
}
export const urlPattern = new RegExp("^https?://.*", "i")
const EmulatorForm = ({ closeCreate }) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const setMongoProfile = useSetRecoilState(mongoProfileState)
  const setSearchBlob = useSetRecoilState(searchBlobData)
  const JWTToken = useRecoilValue(JWTTokenState)
  const setFiles = useSetRecoilState(dataSourceType)
  const setAlertState = useSetRecoilState(alertStates)

  const [newEmulator, setNewEmulator] = useState({
    name: "",
    description: "",
    image: "",
  })
  const [state, setState] = useState(1)
  const [tagValue, setTagValue] = useState([])
  const [categoryValue, setCategoryValue] = useState("")
  const [tempCategoryAlert, setTempCategoryAlert] = useState("")
  const [textfieldError, setTextfieldError] = useState(false)
  const [fileType, setFileType] = useState("none")
  const [modes, setModes] = useState([])
  const [dataSource, setDataSource] = useState({
    type: "none",
    software: "",
    ref: "",
    method: "",
  })

  const handleInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    setNewEmulator({ ...newEmulator, [name]: value })
    setTextfieldError(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    //taking the https://placeimg.com/400/400/animals as image url for each entry
    const emulatorName = e.target.name.value
    const email = localStorage.getItem("email")
    const description = e.target.description.value
    const image = e.target.image.value
    const mode = modes?.map((m) => m?.toLowerCase())
    const dataS =
      dataSource.type === "hosted_calculator"
        ? dataSource
        : {
            ...dataSource,
            type: dataSource.type,
            software: dataSource.software,
            ref: null,
            method: "",
          }
    var data = {}

    image
      ? (data = {
          owner_email: email,
          emulator_name: emulatorName,
          description: description,
          image_url: image,
          tags: tagValue,
          category: categoryValue,
          modes: mode,
          source: dataS,
        })
      : (data = {
          owner_email: email,
          emulator_name: emulatorName,
          description: description,
          tags: tagValue,
          category: categoryValue,
          modes: mode,
          source: dataS,
        })

    state === 2 && SubmitManage(data)
  }

  const SubmitManage = async (data) => {
    if (newEmulator.name?.length === 0 || newEmulator.name?.trim() === "") {
      setTextfieldError({ name: true })
      return
    } else if (categoryValue?.length === 0) {
      setTextfieldError({
        category: true,
      })
      return
    } else if (modes?.length === 0) {
      setTextfieldError({
        mode: true,
      })
      return
    } else if (
      modes?.includes(convertToUppercase(emulatorMode.calculate)) &&
      dataSource.type === emulatorSoftwareType.upload
    ) {
      setTextfieldError({ source: true })
      return
    } else if (
      newEmulator.image?.length > 0 &&
      !(urlRegex.test(newEmulator.image) || base64Regex.test(newEmulator.image))
    ) {
      setTextfieldError({ image: true })
      return
    } else if (
      dataSource.type === "hosted_calculator" &&
      (!urlPattern.test(dataSource.ref) || dataSource.type.length === 0)
    ) {
      setTextfieldError({
        url: true,
      })
      return
    }
    try {
      const response = await EmulationLab.post(createEmulator, data)
      setAlertState({
        boolState: true,
        message: "Emulator created Successfully!",
        severityState: "success",
      })
      closeCreate(false)
      testJWTToken(setMongoProfile, JWTToken)

      setTimeout(() => {
        const path = `/em/${response.data}/manage_configs`
        navigate(path)
      }, 2000)
      blobData(setSearchBlob)
      setFiles(fileType)
    } catch (error) {
      setAlertState({
        boolState: true,
        message:
          error.response.status === 409
            ? "Failed! Emulator name already in use. Try different name!"
            : "Some error occured in creating new emulator. Please try after some time.",
        severityState: "error",
      })
      closeCreate(false)
      console.error(error.response)
    }
  }

  const handleTagChange = (event, value, reason) => {
    setTagValue(value)
    setTextfieldError(false)
  }

  const handleModeChange = (event) => {
    setModes(event.target.value)
    setTextfieldError(false)
  }

  const handleCategoryChange = (e) => {
    const category = e.target.value
    setTempCategoryAlert(commonCategoryChangeHandler(category))
    setCategoryValue(e.target.value)
    setTextfieldError(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
        <CreateEmulator
          newEmulator={newEmulator}
          handleInput={handleInput}
          handleCategoryChange={handleCategoryChange}
          handleTagChange={handleTagChange}
          tagValue={tagValue}
          categoryValue={categoryValue}
          tempCategoryAlert={tempCategoryAlert}
          textfieldError={textfieldError}
          source={dataSource}
          fileType={fileType}
          setFileType={setFileType}
          setDataSource={setDataSource}
          modes={modes}
          handleModeChange={handleModeChange}
          setTextfieldError={setTextfieldError}
        />
        <Grid
          container
          className="mui-grid ml-flex-dir-row ml-align-center ml-justify-center"
        >
          <Button
            variant="contained"
            type="submit"
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            }}
            className="createEm-submit"
            onClick={() => setState(2)}
          >
            <Typography variant="caption" margin="4px">
              Submit
            </Typography>
          </Button>
        </Grid>
      </Box>
    </form>
  )
}

function CreateNewEmulator({ closeCreate, trigger }) {
  return (
    <Dialog
      onKeyDown={(e) => handleKeyDown(e, closeCreate)}
      open={trigger}
      fullWidth={true}
    >
      <AddDialogTitle closeCreate={closeCreate} title={"Add New Emulator"} />
      <DialogContent dividers>
        <Suspense fallback={<Loader />}>
          <div>
            <EmulatorForm closeCreate={closeCreate} />
          </div>
        </Suspense>
      </DialogContent>
    </Dialog>
  )
}

export default CreateNewEmulator
