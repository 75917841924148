import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import * as m from "@mui/material"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import { ResponsiveStream } from "@nivo/stream"
import {
  getEmulatorData,
  outputsParametersAndVariables,
  getGraphResult,
} from "../../state/projectState"
import * as file from "../../GlobalFileContainer"
import { nivoTheme } from "../../charts/nivoTheme"
import { EvaluateTooltip } from "../MLEvaluation/MLIcons"
import FiberNewIcon from "@mui/icons-material/FiberNew"
import * as statusSatate from "../../state/StatusState"

const getRestructuredData = (tooltipData) => {
  const sequence = ["minimum", "25%", "median", "75%", "maximum"]
  let restructuredData = []
  sequence?.map((val) => {
    const findValue = tooltipData.find((tooltip) => tooltip.layerLabel === val)
    restructuredData.push(findValue)
  })

  return restructuredData
}

const MyCustomTooltip = (tooltipData) => {
  const graph = useRecoilValue(getGraphResult)
  const fetchTooltipData = tooltipData.tooltipData.slice.stack
  const restructuredData = getRestructuredData(fetchTooltipData)
  const findMinimum = restructuredData.find(
    (val) => val?.layerLabel === "minimum"
  )
  const fetchGraphActualData = graph?.stream?.data?.find(
    (val) => val?.minimum === findMinimum?.value
  )

  return (
    <m.Grid
      sx={{ backgroundColor: "white", padding: "5px" }}
      container
      flexDirection={"column"}
    >
      {restructuredData?.map((data, i) => (
        <m.Grid
          key={`${data}+${i}`}
          container
          sx={{ padding: "5px" }}
          alignItems={"center"}
          flexDirection={"row"}
        >
          <m.Box
            sx={{ height: "10px", width: "10px", backgroundColor: data?.color }}
          />
          &nbsp;
          <m.Typography variant="body2">{data.layerLabel}-</m.Typography>&nbsp;
          <m.Typography variant="body2">
            {data.value === 0
              ? 0
              : statusSatate.roundValues(fetchGraphActualData[data.layerLabel])}
          </m.Typography>
        </m.Grid>
      ))}
    </m.Grid>
  )
}

const StyledTableCell = m.styled(m.TableCell)(({ theme }) => ({
  [`&.${m.tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
  },
  [`&.${m.tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 5,
  },
}))

const StyledTableRow = m.styled(m.TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
    padding: 5,
  },
}))

const loadingStateData = [
  {
    minimum: true,
    "25%": true,
    median: true,
    "75%": true,
    maximum: true,
  },
]

function ScatterGraph({ activeStep, newRecord }) {
  const graph = useRecoilValue(getGraphResult)
  const emulatorConfig = useRecoilValue(getEmulatorData)
  const outputsParametersAndVariablesState = useRecoilValue(
    outputsParametersAndVariables
  )

  const [ranking, setRanking] = useState([])
  const [openBackDrop, setOpenBackDrop] = useState(true)

  const streamGraph = graph?.stream
  const chartData = graph?.stream?.data
  const chartKeys = chartData?.length
    ? Object.keys(chartData[0])
    : Object.keys(loadingStateData[0])
  const findMatchingOutput = emulatorConfig?.calculator?.OutputVariables?.find(
    (otp) =>
      otp?.ColumnLabel ===
      outputsParametersAndVariablesState?.objectives[0]?.label
  )
  const transformedData = statusSatate.getTransformedData(chartData)

  useEffect(() => {
    setRanking(graph?.ranking)
    setOpenBackDrop(
      !graph ||
        graph?.status === statusSatate.ALGO_STATUS.queued ||
        graph?.status === statusSatate.ALGO_STATUS.failed ||
        graph?.status === statusSatate.ALGO_STATUS.received
    )
  }, [graph])

  const showToolTipData = (id) => {
    const inputArray = Object.entries(ranking[id].inputs).map(
      ([key, { VariableValue }]) => ({ key, value: VariableValue })
    )
    return (
      <div>
        {inputArray.map((input, index) => {
          const findMatchingLabel =
            emulatorConfig.calculator.InputVariables.find(
              (val) => val.ColumnLabel === input.key
            )
          return (
            <m.Typography key={`${input.key}-${index}`} variant="body2">
              {`${findMatchingLabel?.Name}- (${statusSatate.roundValues(
                input.value
              )})`}
              {findMatchingLabel?.Units === "none" ||
              findMatchingLabel?.Units === ""
                ? null
                : ` (${findMatchingLabel?.Units})`}
            </m.Typography>
          )
        })}
      </div>
    )
  }

  return streamGraph ? (
    <m.Grid
      item
      sx={{
        display: "flex",
        m: 0,
        p: 0,
        flexDirection: { xs: "column", md: "row" },
        position: "relative",
      }}
    >
      <m.Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          m: 0,
          p: 0,
        }}
      >
        <div
          className="scattermatrixresponsiveChart"
          style={{ position: "relative" }}
        >
          <m.Backdrop
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0,0,0,0.05)",
            }}
            open={openBackDrop}
          >
            <div className="ml-display-flex ml-flex-dir-col ml-align-center">
              {graph?.status === statusSatate.ALGO_STATUS.failed ? (
                <div>
                  <CancelOutlinedIcon fontSize="large" color="error" />
                </div>
              ) : (
                <file.Loader />
              )}
              <m.Typography variant="body2" className="ml-p-top-10">
                {graph?.status === statusSatate.ALGO_STATUS.failed
                  ? "Failed to process optimization, please retry with different instructions"
                  : "Preparing Chart..."}
              </m.Typography>
            </div>
          </m.Backdrop>
          <ResponsiveStream
            data={openBackDrop ? loadingStateData : transformedData}
            keys={chartKeys}
            stackTooltip={(tooltipData) =>
              !openBackDrop && (
                <MyCustomTooltip
                  tooltipData={tooltipData}
                  chartData={chartData}
                />
              )
            }
            margin={{ top: 10, right: 10, bottom: 35, left: 45 }}
            axisBottom={
              streamGraph?.xType === "text" ||
              streamGraph?.xType === "categorical" ||
              streamGraph?.xUnits === "none" ||
              streamGraph?.xUnits === ""
                ? {
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 0,
                    tickRotation: -65,
                    legend: `Generation Number`,
                    legendPosition: "middle",
                    legendOffset: 30,
                  }
                : {
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 0,
                    tickRotation: -65,
                    legend: `Generation Number (${streamGraph?.xUnits})`,
                    legendPosition: "middle",
                    legendOffset: 30,
                  }
            }
            axisLeft={
              streamGraph?.yType === "text" ||
              streamGraph?.yType === "categorical" ||
              streamGraph?.yUnits === "none" ||
              streamGraph?.yUnits === ""
                ? {
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 2,
                    tickRotation: 0,
                    legend: `${streamGraph?.yLabel}`,
                    legendPosition: "middle",
                    legendOffset: -40,
                  }
                : {
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 2,
                    tickRotation: 0,
                    legend: `${streamGraph?.yLabel} (${streamGraph?.yUnits})`,
                    legendPosition: "middle",
                    legendOffset: -40,
                  }
            }
            borderColor="black"
            colors={["#ffffff00", "#f5e5a6", "#9fdbe7", "#9fdbe7", "#f5e5a6"]}
            defs={[
              {
                id: "dots_1",
                type: "patternDots",
                background: "inherit",
                color: "#9fdbe7",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "dots_2",
                type: "patternDots",
                background: "inherit",
                color: "#f5e5a6",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "squares",
                type: "patternSquares",
                background: "inherit",
                color: "#ffffff",
                size: 2,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines-pattern_1",
                type: "patternLines",
                spacing: 5,
                rotation: -45,
                lineWidth: 0.4,
                background: "#9fdbe7",
                color: " #000000",
              },
              {
                id: "lines-pattern_2",
                type: "patternLines",
                spacing: 5,
                rotation: -45,
                lineWidth: 0.4,
                background: "#f5e5a6",
                color: " #000000",
              },
            ]}
            fill={[
              {
                match: {
                  id: "75%",
                },
                id: "lines-pattern_1",
              },
              {
                match: {
                  id: "maximum",
                },
                id: "lines-pattern_2",
              },
            ]}
            curve="stepAfter"
            yScale={{
              type: "log",
              min: 0,
              max: 30,
            }}
            layers={[
              "grid",
              "axes",
              "layers",
              "dots",
              "slices",
              "legends",
              file.LineLayer,
              file.BestLayer,
            ]}
            offsetType="none"
            theme={nivoTheme}
          />
        </div>
      </m.Grid>
      {activeStep === 4 && (
        <m.Grid
          item
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginBottom: "auto",
            marginTop: "10px",
          }}
        >
          {outputsParametersAndVariablesState.bestResult && (
            <>
              <m.Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {newRecord && <FiberNewIcon color="warning" />}
                {`Best Result (Historically): ${statusSatate.roundValues(
                  outputsParametersAndVariablesState.bestResult
                )}`}
              </m.Typography>
              <br />
            </>
          )}
          {ranking?.length > 0 && (
            <m.TableContainer component={m.Paper}>
              <m.Table aria-label="customized table">
                <m.TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">{`Best Results for ${
                      findMatchingOutput?.NickName ?? streamGraph?.yLabel
                    }  ${
                      findMatchingOutput?.Units === "none" ||
                      findMatchingOutput?.Units === "" ||
                      !findMatchingOutput?.Units
                        ? ""
                        : ` (${findMatchingOutput?.Units})`
                    }`}</StyledTableCell>
                  </StyledTableRow>
                </m.TableHead>
                <m.TableBody>
                  {ranking.map((rank, id) => (
                    <EvaluateTooltip
                      placement="left"
                      arrow
                      key={rank.rank}
                      title={
                        <React.Fragment>{showToolTipData(id)}</React.Fragment>
                      }
                    >
                      <StyledTableRow key={rank.rank}>
                        <StyledTableCell
                          sx={{ cursor: "pointer" }}
                          align="center"
                        >
                          {statusSatate.roundValues(rank.value)}
                        </StyledTableCell>
                      </StyledTableRow>
                    </EvaluateTooltip>
                  ))}
                </m.TableBody>
              </m.Table>
            </m.TableContainer>
          )}
        </m.Grid>
      )}
    </m.Grid>
  ) : (
    <file.Loader />
  )
}

export default ScatterGraph
