import { useRecoilValue } from "recoil"
import { mongoProfileState } from "../state/userState"
import { emulatorMode } from "../state/StatusState"

export const useGetCalcShowHideStatus = (emulator, noOfReleases) => {
  const mongoProfile = useRecoilValue(mongoProfileState)
  const emulatorTeam = emulator?.team
  const isAdmin = mongoProfile?.useModes?.includes("admin")
  const isDirector = emulatorTeam?.director.name === mongoProfile.name
  const isMember =
    isAdmin ||
    isDirector ||
    emulatorTeam?.members.some((member) => member.name === mongoProfile.name)

  return noOfReleases === 0 ? isMember : true
}

export const useGetEmulatorUsageStatus = (emulator) => {
  const { modes, ready_for_calcs, ready_for_emulate } = emulator
  const modeLength = modes?.length
  const isOnlyCalculator =
    modeLength === 1 && modes?.includes(emulatorMode.calculate)
  const isOnlyEmulator =
    modeLength === 1 && modes?.includes(emulatorMode.emulate)
  const isBoth = modeLength === 2
  if (isOnlyCalculator) {
    return !ready_for_calcs ?? true
  } else if (isOnlyEmulator) {
    return !ready_for_emulate ?? true
  } else if (isBoth) {
    return !ready_for_emulate && !ready_for_calcs
  }
}
