import React from "react"
import * as m from "@mui/material"

const returnTextSkeleton = (width) => {
  return <m.Skeleton animation="wave" height={10} width={width} />
}

const AgentSummarySkeleton = () => {
  return (
    <m.Card
      sx={{
        minHeight: "100px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <m.Box className="ml-display-flex ml-flex-dir-row ml-align-center ml-height ml-p-5 width-100">
        <m.Grid item xs={6}>
          <m.Box className="ml-display-flex ml-flex-dir-col ml-align-center">
            <m.Skeleton
              animation="wave"
              variant="circular"
              width={50}
              height={50}
            />
            {returnTextSkeleton("40%")}
          </m.Box>
        </m.Grid>
        <m.Divider orientation="vertical" flexItem />
        <m.Grid item xs={6} padding={"10px"}>
          {[...Array(4)].map((e, i) => (
            <span key={`${e}_${i}`}>{returnTextSkeleton("60%")}</span>
          ))}
        </m.Grid>
      </m.Box>
    </m.Card>
  )
}

export default AgentSummarySkeleton
