import { useEffect } from "react"
import { Avatar, Button, Grid, Paper } from "@material-ui/core"
import { useMsal } from "@azure/msal-react"
import Typography from "@mui/material/Typography"
import logo from "../assets/logo.png"
import { loginRequest } from "../authConfig"
import MetaTags from "./MetaTags"
import useStyles from "./card_style"
import { useTheme } from "@mui/material"

const SSOLogin = () => {
  const theme = useTheme()
  const paperStyle = { padding: "30px 20px", width: 300, margin: "20px auto" }
  const headerStyle = { margin: 0 }
  const buttonStyle = {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  }
  const fullPath = window.location.href
  const { instance } = useMsal()
  const classes = useStyles()

  useEffect(() => {
    localStorage.setItem("path", fullPath)
  }, [])

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    })
  }

  return (
    <Grid>
      <MetaTags />
      <Paper elevation={5} style={paperStyle}>
        <Grid align="center">
          <Avatar
            src={logo}
            alt="logo"
            variant="square"
            style={{ height: "auto" }}
          />
          <h1>Emulation Lab Login</h1>
          <h2 style={headerStyle}>Ready to Emulate?</h2>
          <br />

          <Typography variant="body2" style={buttonStyle}>
            This is a prerelease version of emulation lab, and is only intended
            for exploratory users. Please report any bugs or errors.
          </Typography>
          <br />
        </Grid>
        <br />
        <Button
          style={buttonStyle}
          variant="contained"
          color="primary"
          onClick={() => {
            window.history.replaceState(null, "", "/")
            instance.loginRedirect(loginRequest)
          }}
          fullWidth={true}
        >
          <Typography
            variant="caption"
            margin={"5px"}
            color={theme.palette.common.white}
          >
            Login with SSO v3
          </Typography>
        </Button>
      </Paper>
      <div className={`${classes.colorGraySec} version-position`}>
        <Typography>{process.env.REACT_APP_VERSION}</Typography>
      </div>
    </Grid>
  )
}
export default SSOLogin
