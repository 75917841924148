import { useEffect } from "react"
import { styled } from "@mui/material/styles"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import { Grid } from "@material-ui/core"
import { useSetRecoilState } from "recoil"
import { Typography } from "@mui/material"
import { alertStates } from "../../state/vizState"
import { FileProgressHeader, SnackBar } from "../../GlobalFileContainer"

const UploadError = ({ file, onDelete, errors, allowedFileExtensions }) => {
  const setAlertState = useSetRecoilState(alertStates)

  useEffect(() => {
    setAlertState((prevState) => ({
      ...prevState,
      severityState: "info",
    }))
  }, [])

  const ErrorLinearProgress = styled(LinearProgress)(({ theme }) => ({
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.error.main,
    },
  }))

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="singlefileupload-grid"
    >
      <Grid item style={{ width: "90%" }}>
        <FileProgressHeader file={file} onDelete={onDelete} />
        <ErrorLinearProgress variant="determinate" value={100} />
        {errors.map((error) => {
          return (
            <Typography variant="body2" key={error.message}>
              {error.code === "file-invalid-type"
                ? `File type must be ${allowedFileExtensions}.`
                : error.message}
            </Typography>
          )
        })}
        <SnackBar
          message={`Files other than ${allowedFileExtensions} not uploaded.`}
          open={true}
        />
      </Grid>
    </Grid>
  )
}

export default UploadError
