import axios from "axios"
import { updateMetadata } from "./requests"

const EmulationLab = axios.create({
  // We'll test what we're doing with a different api first
  // baseURL: "http://localhost:5000",
  // baseURL: "http://localhost:5000",
  // baseURL: "https://emulation-lab-services.azurewebsites.net",
  baseURL: process.env.REACT_APP_API_URL,
})

export function cleanReturnJson(responseData) {
  if (typeof responseData === "string" || responseData instanceof String) {
    let cleanerString = responseData
      .replaceAll("-Infinity", "-75000000")
      .replaceAll("Infinity", "75000000")
      .replaceAll("NaN", "0")
    const parsed = JSON.parse(cleanerString)
    return parsed
  } else {
    return responseData
  }
}

export default EmulationLab

export function getAuthEmail() {
  return localStorage.getItem("email")
}

export function getJWTToken() {
  let tempToken = localStorage.getItem("JWTToken")
  if (tempToken) {
    return tempToken
  } else {
    return fetchJWTToken()
  }
}

function getMSALToken() {
  return localStorage.getItem("msalAccessToken")
}

export async function fetchJWTToken(stateSetter) {
  // first:
  const email = getAuthEmail()
  const resp = await EmulationLab.post("/users/auth", {
    email: email.toLowerCase(),
    token: getMSALToken(),
  })
  await localStorage.setItem("JWTToken", resp.data.access_token)
  if (stateSetter) {
    await stateSetter(resp.data.access_token)
  }
  return resp.data.access_token
}

export async function testJWTToken(setMongoProfile) {
  await EmulationLab.get("/users/me")
    .then((e) => {
      setMongoProfile(e.data)
      const profile = e.data.useModes.includes("admin")
      if (profile) {
        EmulationLab.get(updateMetadata)
      }
    })
    .catch((e) => {
      console.error(e)
    })
}
