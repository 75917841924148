import { useState, useEffect } from "react"
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil"
import { Text, Circle, Group } from "react-konva"
import * as core from "@material-ui/core"
import { Dialog, IconButton, Stack, useTheme } from "@mui/material"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft"
import Edit from "@mui/icons-material/Edit"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import {
  authorization,
  chipPositionState,
  editAlertBoxState,
  emulatorSpecificRole,
  fetchAnnotationsState,
  galleryDialogModeTitle,
  getEmulatorData,
  handleKeyDown,
  imageIndex,
  imageLoader,
  imagesState,
  siteWideRole,
} from "../../state/projectState"
import {
  AlertBox,
  AnnotationsCard,
  EmulationLab,
  ErrorBoundary,
  KonvaImageStage,
} from "../../GlobalFileContainer"
import {
  CalculatorCard,
  InputsCard,
  OutputsCard,
} from "../project/CalculatorCard"
import { alertStates } from "../../state/vizState"
import { useParams } from "react-router"
import { confirmDelete } from "../../state/StatusState"

export const convertPosition = (a, b, size) => {
  if (a < 1) {
    let x = a * size.width
    let y = b * size.height

    return { x: x, y: y }
  } else {
    let u = a / size.width
    let v = b / size.height

    return { u: u, v: v }
  }
}

export const ChipRenderer = ({
  id,
  item,
  size,
  setChipPosition,
  disableComponent,
  displayIn,
}) => {
  const [hovered, setHovered] = useState(false)
  const emulatorConfig = useRecoilValue(getEmulatorData)

  const description = (varType) => {
    const varDescription = emulatorConfig?.calculator[varType].find(
      (vars) => vars.Name === item.content
    )?.Description
    return varDescription
  }

  const getDescription =
    item.type === "input"
      ? description("InputVariables")
      : description("OutputVariables")

  const convertedPosition = convertPosition(
    item.position.u,
    item.position.v,
    size
  )

  const konvaChipProperties =
    displayIn === "calc"
      ? {
          circleStroke: 1,
          circleRadius: 5,
          indexFontsize: 8,
          indexOffsetX: 7,
          indexOffsetY: 9,
        }
      : {
          circleStroke: 2,
          circleRadius: 15,
          indexFontsize: 16,
          indexOffsetX: 10,
          indexOffsetY: 12,
        }

  const postAnnotationURL = `/emulators/images/annotations/${item.id}`

  const handleDragBoundFunc = (pos) => {
    const x = Math.max(0, Math.min(pos.x, size.width - 30))
    const y = Math.max(0, Math.min(pos.y, size.height - 25))

    return { x, y }
  }

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleDragEnd = async (e) => {
    const newPos = e.target.position()
    const uvPointer = convertPosition(newPos.x, newPos.y, size)
    const res = await EmulationLab.post(postAnnotationURL, uvPointer)
    if (res) {
      setChipPosition((prev) =>
        prev.map((chip) =>
          chip.content === item.content && chip.type === item.type
            ? {
                ...chip,
                position: uvPointer,
              }
            : chip
        )
      )
    }
  }

  return (
    <>
      <Group
        x={convertedPosition?.x}
        y={convertedPosition?.y}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        draggable={!disableComponent}
        onDragMove={(e) => {
          const labelPos = e.target
          labelPos.position(handleDragBoundFunc(labelPos.position()))
        }}
        onDragEnd={handleDragEnd}
      >
        <Circle
          key={item.content}
          fill={item.color}
          stroke="black"
          strokeWidth={konvaChipProperties.circleStroke}
          radius={konvaChipProperties.circleRadius}
        />
        <Text
          text={id + 1}
          fontFamily="sans-serif"
          fontSize={konvaChipProperties.indexFontsize}
          padding={5}
          fill="black"
          offsetX={konvaChipProperties.indexOffsetX}
          offsetY={konvaChipProperties.indexOffsetY}
        />
      </Group>
      {hovered && displayIn !== "calc" && (
        <Text
          x={convertedPosition?.x - 30}
          y={convertedPosition?.y - 40}
          text={`${item.content}${
            getDescription ? ` - ${getDescription}` : ""
          }`}
          fontFamily="sans-serif"
          fontSize={12}
          padding={5}
          fill="black"
          align="center"
        />
      )}
    </>
  )
}

export const ImageViewer = ({ setThumbnail, disable, setOpenImageDialog }) => {
  const { emulatorId } = useParams()
  const theme = useTheme()
  const getImages = `/emulators/${emulatorId}/images`

  const [chipPosition, setChipPosition] = useRecoilState(chipPositionState)
  const setCalcModeTitle = useSetRecoilState(galleryDialogModeTitle)
  const setLoader = useSetRecoilState(imageLoader)
  const [index, setIndex] = useRecoilState(imageIndex)
  const emulatorConfig = useRecoilValue(getEmulatorData)
  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const setIsFetchingAnnotations = useSetRecoilState(fetchAnnotationsState)
  const setAlertState = useSetRecoilState(alertStates)
  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)
  const [images, setImages] = useRecoilState(imagesState)

  const [chiplabel, setChiplabel] = useState("")
  const [disableComponent, setDisableComponent] = useState(false)
  const [openDialogue, setOpenDialog] = useState(false)
  const [imgId, setImgId] = useState("")

  useEffect(() => {
    openDialogue &&
      setEditAlertBox({
        heading: confirmDelete,
        content: "Are You Sure , You want to delete the image?",
      })
  }, [openDialogue])

  useEffect(() => {
    disableComponentHandler(disable)
  }, [])

  const disableComponentHandler = (disable) => {
    let disableComp = !disable
      ? authorization["manageIO"].includes(siteWideRoleState) ||
        authorization["manageIO"].includes(emulatorSpecificRoleState)
        ? false
        : true
      : true
    setDisableComponent(disableComp)
  }

  const handleNextImage = () => {
    setIsFetchingAnnotations(true)
    setChipPosition([])
    setLoader(true)
    setIndex((prevIndex) => (prevIndex + 1) % images.length)
  }

  const handlePrevImage = () => {
    setIsFetchingAnnotations(true)
    setChipPosition([])
    setLoader(true)
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)
  }

  const navigateToGallery = () => {
    setThumbnail ? setThumbnail(true) : setOpenImageDialog(false)
  }

  const handleDragStart = (e) => {
    var label = e.target.querySelector(".MuiChip-label")?.textContent
    setChiplabel(label)
  }

  const imageEditClickHandler = () => {
    setCalcModeTitle("Edit mode")
    disableComponentHandler(false)
    setAlertState({
      boolState: true,
      message: "Image editor mode opened!",
      severityState: "info",
    })
  }

  const handleImageDelete = async (i) => {
    try {
      const response = await EmulationLab.delete(getImages, {
        data: { public_id: i },
      })

      const name = images.find((item) => item.id === i)?.name

      setImages((img) => img?.filter((im) => im.id !== i))
      setChipPosition([])
      setIsFetchingAnnotations(true)
      setAlertState({
        boolState: true,
        message: "Image deleted successfully!",
        severityState: "success",
      })
      if (index === images.length - 1) {
        navigateToGallery()
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        "Some error occurred while deleting images. Please try again."

      alert(errorMessage)
      console.error(error)
    }
  }

  const closeDialog = () => setOpenDialog(false)

  return images?.length > 0 ? (
    <>
      <core.Paper>
        <Stack direction={{ xs: "column", md: "row" }}>
          <core.Grid container xs={12} md={9}>
            <core.Grid
              item
              xs={12}
              style={{ color: theme.palette.grey.secondaryLight }}
              className="ml-display-flex ml-space-between ml-align-center annotate-delete"
            >
              <core.Typography variant="body1" className="ml-p-5">
                {images[index]?.name || ""}
              </core.Typography>
              {!disableComponent ? (
                <IconButton
                  onClick={(e) => {
                    setOpenDialog(true), setImgId(images[index].id)
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              ) : authorization["manageIO"].includes(siteWideRoleState) ||
                authorization["manageIO"].includes(
                  emulatorSpecificRoleState
                ) ? (
                <IconButton
                  onClick={imageEditClickHandler}
                  variant="contained"
                  size="small"
                  color="primary"
                >
                  <Edit />
                </IconButton>
              ) : (
                ""
              )}
            </core.Grid>
            <KonvaImageStage
              chiplabel={chiplabel}
              disableComponent={disableComponent}
              displayIn="gallery"
            />
            <core.Grid item xs={12} md={12}>
              <core.Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
              >
                <core.Grid item>
                  <IconButton onClick={handlePrevImage} disabled={index === 0}>
                    <ArrowLeftIcon />
                  </IconButton>
                </core.Grid>
                <core.Grid item>
                  <core.Typography variant="body1">
                    {index + 1}/ {images.length}
                  </core.Typography>
                </core.Grid>
                <core.Grid item>
                  <IconButton
                    onClick={handleNextImage}
                    disabled={index === images.length - 1}
                  >
                    <ArrowRightIcon />
                  </IconButton>
                </core.Grid>
              </core.Grid>
            </core.Grid>
          </core.Grid>
          <core.Grid container xs={12} md={3}>
            <CalculatorCard showDescription={false}>
              <ErrorBoundary>
                <Stack
                  direction={{ xs: "row", md: "column" }}
                  justifyContent="space-between"
                  sx={{ height: "100%" }}
                  spacing={3}
                >
                  <InputsCard
                    emulatorConfig={emulatorConfig}
                    showDescription={false}
                    handleDragStart={handleDragStart}
                    chipPosition={chipPosition}
                    disableComponent={disableComponent}
                  />
                  <OutputsCard
                    emulatorConfig={emulatorConfig}
                    showDescription={false}
                    handleDragStart={handleDragStart}
                    chipPosition={chipPosition}
                    disableComponent={disableComponent}
                  />
                  <AnnotationsCard
                    chipPosition={chipPosition}
                    setChipPosition={setChipPosition}
                    disableComponent={disableComponent}
                  />
                </Stack>
              </ErrorBoundary>
            </CalculatorCard>
          </core.Grid>
        </Stack>
      </core.Paper>
      <Dialog
        onKeyDown={(e) => handleKeyDown(e, closeDialog)}
        open={openDialogue}
      >
        <AlertBox
          heading={editAlertBox.heading}
          content={editAlertBox.content}
          closeDialog={closeDialog}
          callFunction={() => handleImageDelete(imgId)}
        />
      </Dialog>
    </>
  ) : (
    navigateToGallery()
  )
}
