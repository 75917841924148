import { useState, useEffect } from "react"
import * as m from "@mui/material"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import { useRecoilState, useRecoilValue } from "recoil"
import useStyles from "../../card_style"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"

export function TitleVariable(updateToggle, variableType) {
  const classes = useStyles()
  return (
    <m.Box className={`${classes.backgroundPrimaryMain} titleadd-edit`}>
      <m.Typography
        variant="h5"
        id="varheading"
        className={classes.colorWhiteIcon}
      >
        {updateToggle ? "Edit " : "Add "}
        {variableType} Variable
      </m.Typography>
    </m.Box>
  )
}

function VariableDefiner({
  formValuesOutput,
  nicknameError,
  unitError,
  unique1,
  setFormValuesOutput,
  handleInputChange,
  nameError,
  descriptionError,
  columnPrefix,
  domainData,
  columnLabelError,
  dataColumnLabels,
  listIndex,
  pythonVarError,
  columnLabelValidation,
  setColumnLabelValidation,
}) {
  let myRef = {}
  const filter = createFilterOptions()
  const classes = useStyles()
  const theme = m.useTheme()

  const emData = useRecoilValue(storedSates.getEmulatorData)
  const [isSameValues, setIsSameValues] = useRecoilState(
    storedSates.manageConfigsSameValues
  )
  const updateToggle = useRecoilValue(storedSates.updateVar)
  const updateData = useRecoilValue(storedSates.addData)
  const Software = useRecoilValue(storedSates.dataSourceType)

  const [confirm, setConfirm] = useState(false)
  const [labelmismatch, setLabelmismatch] = useState(false)
  const [labelmatch, setLabelmatch] = useState(false)
  const [UnitsGroup, setUnitGroup] = useState([])
  const [warning, setWarning] = useState(false)

  const inputOrOutput =
    columnPrefix === "in" ? "InputVariables" : "OutputVariables"
  const prevNickname =
    updateToggle && emData?.calculator?.[inputOrOutput][listIndex].NickName

  const prevName =
    updateToggle && emData?.calculator?.[inputOrOutput][listIndex].Name
  const prevColumnLabel =
    updateToggle && emData?.calculator?.[inputOrOutput][listIndex].ColumnLabel

  useEffect(() => {
    if (
      !updateData &&
      !updateToggle &&
      formValuesOutput?.NickName?.length > 0
    ) {
      const updatedFormValuesOutput = {
        ...formValuesOutput,
        ColumnLabel: storedSatus.colRegex.test(formValuesOutput.NickName)
          ? formValuesOutput.NickName.replace(storedSatus.colRegex, "_")
          : `${formValuesOutput.NickName}`,
        ApiLabel: `${columnPrefix}_${formValuesOutput.NickName}`,
      }
      setFormValuesOutput(updatedFormValuesOutput)
    }
  }, [formValuesOutput?.NickName])

  useEffect(() => {
    if (!updateData && dataColumnLabels.length > 0) {
      if (
        !dataColumnLabels?.includes(formValuesOutput.ColumnLabel) &&
        formValuesOutput.ColumnLabel !== ""
      ) {
        setLabelmismatch(true), setLabelmatch(false)
      } else {
        if (formValuesOutput.ColumnLabel !== "") {
          setLabelmismatch(false)
          setLabelmatch(true)
        }
      }
    }
    if (storedSatus.colRegex.test(formValuesOutput.ColumnLabel)) {
      setColumnLabelValidation(true)
    } else {
      setColumnLabelValidation(false)
    }
  }, [formValuesOutput.ColumnLabel])

  const handleChangeLabel = (e) => {
    const label = e.target.value
    setFormValuesOutput({
      ...formValuesOutput,
      ColumnLabel: label.replace(storedSatus.colRegex, "_"),
      ApiLabel: label?.startsWith(columnPrefix)
        ? `${columnPrefix}_${label?.slice(3)}`
        : label,
    })
    if (updateData) {
      if (
        label.replace(storedSatus.colRegex, "_") !==
        Object.keys(domainData).toString()
      ) {
        setLabelmismatch(true), setLabelmatch(false)
      } else {
        setLabelmismatch(false)
        setLabelmatch(true)
      }
    }
  }

  const handleAutocompleteChange = (event, value, reason) => {
    switch (reason) {
      case "input":
        break
      case "reset":
      case "clear":
        if (reason === "reset" && value) {
          handleUnitChange({ target: { value } })
        }
        if (reason === "clear") {
          setWarning(false)
          setConfirm(false)
          setUnitGroup([])
        }
        break
      default:
        console.info(reason)
    }
  }

  const handleUnitChange = (e) => {
    setFormValuesOutput({
      ...formValuesOutput,
      Units: e.target.value,
    })
    const isExisting = unique1
      .map((un) => un.split("-")[1])
      .some((option) => e.target.value === option)
    if (!isExisting && e.target.value !== "") {
      setWarning(true)
      setConfirm(false)
      return "look like new unit."
    } else {
      if (
        unique1.map((un) => {
          if (un.split("-")[1] === e.target.value) {
            setUnitGroup(un)
            setWarning(false)
            setConfirm(true)
          }
        })
      )
        return `Units is assigned.`
    }
  }

  const handleNicknameChange = (e) => {
    commonNameNicknameChange(e, "nick")
  }

  const handleNameChange = (e) => {
    commonNameNicknameChange(e, "name")
  }

  const handleColumnChange = (e) => {
    commonNameNicknameChange(e, "column")
  }

  const handlePythonName = (e) => {
    commonNameNicknameChange(e, storedSatus.emulatorSoftwareType.python)
  }

  const commonNameNicknameChange = (e, type) => {
    const isName = type === "name"
    const isColumn = type === "column"
    const isNickName = type === "nick"
    const isPython = type === storedSatus.emulatorSoftwareType.python
    const regex = /^[a-z_](?:[a-z0-9_]*|[0-9]*)$/

    if (isPython && (e.target.value === "" || regex.test(e.target.value))) {
      setFormValuesOutput({
        ...formValuesOutput,
        PythonVariableName: e.target.value,
      })
    } else {
      const errorKey = isName
        ? "isSameNameError"
        : isColumn
        ? "isSameColumnLabelError"
        : isNickName
        ? "isSameNickNameError"
        : null
      const prevValue = isName
        ? prevName
        : isColumn
        ? prevColumnLabel
        : isNickName
        ? prevNickname
        : null

      if (errorKey && prevValue !== e.target.value) {
        setIsSameValues((prevState) => ({
          ...prevState,
          [errorKey]: true,
        }))
      }

      const outputKey = isName
        ? "Name"
        : isColumn
        ? "ColumnLabel"
        : isNickName
        ? "NickName"
        : null

      if (outputKey !== "ColumnLabel") {
        setFormValuesOutput({
          ...formValuesOutput,
          [outputKey]: e.target.value,
        })
      }
      if (isColumn) {
        return setFormValuesOutput({
          ...formValuesOutput,
          ColumnLabel: e.target.value.replace(storedSatus.colRegex, "_"),
        })
      }
    }
  }

  const handleDefaultColumnLabel = () => {
    if (updateData) {
      setFormValuesOutput({
        ...formValuesOutput,
        ColumnLabel: Object.keys(domainData)?.toString(),
        ApiLabel: Object.keys(domainData)?.toString(),
      })
      setLabelmismatch(false)
    } else {
      setFormValuesOutput({
        ...formValuesOutput,
        ColumnLabel: formValuesOutput.NickName,
        ApiLabel: `${columnPrefix}_${formValuesOutput.NickName}`,
      })
    }
  }

  const misMatchWarning = (label) => (
    <m.FormLabel size="small" color="primary">
      <m.Typography
        variant="body2"
        className={`mismatchlabel ${
          label === "mismatch"
            ? classes.colorDangerIcon
            : classes.colorSuccessIcon
        }`}
      >
        {label === "mismatch"
          ? "There's a mismatch in column Labels."
          : "The column Labels matches."}
      </m.Typography>
    </m.FormLabel>
  )

  const showNameNickname = (
    fieldName,
    actualName,
    changeFunction,
    nameError,
    info,
    sameValue,
    column
  ) => (
    <m.Stack>
      <m.FormLabel size="small" id={`${fieldName}-label`} color="primary">
        {storedSatus.renderFormLabel(actualName, classes)}
      </m.FormLabel>
      <m.TextField
        id={`${fieldName}-input`}
        name={fieldName}
        type="text"
        value={formValuesOutput[fieldName]}
        onChange={
          fieldName === "PythonVariableName"
            ? handlePythonName
            : updateToggle
            ? changeFunction
            : column ?? handleInputChange
        }
        size="small"
        autoComplete="off"
        error={
          (nameError &&
            (formValuesOutput[fieldName]?.length === 0 ||
              !formValuesOutput[fieldName])) ||
          sameValue ||
          (column && columnLabelValidation) ||
          false
        }
        helperText={
          (nameError &&
            (formValuesOutput[fieldName]?.length === 0 ||
              !formValuesOutput[fieldName]) &&
            info) ||
          (sameValue && (
            <>
              {actualName} already exist,please try
              <br />
              another {actualName}.
            </>
          )) ||
          (column && columnLabelValidation && (
            <>
              Uploaded file contains column label <br />
              with spaces/special characters. Update your file
            </>
          ))
        }
      />
      {column &&
        ((labelmismatch && misMatchWarning("mismatch")) ||
          (labelmatch && misMatchWarning("match")))}
    </m.Stack>
  )

  const showCommonCellWorksheet = (field, api) => (
    <m.Stack>
      <m.FormLabel size="small" id={`${field}-label`} color="primary">
        <m.Typography
          variant="body1"
          fontWeight={650}
          className={`${classes.colorBlack} boldLabel`}
        >
          {api ?? field}
        </m.Typography>
      </m.FormLabel>
      <m.TextField
        id={`${field}_label`}
        name={field}
        type="text"
        size="small"
        autoComplete="off"
        value={formValuesOutput[field]}
        onChange={handleInputChange}
      />
    </m.Stack>
  )

  const showCommonInput = (type) => (
    <m.FormControlLabel
      value={type?.toLowerCase()}
      color="primary"
      control={<m.Radio name="Type" color="primary" />}
      label={type}
    />
  )

  return (
    <>
      <m.Stack direction="row" spacing={2}>
        {showNameNickname(
          "Name",
          "Variable Name",
          handleNameChange,
          nameError,
          storedSatus.handeEmptyTextfield.missingName,
          isSameValues.isSameName
        )}
        {showNameNickname(
          "NickName",
          "Nickname",
          handleNicknameChange,
          nicknameError,
          storedSatus.handeEmptyTextfield.missingNickName,
          isSameValues.isSameNickName
        )}
      </m.Stack>
      <m.Stack direction="row" spacing={2} marginTop={"4px"}>
        {showNameNickname(
          "ColumnLabel",
          "Column Label",
          handleColumnChange,
          columnLabelError,
          storedSatus.handeEmptyTextfield.missingLabel,
          isSameValues.isSamecolumnLabel,
          handleChangeLabel
        )}
        <m.Stack
          display={"flex"}
          sx={{
            justifyContent: {
              xs: updateToggle || updateData ? "flex-start" : "center",
              sm: updateToggle || updateData ? "center" : "end",
            },
          }}
        >
          <m.Button
            sx={{ color: theme.palette.common.white, marginTop: "4px" }}
            onClick={handleDefaultColumnLabel}
            color="primary"
            variant="contained"
          >
            <m.Typography variant="caption" margin="4px">
              Default Column Label
            </m.Typography>
          </m.Button>
        </m.Stack>
      </m.Stack>
      {Software === storedSatus.emulatorSoftwareType.excel && (
        <m.Stack direction={"row"} spacing={2} marginTop={"4px"}>
          {showCommonCellWorksheet("Worksheet")}
          {showCommonCellWorksheet("Cell")}
        </m.Stack>
      )}
      {Software === storedSatus.emulatorSoftwareType.hosted && (
        <m.Stack direction={"row"} spacing={2} marginTop={"4px"}>
          {showCommonCellWorksheet("ApiLabel", "Api Label")}
        </m.Stack>
      )}
      {Software === storedSatus.emulatorSoftwareType.mathcad && (
        <m.Stack direction={"row"} spacing={2} marginTop={"4px"}>
          {showCommonCellWorksheet("Alias")}
        </m.Stack>
      )}
      {Software === storedSatus.emulatorSoftwareType.grasshopper && (
        <m.Stack direction={"row"} spacing={2} marginTop={"4px"}>
          {showCommonCellWorksheet("ParamName", "Param Name")}
        </m.Stack>
      )}
      {Software === storedSatus.emulatorSoftwareType.python && (
        <m.Stack direction={"row"} spacing={2} marginTop={"4px"}>
          {showNameNickname(
            "PythonVariableName",
            "Python Variable Name",
            handlePythonName,
            pythonVarError,
            storedSatus.handeEmptyTextfield.missingPythonVar
          )}
        </m.Stack>
      )}
      <m.Stack direction={"row"} spacing={2} marginTop={"4px"}>
        <m.FormControl>
          <m.FormLabel size="small" color="primary">
            <m.Typography
              variant="body1"
              fontWeight={650}
              className={`${classes.colorBlack} boldLabel`}
            >
              Input Type
            </m.Typography>
          </m.FormLabel>
          <m.RadioGroup
            name="Type"
            value={formValuesOutput.Type}
            onChange={handleInputChange}
            row
            size="small"
            aria-label="Type"
          >
            {showCommonInput("Number")}
            {showCommonInput("Quantity")}
            {showCommonInput("Text")}
          </m.RadioGroup>
        </m.FormControl>
        {formValuesOutput.Type === "quantity" && (
          <m.Stack marginTop={"4px"}>
            <m.FormLabel size="small" id="Units-label" color="primary">
              {storedSatus.renderFormLabel("Units", classes)}
            </m.FormLabel>
            <Autocomplete
              freeSolo
              onSelect={(e) => handleUnitChange(e)}
              value={
                formValuesOutput?.Units?.length > 0
                  ? formValuesOutput.Units
                  : ""
              }
              color="primary"
              name="Units"
              type="text"
              size="small"
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                return filtered
              }}
              onInputChange={(event, value, reason) =>
                handleAutocompleteChange(event, value, reason)
              }
              options={[...new Set(unique1.map((un) => un.split("-")[1]))]}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              sx={{ width: 150, marginLeft: -2.8 }}
              renderInput={(params) => (
                <m.TextField
                  {...params}
                  id="units"
                  name="Units"
                  type="text"
                  size="small"
                  color="primary"
                  required
                  error={unitError && formValuesOutput?.Units?.length === 0}
                  helperText={
                    !updateToggle
                      ? unitError &&
                        formValuesOutput?.Units?.length === 0 &&
                        "You must enter Units"
                      : unitError &&
                        (formValuesOutput?.Units?.length === 0 ||
                          !formValuesOutput?.Units) &&
                        "You must enter Units"
                  }
                />
              )}
            />
            {warning ? (
              <m.Typography
                variant="body2"
                className={`${classes.colorSecondary} warningText`}
              >
                You have Entered new
                <br /> Unit.
              </m.Typography>
            ) : (
              confirm && (
                <m.Typography
                  variant="body2"
                  className={`${classes.colorSuccessIcon} confirmingText`}
                >
                  Units: {UnitsGroup?.split("-")[0] || ""} is assigned.
                </m.Typography>
              )
            )}
          </m.Stack>
        )}
      </m.Stack>
      <>
        <m.FormLabel size="small" id="Increment-label" color="primary">
          {storedSatus.renderFormLabel("Description", classes)}
        </m.FormLabel>
        <m.TextField
          id="outlined-multiline-static"
          color="primary"
          multiline
          fullWidth
          rows={3}
          name="Description"
          size="small"
          autoComplete="off"
          required
          inputRef={myRef}
          onChange={handleInputChange}
          value={formValuesOutput?.Description}
          error={descriptionError && !formValuesOutput?.Description}
          helperText={
            descriptionError &&
            !formValuesOutput?.Description &&
            "You must add Description."
          }
        />
      </>
    </>
  )
}

export default VariableDefiner
