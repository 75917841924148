import { useState, useEffect, useRef } from "react"
import * as m from "@mui/material"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { useRecoilState, useRecoilValue } from "recoil"
import { useSearchParams } from "react-router-dom"
import {
  errorFieldsState,
  outputsParametersAndVariables,
} from "../../../state/projectState"
import { useWindowDimensions } from "../../../GlobalFileContainer"
import { ERROR_MESSAGE } from "../../../state/StatusState"

const LimitedSpaceList = ({ list, unit }) => {
  const [visibleCount, setVisibleCount] = useState(4)

  const formatList = (list, visibleCount) => {
    if (list.length <= visibleCount) {
      return list.join(", ")
    }

    const start = list.slice(0, Math.floor(visibleCount / 2))
    const end = list.slice(-Math.ceil(visibleCount / 2))

    return `${start.join(", ")}...${end.join(", ")}`
  }

  useEffect(() => {
    const calculateVisibleCount = () => {
      const avgItemWidth = (list[0].length + 4) * 5
      const count = Math.floor(200 / avgItemWidth)
      setVisibleCount(count - 1)
    }

    calculateVisibleCount()
  }, [])

  const formattedList = formatList(list, visibleCount)

  return (
    <m.Typography className="ed-small" variant="body2" noWrap>
      {formattedList}&nbsp;{unit !== "none" ? unit : ""}
    </m.Typography>
  )
}

function DropDowns(props) {
  const theme = m.useTheme()
  const { height } = useWindowDimensions()
  const [modeSearchParam] = useSearchParams()
  const tryitMode = modeSearchParam.get("mode")

  const title = props.variable.Name
  const type = props.variable.Domain.Type
  const columnlabel = props.variable.ColumnLabel
  const description = props.variable.Description

  const errorFields = useRecoilValue(errorFieldsState)
  const [parametersAndVariablesState, setParametersAndVariablesState] =
    useRecoilState(outputsParametersAndVariables)

  const [variables, setVariables] = useState({})
  const [parameters, setParameters] = useState({})
  const [dropdownError, setDropdownError] = useState(false)
  const [value, setValue] = useState("")
  const [selectedVariable, setSelectedVariable] = useState(false)

  const findVariable =
    parametersAndVariablesState?.variables.length > 0 &&
    parametersAndVariablesState?.variables.find(
      (val) => val.label === columnlabel
    )
  const findParameter =
    parametersAndVariablesState?.parameters.length > 0 &&
    parametersAndVariablesState?.parameters.find(
      (val) => val.label === columnlabel
    )
  const dropdownStyles = {
    commonStyle: {
      minWidth: 200,
      maxWidth: 200,
    },
    inputLabelStyle: {
      top: "-4px",
    },
    displayPropsStyle: {
      padding:
        props.pageOptimize && selectedVariable
          ? "8px 10px 8px 8px"
          : "10px 15px 10px 0px",
    },
    paperStyle: {
      maxHeight: height - 400,
      overflow: "auto",
    },
  }

  useEffect(() => {
    if (!props.page) {
      if (findVariable) {
        setSelectedVariable(true)
      }
      if (findParameter) {
        setValue(findParameter?.value)
      }
    }
    if (props.options.length === 1) {
      setValue(props.options[0])
    }
    if (
      props.variable.DefaultValue !== undefined &&
      props.variable.DefaultValue !== null
    ) {
      setValue(props.variable.DefaultValue)
    }
  }, [])

  useEffect(() => {
    setVariables({
      label: columnlabel,
      domain: {
        type: type,
        values: selectedVariable ? props.options : [],
      },
    })
  }, [selectedVariable])

  useEffect(() => {
    if (selectedVariable) {
      if (!findVariable) {
        setParametersAndVariablesState((prev) => ({
          ...prev,
          parameters: prev.parameters.filter(
            (value) => value.label !== columnlabel
          ),
          variables: [...prev.variables, variables],
        }))
      } else {
        setParametersAndVariablesState((prev) => ({
          ...prev,
          variables: prev.variables.map((v) =>
            v.label === columnlabel ? variables : v
          ),
        }))
      }
    }
  }, [variables])

  useEffect(() => {
    if (props.generateRandomInputs && !selectedVariable) {
      const randomIndex = Math.floor(Math.random() * props.options.length)
      setValue(props.options[randomIndex])
      props.setGenerateRandomInputs(false)
    }
  }, [props.generateRandomInputs])

  useEffect(() => {
    setParameters({
      label: columnlabel,
      value: value,
    })
  }, [value])

  useEffect(() => {
    if (value !== "") {
      if (!findParameter) {
        setParametersAndVariablesState((prev) => ({
          ...prev,
          parameters: [...prev.parameters, parameters],
        }))
      }
    } else {
      setParametersAndVariablesState((prev) => ({
        ...prev,
        parameters: prev.parameters.filter((val) => val.label !== columnlabel),
      }))
    }
  }, [parameters])

  useEffect(() => {
    if (
      props.postBody &&
      Object.keys(props.postBody).length > 0 &&
      value === ""
    ) {
      let extractedData = props.postBody?.FeaturesData?.filter(
        (item) => item.Label === columnlabel
      )
      let getValue = extractedData[0]?.Data[0]
      setValue(getValue)
    }
  }, [props.postBody])

  useEffect(() => {
    if (!value) {
      setDropdownError(errorFields)
    } else {
      setDropdownError(false)
    }
  }, [errorFields, value])

  useEffect(() => {
    props.updateFeatureOutput(props.inputindex, columnlabel, value)
  }, [value, tryitMode])

  const handleChange = (event) => {
    event.preventDefault()
    setValue(event.target.value)
    setParametersAndVariablesState((prev) => ({
      ...prev,
      parameters: prev.parameters.map((param) =>
        param.label === columnlabel
          ? { ...param, value: event.target.value }
          : param
      ),
    }))
  }

  const handleVariableSelection = () => {
    if (selectedVariable) {
      setParametersAndVariablesState((prev) => ({
        ...prev,
        variables: prev.variables.filter(
          (value) => value.label !== columnlabel
        ),
      }))
    }

    setSelectedVariable(!selectedVariable)
    setValue("")
  }

  return (
    <div className="ml-display-flex ml-flex-dir-col" title={description}>
      <m.Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <m.Typography
          variant="body2"
          className="ml-display-flex ml-align-center"
        >
          {title}
        </m.Typography>
        {!props.page && selectedVariable && (
          <m.IconButton
            sx={{ m: 0, p: 0 }}
            onClick={props.pageOptimize ? handleVariableSelection : undefined}
          >
            {props.pageOptimize && (
              <DeleteOutlineOutlinedIcon
                color="primary"
                sx={{ fontSize: "20px", m: 0, p: 0 }}
              />
            )}
          </m.IconButton>
        )}
      </m.Box>
      <div className="calc-range-hover">
        <m.FormControl
          style={dropdownStyles.commonStyle}
          fullWidth
          error={dropdownError}
        >
          {dropdownError && (
            <m.InputLabel
              shrink={true}
              id="demo-simple-select-label"
              variant="filled"
              style={dropdownStyles.inputLabelStyle}
            >
              {dropdownError ? ERROR_MESSAGE.requiredField : ""}
            </m.InputLabel>
          )}
          <m.Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              textAlign: "center",
              ".MuiSelect-icon": { right: props.unit !== "none" ? "20%" : 7 },
            }}
            value={props.pageOptimize || selectedVariable ? "" : value}
            displayEmpty={props.pageOptimize || selectedVariable}
            disabled={
              props.options.length === 1 ||
              (!props.pageOptimize && selectedVariable)
            }
            autoFocus={!props.pageOptimize && props.inputindex === 0}
            endAdornment={
              props.unit !== "none" && (
                <m.InputAdornment position="end">{props.unit}</m.InputAdornment>
              )
            }
            SelectDisplayProps={{
              style: dropdownStyles.displayPropsStyle,
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                style: dropdownStyles.paperStyle,
              },
              getContentAnchorEl: null,
            }}
            onChange={
              props.pageOptimize || selectedVariable ? null : handleChange
            }
          >
            {!props.page && selectedVariable && (
              <m.MenuItem value="">
                <em>All Selected</em>
              </m.MenuItem>
            )}
            {props.options.map((option, index) => {
              return (
                <m.MenuItem
                  disabled={!props.page && selectedVariable}
                  key={option + index}
                  value={option}
                >
                  {option}
                </m.MenuItem>
              )
            })}
          </m.Select>
        </m.FormControl>
        {props.pageOptimize && !selectedVariable && (
          <m.Box
            sx={{
              backgroundColor: theme.palette.grey.light,
              cursor: "pointer",
              opacity: !props.pageOptimize && selectedVariable ? 1 : 0,
            }}
            className="selected-input-div"
            onClick={props.pageOptimize && handleVariableSelection}
          >
            <m.Typography>
              {props.pageOptimize
                ? selectedVariable
                  ? ""
                  : "Set as variable"
                : ""}
            </m.Typography>
          </m.Box>
        )}
      </div>
      <LimitedSpaceList list={props.options} unit={props.unit} />
    </div>
  )
}

export default DropDowns
