import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { RecoilRoot } from "recoil"
import jwt_decode from "jwt-decode"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { EventType } from "@azure/msal-browser"
import { PublicClientApplication } from "@azure/msal-browser"
import App from "./App"
import { msalConfig } from "./authConfig"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { EmulationLab } from "./GlobalFileContainer"
import ErrorBoundary from "./components/ErrorBoundary"
import { appInsights } from "./errorManagement/AppInsights"

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.handleRedirectPromise().then((result) => {
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
    }
  })
})

const fetchNewJwt = async (msalToken) => {
  try {
    localStorage.removeItem("JWTToken")
    const bodyParameters = {
      email: localStorage.getItem("email"),
      token: msalToken,
    }

    const response = await EmulationLab.post("/users/auth", bodyParameters)
    const token = response.data.access_token

    localStorage.setItem("JWTToken", token)
    return token
  } catch (error) {
    console.error(error)
  }
}

const updateRequestToken = async (request) => {
  const token = localStorage.getItem("JWTToken")

  if (request.url.includes("/users/auth") && !request.data.token) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"],
        account: msalInstance.getAllAccounts()[0],
      })

      request.data.token = response.accessToken
      return request
    } catch (error) {
      console.log(error)
      throw error
    }
  } else if (token) {
    const decoded = jwt_decode(token)
    const today = new Date()
    const expDate = new Date(0)
    expDate.setUTCSeconds(decoded.exp - 30)

    if (expDate < today) {
      try {
        const response = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
          account: msalInstance.getAllAccounts()[0],
        })

        const newToken = await fetchNewJwt(response.accessToken)
        localStorage.setItem("JWTToken", newToken)
        request.headers.Authorization = `Bearer ${newToken}`
        return request
      } catch (error) {
        console.log(error)
        throw error
      }
    } else {
      request.headers.Authorization = `Bearer ${token}`
    }
  }

  return request
}
EmulationLab.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    appInsights.trackException({
      exception: error?.response,
      severityLevel: error?.config?.headers?.severity || SeverityLevel.Critical,
      properties: { typeName: "Error" },
    })
    return Promise.reject(error)
  }
)
const handleRequestError = (error) => {
  return Promise.reject(error)
}

const script = document.createElement("script")
script.src = "https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/rhino3dm.min.js"
const RootComponent = () => {
  useEffect(() => {
    EmulationLab.interceptors.request.use(
      updateRequestToken,
      handleRequestError
    )
  }, [])

  return (
    <React.StrictMode>
      <RecoilRoot>
        <ErrorBoundary>
          <App msalInstance={msalInstance} />
        </ErrorBoundary>
      </RecoilRoot>
    </React.StrictMode>
  )
}

script.addEventListener(
  "load",
  ReactDOM.render(<RootComponent />, document.getElementById("root"))
)
document.body.appendChild(script)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
