import React, { useEffect } from "react"
import { useRecoilValue } from "recoil"
import * as core from "@material-ui/core"
import { styled } from "@mui/material/styles"
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined"
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import { tooltipClasses } from "@mui/material/Tooltip"
import "./MLEvaluation.css"
import { emulatorStatus } from "../../state/projectState"
import { ALGO_STATUS, trainingDeployed } from "../../state/StatusState"
import useStyles from "../card_style"
import { Typography } from "@mui/material"

export const EvaluateTooltip = styled(({ className, ...props }) => (
  <core.Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12.8),
    border: "1px solid #dadde9",
  },
}))

const caseFailed = () => {
  return (
    <div>
      <Typography variant="body2">
        Some part of the ML training failed for this algorithm.
        <br /> This is expected, but if all failed, there could be an issue with
        the data
      </Typography>
    </div>
  )
}

const caseRunning = () => {
  return (
    <div>
      <Typography variant="body2">
        Emulator is either running or has stalled, this is expected 3 or 4
        algorithms on any one output
      </Typography>
    </div>
  )
}

function MLIcons({
  modifiedName,
  i,
  show,
  trainingObject,
  key,
  size,
  sizeforPipeline,
  speed,
  timeForpipeline,
  algoName,
  setAlgoName,
  setKey,
  setShowActive,
  setRunSingleAlgorithm,
  runSingleAlgorithm,
  setShowRerun,
  reRunButton,
}) {
  const classes = useStyles()
  const emulatorStatusState = useRecoilValue(emulatorStatus)

  var obj = {}
  obj[key] = modifiedName

  const selectedAlgoValues = trainingObject?.algorithms[modifiedName]
  const activeAlgo = trainingObject?.active_algorithm
  const evaluationData = trainingObject?.evaluation
  const newSize = size ? +size.split(" ")[0] : ""
  const newSpeed = speed ? +speed?.split(" ")[0] : ""
  const evalData = { ...evaluationData }

  useEffect(() => {
    show === -1 && setAlgoName("")
    i === show && setAlgoName(activeAlgo)
    setRunSingleAlgorithm({
      target: "",
      algorithms: [""],
    })
    setShowRerun(false)
  }, [i, show])

  function formatBytes(bytes, decimals) {
    if (!bytes) return "0 Bytes"
    const singleByte = bytes.split(" ")[0]

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(singleByte) / Math.log(k))

    return `${parseFloat((singleByte / Math.pow(k, i)).toFixed(dm))} ${
      sizes[i]
    }`
  }

  const handleClick = () => {
    setShowActive(true)
    setShowRerun(!reRunButton)

    setKey(selectedAlgoValues.execution_status === ALGO_STATUS.done ? obj : "")

    selectedAlgoValues.execution_status === ALGO_STATUS.done &&
      setAlgoName(modifiedName)

    setRunSingleAlgorithm({
      target: key,
      algorithms: [modifiedName],
    })
  }

  const handleRerunClick = () => {
    selectedAlgoValues?.timeout && getTimeoutStatus(selectedAlgoValues?.timeout)
      ? setShowRerun(false)
      : setShowRerun(!reRunButton)
    setShowActive(false)

    setRunSingleAlgorithm({
      target: key,
      algorithms: [modifiedName],
    })

    setAlgoName("")
  }

  function convertTime(timeString) {
    try {
      const roundedTime = parseFloat(timeString).toFixed(3)
      return roundedTime + "s"
    } catch {
      console.error("unable to convert time")
      return timeString
    }
  }

  const getTimeoutStatus = (timeout) => {
    if (timeout) {
      const convertedTimeout = new Date(timeout)
      const currentDateUTC = new Date().getTime() - 330 * 60 * 1000
      return currentDateUTC > convertedTimeout
    }
    return false
  }

  const getStatus = () => {
    return trainingObject?.algorithms[modifiedName]?.execution_status || null
  }

  const getStatusIcon = () => {
    const status = selectedAlgoValues?.execution_status

    if (status === "done") {
      return modifiedName === activeAlgo ? (
        <CheckCircleIcon
          fontSize="small"
          className={classes.colorSuccessIcon}
        />
      ) : (
        <CheckCircleOutlineOutlinedIcon
          fontSize="small"
          className={classes.colorSuccessIcon}
        />
      )
    } else if (status === "ready") {
      return (
        <CircleOutlinedIcon fontSize="small" className={classes.colorGraySec} />
      )
    } else if (status === "pending") {
      return (
        <AdjustOutlinedIcon fontSize="small" className={classes.colorGraySec} />
      )
    } else if (
      status === "failed" &&
      !getTimeoutStatus(selectedAlgoValues?.timeout)
    ) {
      return (
        <CancelOutlinedIcon
          fontSize="small"
          className={classes.colorDangerIcon}
        />
      )
    } else if (
      status === "running" &&
      !getTimeoutStatus(selectedAlgoValues?.timeout)
    ) {
      return (
        <DonutLargeOutlinedIcon
          fontSize="small"
          className={`${classes.colorPrimaryMain} mliconrunning`}
        />
      )
    } else if (
      status === "failed" &&
      getTimeoutStatus(selectedAlgoValues?.timeout)
    ) {
      return (
        <CancelOutlinedIcon
          className={classes.colorLightDangerIcon}
          fontSize="small"
        />
      )
    }
    return null
  }

  const renderBadgeOrFiberIcon = (rank) => {
    if (rank) {
      return (
        <core.Badge
          badgeContent={rank}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          className={classes.colorGraySec}
          id="mlrankbadge"
          color="black"
        >
          <CircleOutlinedIcon
            fontSize="small"
            className={classes.colorWhiteIcon}
          />
        </core.Badge>
      )
    } else {
      return (
        <FiberManualRecordIcon
          fontSize="small"
          className={classes.colorGraySec}
          style={{ marginBottom: "3.5px" }}
        />
      )
    }
  }

  const renderAdditionalIcons = (show) => {
    if (show) {
      return (
        <>
          <core.Box className="margin-bottom-5 ml-display-flex ml-justify-start">
            {newSize >= sizeforPipeline ? (
              <FiberManualRecordIcon
                fontSize="small"
                className={classes.colorGraySec}
              />
            ) : (
              (sizeforPipeline > newSize && sizeforPipeline < 2 * newSize && (
                <CancelOutlinedIcon
                  fontSize="small"
                  className={classes.colorLightDangerIcon}
                />
              )) ||
              (sizeforPipeline >= 2 * newSize && (
                <CancelOutlinedIcon
                  fontSize="small"
                  className={classes.colorDangerIcon}
                />
              )) ||
              (sizeforPipeline === 0 && (
                <FiberManualRecordIcon
                  fontSize="small"
                  className={classes.colorGraySec}
                />
              ))
            )}
          </core.Box>
          <core.Box className="margin-bottom-5 ml-display-flex ml-justify-start">
            {newSpeed >= timeForpipeline ? (
              <FiberManualRecordIcon
                fontSize="small"
                className={classes.colorGraySec}
              />
            ) : (
              (timeForpipeline > newSpeed && timeForpipeline < 2 * newSpeed && (
                <CancelOutlinedIcon
                  fontSize="small"
                  className={classes.colorLightDangerIcon}
                />
              )) ||
              (timeForpipeline >= 2 * newSpeed && (
                <CancelOutlinedIcon
                  fontSize="small"
                  className={classes.colorLightDangerIcon}
                />
              )) ||
              (timeForpipeline === 0 && (
                <FiberManualRecordIcon
                  fontSize="small"
                  className={classes.colorGraySec}
                />
              ))
            )}
          </core.Box>
        </>
      )
    }
    return null
  }

  const handleRunOrRerun = (e) => {
    e.stopPropagation()
    if (!trainingDeployed?.includes(emulatorStatusState)) {
      return
    }

    if (show === i) {
      if (selectedAlgoValues?.execution_status === ALGO_STATUS.done) {
        handleClick()
      } else {
        handleRerunClick()
      }
    }
  }

  function renderAlgoHoverText(modifiedName) {
    const status = getStatus(modifiedName)

    if (status === ALGO_STATUS.failed) {
      return caseFailed()
    }

    if (status === ALGO_STATUS.running) {
      return caseRunning()
    }

    return Object.keys(evalData)?.map((key, index) => {
      const aName = key !== "last_evaluated" ? key?.split(": ")[1] : ""

      return (
        <div key={`training${key}` + index}>
          {key === modifiedName && (
            <div>
              <Typography variant="body2">{aName}</Typography>
              <br />
              {evalData[key].synopsis && (
                <>
                  <Typography variant="body2">
                    {evalData[key].synopsis}
                  </Typography>
                  <br />
                </>
              )}
              <Typography variant="body2">Reviewer Note:</Typography>
              <br />
              <Typography variant="body2">
                File Size: {formatBytes(selectedAlgoValues["pipeline_size"], 2)}
              </Typography>
              <br />
              <Typography variant="body2">
                Query Speed:
                {convertTime(selectedAlgoValues["mean_predict_time"])}
              </Typography>
            </div>
          )}
        </div>
      )
    })
  }

  return (
    <core.Box
      className={`newAlignAlgo ml-display-flex ml-flex-dir-col ${
        show === i && algoName === modifiedName
          ? classes.backgroundPrimaryLight
          : show === i && runSingleAlgorithm.algorithms[0] === modifiedName
          ? classes.bgLightDangerIcon
          : ""
      }`}
      onClick={handleRunOrRerun}
    >
      <EvaluateTooltip
        placement="top"
        title={
          <React.Fragment>{renderAlgoHoverText(modifiedName)}</React.Fragment>
        }
      >
        <core.Box>
          {show === i && (
            <core.Box className="ml-justify-center ml-text-center ml-display-flex eval-m-1-2">
              <core.Typography
                id="i"
                variant="body1"
                className={classes.algoVertical}
              >
                {modifiedName?.split(": ")[1] || "N/A"}
              </core.Typography>
            </core.Box>
          )}
          <core.Box className={show === i && "eval-m-1-3"}>
            <core.Box className="margin-bottom-5 ml-display-flex ml-justify-start">
              {getStatusIcon()}
            </core.Box>
            <core.Box className="ml-display-flex">
              {renderBadgeOrFiberIcon(
                trainingObject?.evaluation?.[modifiedName]?.rank
              )}
            </core.Box>
            <core.Box className="margin-bottom-5 ml-display-flex ml-justify-start">
              <FiberManualRecordIcon
                fontSize="small"
                className={classes.colorGraySec}
              />
            </core.Box>
            {renderAdditionalIcons(show === i)}
          </core.Box>
        </core.Box>
      </EvaluateTooltip>
    </core.Box>
  )
}

export default MLIcons
