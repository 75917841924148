import { useState, useEffect, useCallback } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { debounce } from "lodash"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import {
  apiNotificationData,
  appLayout,
  cardFilterSearchStringState,
  fetchDataNotes,
  selectedVersion,
  siteWideRole,
} from "../../state/projectState"
import "./navContent.css"
import {
  AdvanceSearchFilter,
  ErrorBoundary,
  MetaTags,
  PosterGrid,
  useNavigatePath,
  useWindowDimensions,
} from "../../GlobalFileContainer"
import * as requests from "../../state/requests"
import { convertToUppercase, fontWeight650 } from "../../state/StatusState"
import CommonMobileNav from "./CommonMobileNav"
import newIcons from "../../assets/newIcons"

export const classNameString = ({ isActive }) => {
  const baseClass = "gl-tab-nav-item main-heading-emu"
  const activeClass = "active gl-tab-nav-item-active"

  return `${baseClass} ` + `${isActive ? activeClass : ""}`
}

export const renderFilterButton = (handleOpenFilters, isAppliedFilter) => {
  const theme = m.useTheme()

  return (
    <m.Button
      variant="contained"
      sx={{
        height: "39px",
        color: theme.palette.common.white,
        minWidth: isAppliedFilter ? "120px" : "auto",
      }}
      size="small"
      color="primary"
      onClick={(e) => handleOpenFilters(e)}
    >
      <FilterAltOutlinedIcon />
      <m.Typography variant="caption">
        {isAppliedFilter ? "Edit" : null} Filter
      </m.Typography>
    </m.Button>
  )
}

let navigationOptions = [
  // {
  //   group: "explore",
  //   to: "/",
  //   buttonText: "Stable",
  //   routerPath: "/",
  //   fetchUrl: requests.fetchStableCards,
  // },
  {
    group: "explore",
    to: "/trending",
    routerPath: "trending",
    buttonText: "Trending",
    fetchUrl: requests.fetchTrending,
  },
  {
    group: "explore",
    to: "/recent",
    routerPath: "recent",
    buttonText: "New",
    fetchUrl: requests.fetchMostRecent,
  },
  {
    group: "explore",
    to: "/mostused",
    routerPath: "mostused",
    buttonText: "Most Used",
    fetchUrl: requests.fetchMostUsed,
  },
  {
    group: "explore",
    to: "/toprated",
    routerPath: "toprated",
    buttonText: "Top Rated",
    fetchUrl: requests.topRated,
  },
  {
    group: "explore",
    to: "/structures",
    routerPath: "structures",
    buttonText: "Structures",
    fetchUrl: requests.fetchStructures,
  },
  {
    group: "explore",
    to: "/enclosures",
    routerPath: "enclosures",
    buttonText: "Enclosures",
    fetchUrl: requests.fetchEnclosures,
  },
  {
    group: "explore",
    to: "/securedesign",
    routerPath: "securedesign",
    buttonText: "SecureDesign",
    fetchUrl: requests.fetchSecureDesign,
  },
  {
    group: "my",
    to: "/my/all",
    routerPath: "all",
    buttonText: "All",
    fetchUrl: requests.fetchUserLibrary,
  },
  {
    group: "my",
    to: "/my/director",
    routerPath: "director",
    buttonText: "As Director",
    fetchUrl: requests.fetchUserAsDirector,
  },
  {
    group: "my",
    to: "/my/collab",
    routerPath: "collab",
    buttonText: "As Collaborator",
    fetchUrl: requests.fetchUserCollaborations,
  },
  {
    group: "my",
    to: "/my/favorites",
    routerPath: "favorites",
    buttonText: "Favorites",
    fetchUrl: requests.fetchUserLibraryFavorites,
  },
  // {
  //   group: "explore",
  //   to: "/all/cards",
  //   buttonText: "All",
  //   routerPath: "all/cards",
  //   fetchUrl: requests.fetchAll,
  // },
]

const textOptions = {
  my: {
    title: "My Emulators",
    description:
      "I'm working really hard on these to help revolutionize the way we do design at Walter P Moore",
  },
  explore: {
    title: "Explore Emulators",
  },
}

export const fetchTheNotifications = (setNotificationCount) => {
  fetchDataNotes()
    .then((data) => {
      setNotificationCount(data)
    })
    .catch((error) => {
      console.error("Error fetching API data:", error)
    })
}

const RedirectRoute = () => {
  const navigate = useNavigate()
  const path = window.location.href

  useEffect(() => {
    const redesignedPath = useNavigatePath(path)
    redesignedPath !== null && navigate(redesignedPath)
  }, [])

  return null
}

export default function ExploreNav({ navGroup, setOpenFilter, openFilter }) {
  const filterOptions = ["Tags", "Category", "Modes", "Status", "Members"]
  const { width } = useWindowDimensions()
  const [cardsNameFilter, setCardsNameFilter] = useRecoilState(
    cardFilterSearchStringState
  )
  const setNotificationCount = useSetRecoilState(apiNotificationData)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const [layout, setLayout] = useRecoilState(appLayout)
  const setSelectedVersions = useSetRecoilState(selectedVersion)

  const [navOptions, setNavOptions] = useState(navigationOptions)
  const [anchorEl, setAnchorEl] = useState(null)
  const [appliedFilters, setAppliedFilters] = useState({
    tagList: [],
    categoryList: [],
    modeList: [],
    statusList: [],
    memberList: [],
    others: [],
  })
  const [cards, setCards] = useState([])
  const [menuItem, setMenuItem] = useState("All")
  const [isLoading, setIsLoading] = useState(true)
  const [searchFieldVal, setSearchFieldVal] = useState("")

  const theme = core.useTheme()
  const isAppliedFilter = Object.entries(appliedFilters)
    .filter(([key, value]) => key !== "others")
    .some(([key, value]) => value.length > 0)

  useEffect(() => {
    setSearchFieldVal(cardsNameFilter)
    setSelectedVersions(null)
  }, [])

  useEffect(() => {
    if (navGroup === "my") {
      setAppliedFilters((prev) => ({
        ...prev,
        others: [],
      }))
    }
  }, [navGroup])

  useEffect(() => {
    setLayout(width < 960 ? "list" : "grid")
  }, [width])

  useEffect(() => {
    setIsLoading(true)
    const filteredOptions = navigationOptions
      .filter((navOpt) => navGroup == navOpt.group)
      .filter((opt) => opt.routerPath !== "/")

    if (window.location.pathname === "/") {
      siteWideRoleState === "consumer"
        ? filteredOptions.push({
            group: "explore",
            to: "/",
            buttonText: "Stable",
            routerPath: "/",
            fetchUrl: requests.fetchStableCards,
          })
        : filteredOptions.push({
            group: "explore",
            to: "/",
            buttonText: "All",
            routerPath: "/",
            fetchUrl: requests.fetchAll,
          })
    }

    setNavOptions(filteredOptions)
  }, [navGroup, siteWideRoleState])

  useEffect(() => {
    fetchTheNotifications(setNotificationCount)
  }, [navGroup])

  const changeHandler = (event) => {
    const newValue = event.target.value
    setSearchFieldVal(newValue)
    debounceChangeHandler(event)
  }

  const debounceChangeHandler = debounce((a) => {
    setCardsNameFilter(a.target.value.toLowerCase().trim())
  }, 300)

  const handleOpenFilters = (e) => {
    setAnchorEl(e.currentTarget)
    setOpenFilter(true)
  }
  const toggleDrawer = (open) => (event) => {
    if (
      !(
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      )
    ) {
      setOpenFilter(open)
    }
  }

  const resetFilter = () => {
    setAppliedFilters({
      tagList: [],
      modeList: [],
      statusList: [],
      categoryList: [],
      memberList: [],
      others: [],
    })
  }

  const handleKeyDown = (event) => {
    const presentAlready = appliedFilters.others.some(
      (str) => str.toLowerCase().trim() === searchFieldVal.toLowerCase().trim()
    )
    if (
      window.location.pathname !== "/" ||
      searchFieldVal.trim() === "" ||
      presentAlready
    ) {
      return
    }
    if (event.key === "Enter") {
      setAppliedFilters((prev) => ({
        ...prev,
        others: [...prev.others, searchFieldVal],
      }))
      setSearchFieldVal("")
      setCardsNameFilter("")
    }
  }

  const renderSearchField = (xs, md) => (
    <m.TextField
      sx={{ display: { xs: xs, sm: md } }}
      type="search"
      name="name"
      variant="outlined"
      placeholder="Search..."
      size="small"
      spellCheck="false"
      autoComplete="off"
      style={{
        backgroundColor: theme.palette.common.white,
        minWidth: md === "flex" ? "320px" : "auto",
      }}
      value={searchFieldVal}
      onChange={changeHandler}
      onKeyDown={handleKeyDown}
    />
  )

  const handleDisplay = (event, newlayout) => {
    setLayout(newlayout)
  }

  const deleteFilterHandler = (category, value) => {
    setAppliedFilters((prev) => ({
      ...prev,
      [category]: prev[category].filter((item) => item !== value),
    }))
  }

  return (
    <>
      <MetaTags />
      <core.Paper
        elevation={0}
        className="App-paper"
        style={{ backgroundColor: theme.palette.grey.light }}
      >
        <m.Grid container direction={"column"} padding={"24px 32px"}>
          <m.Grid item paddingBottom={"24px"}>
            <m.Typography variant="h4" fontWeight={fontWeight650}>
              {textOptions[navGroup].title}
            </m.Typography>
          </m.Grid>
          <m.Grid item paddingBottom={"24px"}>
            <m.Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={navGroup === "my" ? "space-between" : "end"}
            >
              {navGroup === "my" ? (
                <ErrorBoundary>
                  <CommonMobileNav
                    navOptions={navOptions}
                    menuItem={menuItem}
                    setMenuItem={setMenuItem}
                  />
                </ErrorBoundary>
              ) : (
                <m.Grid
                  container
                  direction="row"
                  rowSpacing={1}
                  columnSpacing={1}
                >
                  {Object.entries(appliedFilters).map(([category, items]) =>
                    items.map((item, index) => (
                      <m.Grid key={index} item xs="auto">
                        <m.Chip
                          sx={{
                            backgroundColor: theme.palette.common.white,
                            cursor: "pointer",
                          }}
                          variant="outlined"
                          label={
                            <m.Typography variant="body2" fontWeight={600}>
                              {convertToUppercase(item)}
                            </m.Typography>
                          }
                          onDelete={() => deleteFilterHandler(category, item)}
                        />
                      </m.Grid>
                    ))
                  )}
                </m.Grid>
              )}
              <m.Box
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {renderSearchField("none", "flex")}
                {renderFilterButton(handleOpenFilters, isAppliedFilter)}
                <m.ToggleButtonGroup
                  disabled={width < 960}
                  value={layout}
                  exclusive
                  onChange={handleDisplay}
                  aria-label="text alignment"
                >
                  <m.ToggleButton value="grid">
                    <img
                      height={"16px"}
                      src={newIcons["layoutGrid"]}
                      alt={"layoutGrid"}
                    />
                  </m.ToggleButton>
                  <m.ToggleButton value="list">
                    <img
                      height={"16px"}
                      src={newIcons["layoutList"]}
                      alt={"layoutList"}
                    />
                  </m.ToggleButton>
                </m.ToggleButtonGroup>
              </m.Box>
            </m.Box>
          </m.Grid>
          <m.Grid item className="explore-allcards">
            <Routes>
              {navOptions.map((navigationOption) => {
                return navigationOption.children ? (
                  navigationOption.children.map((navChild) => (
                    <Route
                      key={navChild.group + navChild.to}
                      path={navChild.routerPath}
                      exact
                      element={
                        <ErrorBoundary>
                          <PosterGrid
                            fetchUrl={navChild.fetchUrl}
                            appliedFilters={appliedFilters}
                            cards={cards}
                            setCards={setCards}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                          />
                        </ErrorBoundary>
                      }
                    />
                  ))
                ) : (
                  <Route
                    key={navigationOption.group + navigationOption.to}
                    path={navigationOption.routerPath}
                    exact
                    element={
                      <ErrorBoundary>
                        <PosterGrid
                          fetchUrl={navigationOption.fetchUrl}
                          appliedFilters={appliedFilters}
                          cards={cards}
                          setCards={setCards}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                        />
                      </ErrorBoundary>
                    }
                  />
                )
              })}
              <Route path="/redirect" exact element={<RedirectRoute />} />
            </Routes>
            <ErrorBoundary>
              <AdvanceSearchFilter
                toggleDrawer={toggleDrawer}
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                anchorEl={anchorEl}
                cards={cards}
                isAppliedFilter={isAppliedFilter}
                resetFilter={resetFilter}
                renderSearchField={renderSearchField}
                filterOptions={filterOptions}
              />
            </ErrorBoundary>
          </m.Grid>
        </m.Grid>
      </core.Paper>
    </>
  )
}
