import { ResponsiveScatterPlotCanvas } from "@nivo/scatterplot"
import { nivoTheme } from "./nivoTheme"

export function getRandomSlice(inputData, sliceLength) {
  const indexes = inputData.map((el, i) => i)
  const shuffledIndexes = indexes.sort(() => 0.5 - Math.random())

  const selectedIndexes = shuffledIndexes.slice(0, sliceLength)
  return selectedIndexes.map((i) => inputData[i])
}

const ScatterPlot = ({ graph, capturVersion, iterator }) => {
  if (Object.keys(graph).length === 0) {
    if (capturVersion) {
      iterator()
    }
    return <>No graph to display</>
  } else if (graph.data.length === 0) {
    if (capturVersion) {
      iterator()
    }
    return <>No data returned from back end to display</>
  }
  var data = graph.data[0].data
  if (data.length > 20000) {
    data = getRandomSlice(data, 20000)
  }
  if (graph.xType === "text" || graph.xType === "categorical") {
    try {
      data.sort((a, b) => a.x.localeCompare(b.x))

      data.sort((a, b) => {
        let fa = a.x.toLowerCase(),
          fb = b.x.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
    } catch {
      //pass
    }
  }

  if (graph.yType === "text" || graph.yType === "categorical") {
    try {
      data.sort((a, b) => a.y.localeCompare(b.y))
      data.sort((a, b) => {
        let fa = a.y.toLowerCase(),
          fb = b.y.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })
    } catch (e) {
      //pass
    }
  }
  const maxYValue = Math.max(...data.map((item) => item.y))
  const minXValue = Math.min(...data.map((item) => item.x))

  if (capturVersion) {
    return (
      <ResponsiveScatterPlotCanvas
        data={[{ data }]}
        animate={false}
        colors={{ scheme: "category10" }}
        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
        xScale={
          graph.xType === "text" || graph.xType === "categorical"
            ? { type: "point" }
            : { type: "linear", min: "auto", max: "auto" }
        }
        yScale={
          graph.y === "text" || graph.yType === "categorical"
            ? { type: "point" }
            : { type: "linear", min: "auto", max: "auto" }
        }
        nodeSize={4}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
      />
    )
  } else {
    return (
      <ResponsiveScatterPlotCanvas
        data={[{ data }]}
        theme={nivoTheme}
        colors={{ scheme: "category10" }}
        enableGridX={false}
        enableGridY={false}
        margin={{ top: 30, right: 10, bottom: 120, left: 80 }}
        xScale={
          graph.xType === "text" || graph.xType === "categorical"
            ? { type: "point" }
            : {
                type: "linear",
                min: Math.floor(minXValue) - 0.5,
                max: "auto",
              }
        }
        xFormat={function (e) {
          return Boolean(graph.xUnits) || graph.xUnits === "none"
            ? e
            : e + ` ${graph.xUnits}`
        }}
        yScale={
          graph.yType === "text"
            ? { type: "point" }
            : {
                type: "linear",
                min: "auto",
                max: maxYValue + 10,
              }
        }
        yFormat={function (e) {
          return Boolean(graph.xUnits) || graph.yUnits === "none"
            ? e
            : e + ` ${graph.yUnits}`
        }}
        nodeSize={5}
        axisTop={null}
        axisRight={null}
        axisBottom={
          graph.xType === "text" || graph.xType === "categorical"
            ? {
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -65,
                legend: `${graph.xLabel}`,
                legendPosition: "middle",
                legendOffset: 75,
              }
            : {
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -65,
                legend: `${graph.xLabel} (${graph.xUnits})`,
                legendPosition: "middle",
                legendOffset: 46,
              }
        }
        axisLeft={
          graph.yType === "text" || graph.yType === "categorical"
            ? {
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: `${graph.yLabel}`,
                legendPosition: "middle",
                legendOffset: -60,
              }
            : {
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: `${graph.yLabel} (${graph.yUnits})`,
                legendPosition: "middle",
                legendOffset: -60,
              }
        }
      />
    )
  }
}

export default ScatterPlot
