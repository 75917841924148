import { Dialog, Snackbar } from "@material-ui/core"
import { Alert, AlertTitle } from "@mui/material"
import { handleKeyDown } from "../state/projectState"

function EmulationSelectionAlert({ title, setOpen, open, severity, children }) {
  const handleClose = () => setOpen(false)

  return (
    <Dialog
      onKeyDown={(e) => handleKeyDown(e, handleClose)}
      onClose={handleClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={severity}>
          <AlertTitle>{title}</AlertTitle>
          {children}
        </Alert>
      </Snackbar>
    </Dialog>
  )
}
export default EmulationSelectionAlert
