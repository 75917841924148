import { useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import { AppBar, Tab, Tabs } from "@mui/material"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import "./CalculatorConfig.css"

function panelProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  }
}

function CalcFileUpload({
  uploadOpen,
  closeCalcManager,
  loader,
  fileList,
  setFileList,
  submitData,
  calcTabIndex,
  setCalcTabIndex,
  getFiles,
}) {
  const showNotification = useRecoilValue(storedSates.notificationState)
  const setFiles = useSetRecoilState(storedSates.filesFromDropzone)

  const handleChange = (event, newValue) => {
    setFileList([])
    setFiles([])
    setCalcTabIndex(newValue)
  }

  return (
    <core.Dialog
      onKeyDown={(e) => storedSates.handleKeyDown(e, closeCalcManager)}
      open={uploadOpen}
      fullWidth={true}
      maxWidth="lg"
    >
      <file.AddDialogTitle
        callFunction={closeCalcManager}
        title={"Calc File Manager"}
      />
      <core.DialogContent id="file-mang">
        <AppBar position="static" color="default">
          <Tabs
            value={calcTabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
          >
            <Tab label="Calc Files" {...panelProps(0)} />
            <Tab label="Supporting Files" {...panelProps(1)} />
          </Tabs>
        </AppBar>
        {loader && (
          <file.Loader
            margin={"10px"}
            linear={true}
            message="Loading Data..."
          />
        )}
        <file.UploadEmulatorFiles
          fileType={calcTabIndex === 0 ? "calculator" : "supporting"}
          getFiles={getFiles}
          fileList={fileList}
          setFileList={setFileList}
          showNotification={showNotification}
          submitData={submitData}
        />
      </core.DialogContent>
    </core.Dialog>
  )
}

export default CalcFileUpload
