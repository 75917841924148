import { useEffect, useLayoutEffect, useState } from "react"
import { Grid } from "@mui/material"
import {
  DropDowns,
  RangeInput,
  ErrorBoundary,
} from "../../../GlobalFileContainer"
import useStyles from "../../card_style"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  getUnitList,
  matchUnit,
  outputsParametersAndVariables,
} from "../../../state/projectState"

const generatePostBody = (calculatorId, features) => {
  return {
    emulator_id: calculatorId,
    FeaturesData: features,
  }
}

const generateSingleFeaturePostPart = (label, featureValue) => {
  return {
    Label: label,
    Data: [String(featureValue)],
  }
}

const CalculatorInput = (props) => {
  const classes = useStyles()
  const searchParamsString = window.location.search
  const [features, setFeatures] = useState([])

  const setParametersAndVariablesState = useSetRecoilState(
    outputsParametersAndVariables
  )
  const uniqueList = useRecoilValue(getUnitList)

  useEffect(() => {
    if (
      searchParamsString.length > 0 &&
      window.location.pathname.includes("tryit")
    ) {
      const urlSearchParams = new URLSearchParams(searchParamsString)
      urlSearchParams.delete(urlSearchParams.keys().next().value)

      const searchParamsObject = Object.fromEntries(urlSearchParams.entries())

      const featuresData = Object.entries(searchParamsObject).map(
        ([key, value]) => {
          const findLabel = props.calcConfig.InputVariables.find(
            (input) => input.ColumnLabel === key
          )
          const selectedKey = findLabel ? key : `in ${key}`
          return generateSingleFeaturePostPart(selectedKey, value)
        }
      )
      props.setPostBody2(generatePostBody(props.calcId, featuresData))
    }
  }, [])

  useLayoutEffect(() => {
    setFeatures(Array(props.calcConfig.InputVariables.length))
  }, [])

  const updateFeatureOutput = (i, label, featureValue) => {
    setFeatures((arr) => {
      const updatedArr = Object.assign([], arr)
      updatedArr[i] = generateSingleFeaturePostPart(
        label,
        removeUnits(featureValue)
      )
      featureValue &&
        props.setPostBody2(generatePostBody(props.calcId, updatedArr))
      !props.page &&
        setParametersAndVariablesState((prev) => ({
          ...prev,
          parameters: prev.parameters.map((param) =>
            param.label === label ? { ...param, value: featureValue } : param
          ),
        }))
      return updatedArr
    })
  }

  const removeUnits = (input) => {
    if (typeof input !== "string") {
      return input
    }
    const unitPattern = /( ft| psf| inch| pcf| in| ksi| pli| psi)+$/

    return input.replace(unitPattern, "").trim()
  }

  const rangeInputTemplate = (input, inputindex) => {
    return (
      <ErrorBoundary>
        <RangeInput
          variable={input}
          unit={matchUnit(input?.Units ?? "none", uniqueList)}
          inputindex={inputindex}
          updateFeatureOutput={updateFeatureOutput}
          setCheckingRangeWarning={props.setCheckingRangeWarning}
          checkingRangeWarning={props.checkingRangeWarning}
          postBody={props.postBody}
          generateRandomInputs={props.generateRandomInputs}
          setGenerateRandomInputs={props.setGenerateRandomInputs}
          pageOptimize={props.pageOptimize}
          page={props.page}
        />
      </ErrorBoundary>
    )
  }

  const catInputTemplate = (input, inputindex) => {
    let options = input.Domain.Options

    return (
      <DropDowns
        variable={input}
        unit={matchUnit(input?.Units ?? "none", uniqueList)}
        options={options}
        inputindex={inputindex}
        updateFeatureOutput={updateFeatureOutput}
        postBody={props.postBody}
        generateRandomInputs={props.generateRandomInputs}
        setGenerateRandomInputs={props.setGenerateRandomInputs}
        pageOptimize={props.pageOptimize}
        page={props.page}
      />
    )
  }
  let inputDOMElements = props?.calcConfig?.InputVariables?.map(
    (input, inputindex) => {
      if (input.Domain.Type === "discrete") {
        // also works for text
        return catInputTemplate(input, inputindex)
      } else if (input.Type === "quantity") {
        return rangeInputTemplate(input, inputindex)
      } else if (input.Type === "number") {
        return rangeInputTemplate(input, inputindex)
      } else if (input.Type === "categorical") {
        return catInputTemplate(input, inputindex)
      } else {
        console.error("Bad Input Type on ", `${input.Type}`)
        console.error("Bad Input Type on ", `${input.Domain.Type}`)
        return null
      }
    }
  )

  return (
    <Grid
      container
      direction="row"
      rowSpacing={1}
      columnSpacing={2}
      className={classes.calcCardMargin}
    >
      {inputDOMElements.map((inputDomElement, index) => {
        return (
          <Grid
            item
            xs="auto"
            className="tryit-dropdown-grid"
            key={inputDomElement + index}
          >
            {inputDomElement}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default CalculatorInput
