import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { Stack } from "@mui/system"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import * as core from "@material-ui/core"
import "react-tabulator/lib/styles.css"
import "react-tabulator/lib/css/tabulator.min.css"
import { ReactTabulator } from "react-tabulator"
import {
  EmulationLab,
  MultipleFileUpload,
  DeleteConfirmation,
  AlertBox,
  ShowResult,
} from "../../GlobalFileContainer"
import {
  dataSet,
  dataSourceType,
  editAlertBoxState,
  emulatorSpecificRole,
  emulatorStatus,
  getEmulatorData,
  handleKeyDown,
  siteWideRole,
  uploadEmFiles,
} from "../../state/projectState"
import { uploadEmulatorFilesRequests } from "../../state/requests"
import {
  convertBytesToHighestMeasurement,
  excel,
  failedValidationNote,
  grasshopper,
  paginationOptions,
  python,
} from "../../state/StatusState"
import { Typography } from "@mui/material"
import { configInpOut } from "../../hooks/useCalculatorConfig"

const fileExtensions = (fileType) => {
  switch (fileType) {
    case "data":
      return "csv"
    case "models":
      return "csv,PIPELINE"
    case "supporting":
      return "csv, json, txt, yaml, yml, db, sdb, edb"
    default:
      return "xlsx,xlsm,xls,mcdx,gh,ghx,py"
  }
}

const UploadEmulatorFiles = ({
  fileType,
  getFiles,
  fileList,
  setFileList,
  setShowHeader,
  setShowRow,
  validBtn,
  onReset,
  setColumn,
  setRow,
  showNotification,
  submitData,
}) => {
  var columns = []
  const { emulatorId } = useParams()
  const { inpVar, outVar } = configInpOut()
  const noConfigs = inpVar === 0 || outVar === 0
  const options =
    (fileType === "models" || fileType === "data") && paginationOptions(10)
  const count = fileType === "data"
  const allowedFileExtensions = fileExtensions(fileType)

  const badReq = `/emulators/${emulatorId}/data/validate?verbose=True`
  const getOrDeleteEmulatorFiles = uploadEmulatorFilesRequests(
    emulatorId,
    fileType,
    "get/delete"
  )
  const downloadEmulatorFiles = uploadEmulatorFilesRequests(
    emulatorId,
    fileType,
    "download"
  )

  const [uploadEmFilesState, setUploadEmFilesState] =
    useRecoilState(uploadEmFiles)
  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)
  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const setFiles = useSetRecoilState(dataSourceType)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const [emulatorData, SetEmulatorData] = useRecoilState(getEmulatorData)
  const setEmulatorData = useSetRecoilState(dataSet)
  const setEmulatorStatusState = useSetRecoilState(emulatorStatus)

  const [deleteConfirmationBox, setDeleteConfirmationBox] = useState(false)
  const [updatedData, setUpdatedData] = useState(fileList)
  const [deleteData, setDeleteData] = useState({})
  const [changeActive, setChangeActive] = useState(false)
  const [actFile, setActFile] = useState("")
  const [viewResult, setViewResult] = useState(false)
  const [refKey, setRefKey] = useState("")

  const showIcons =
    emulatorSpecificRoleState !== "em" || siteWideRoleState === "admin"

  useEffect(() => {
    changeActive &&
      setEditAlertBox({
        heading: "Confirm",
        content: `Are you sure , You want to change active calculator to ${actFile}?`,
      })
  }, [changeActive])

  useEffect(() => {
    setUpdatedData(fileList)
  }, [updatedData, uploadEmFilesState.listStatus])

  const closeDialog = () => {
    const updatedFileList = fileList?.map((item) => {
      return {
        ...item,
        ref: item.name === emulatorData?.source?.ref?.split("/")[2],
      }
    })
    fileType === "calculator" && setFileList(updatedFileList)
    setChangeActive(false)
  }

  const confirmDialog = () => {
    return (
      <core.Dialog
        onKeyDown={(e) => handleKeyDown(e, closeDialog)}
        open={changeActive}
        fullWidth={true}
      >
        <AlertBox
          heading={editAlertBox.heading}
          content={editAlertBox.content}
          closeDialog={closeDialog}
          callFunction={changeRef}
        />
      </core.Dialog>
    )
  }

  const updateEmulatorState = async (fileData) => {
    var emulatorURL = `/emulators/${emulatorId}`
    const fetchData = await EmulationLab.get(emulatorURL)
    const data = fetchData?.data
    SetEmulatorData(data)
    setEmulatorData(data?.data?.total_row_count)
    setEmulatorStatusState(data?.status)
    if (!fileData) {
      const updatedFileList = fileList?.map((item) => {
        return {
          ...item,
          ref: item.name === data?.source?.ref?.split("/")[2],
        }
      })
      fileType === "calculator" && setFileList(updatedFileList)
    }
    setFiles(
      data?.source?.type === "calculator"
        ? data?.source?.software
        : data?.source?.type
    )
  }

  var DeleteButton = function () {
    return "<i class='fa fa-trash' style='color: grey'></i>"
  }

  const postData = async (toShowData) => {
    setUploadEmFilesState((prevState) => ({
      ...prevState,
      loaderState: true,
      loaderMsg: "Processing...",
    }))
    try {
      onReset()
      const res = await EmulationLab.post(
        badReq,
        {
          key: toShowData.key,
        },
        {
          headers: {
            "Content-Type": "application/json",
            severity: SeverityLevel.Error,
          },
        }
      )
      if (res.status == 200) {
        setUploadEmFilesState((prevState) => ({
          ...prevState,
          fileName: toShowData.name,
        }))
        if (typeof res.data.columns !== "string") {
          setColumn(res.data.columns)
        }
        if (typeof res.data.rows !== "string") {
          setRow(res.data.rows)
        }
      }
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        loaderState: false,
      }))
    } catch (error) {
      alert("error in posting count..")
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        loaderState: false,
      }))
    }
  }

  const deleteFunction = async (cell) => {
    const toBeDeletedData = cell.getRow().getData()
    setDeleteData(toBeDeletedData)
    setDeleteConfirmationBox(true)
    if (count) {
      setColumn("")
      setRow("")
    }
  }

  const showDataHeader = (cell) => {
    const toShowData = cell.getRow().getData()
    postData(toShowData)
    setShowHeader(true)
    setShowRow(false)
  }

  const showDataRow = async (cell) => {
    const toShowData = cell.getRow().getData()
    postData(toShowData)
    setShowRow(true)
    setShowHeader(false)
  }

  const shoRow = (cell) => {
    setShowRow(false)
    setShowHeader(false)
  }

  const shoCol = (cell) => {
    setShowRow(false)
    setShowHeader(false)
  }

  var DownloadButton = function () {
    return "<i class='fas fa-file-download' style='color: grey'></i>"
  }

  const changeActiveRef = (name) => {
    setActFile(name)
    if (fileType === "supporting") {
      changeRef(name)
    } else {
      setChangeActive(true)
    }
  }

  const removeActiveSupp = (name) => {
    const dataS = {
      ...emulatorData.source,
      supporting_refs: emulatorData?.source?.supporting_refs?.filter(
        (supRef) => supRef?.split("/")[2] !== name
      ),
    }
    submitData(dataS, name, dataS.supportingRefs, fileType)
  }
  const changeRef = (file) => {
    const extension = actFile.split(".")[1]?.toLocaleLowerCase()

    if (fileType === "calculator") {
      const dataS = {
        ...emulatorData.source,
        type: "calculator",
        software:
          (excel?.includes(extension) && "excel") ||
          (grasshopper?.includes(extension) && "grasshopper") ||
          (python?.includes(extension) && "python") ||
          "mathcad",
        ref: `${emulatorId}/calculator/${actFile}`,
        hasModel: !grasshopper?.includes(extension)
          ? false
          : emulatorData?.source?.hasModel,
      }
      submitData(dataS, actFile, dataS.ref)
    } else {
      const supportingRefs = [
        ...(emulatorData?.source?.supporting_refs || []),
        `${emulatorId}/supporting/${file}`,
      ]

      const dataS = {
        ...emulatorData.source,
        supporting_refs: supportingRefs,
      }
      submitData(dataS, file, supportingRefs, fileType)
    }
  }

  const downloadFunction = async (cell) => {
    const fileName = cell.getRow().getData().name
    const toBeDownloadedData = {
      key: cell.getRow().getData().key,
    }

    try {
      const response = await EmulationLab.post(
        downloadEmulatorFiles,
        toBeDownloadedData,
        {
          responseType: "arraybuffer", // Set the responseType to arraybuffer
          headers: {
            severity: SeverityLevel.Error,
          },
        }
      )

      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      alert("Failed to download the file, please try again..")
    }
  }

  const deleteDownloadIcon = (actionIcon, actionFunction, icon, type) => {
    const columnObject = {
      title: "",
      hozAlign: "center",
      headerHozAlign: "center",
      maxWidth: icon ? "42px" : "30px",
      headerSort: false,
      formatter: actionIcon,
      cellClick: function (e, cell) {
        actionFunction(cell, type)
      },
      visible: showIcons,
    }
    return columnObject
  }

  const ShowName = {
    title: "File Name",
    field: "name",
    responsive: 0,
    minWidth: "150px",
  }

  const showLastModify = {
    title: "Last Modified",
    field: "last_modified",
    responsive: 3,
    minWidth: "120px",
    visible: window.innerWidth > 1200,
  }

  const sizeBytes = () => {
    return {
      title: "Size",
      field: "size_bytes",
      responsive: 2,
      hozAlign: "center",
      minWidth: "90px",
      headerHozAlign: "center",
      formatter: function (cell) {
        const cellValue = cell.getValue()
        return convertBytesToHighestMeasurement(cellValue)
      },
    }
  }

  const openResult = (cell) => {
    const rowData = cell.getRow().getData()
    const referenceFile = rowData?.key
    setRefKey(referenceFile)
    setViewResult(true)
  }

  columns = count
    ? [
        ShowName,
        showLastModify,
        sizeBytes(),
        {
          title: "# of Rows",
          field: "count",
          visible: allowedFileExtensions === "csv",
          hozAlign: "center",
          headerHozAlign: "center",
          responsive: 2,
          minWidth: "90px",
        },
        {
          title: "View Data",
          field: "",
          headerHozAlign: "center",
          formatter: () => {
            return `<div style="display: flex; justify-content: center;">
                    <button style="padding:2px;background-color: #39C2D7; color: white; border: none; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);">View data</button>
                  </div>`
          },
          cellClick: (e, cell) => {
            openResult(cell)
          },
          visible: !noConfigs,
        },
        {
          title: "Validation",
          visible: allowedFileExtensions === "csv",
          columns: [
            {
              title: "Column",
              field: "head",
              headerSort: false,
              visible: allowedFileExtensions === "csv" && showIcons,
              headerHozAlign: "center",
              hozAlign: "center",
              maxWidth: "90px",
              cellClick: function (e, cell) {
                const value1 = cell.getValue()
                cell.getRow()?.getData()?.validateData !== ""
                  ? value1.err === 0 && value1.warn === 0
                    ? shoCol(cell)
                    : showDataHeader(cell)
                  : ""
              },
              tooltip: function (cell) {
                try {
                  const cellData = cell.getValue()

                  return JSON.stringify(cellData?.data_for_hover, " ", 2)
                    .replaceAll('"', "")
                    .replaceAll("{", "")
                    .replaceAll("}", "")
                    .replace("\n", "")
                } catch {
                  return ""
                }
              },
              formatter: function (cell) {
                const value1 = cell.getValue()
                const err = value1?.err
                const warn = value1?.warn

                return value1
                  ? (err === failedValidationNote &&
                      warn === failedValidationNote &&
                      `<p style="color:#EFADB1">${failedValidationNote}</p>`) ||
                      (err === 0 &&
                        warn === 0 &&
                        `<li style="color:green" class="fa fa-check-circle-o"></li>`) ||
                      (err > 0 &&
                        warn > 0 &&
                        `<i style="color:#EFADB1" class="fa fa-times-circle-o"></i>` +
                          `${err}` +
                          `<i style="color:yellow" class="fa fas fa-exclamation-triangle"></i>` +
                          `${warn}`) ||
                      (err === 0 &&
                        warn > 0 &&
                        `<i style="color:yellow" class="fa fas fa-exclamation-triangle"></i>` +
                          `${warn}`) ||
                      (err > 0 &&
                        warn === 0 &&
                        `<i style="color:red" class="fa fa-times-circle-o"></i>` +
                          `${err}`)
                  : ""
              },
            },
            {
              title: "Row",
              field: "row",
              headerSort: false,
              headerHozAlign: "center",
              visible: allowedFileExtensions === "csv" && showIcons,
              hozAlign: "center",
              maxWidth: "90px",
              cellClick: function (e, cell) {
                const value1 = cell.getValue()
                cell.getRow()?.getData()?.validateData !== ""
                  ? value1.err === 0 && value1.warn === 0
                    ? shoRow(cell)
                    : showDataRow(cell)
                  : ""
              },
              tooltip: function (cell) {
                try {
                  const cellData = cell.getValue()

                  return JSON.stringify(cellData?.data_for_hover, " ", 2)
                    .replaceAll('"', "")
                    .replaceAll("{", "")
                    .replaceAll("}", "")
                    .replace("\n", "")
                } catch {
                  return ""
                }
              },
              formatter: function (cell) {
                const value1 = cell.getValue()
                const err = value1?.err
                const warn = value1?.warn
                return value1
                  ? (err === failedValidationNote &&
                      warn === failedValidationNote &&
                      `<p style="color:red">${failedValidationNote}</p>`) ||
                      (err === 0 &&
                        warn === 0 &&
                        `<li style="color:green" class="fa fa-check-circle-o"></li>`) ||
                      (err > 0 &&
                        warn > 0 &&
                        `<i style="color:red" class="fa fa-times-circle-o"></i>` +
                          `${err}     ` +
                          `<i style="color:yellow" class="fa fas fa-exclamation-triangle"></i>` +
                          `${warn}`) ||
                      (err === 0 &&
                        warn > 0 &&
                        `<i style="color:yellow" class="fa fas fa-exclamation-triangle"></i>` +
                          `${warn}     `) ||
                      (err > 0 &&
                        warn === 0 &&
                        `<i style="color:red" class="fa fa-times-circle-o"></i>` +
                          `${err}     `)
                  : ""
              },
            },
          ],
        },
        {
          title: "Actions",
          columns: [
            deleteDownloadIcon(DeleteButton, deleteFunction),
            deleteDownloadIcon(DownloadButton, downloadFunction),
          ],
        },
      ]
    : [
        ShowName,
        showLastModify,
        sizeBytes(),
        {
          title: "Actions",
          columns: [
            deleteDownloadIcon(DeleteButton, deleteFunction, "size", "del"),
            deleteDownloadIcon(DownloadButton, downloadFunction, "size"),
          ],
        },
        {
          title:
            fileType === "calculator" ? "Active Calculator" : "Supporting Refs",
          field: fileType === "calculator" ? "ref" : "supporting_refs",
          headerSort: false,
          headerHozAlign: "center",
          hozAlign: "center",

          maxWidth: "90px",
          cellClick: function (e, cell) {
            const value1 = cell.getRow().getData().name
            const isSelected =
              fileType === "calculator"
                ? cell.getRow().getData().ref
                : cell.getRow().getData().supporting_refs
            isSelected
              ? fileType === "calculator"
                ? console.log("Already active File")
                : removeActiveSupp(value1)
              : changeActiveRef(value1, fileType === "supporting")
          },
          tooltip: function (cell) {
            const isSelected =
              fileType === "calculator"
                ? cell.getRow().getData().ref
                : cell.getRow().getData().supporting_refs
            const activeFile =
              fileType === "calculator" ? "Calculator" : "Supporting File"
            return isSelected
              ? `Active  ${activeFile}`
              : `Select to set as Active ${activeFile}`
          },
          formatter: function (cell) {
            const isSelected =
              fileType === "calculator"
                ? cell.getRow().getData().ref
                : cell.getRow().getData().supporting_refs
            return isSelected
              ? `<li style="color:green" class="fa fa-check-square"></li>`
              : `
            <input
              type="checkbox"
            />
          `
          },
        },
      ]

  const closeResult = () => {
    setViewResult(!viewResult)
  }
  const rowFormatter = (row) => {
    row.getElement().classList.add("row-height-fix")
  }
  return (
    <>
      <core.Grid container spacing={0} className="mainG-heading">
        {count && showIcons && validBtn()}
        <br />
      </core.Grid>
      {confirmDialog()}
      <Stack spacing={0}>
        <Stack
          spacing={0}
          direction="column"
          alignItems="left"
          justifyContent="center"
        >
          <MultipleFileUpload
            getEmulatorFiles={getOrDeleteEmulatorFiles}
            allowedFileExtensions={allowedFileExtensions}
            count={count}
            updateEmulatorState={updateEmulatorState}
            setFileList={setFileList}
          />
        </Stack>
        <Typography variant="h6">List of current files:</Typography>
        {uploadEmFilesState.listStatus && (
          <ReactTabulator
            layoutColumnsOnNewData={true}
            responsiveLayout={"hide"}
            key={fileList}
            data={fileList}
            columns={columns}
            reactiveData={true}
            updateData={updatedData}
            replaceData={updatedData}
            placeholder={
              showNotification
                ? "Failed to load the files,please try again.."
                : "No uploaded file, please upload to see."
            }
            options={options}
            rowFormatter={rowFormatter}
          />
        )}
      </Stack>
      {deleteConfirmationBox && (
        <DeleteConfirmation
          deleteData={deleteData}
          open={deleteConfirmationBox}
          close={setDeleteConfirmationBox}
          deleteConfirm={getFiles}
          deleteEmulatorFiles={getOrDeleteEmulatorFiles}
          setUpdate={setUploadEmFilesState}
          updateEmulatorState={updateEmulatorState}
          fileType={fileType}
          submitData={submitData}
          removeActiveSupp={removeActiveSupp}
        />
      )}
      <ShowResult
        ViewResult={viewResult}
        refKey={refKey}
        closeResult={closeResult}
        title={"Data"}
      />
    </>
  )
}

export default UploadEmulatorFiles
