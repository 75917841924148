import * as core from "@material-ui/core"
import { StatusInfo } from "../components/project/StatusInfo"
import EmulationLab from "./axios"
import { estimateUrl } from "./requests"
import calculatorImages from "../assets/calculatorSVGs"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CircleIcon from "@mui/icons-material/Circle"
import CancelIcon from "@mui/icons-material/Cancel"
import ErrorIcon from "@mui/icons-material/Error"
import excelImages from "../assets/Agent Health Icons - v1/Excel"
import ghImages from "../assets/Agent Health Icons - v1/Grasshopper"
import pythonImages from "../assets/Agent Health Icons - v1/Python"
import mathcadImages from "../assets/Agent Health Icons - v1/Mathcad"
import optImages from "../assets/Agent Health Icons - v1/Optimization"
import * as result from "../assets/Results/Utils"
import { Typography } from "@mui/material"
import allImage from "../assets/Agent Health Icons - v1/All"

export const commonFontFamily =
  "sans-serif,Lucida Sans,Lucida Sans Regular,Lucida Grande,Lucida Sans Unicode,Geneva,Verdana"

export const ERROR_MESSAGE = {
  requiredField: "Required field",
  outOfRange: "Out of range value",
}
export const RUN_TYPES = ["calculate", "lookup", "emulate"]
export const optimiseModesStrings = {
  genetic: "genetic algorithm",
  differential: "differential evolution",
  nsg: "NSGA2",
}
export const OPTIMIZE_MODES = [
  optimiseModesStrings.genetic,
  optimiseModesStrings.differential,
  optimiseModesStrings.nsg,
]

export const colRegex = /[ \-.\s]/g
export const OUTPUT_OBJECTIVES = ["minimize", "maximize", "equal"]
export const EMPTY_STATES = ["none", undefined, ""]
export const PREPARING_ARRAY = ["idea", "preparing"]
export const TRAINING_ARRAY = ["idea", "preparing", "prepared"]
export const showCalcIcon = ["prepared", "deployed", "training"]
export const uniqueListofStatus = [
  "busy",
  "idle",
  "off",
  "None",
  "others",
  "broken",
  "ERROR: status missing",
]
export const allStatus = [
  "Idea Initiated",
  "Function Defined",
  "Calculator Testing",
  "Calculator Released",
  "Data Upload",
  "Data Reference",
  "Data Synthesis",
  "Data Available",
  "Lookup Released",
  "Training in Progress",
  "Emulator Testing",
  "Emulator Released",
]
export const allSoftwareWithDependency = [
  "excel: none",
  "excel: excel_wpm",
  "excel: null",
  "excel: deprecated",
  "grasshopper: none",
  "grasshopper: gh_eng",
  "grasshopper: null",
  "grasshopper: deprecated",
  "python: none",
  "python: python_data",
  "python: null",
  "python: deprecated",
  "mathcad: null",
  "mathcad: none",
  "optimization: none",
]
export const trainingDeployed = ["deployed", "training"]
export const trainingArchived = ["deployed", "training", "archived"]
export const breadCrumbsStrings = {
  none: "None",
  role: "em",
  beta: "(Beta)",
  released: "(Released)",
  archived: "(Archived)",
}

export const ALGO_STATUS = {
  ready: "ready",
  pending: "pending",
  running: "running",
  done: "done",
  failed: "failed",
  queued: "queued",
  complete: "complete",
  received: "received",
  canceled: "canceled",
  timedOut: "timed-out",
}

export const passFailedArray = [ALGO_STATUS.complete, ALGO_STATUS.failed]

export const TEST_STATUS = {
  pass: "pass",
  fail: "fail",
  running: "running",
  draft: "draft",
  calculation_fail: "calculation fail",
  canceled: "canceled",
}

export const TEST_LEGEND_DESC = {
  pass: "Test Result- Pass",
  fail: "Test Result- Fail",
  running: "Calculation under process",
  draft: "Test Created",
  calculation_fail: "Calculation Failed",
  canceled: "Test canceled by user",
}

export const getOperator = (operator) => {
  switch (operator) {
    case testRule.equalTto:
      return "="
    case testRule.greater:
      return ">"
    case testRule.greaterOrEqual:
      return ">="
    case testRule.lesser:
      return "<"
    case testRule.lesserOrEqual:
      return "<="
    case testRule.notEqual:
      return "!="
    case testRule.inSet:
      return "in"
  }
}

export const isDataValid = (postBody) => {
  return postBody?.FeaturesData?.every((item) => {
    return item.Data.every((value) => value.trim() !== "")
  })
}

export const allValuesFilled = (checkingRangeWarning) => {
  return checkingRangeWarning.every((value) => value === false)
}

export const areObjectsEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export const statusInfor = (status) => {
  return StatusInfo[status]?.info
}

export const convertToUppercase = (data, multiple) => {
  const convertedString =
    data &&
    (multiple
      ? data
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : data?.charAt(0)?.toUpperCase() + data?.slice(1))
  return convertedString
}

export const latestVersion = (emulatorConfig) => {
  return emulatorConfig?.versions?.latest_stable
}

export const betaVersion = (emulatorConfig) => {
  return emulatorConfig?.versions?.beta
}

export const formatTime = (timeString) => {
  let [hours, minutes, seconds] = (timeString ?? "").split(":").map(parseFloat)

  seconds = Math.max(1, seconds)
  minutes += Math.floor(seconds / 60)
  hours += Math.floor(minutes / 60)

  if (hours >= 1) {
    return `${Math.round(hours)}hour${hours >= 2 ? "s" : ""}`
  } else if (minutes >= 1) {
    return `${Math.round(minutes)}min${minutes >= 2 ? "s" : ""}`
  } else {
    return `${Math.round(seconds)}sec${seconds >= 2 ? "s" : ""}`
  }
}

export const findEstimatedTime = async (data, setEstimateTime, time) => {
  try {
    const res = await EmulationLab.post(estimateUrl, data)
    const inputTime =
      res &&
      res.data[time ? "expected compute time" : "expected roundtrip time"]
    const formattedTime =
      inputTime === "infinity" ? inputTime : formatTime(inputTime)

    formattedTime &&
      setEstimateTime({
        agentCount: res.data.active_agent_count,
        wait: formattedTime,
      })
  } catch (error) {
    console.error(error)
  }
}

export const sourceType = (emulatorConfig) => {
  return emulatorConfig?.source?.type
}

export const sourceRef = (emulatorConfig) => {
  return emulatorConfig?.source?.ref
}

export const sourceSupRef = (emulatorConfig) => {
  return emulatorConfig?.source?.supporting_refs
}

export const sourceSoftware = (emulatorConfig) => {
  return emulatorConfig?.source?.software
}

export const sourceMethod = (emulatorConfig) => {
  return emulatorConfig?.source?.method
}

export const checkAuthorization = (
  emulator,
  mongoProfile,
  noOfReleases,
  renderIconButton
) => {
  const emulatorTeam = emulator?.team
  const isMember =
    emulatorTeam?.director.name === mongoProfile.name ||
    emulatorTeam?.members.some((member) => member.name === mongoProfile.name)

  if (noOfReleases === 0) {
    return isMember ? renderIconButton("styled") : null
  } else {
    return <>{renderIconButton("none")}</>
  }
}

export const errorStrings = ["error", "warning"]
export const inputOutputLabels = {
  input: "valid_input_labels",
  output: "valid_output_labels",
}

export const failedValidationNote = "Failed to validate"
export const confirmDelete = "Confirm Delete"

export const excel = ["xlsx", "xlsm", "xls"]
export const grasshopper = ["gh", "ghx"]
export const mathcad = ["mcdx"]
export const python = ["py"]
export const jobTypes = ["all", "active", "inactive"]

export const deletePropsMap = {
  host: ["Alias", "ParamName", "Cell", "Worksheet", "PythonVariableName"],
  python: ["Alias", "ParamName", "Cell", "Worksheet", "ApiLabel"],
  mathcad: ["ApiLabel", "Cell", "Worksheet", "ParamName", "PythonVariableName"],
  grasshopper: ["Alias", "ApiLabel", "Cell", "Worksheet", "PythonVariableName"],
  default: ["Alias", "ParamName", "ApiLabel", "PythonVariableName"],
}

export const handeEmptyTextfield = {
  missingName: "You must Enter Name.",
  missingNickName: "You must Enter Nickname.",
  missingLabel: "You must Enter Column Label.",
  missingDesc: "You must Enter Description.",
  missingUnit: "You must select a Unit.",
  missingPythonVar: "You must Enter a Python Variable Name.",
}

export const handleEmptyTest = {
  missingRule: "You must Enter Rule.",
  missingOutput: "You must Enter Expected Output.",
  missingTolerance: "You Must enter Tolarance.",
  missingMaxValue: "You must enter Max Value.",
}

export const getAllMatrixUrl = (id, i, j) => {
  return `/emulators/${id}/data/charts/scatterplot-matrix/${i}/${j}?folder=explore_matrix`
}

export const paginationOptions = (number) => {
  const pages = {
    pagination: "local",
    paginationSize: number,
    paginationSizeSelector: [number, 25, 50, 100, true],
  }
  return pages
}

export const startPollingData = (
  fetched,
  CallData_polling,
  interval,
  setPollingIntervalId,
  setTime
) => {
  if (fetched) {
    const id = setInterval(() => CallData_polling(), 11000)
    interval.push(id)
    setPollingIntervalId(id)
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 10))
    }, 1000)
    return () => clearInterval(timer)
  } else {
    interval.forEach((id) => {
      clearInterval(id)
    })
  }
}

export const showTimer = (time) => (
  <core.Typography variant="body1" className="mismatchlabel">
    Page will refresh in: {`0:${time}`}
  </core.Typography>
)

export const getParamString = (emulatorCalcInputConfig) => {
  const paramString = emulatorCalcInputConfig
    ?.map((input) => {
      return `${
        input.ColumnLabel.includes("in ")
          ? input.ColumnLabel?.replace("in ", "")
          : input.ColumnLabel
      }=`
    })
    .join("&")

  return paramString
}

export const iconMappings = {
  [RUN_TYPES[0]]: {
    selected: calculatorImages["calcActive"],
    inactive: calculatorImages["calcInactive"],
    unavailable: calculatorImages["calcUnavail"],
  },
  [RUN_TYPES[1]]: {
    selected: calculatorImages["lookupActive"],
    inactive: calculatorImages["lookupInactive"],
    unavailable: calculatorImages["lookupUnavail"],
  },
  [RUN_TYPES[2]]: {
    selected: calculatorImages["emuActive"],
    inactive: calculatorImages["emuInactive"],
    unavailable: calculatorImages["emuUnavail"],
  },
}

export const emulatorMode = {
  emulate: "emulate",
  calculate: "calculate",
  lookup: "lookup",
}

export const allEmulatorStatus = {
  idea: "idea",
  initialized: "initialized",
  preparing: "preparing",
  prepared: "prepared",
  training: "training",
  deployed: "deployed",
  predict: "predict",
  release: "release",
}

export const getFilteredSubStatusConfig = (modes) => {
  const filteredSubStatus = subStatus.filter((status) =>
    modes.some((value) => {
      let hasMode = status?.mode?.includes(value)
      if (status.title !== "Data Reference") {
        if (status.title === "Data Synthesis") {
          return modes.includes("calculate") && hasMode
        } else return hasMode
      }
    })
  )
  return filteredSubStatus
}

export const subStatus = [
  {
    title: allStatus[0],
    mode: RUN_TYPES,
  },
  {
    title: allStatus[1],
    mode: RUN_TYPES,
  },
  {
    title: allStatus[2],
    mode: [RUN_TYPES[0]],
  },
  {
    title: allStatus[3],
    mode: [RUN_TYPES[0]],
  },
  {
    title: allStatus[4],
    mode: [RUN_TYPES[1], RUN_TYPES[2]],
  },
  {
    title: allStatus[5],
    mode: [RUN_TYPES[1], RUN_TYPES[2]],
  },
  {
    title: allStatus[6],
    mode: [RUN_TYPES[2]],
  },
  {
    title: allStatus[7],
    mode: [RUN_TYPES[1], RUN_TYPES[2]],
  },
  {
    title: allStatus[8],
    mode: [RUN_TYPES[1]],
  },
  {
    title: allStatus[9],
    mode: [RUN_TYPES[2]],
  },
  {
    title: allStatus[10],
    mode: [RUN_TYPES[2]],
  },
  {
    title: allStatus[11],
    mode: [RUN_TYPES[2]],
  },
]

export const emulatorSoftwareType = {
  excel: "excel",
  hosted: "hosted_calculator",
  mathcad: "mathcad",
  upload: "upload",
  grasshopper: "grasshopper",
  python: "python",
}

export const testRule = {
  between: "between",
  greater: "greater_than",
  greaterOrEqual: "greater_than_or_equal",
  equal: "equal",
  lesser: "lesser_than",
  lesserOrEqual: "lesser_than_or_equal",
  equalTto: "equal to",
  contains: "output contains",
  notEqual: "not_equals",
  inSet: "in_set",
}

export const evaluateTestRuleTrueOrFalse = async (
  rule,
  expectedData,
  minValue,
  maxValue,
  tolerance,
  actualData
) => {
  switch (rule) {
    case testRule.between:
      return result.areValuesInBetween(minValue, maxValue, actualData)
    case testRule.greater:
      return result.isValueMoreThan(expectedData, actualData)
    case testRule.equal:
      return result.areValuesEqualWithTolerancePercent(
        expectedData,
        tolerance,
        actualData
      )
    case testRule.lesser:
      return result.isValueLess(expectedData, actualData)
    case testRule.equalTto:
      return result.isValueExactMatch(expectedData, actualData)
    case testRule.contains:
      return result.stringContains(expectedData, actualData)
    case testRule.greaterOrEqual:
      return result.isValueMoreOrEqual(expectedData, actualData)
    case testRule.lesserOrEqual:
      return result.isValueLessOrEqual(expectedData, actualData)
    case testRule.notEqual:
      return result.isValueNotEqual(expectedData, actualData)
    case testRule.inSet:
      return result.isValueInSet(expectedData, actualData)
    default:
      return ""
  }
}

export const evaluateTestRule = async (
  rule,
  expectedData,
  minValue,
  maxValue,
  tolerance,
  actualData
) => {
  const status = evaluateTestRuleTrueOrFalse(
    rule,
    expectedData,
    minValue,
    maxValue,
    tolerance,
    actualData
  )
  return status
    .then((res) => (res ? "pass" : "fail"))
    .catch(() => alert("Some error occurred in evaluating test."))
}

export const textCatArray = ["text", "categorical"]

export const renderFormLabel = (value, classes, minValue) => {
  return (
    <Typography
      variant="body1"
      fontWeight={"650"}
      className={`${classes.colorBlack} boldLabel ml-display-flex ml-flex-dir-row`}
    >
      {value}&nbsp;
      {minValue && (
        <Typography
          variant="body2"
          fontWeight={200}
          className={`${classes.colorSecondary} ml-display-flex ml-flex-dir-row`}
        >
          ({value === "Start" ? "Min" : "Max"}. Value: {minValue})
        </Typography>
      )}
      <Typography variant="h5" className={classes.colorPrimaryMain}>
        {value ? "*" : ""}
      </Typography>
    </Typography>
  )
}
export const fontWeight650 = "650"
export const uploadEmFilesOrTrain = "uploadEmFilesOrTrain"

export const compareLengths = (selectedOutputs, emulatorConfig) => {
  let variableLength = selectedOutputs?.variables.length
  let parameterLength = selectedOutputs?.parameters.length
  let configLength = emulatorConfig?.calculator?.InputVariables.length
  let boolValue = variableLength + parameterLength !== configLength
  return boolValue
}
export const optimisationObject = (id, outputObject, isSameEmulator) => {
  const updatedObject = {
    emulator_id: id,
    instructions: {
      solver: outputObject?.solver,
      mode: outputObject?.mode,
      max_time: outputObject?.max_time,
      max_iteration: outputObject?.max_iteration,
      objectives: outputObject?.objectives,
      parameters: outputObject?.parameters || [],
      variables: outputObject?.variables || [],
      constraints: isSameEmulator
        ? [
            {
              label: "out FS Overturning",
              mode: "greater_than_or_equal",
              value: "1.5",
            },
            {
              label: "out FS Sliding",
              mode: "greater_than_or_equal",
              value: "1.5",
            },
            {
              label: "out e",
              mode: "less_than_or_equal",
              reference: "L/6 (TODO: add)",
            },
            {
              label: "out qtoe",
              mode: "less_than_or_equal",
              reference: "in Allowable Bearing Pressure",
            },
            {
              label: "out qheel",
              mode: "greater_than_or_equal",
              value: "0",
            },
          ]
        : outputObject?.constraints || [],
    },
  }
  return updatedObject
}

export const getTransformedData = (chartData) => {
  const transformedData =
    chartData &&
    chartData?.map((current, index, array) => {
      const outputObj = Object.keys(current).reduce(
        (result, key, index, keys) => {
          const currentValue = current[key]
          const previousKey = keys[index - 1]

          if (index > 0) {
            result[key] = currentValue - current[previousKey]
          } else {
            result[key] = currentValue
          }

          return result
        },
        {}
      )

      return outputObj
    })

  return transformedData ?? []
}

function countZerosAfterDecimal(value) {
  const stringValue = value.toString()
  let countZeros = 0
  let foundDecimalPoint = false

  for (let i = 0; i < stringValue.length; i++) {
    const currentChar = stringValue[i]

    if (foundDecimalPoint) {
      if (currentChar === "0") {
        countZeros++
      } else {
        break
      }
    }

    if (currentChar === ".") {
      foundDecimalPoint = true
    }
  }

  return countZeros <= 1 ? 2 : countZeros + 1
}

export const roundValues = (value) => {
  const parsedNumber = parseFloat(value)
  if (!isNaN(parsedNumber)) {
    const stringValue = value.toString()
    const hasZeros = /\.00+\d*$/.test(stringValue)
    const countZeros = hasZeros ? countZerosAfterDecimal(value) : 2
    return Number(parsedNumber.toFixed(countZeros))
  } else return value
}

export const getDisableState = (name, modes) => {
  switch (name) {
    case "Emulator Members":
      return "You must become a collaborator to view this page."
    case "Explore Data":
      return "Upload data files to view this page."
    case "Generate Data":
      return "Emulator should be ready for calculations to generate data."
    case "ML Evaluation":
      return "Upload data files to view this page."
    case "Emulator File Manager":
      return "Only admins can access this page."
    case "Calculator": {
      if (modes.includes(RUN_TYPES[2])) {
        return "Data files must be uploaded and ML training should be complete to use calculator."
      } else if (modes.includes(RUN_TYPES[0])) {
        return "Config and active source ref must be provided to use calculator."
      } else {
        return ""
      }
    }
    case "Calculator Tests":
      return "Calculate mode must be selected and active source file should be defined to try tests."
    case "Optimization":
      return "Calculator should be ready for calculations to try optimize."

    default:
      return ""
  }
}

const getIconForStatus = (software, subType, status, size, title) => {
  const statusPrefix = status.substring(0, 2)
  const result = subType + statusPrefix

  let imgSrc = ""
  switch (status) {
    case "none":
    case "idle":
    case "off":
    case "busy":
    case "broken":
      imgSrc = software[result]
      break
    default:
      break
  }

  return imgSrc ? (
    <img
      className="object-scale-down"
      title={title}
      width={size}
      src={imgSrc}
    />
  ) : (
    title
  )
}

export const pickStatusWithSoftwareIcons = (
  key,
  subType,
  status,
  size,
  title
) => {
  let images
  let imageIndex = null

  switch (key) {
    case "all":
      images = allImage
      switch (subType) {
        case "none":
          imageIndex = 0
          break
      }
      break
    case "excel":
      images = excelImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "excel_wpm":
          imageIndex = 1
          break
        case "null":
          imageIndex = 2
          break
        case "deprecated":
          imageIndex = 3
          break
        default:
          break
      }
      break
    case "python":
      images = pythonImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "python_data":
          imageIndex = 1
          break
        case "null":
          imageIndex = 2
          break
        case "deprecated":
          imageIndex = 3
          break
        default:
          break
      }
      break
    case "grasshopper":
      images = ghImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "gh_eng":
          imageIndex = 1
          break
        case "null":
          imageIndex = 2
          break
        case "deprecated":
          imageIndex = 3
          break
        default:
          break
      }
      break
    case "mathcad":
      images = mathcadImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "null":
          imageIndex = 2
          break
        default:
          break
      }
      break
    case "optimization":
      images = optImages
      switch (subType) {
        case "none":
          imageIndex = 0
          break
        case "null":
          imageIndex = 2
          break
        default:
          break
      }
      break
    default:
      return `${key}: ${subType}`
  }

  return imageIndex === null
    ? `${key}: ${subType}`
    : getIconForStatus(images, imageIndex, status, size, title)
}

export const seperateSoftwareAndSubType = (software) => {
  const includesColon = software?.includes(":")
  const includesStatus = software?.includes("(")

  if (!includesColon) {
    return [software, "", ""]
  }

  const [extractedSoftware, extractedStatus] = includesStatus
    ? [String(software.split(" (")[0]), String(software.split(" (")[1])]
    : [software, "none"]
  const softwareType = includesColon
    ? String(extractedSoftware.split(":")[0])
    : extractedSoftware
  const softwareSubType =
    (!includesColon
      ? false
      : String(extractedSoftware.split(":")[1]?.replace(" ", ""))) ?? false
  const extractStatus = String(extractedStatus?.split(":")[0]) ?? "none"
  const softwareStatus = extractStatus === "?" ? "none" : extractStatus

  return [softwareType, softwareSubType, softwareStatus]
}

export const returnAgentStatusIcon = (status, theme, classes, size) => {
  const iconProps = {
    fontSize: size ?? "medium",
    titleAccess: status,
  }

  switch (String(status)) {
    case "ERROR: status missing":
      return <CancelIcon {...iconProps} className={classes.colorDangerIcon} />
    case "None":
      return ""
    case "busy":
      return (
        <RemoveCircleIcon {...iconProps} className={classes.colorSecondary} />
      )
    case "off":
      return <CircleIcon {...iconProps} className={classes.colorGraySec} />
    case "idle":
      return (
        <CheckCircleIcon
          {...iconProps}
          sx={{ color: theme.palette.status.success }}
        />
      )
    case "broken":
      return <ErrorIcon {...iconProps} className={classes.colorDangerIcon} />
    default:
      return
  }
}

export const convertBytesToHighestMeasurement = (bytes) => {
  const sizes = {
    Bytes: 10,
    KB: 1000,
    MB: 1000000,
    GB: 1000000000,
    TB: 1000000000000,
  }

  const roundNumber = (value) => {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }

  if (bytes === 0) return "0 Byte"

  if (bytes < 10) return `${bytes} B`

  if (Math.floor(bytes / sizes.KB) < 100) {
    const val = bytes / sizes.KB
    return `${roundNumber(val)} ${Object.keys(sizes)[1]}`
  } else if (Math.floor(bytes / sizes.MB) < 100) {
    const val = bytes / sizes.MB
    return `${roundNumber(val)} ${Object.keys(sizes)[2]}`
  } else if (Math.floor(bytes / sizes.GB) < 100) {
    const val = bytes / sizes.GB
    return `${roundNumber(val)} ${Object.keys(sizes)[3]}`
  } else {
    const val = bytes / sizes.TB
    return `${roundNumber(val)} ${Object.keys(sizes)[4]}`
  }
}

export const handleContextCopy = (value, setAlertState, id) => {
  const jobId = value
  navigator.clipboard
    .writeText(jobId)
    .then(() => {
      setAlertState({
        boolState: true,
        message: `${id} copied to clipboard!`,
        severityState: "info",
      })
    })
    .catch((error) => {
      console.error(`Failed to copy ${id} to clipboard:`, error)
    })
}
