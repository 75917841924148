import { useState, useEffect } from "react"
import { Suspense } from "react-is"
import * as core from "@material-ui/core"
import { Loader, EmulationLab } from "../../GlobalFileContainer"
import "./MLEvaluation.css"
import { EMPTY_STATES } from "../../state/StatusState"
import { useParams } from "react-router-dom"
import { Typography, useTheme } from "@mui/material"

const MLCharts = ({ item, algoName }) => {
  const theme = useTheme()
  const { emulatorId } = useParams()

  const [selectedImage, setSelectedImage] = useState()
  const [itemData, setItemData] = useState([])
  const [route, setRoute] = useState("/histogram/image")
  const [imageName, setImageName] = useState("Histogram")
  const [loader, setLoader] = useState(false)
  const [errorMessageInFetching, setErrorMessageInFetching] = useState("")

  const thumbnailApi = `engine/fit/${emulatorId}/figures?target=${item}`

  const fetchThumbnails = async () => {
    try {
      const response = await EmulationLab.get(thumbnailApi)
      setItemData(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchImageUsingRoute = async () => {
    try {
      const imageApi = `engine/fit/${emulatorId}/figures/${route}?target=${item}&algorithm=${algoName}`
      setLoader(true)
      const response = await EmulationLab.get(imageApi, {
        responseType: "blob",
        headers: {
          "Content-Type": "image/png",
        },
      })
      const file = new File([response.data], "image.png")
      const url = URL.createObjectURL(file, { type: "image/png" })
      setSelectedImage(url)
      setLoader(false)
    } catch (error) {
      console.log(error.response)
      setErrorMessageInFetching("Couldn't fetch image!")
      setLoader(false)
    }
  }

  //to fetch all the data
  useEffect(() => {
    fetchThumbnails()
  }, [])

  //to fetch selected image on thumbnail click for respective algorithm
  useEffect(() => {
    setErrorMessageInFetching("")
    {
      !EMPTY_STATES.includes(algoName) && item && fetchImageUsingRoute()
    }
  }, [route, algoName])

  return (
    <Suspense fallback={<Loader />}>
      {itemData && (
        <core.Box className="ml-display-flex ml-flex-dir-col">
          <core.Typography variant="body1">
            Select Charts to Review Highlighted Algorithm
          </core.Typography>
          <core.Box className="MLCharts-main-card ml-display-flex ml-flex-dir-row">
            <core.Box className="MLCharts-image-container ml-display-flex ml-flex-dir-col">
              <core.Box className="MLCharts-imagelist">
                <core.ImageList rowHeight={150} cols={2}>
                  {itemData.map((item) => (
                    <core.ImageListItem
                      key={item.route}
                      className="MLCharts-imgItem"
                      style={{
                        border: item.route === route ? "3px solid #39C2D7" : "",
                      }}
                    >
                      <img
                        src={`${item.thumbnail}?w=100&h=100&fit=crop&auto=format`}
                        srcSet={`${item.thumbnail}?w=100&h=100&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.name}
                        loading="lazy"
                        onClick={() => {
                          setRoute(item.route)
                          setImageName(item.name)
                        }}
                      />
                      <core.Box
                        bgcolor={theme.palette.primary.main}
                        color={theme.palette.common.white}
                        className={
                          item.route === route
                            ? "MLCharts-image-title opacity-on"
                            : "MLCharts-image-title "
                        }
                      >
                        <Typography variant="body1">{item.name}</Typography>
                      </core.Box>
                    </core.ImageListItem>
                  ))}
                </core.ImageList>
              </core.Box>
            </core.Box>
            <core.Divider orientation="vertical" />
            <core.Box className="MLCharts-container">
              {loader && <Loader />}
              {errorMessageInFetching && (
                <core.Typography variant="body2">
                  {errorMessageInFetching}
                </core.Typography>
              )}
              {!loader && (
                <img
                  src={selectedImage}
                  alt={imageName}
                  className="MlCharts-image-selected"
                />
              )}
            </core.Box>
          </core.Box>
        </core.Box>
      )}
    </Suspense>
  )
}

export default MLCharts
