import { configInpOut } from "../hooks/useCalculatorConfig"
import useErrorPage from "../hooks/useErrorPage"

function CommonerrorPageHandle(props) {
  const { inpVar, outVar } = configInpOut()
  const errorPage = useErrorPage(props.title)

  let contentToShow = props.children

  if (
    (props.title === "Calculator" || props.title === "Tests") &&
    (inpVar === 0 || outVar === 0)
  ) {
    contentToShow = errorPage
  } else if (
    props.title === "ML Evaluation" &&
    (outVar === 0 || inpVar === 0)
  ) {
    contentToShow = errorPage
  }

  return contentToShow
}

export default CommonerrorPageHandle
