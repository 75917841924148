import { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import * as m from "@mui/material"
import { Paper } from "@material-ui/core"
import { TitleVariable } from "./VariableDefiner"
import { useParams } from "react-router"
import useStyles from "../../card_style"
import { cubeSides } from "../../tryit/CalculatorForm/ThreedCanvas"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"

export default function OutputDefiner({
  listIndex,
  visible,
  unique1,
  setOutputDialogVisible,
  btnStyle,
  updateVariable,
  addVar,
  domainData,
  dataColumnLabels,
}) {
  let { emulatorId } = useParams()
  const classes = useStyles()

  const updateToggle = useRecoilValue(storedSates.updateVar)
  const updateData = useRecoilValue(storedSates.addData)
  const [emData, setEmData] = useRecoilState(storedSates.getEmulatorData)
  const allNames = useRecoilValue(storedSates.manageConfigsNames)
  const [isSameValues, setIsSameValues] = useRecoilState(
    storedSates.manageConfigsSameValues
  )
  const [emulatorStatusState, setEmulatorStatusState] = useRecoilState(
    storedSates.emulatorStatus
  )

  const [nicknameError, setNicknameError] = useState(false)
  const [unitError, setUnitError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [columnLabelError, setColumnLabelError] = useState(false)
  const [descriptionError, setSDescriptionError] = useState(false)
  const outputVariables = emData?.calculator?.OutputVariables
  const modelPositionJSON = outputVariables?.[listIndex]?.ModelVisual
  const [modelPosition, setModelPosition] = useState(
    modelPositionJSON ? JSON.parse(JSON.stringify(modelPositionJSON)) : null
  )

  const outputVar = emData?.calculator?.OutputVariables
  const worksheet = outputVar && outputVar[outputVar?.length - 1]?.Worksheet

  const defaultVar = {
    Name: "",
    NickName: "",
    ColumnLabel: "",
    Worksheet: worksheet || "",
    ApiLabel: "",
    Cell: "",
    Units: "",
    Description: "",
    Type: "number",
    Alias: "",
    ParamName: "",
    PythonVariableName: "",
  }
  const [formValuesOutput, setFormValuesOutput] = useState(defaultVar)
  const [pythonVarError, setPythonVarError] = useState(false)
  const [columnLabelValidation, setColumnLabelValidation] = useState(false)

  useEffect(() => {
    setIsSameValues({
      isSameName: false,
      isSameNameError: false,
      isSameNickName: false,
      isSameNickNameError: false,
      isSamecolumnLabel: false,
      isSameColumnLabelError: false,
    })
  }, [])

  useEffect(() => {
    if (updateToggle) {
      const outputVariables = JSON.parse(
        JSON.stringify(emData.calculator.OutputVariables.at(listIndex))
      )
      const Nickname = outputVariables.NickName
      if (!outputVariables.ApiLabel) {
        const updatedForm = {
          ...outputVariables,
          ApiLabel: `in_${Nickname}`,
        }
        setFormValuesOutput(updatedForm)
      } else {
        setFormValuesOutput(outputVariables)
      }
    }
  }, [listIndex])

  useEffect(() => {
    if (updateData) {
      const nickname = Object.keys(domainData)?.toString()
      const varType = domainData[nickname]?.sensed_type
      setFormValuesOutput({
        ...formValuesOutput,
        Name: nickname,
        NickName: nickname,
        ColumnLabel: nickname,
        ApiLabel: nickname,
        Type:
          (varType === "categorical" && "text") ||
          (varType === "numerical" && "number") ||
          "quantity",
      })
    }
  }, [updateData])

  useEffect(() => {
    if (updateToggle) {
      setFormValuesOutput(
        JSON.parse(
          JSON.stringify(emData.calculator.OutputVariables.at(listIndex))
        )
      )
    }
  }, [listIndex])

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget
    setFormValuesOutput({
      ...formValuesOutput,
      [name]: value,
    })
  }

  const downgradeStatusandAddVar = () => {
    storedSates
      .updateTheStatus(emulatorId, storedSatus.allEmulatorStatus.preparing)
      .then(() => {
        setEmulatorStatusState(storedSatus.allEmulatorStatus.preparing)
        handleSubmit()
      })
      .catch((err) => console.error(err))
  }

  const validateInput = (field, value) => {
    if (!value || value.length === 0) {
      switch (field) {
        case "Name":
          setNameError(true)
          return storedSatus.handeEmptyTextfield.missingName
        case "NickName":
          setNicknameError(true)
          return storedSatus.handeEmptyTextfield.missingNickName
        case "ColumnLabel":
          setColumnLabelError(true)
          return storedSatus.handeEmptyTextfield.missingLabel
        case "Description":
          formValuesOutput.Description = ""
          setSDescriptionError(true)
          return storedSatus.handeEmptyTextfield.missingDesc
        case "Units":
          setUnitError(true)
          return storedSatus.handeEmptyTextfield.missingUnit
        case "PythonVariableName":
          setPythonVarError(true)
          return storedSatus.handeEmptyTextfield.missingPythonVar
        default:
          return ""
      }
    }
    return ""
  }

  const handleValidation = () => {
    let errorMessage = ""

    errorMessage = validateInput("Name", formValuesOutput.Name)
    if (errorMessage) return errorMessage

    errorMessage = validateInput("NickName", formValuesOutput.NickName)
    if (errorMessage) return errorMessage

    errorMessage = validateInput("ColumnLabel", formValuesOutput.ColumnLabel)
    if (errorMessage) return errorMessage

    errorMessage = validateInput(
      "PythonVariableName",
      formValuesOutput.PythonVariableName
    )
    if (
      errorMessage &&
      emData?.source?.software === storedSatus.emulatorSoftwareType.python &&
      emData?.source?.type !== storedSatus.emulatorSoftwareType.hosted
    )
      return errorMessage

    errorMessage = validateInput("Description", formValuesOutput.Description)
    if (errorMessage) return errorMessage

    if (
      formValuesOutput.Type === "quantity" &&
      (!formValuesOutput.Units || formValuesOutput.Units === "")
    ) {
      setUnitError(true)
      return storedSatus.handeEmptyTextfield.missingUnit
    }
    return ""
  }

  const containsName = (array, nameToFind, label) => {
    if (updateToggle) {
      const newArray = array.filter(
        (item) => item !== emData.calculator.OutputVariables[listIndex][label]
      )
      return newArray.some(
        (item) => item.toLowerCase().trim() === nameToFind.toLowerCase().trim()
      )
    }
    return array.some(
      (item) => item.toLowerCase().trim() === nameToFind.toLowerCase().trim()
    )
  }

  const commonNoteSameName = (names, name, sameName, note, error, label) => {
    if (containsName(names, name, label)) {
      if (!updateToggle) {
        setIsSameValues((prevState) => ({
          ...prevState,
          [sameName]: true,
        }))
        return note
      } else if (updateToggle && error) {
        setIsSameValues((prevState) => ({
          ...prevState,
          [sameName]: true,
        }))
        return note
      }
    } else {
      setIsSameValues((prevState) => ({
        ...prevState,
        [sameName]: false,
      }))
    }
  }

  const handleSameError = () => {
    let errorMessage = ""
    errorMessage = commonNoteSameName(
      allNames.names,
      formValuesOutput.Name,
      "isSameName",
      storedSatus.handeEmptyTextfield.missingName,
      isSameValues.isSameNameError,
      "Name"
    )
    if (errorMessage) return errorMessage
    errorMessage = commonNoteSameName(
      allNames.nickNames,
      formValuesOutput.NickName,
      "isSameNickName",
      storedSatus.handeEmptyTextfield.missingNickName,
      isSameValues.isSameNickNameError,
      "NickName"
    )
    if (errorMessage) return errorMessage
    errorMessage = commonNoteSameName(
      allNames.columnLabels,
      formValuesOutput.ColumnLabel,
      "isSamecolumnLabel",
      storedSatus.handeEmptyTextfield.missingLabel,
      isSameValues.isSameColumnLabelError,
      "ColumnLabel"
    )
    if (errorMessage) return errorMessage
  }

  const handleSubmit = () => {
    const validationError = handleValidation()
    if (validationError) return validationError

    const sameNameValidations = handleSameError()
    if (sameNameValidations) return sameNameValidations

    if (columnLabelValidation) return

    const tempOutputObj = formValuesOutput

    if (
      (formValuesOutput.Type === "text" ||
        formValuesOutput.Type === "number") &&
      formValuesOutput.Units?.length >= 0
    ) {
      tempOutputObj.Units === "none"
    }

    const temp = JSON.parse(JSON.stringify(emData))
    const Software = storedSatus.sourceSoftware(emData)
    const softwareType = storedSatus.sourceType(emData)

    const propertiesToDelete =
      (softwareType === storedSatus.emulatorSoftwareType.hosted &&
        storedSatus.deletePropsMap["host"]) ||
      storedSatus.deletePropsMap[Software] ||
      storedSatus.deletePropsMap.default

    propertiesToDelete.forEach((prop) => {
      delete tempOutputObj[prop]
    })

    if (updateToggle) {
      temp.calculator.OutputVariables[listIndex] = tempOutputObj
    } else if (temp?.calculator?.OutputVariables) {
      temp.calculator.OutputVariables.push(tempOutputObj)
    } else {
      temp.calculator["OutputVariables"] = [tempOutputObj]
    }
    const updatedData = modelPosition
      ? storedSates.sendModelWithRotation(
          modelPosition,
          undefined,
          formValuesOutput
        )
      : formValuesOutput
    !modelPosition && setEmData(temp)
    updateToggle
      ? updateVariable("output", updatedData, true, undefined, modelPosition)
      : addVar("output", tempOutputObj)
  }

  const renderButtons = (small, medium) => (
    <m.Box
      className="ml-display-flex btnbox"
      paddingBottom="1rem"
      sx={{ display: { xs: small, md: medium } }}
    >
      <m.Button
        onClick={() => setOutputDialogVisible(false)}
        style={btnStyle}
        color="primary"
        variant="contained"
      >
        <m.Typography variant="caption" margin="4px">
          Cancel
        </m.Typography>
      </m.Button>
      <m.Button
        onClick={
          emulatorStatusState === "training"
            ? downgradeStatusandAddVar
            : handleSubmit
        }
        style={btnStyle}
        color="primary"
        variant="contained"
        fullWidth
      >
        <m.Typography variant="caption" margin="4px">
          Save
        </m.Typography>
      </m.Button>
    </m.Box>
  )

  const changeModelPosition = (e) => {
    setModelPosition(e.target.value)
  }

  if (!visible) {
    return <></>
  } else {
    return (
      <>
        <Paper sx={{ width: "auto", margin: "0.5rem" }} elevation={1}>
          {TitleVariable(updateToggle, "Output")}
          <m.Box>
            <m.Stack direction="column" spacing={2}>
              <m.Grid
                container
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <m.Grid item padding="1rem" maxWidth={"33rem"}>
                  <file.VariableDefiner
                    formValuesOutput={formValuesOutput}
                    unique1={unique1}
                    handleInputChange={handleInputChange}
                    nicknameError={nicknameError}
                    unitError={unitError}
                    setFormValuesOutput={setFormValuesOutput}
                    nameError={nameError}
                    descriptionError={descriptionError}
                    columnLabelError={columnLabelError}
                    columnPrefix={"out"}
                    domainData={domainData}
                    dataColumnLabels={dataColumnLabels}
                    listIndex={listIndex}
                    pythonVarError={pythonVarError}
                    columnLabelValidation={columnLabelValidation}
                    setColumnLabelValidation={setColumnLabelValidation}
                  />
                  {modelPosition?.length > 0 && (
                    <m.Stack>
                      <m.FormLabel size="small" color="primary">
                        <m.Typography
                          variant="body1"
                          fontWeight={650}
                          className={`${classes.colorBlack} boldLabel`}
                        >
                          Model Position
                        </m.Typography>
                      </m.FormLabel>
                      <m.FormControl fullWidth>
                        <m.Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={changeModelPosition}
                          value={modelPosition}
                        >
                          {cubeSides.map((cs) => (
                            <m.MenuItem value={cs} key={cs}>
                              {cs}
                            </m.MenuItem>
                          ))}

                          <m.MenuItem value={"Custom Position"}>
                            Custom Position
                          </m.MenuItem>
                        </m.Select>
                      </m.FormControl>
                    </m.Stack>
                  )}
                  For Validation input use 'design_OK' for Nickname. This field
                  will filter your data.
                  {!modelPosition && (
                    <m.Divider sx={{ display: { xs: "flex", md: "none" } }} />
                  )}
                  {renderButtons("none", "flex")}
                </m.Grid>
                <m.Grid
                  display={"flex"}
                  className={classes.backgroundPrimaryLight}
                >
                  <m.Divider
                    orientation="vertical"
                    sx={{ display: { xs: "none", md: "block" } }}
                  />
                  <file.VariableHelperText />
                </m.Grid>
              </m.Grid>
              {renderButtons("flex", "none")}
            </m.Stack>
          </m.Box>
        </Paper>
      </>
    )
  }
}
