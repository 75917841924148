import { useState, useEffect } from "react"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { useSetRecoilState } from "recoil"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import { styled } from "@mui/material/styles"
import * as core from "@material-ui/core"
import { FileProgressHeader, EmulationLab } from "../../GlobalFileContainer"
import "./UploadFiles.css"
import {
  featureSelectionReload,
  filesFromDropzone,
  globalDialogBox,
} from "../../state/projectState"
import { Typography } from "@mui/material"

const SingleFileUpload = ({ file, onDelete, onUpload, getEmulatorFiles }) => {
  const setReloadProject = useSetRecoilState(featureSelectionReload)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)
  const setFiles = useSetRecoilState(filesFromDropzone)

  const [uploaded, setUploaded] = useState(0)
  const [errorResponse, setErrorResponse] = useState("")
  const [fullerrorResponse, setFullErrorResponse] = useState("")
  const [uploadError, setUploadError] = useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)
  const UploadErrorProgress = styled(LinearProgress)(({ theme }) => ({
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: fileExtensionError
        ? theme.palette.error.main
        : theme.palette.warning.main,
    },
  }))

  const missingInput = fullerrorResponse?.missing_input_labels
  const missingOutput = fullerrorResponse?.missing_output_labels

  useEffect(() => {
    upload(file)
  }, [])

  const upload = async (file) => {
    try {
      var formdata = new FormData()
      formdata.append("file", file)
      const res = await EmulationLab.post(getEmulatorFiles, formdata, {
        onUploadProgress: (data) => {
          setUploaded(50)
        },
        headers: {
          "Content-Type":
            "multipart/form-data boundary=<calculated when request is sent>",
        },
      })
      if (res.status == 201 || res.status == 200) {
        setUploaded(100)
        onUpload()
      }
      setReloadProject(true)
      setTimeout(() => {
        setFiles((curr) => {
          return curr.filter((fwrap) => fwrap.file.name !== file.name)
        })
      }, 5000)
    } catch (error) {
      const errorData = error.response.data
      const errorStatus = error.response.status
      setUploadError(true)
      setUploaded(100)
      setErrorResponse(errorData?.message.toString())
      errorStatus === 401
        ? setFileExtensionError(true)
        : setFileExtensionError(false)
      setFullErrorResponse(errorData?.issues)
    }
  }

  const openModal = () => {
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: (
        <>
          {fullerrorResponse &&
            missingInput?.length > 0 &&
            missingInputOutputs("inputs", missingInput)}
          {fullerrorResponse &&
            missingOutput?.length > 0 &&
            missingInputOutputs("outputs", missingOutput)}
        </>
      ),
      title: "Error Details",
    }))
  }

  const missingInputOutputs = (type, missingLabel) => (
    <>
      <core.Box>
        <core.Typography variant="h6" id="headerheading">
          <PlayArrowIcon />
          &nbsp;These are the missing {type} Labels, please add these:
        </core.Typography>
      </core.Box>
      {missingLabel?.map((inp) => (
        <core.Box key={inp}>
          <ul key={inp} className="ulchilddata">
            <li>{inp}</li>
          </ul>
        </core.Box>
      ))}
    </>
  )

  return (
    <>
      <core.Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className="singlefileupload-grid"
      >
        <core.Grid item style={{ width: "90%" }}>
          <FileProgressHeader file={file} onDelete={onDelete} />
          {uploadError ? (
            <>
              <UploadErrorProgress variant="determinate" value={uploaded} />
              <Typography variant="body2">
                {errorResponse ? (
                  <>
                    {errorResponse}
                    {(missingInput?.length > 0 ||
                      missingOutput?.length > 0) && (
                      <a onClick={openModal}>view more</a>
                    )}
                  </>
                ) : (
                  "User is not authorized to make changes to this emulator."
                )}
              </Typography>
            </>
          ) : (
            <LinearProgress variant="determinate" value={uploaded} />
          )}
        </core.Grid>
      </core.Grid>
    </>
  )
}
export default SingleFileUpload
