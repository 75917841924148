import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { useRecoilValue } from "recoil"
import { emulatorSpecificRole } from "../../state/projectState"
import BackgroundLetterAvatars from "../../StringAvatar"

const ReturnMemberCell = ({
    name,
    email,
    avatar,
    id,
    memberCells,
    onlyNames,
    member,
    returnTransferDirectorButton,
}) => {
    const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)

    return (
        <core.TableCell>
            <core.Box className="ml-display-flex ml-flex-dir-row ml-align-center">
                <core.Box className="ml-p-5">
                    {avatar ? (
                        <m.Avatar src={avatar} />
                    ) : (
                        <>
                            <BackgroundLetterAvatars name={name ? name : "? ?"} />
                        </>
                    )}
                </core.Box>
                <core.Box className="ml-display-flex ml-flex-dir-col">
                    <m.Typography variant="body2">
                        <b>{name}</b>
                        {avatar && (
                            <m.Typography
                                sx={{ display: { xs: "contents", md: "none" } }}
                                className="ed-small"
                            >
                                {" "}
                                &nbsp;{"(Director)"}
                            </m.Typography>
                        )}
                    </m.Typography>
                    <m.Typography variant="body2">{email} </m.Typography>
                </core.Box>
            </core.Box>
            {!onlyNames && (
                <m.Box sx={{ display: { xs: "contents", md: "none" } }}>
                    {emulatorSpecificRoleState === "director" && avatar ? (
                        <core.Box className="director-cells">
                            {returnTransferDirectorButton()}
                        </core.Box>
                    ) : null}
                    {!avatar ? (
                        <core.Box className="member-cells">
                            {memberCells(
                                email,
                                member.requested_role,
                                member.request_status,
                                id,
                                member.approved_role
                            )}
                        </core.Box>
                    ) : null}
                </m.Box>
            )}
        </core.TableCell>
    )
}

export default ReturnMemberCell