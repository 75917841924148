import React from "react"
import * as m from "@mui/material"
import "../../result-optimization/OptimizationMain.css"

const RefreshButtonStyling = ({
  children,
  progress,
  strokeDasharray,
  height,
  width,
  isPolling,
}) => {
  const theme = m.useTheme()

  return (
    <m.Box className="circle1">
      {children}
      {isPolling && (
        <svg
          id="svg-refresh"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width={`${width}px`}
          height={`${height}px`}
        >
          <defs>
            <linearGradient id="GradientColor">
              <stop offset="100%" stopColor={theme.palette.secondary.main} />
            </linearGradient>
          </defs>
          <rect
            id="circle-refresh"
            height={`${height - 10}px`}
            width={`${width - 10}px`}
            rx="5"
            ry="5"
            x="5"
            y="5"
            style={{ opacity: "60%" }}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={
              strokeDasharray - strokeDasharray * (progress / 100)
            }
            strokeLinecap="round"
          />
        </svg>
      )}
    </m.Box>
  )
}

export default RefreshButtonStyling
