import { useState, useEffect } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useParams, useSearchParams } from "react-router-dom"
import * as core from "@material-ui/core"
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js"
import * as m from "@mui/material"
import { CalculatorInput } from "../../../GlobalFileContainer"
import {
  errorFieldsState,
  estimatedTimeState,
  findTrueModes,
  getEmulatorData,
  outputsParametersAndVariables,
  siteWideRole,
  updateURLSearchParams,
} from "../../../state/projectState"
import useStyles from "../../card_style"
import {
  findEstimatedTime,
  RUN_TYPES,
  isDataValid,
  allValuesFilled,
  roundValues,
  optimiseModesStrings,
} from "../../../state/StatusState"
import { ModeButton } from "./ModeButton"
import AlgorithmButton from "./AlgorithmButton"

const EMULATOR_MODE = "emulator"
const HOSTED_MODE = "hosted_calculator"
const CALCULATOR_MODE = "calculator"

export const showEstimatedTime = (estimateTime) => (
  <>
    <core.Box className="ml-display-flex ml-flex-dir-row ml-align-center">
      {estimateTime.agentCount !== undefined && (
        <core.Typography variant="body2" className="ed-small">
          Active agents- {estimateTime.agentCount},
        </core.Typography>
      )}
      <core.Typography variant="body2" className="ed-small">
        Estimated wait- {estimateTime.wait}
      </core.Typography>
    </core.Box>
  </>
)

function CalculatorInputCard(props) {
  const appInsights = useAppInsightsContext()
  const classes = useStyles()
  const { emulatorId } = useParams()
  const [modeSearchParam] = useSearchParams()

  const emulatorConfig = useRecoilValue(getEmulatorData)
  const trueModes = useRecoilValue(findTrueModes)
  const setErrorFields = useSetRecoilState(errorFieldsState)
  const [selectedOutputs, setSelectedOutputs] = useRecoilState(
    outputsParametersAndVariables
  )
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const [estimateTime, setEstimateTime] = useRecoilState(estimatedTimeState)

  const [timeAndIterationValue, setTimeAndIterationValue] = useState({
    time: 120,
    iteration: 200,
  })
  const [error, setError] = useState({ max_iteration: false, max_time: false })
  const [errorMessage, setErrorMessage] = useState("")

  const data = {
    type: props.pageOptimize ? "optimization" : emulatorConfig?.source?.type,
    software: emulatorConfig?.source?.software,
    emulator_id: emulatorId,
    N: 1,
  }

  const rangeTextFieldStyles = {
    inputLabelPropsStyle: {
      fontSize: "10px",
      top: "-13px",
      letterSpacing: "0.5px",
    },
    inputPropsStyle: {
      maxWidth: "60px",
      padding:
        error.max_iteration || error.max_time ? "14px 7px 6px" : "10px 7px",
    },
    inputAdornmentStyle: {
      padding:
        error.max_iteration || error.max_time ? "14px 0px 6px" : "10px 0px",
      margin: "0px",
    },
    commonStyle: {
      fontSize: "12px",
      paddingRight: "5px",
    },
  }

  const [tryitMode, setTryItMode] = useState(
    modeSearchParam.get("mode") ?? selectedOutputs.mode
  )
  const [selectedAlgorithm, setSelectedAlgorithm] = useState(
    optimiseModesStrings.genetic
  )
  const [postBody, setPostBody] = useState({})

  const [checkingRangeWarning, setCheckingRangeWarning] = useState(
    Array(emulatorConfig?.calculator?.InputVariables.length).fill(false)
  )
  const isDataValidState = isDataValid(postBody)
  const allValuesFilledState = allValuesFilled(checkingRangeWarning)
  const trackPostedInputs = useTrackEvent(appInsights, "PostedInputs", postBody)
  const compareVariableLength =
    emulatorConfig?.calculator?.InputVariables.length ===
    selectedOutputs?.variables.length
  const showHideBest = selectedOutputs?.bestResult
    ? props.setAsVariable
      ? compareVariableLength
      : props.pageOptimize
    : false

  useEffect(() => {
    setErrorFields(false)
    if (selectedOutputs.max_iteration) {
      const timeString = selectedOutputs.max_time
      const [hours, minutes, seconds] = timeString.split(":").map(Number)
      const totalSeconds = hours * 3600 + minutes * 60 + seconds
      setTimeAndIterationValue({
        time: totalSeconds,
        iteration: selectedOutputs.max_iteration,
      })
    }
  }, [])

  useEffect(() => {
    const errorState = error.max_iteration || error.max_time
    props.setValidateOptimizeButton &&
      props.setValidateOptimizeButton(errorState || !allValuesFilledState)
  }, [allValuesFilledState, error])

  useEffect(() => {
    if (
      (Object.keys(estimateTime).length === 0 &&
        tryitMode === RUN_TYPES[0] &&
        trueModes?.includes(tryitMode)) ||
      props.pageOptimize
    ) {
      findEstimatedTime(data, setEstimateTime)
    }
    if (props.pageOptimize) {
      setSelectedOutputs((prev) => ({
        ...prev,
        mode: tryitMode,
      }))
    }
  }, [trueModes, tryitMode])

  useEffect(() => {
    if (window.location.pathname.includes("tryit")) {
      const listOfInputs = {}
      postBody?.FeaturesData?.map((input) => {
        const changedLabel = input.Label?.replace("in ", "")
        listOfInputs[changedLabel] = input?.Data[0]
      })
      const existingParams = new URLSearchParams(modeSearchParam)
      Object.entries(listOfInputs).map(([key, value]) =>
        existingParams.set(key, value)
      )
      updateURLSearchParams(listOfInputs)
    }
  }, [postBody])

  const processClick = (e) => {
    e.preventDefault()
    if (!isDataValidState) {
      setErrorFields(true)
    } else if (allValuesFilledState) {
      props.setCurrentIndex(0)
      trackPostedInputs()
      props.setPostBody1(postBody)
    }
  }

  const showImportantNotes = (mode) => {
    const message =
      mode === EMULATOR_MODE
        ? "Please run the MLEvaluation to run the emulator."
        : mode === HOSTED_MODE
        ? "Please add Ref. Key in manage configs to run the calculator."
        : emulatorConfig?.ready_for_calcs
        ? "Please set Active Calculator file to run the calculator."
        : "The calculator is not ready for calculations."

    return (
      <core.Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <core.Typography variant="body2" className="ed-small">
          {message}
        </core.Typography>
      </core.Box>
    )
  }

  const getCalculatorWarning = () => {
    if (tryitMode === RUN_TYPES[0] && Object.keys(estimateTime).length !== 0) {
      return (
        <core.Typography color="secondary" variant="body2" className="ed-small">
          Calculators are undergoing testing.
          <br /> Results may not always be available or correct.
        </core.Typography>
      )
    } else {
      return null
    }
  }

  const renderEstimateTime = () => {
    return Object.keys(estimateTime).length !== 0 &&
      (!props.pageOptimize || tryitMode === RUN_TYPES[0])
      ? showEstimatedTime(estimateTime)
      : null
  }

  const renderReasonForDisable = () => {
    switch (tryitMode) {
      case RUN_TYPES[2]:
        return emulatorConfig?.modes?.includes(RUN_TYPES[2])
          ? showImportantNotes(EMULATOR_MODE)
          : null
      case RUN_TYPES[0]:
        return emulatorConfig?.source?.type === HOSTED_MODE
          ? showImportantNotes(HOSTED_MODE)
          : emulatorConfig?.source?.type === CALCULATOR_MODE
          ? showImportantNotes()
          : null
      default:
        return null
    }
  }

  const convertToTimeFormat = (userInput) => {
    const totalSeconds = parseInt(userInput, 10)

    if (!isNaN(totalSeconds) && totalSeconds >= 0) {
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
      const seconds = totalSeconds % 60

      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
      return formattedTime
    } else {
      return ""
    }
  }

  const handleInputChange = (e, label) => {
    const labelIsMaxIteration = label === "max_iteration"
    const value = e.target.value === "" ? "" : Number(e.target.value)
    const maxValue = labelIsMaxIteration ? 200 : 120
    const getConvertedTime = !labelIsMaxIteration && convertToTimeFormat(value)
    setTimeAndIterationValue((prev) => ({
      ...prev,
      [`${label.split("_")[1]}`]: value,
    }))
    if (
      (value > maxValue || value < 1 || value === "") &&
      siteWideRoleState !== "admin"
    ) {
      setError((prev) => ({
        ...prev,
        [label]: true,
      }))
      setErrorMessage(
        value < 1 || value === ""
          ? "Should be >0"
          : `Should not be >${maxValue}`
      )
    } else {
      setError((prev) => ({
        ...prev,
        [label]: false,
      }))
      setSelectedOutputs((prev) => ({
        ...prev,
        [label]: `${labelIsMaxIteration ? value : getConvertedTime}`,
      }))
    }
  }

  const iterationTimeInputs = (label, value) => {
    const labelIsMaxIteration = label === "Max Generations"
    const labelValue = labelIsMaxIteration ? "max_iteration" : "max_time"
    return (
      <div className="ml-display-flex ml-flex-dir-col margin-left-20">
        <div>
          <m.Typography variant="subtitle2">{label}</m.Typography>
        </div>
        <m.TextField
          fullWidth
          autoComplete="off"
          type="number"
          InputLabelProps={{
            shrink: false,
            style: rangeTextFieldStyles.inputLabelPropsStyle,
          }}
          inputProps={{
            step: 1,
            style: rangeTextFieldStyles.inputPropsStyle,
          }}
          value={value}
          InputProps={{
            endAdornment: !labelIsMaxIteration && (
              <m.InputAdornment
                position="end"
                style={rangeTextFieldStyles.inputAdornmentStyle}
              >
                <m.Typography variant="subtitle2">sec</m.Typography>
              </m.InputAdornment>
            ),
            style: rangeTextFieldStyles.commonStyle,
          }}
          onChange={(e) => handleInputChange(e, labelValue)}
          focused={error[labelValue]}
          color={error[labelValue] ? "warning" : undefined}
          label={error[labelValue] && errorMessage}
        />
      </div>
    )
  }

  return (
    <core.Card>
      <core.CardContent className={classes.calcMainCard}>
        <core.Typography variant="h5" className={classes.calculatorTypo}>
          Inputs
        </core.Typography>
        <CalculatorInput
          calcId={props.activeEmulatorId}
          calcConfig={props.calcConfig}
          setPostBody2={setPostBody}
          setCheckingRangeWarning={setCheckingRangeWarning}
          checkingRangeWarning={checkingRangeWarning}
          postBody={postBody}
          generateRandomInputs={props.generateRandomInputs}
          setGenerateRandomInputs={props.setGenerateRandomInputs}
          pageOptimize={props.setAsVariable}
          page={props.page}
        />
        <m.Divider />
        <core.Grid
          item
          className={`${classes.calculatorBtnGroup} calc-toggle-mode`}
          justifyContent="space-between"
          alignItems="center"
        >
          {!props?.setAsVariable && (
            <ModeButton
              tryitMode={tryitMode}
              setTryItMode={setTryItMode}
              pageOptimize={props.pageOptimize}
            />
          )}
          {props.pageOptimize && (
            <m.Grid
              item
              className="ml-display-flex width-100"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
              }}
            >
              <m.Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "start",
                }}
              >
                {!props?.setAsVariable && (
                  <>
                    <AlgorithmButton
                      selectedAlgorithm={selectedAlgorithm}
                      setSelectedAlgorithm={setSelectedAlgorithm}
                    />
                    {iterationTimeInputs(
                      "Max Generations",
                      timeAndIterationValue.iteration
                    )}
                    {iterationTimeInputs(
                      "Max Time",
                      timeAndIterationValue.time
                    )}
                  </>
                )}
              </m.Box>
              {!props.setAsVariable && (
                <m.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  {showHideBest && (
                    <m.Typography variant="body2">
                      <b>{`Best Result (Historically):
                     ${roundValues(selectedOutputs?.bestResult)}`}</b>
                    </m.Typography>
                  )}
                  {renderEstimateTime()}
                </m.Box>
              )}
            </m.Grid>
          )}
          {!props.setAsVariable && (
            <core.Grid
              direction="column"
              className="ml-display-flex ml-align-center"
            >
              <core.Grid direction="row">
                {!props.pageOptimize && (
                  <core.Button
                    id="tryitbtn"
                    title={
                      !trueModes?.includes(tryitMode) &&
                      `${tryitMode} feature will be launched soon!`
                    }
                    disabled={!trueModes?.includes(tryitMode) || props.loader}
                    color="primary"
                    variant="contained"
                    onClick={processClick}
                  >
                    <m.Typography variant="caption" margin="4px">
                      {tryitMode === RUN_TYPES[1]
                        ? RUN_TYPES[1]
                        : `Run the ${tryitMode?.slice(0, -1) + "or"}`}
                    </m.Typography>
                  </core.Button>
                )}
                {!props.pageOptimize && (
                  <core.Button
                    id="tryitbtn"
                    title={"Get random set of inputs."}
                    disabled={
                      (!props.pageOptimize
                        ? !trueModes?.includes(tryitMode)
                        : false) || props.loader
                    }
                    color="primary"
                    variant="contained"
                    onClick={() => props.setGenerateRandomInputs(true)}
                  >
                    <m.Typography variant="caption" margin="4px">
                      Randomize Inputs
                    </m.Typography>
                  </core.Button>
                )}
              </core.Grid>

              {!props.pageOptimize && (
                <>
                  {renderEstimateTime()}
                  {!trueModes?.includes(tryitMode) && renderReasonForDisable()}
                  {getCalculatorWarning()}
                </>
              )}
            </core.Grid>
          )}
        </core.Grid>
      </core.CardContent>
    </core.Card>
  )
}

export default CalculatorInputCard
