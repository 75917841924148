import { Box, Grid } from "@material-ui/core"
import { Stack } from "@mui/system"
import { varFontSize } from "./CalculatorConfig"
import { useWindowDimensions } from "../../../GlobalFileContainer"
import useStyles from "../../card_style"
import { Typography } from "@mui/material"

function VariableInfoLine(props) {
  const { width } = useWindowDimensions()
  const classes = useStyles()

  if (
    props.variable &&
    !props.children &&
    Object.keys(props.variable) &&
    !Object.keys(props.variable).includes(props.varKey)
  ) {
    // show just the title or skip it, the value doesn't exist
    return (
      <>
        {props.require && (
          <Box>
            <Typography
              variant="body1"
              fontWeight={650}
              className={`${classes.colorBlack} miniLabel`}
            >
              {props.varKey}:
            </Typography>
          </Box>
        )}
      </>
    )
  }

  return (
    <Box width="100%">
      <Stack direction="horizontal" alignItems="top" spacing={2}>
        <Typography
          variant="body1"
          fontWeight={650}
          className={
            (props.sum &&
              `${classes.colorBlack} blockwidth1 blockwidths miniLabel`) ||
            (props.summary &&
              (width > 429
                ? `${classes.colorBlack} blockwidth2 blockwidths miniLabel`
                : `${classes.colorBlack} blockwidth1 blockwidths miniLabel`)) ||
            `${classes.colorBlack} miniLabel`
          }
        >
          {props.varKey}:
        </Typography>
        <span>&nbsp;</span>
        <span className="childlabel">
          <Grid
            style={varFontSize}
            className={props.sum || props.summary ? "blockwidths" : "noclass"}
          >
            {props.children ? (
              props.children
            ) : (
              <Typography variant="body1">
                {props.variable?.[props.varKey]}
              </Typography>
            )}
          </Grid>
        </span>
      </Stack>
    </Box>
  )
}

export default VariableInfoLine
