import { atom } from "recoil"
import { cleanReturnJson } from "./axios"

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }

export const msalUserState = atom({
  key: "msalUserState",
  default: cleanReturnJson(localStorage.getItem("msalUserState")),
  effects: [localStorageEffect("msalUser")],
})

export const mongoProfileState = atom({
  key: "mongoProfileState",
  default: cleanReturnJson(localStorage.getItem("mongoProfileState")),
  effects: [localStorageEffect("mongoProfile")],
})

export const JWTTokenState = atom({
  key: "JWTTokenState",
  default: localStorage.getItem("JWTToken"),
  effects: [localStorageEffect("JWTToken")],
})
