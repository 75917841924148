import * as m from "@mui/material"
import { useWindowDimensions } from "../GlobalFileContainer"
import useStyles from "./card_style"

const PosterGridSkeleton = () => {
  const { width } = useWindowDimensions()
  const classes = useStyles()

  const renderSkeleton = (number) => (
    <m.Skeleton
      animation="wave"
      variant="circular"
      width={number ? 40 : 50}
      height={number ? 40 : 50}
    />
  )
  return (
    <m.Card sx={{ filter: "blur(2px)" }} className="emu-row__card">
      <m.CardHeader
        className="emu-card-header"
        title={
          <m.Skeleton
            animation="wave"
            height={15}
            width="80%"
            style={{ marginTop: 6, marginBottom: 6 }}
          />
        }
      />
      <m.Container
        sx={{ display: { xs: "none", md: "flex" } }}
        style={{ paddingLeft: "0px" }}
      >
        <span className={`${classes.backgroundWhite} selectioncard-chips`}>
          <m.Skeleton
            animation="wave"
            height={10}
            style={{ marginBottom: 4 }}
            width="40%"
          />
        </span>
      </m.Container>
      <div className={`${classes.colorWhiteIcon} cards-positions`}>
        <m.Skeleton
          className="image_color_filter1"
          sx={{ height: 350, width: { xs: "100px", md: "100%" } }}
          animation="wave"
          variant="rectangular"
        />
      </div>

      <div className={`${classes.backgroundWhite} emu-card-content-div`}>
        <m.CardContent
          sx={{ display: { xs: "none", md: "block" } }}
          className="emu-card-content-col1"
        >
          <m.Stack direction="column" spacing={5}>
            <m.Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              className="emu-card-content-stack2"
            >
              {renderSkeleton()}
              {renderSkeleton()}
              {renderSkeleton()}
            </m.Stack>
            {
              <m.Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              ></m.Stack>
            }
          </m.Stack>
        </m.CardContent>
        <div className={`${classes.bgLightPink} skeleton-emu-div2`}>
          <m.Stack
            direction="row"
            sx={{ paddingTop: 1, paddingBottom: 1 }}
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {renderSkeleton("number")}
            {renderSkeleton("number")}
            {renderSkeleton("number")}
            {width < 480 && (
              <>
                {renderSkeleton("number")}
                {renderSkeleton("number")}
              </>
            )}
          </m.Stack>
        </div>
      </div>
    </m.Card>
  )
}

export default PosterGridSkeleton
