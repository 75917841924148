import { useState } from "react"
import { useSetRecoilState, useRecoilValue } from "recoil"
import CompareArrowsIcon from "@mui/icons-material/CompareArrows"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import {
  TiLightbulb,
  TiCogOutline,
  TiMediaPause,
  TiArrowSyncOutline,
  TiMediaPlayOutline,
} from "react-icons/ti"
import { BsFillCalculatorFill } from "react-icons/bs"
import { RiFileSearchLine } from "react-icons/ri"
import { SlChemistry } from "react-icons/sl"
import * as m from "@mui/material"
import * as core from "@material-ui/core"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { useNavigate } from "react-router-dom"
import "./Card.css"
import gh3dimage from "../assets/gh3ddimage.png"

import {
  GrasshopperInstructions,
  EmulatorStatus,
  links,
  EmulationSelectionAlert,
  CloneEmulator,
  useWindowDimensions,
  DeleteEmulator,
  HeartnContribute,
  ErrorBoundary,
  Authorization,
} from "../GlobalFileContainer"
import { alertStates } from "../state/vizState"
import {
  emulatorSpecificRole,
  emulatorStatus,
  handleKeyDown,
  siteWideRole,
} from "../state/projectState"
import _ from "lodash"
import "./Card.css"
import { styled } from "@material-ui/styles"
import {
  checkAuthorization,
  betaVersion,
  breadCrumbsStrings,
  latestVersion,
  emulatorMode,
  allEmulatorStatus,
} from "../state/StatusState"
import useStyles from "./card_style"
import {
  useGetCalcShowHideStatus,
  useGetEmulatorUsageStatus,
} from "../hooks/useGetCalcShowHideStatus"

function getemulatorStatusPercentage(emulator) {
  try {
    switch (emulator.status.toLocaleLowerCase()) {
      case allEmulatorStatus.idea:
        return 2
      case allEmulatorStatus.initialized:
        return 2
      case allEmulatorStatus.preparing:
        return 20
      case allEmulatorStatus.prepared:
        return 40
      case allEmulatorStatus.training:
        return 60
      case allEmulatorStatus.deployed:
        return 100
    }
  } catch {
    return 0
  }
}

export default function EmulatorSelectionCard({
  emulator,
  mongoProfile,
  searchResultKeys,
  fetchData,
}) {
  let navigate = useNavigate()
  const classes = useStyles()
  const theme = m.useTheme()
  const { width } = useWindowDimensions()
  const latestVers = latestVersion(emulator)
  const isBeta = latestVers === breadCrumbsStrings.none
  const betaVers = betaVersion(emulator)
  const percentage = getemulatorStatusPercentage(emulator)
  const noOfReleases = Object.keys(emulator.versions.releases).length
  const showCalculator = useGetCalcShowHideStatus(emulator, noOfReleases)
  const useEmulator = useGetEmulatorUsageStatus(emulator)
  const disableCalc = !showCalculator || useEmulator
  const isCountZero = emulator?.total_row_count === 0

  const setEmulatorStatusState = useSetRecoilState(emulatorStatus)
  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const setAlertState = useSetRecoilState(alertStates)

  const [editMeta, setEditMeta] = useState(false)
  const [openGHAlert, setOpenGHAlert] = useState(false)
  const [showPopUp, setShowPopUp] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const open = Boolean(anchorEl)

  const StyledBadge = styled(m.Badge)(() => ({
    "& .MuiBadge-badge": {
      right: 3,
      top: 35,
      padding: "0 4px",
    },
  }))

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
  }

  const closeStatus = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setShowPopUp(false)
  }

  const tryItRouteChange = (e, mode) => {
    e.stopPropagation()
    e.preventDefault()
    let path = `/em/${emulator.id}/tryit?mode=${mode}`
    navigate(path)
    setEmulatorStatusState(emulator.status)
    return false
  }

  const investigateRouteChange = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let path = `/em/${emulator.id}/summary`
    setEmulatorStatusState(emulator.status)
    navigate(path)
    return false
  }

  const cloneEmulator = (e) => {
    e.stopPropagation()
    setEditMeta(true)
    setAnchorEl(null)
  }

  const menuItemClick = (e, route) => {
    e.stopPropagation()
    e.preventDefault()
    navigate(route)
    setEmulatorStatusState(emulator.status)
  }

  const ghClickHandler = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setOpenGHAlert(true)
  }

  const shareEmulator = async (e) => {
    e.stopPropagation()

    let url = `${process.env.REACT_APP_API_URL}/emulators/${emulator.id}/share`
    await navigator.clipboard.writeText(url)
    setAlertState({
      boolState: true,
      message: "Emulator link copied to clipboard!",
      severityState: "info",
    })
    setAnchorEl(null)
  }

  const handleDeleteDialogOpen = (e) => {
    e.preventDefault()
    setOpenDeleteDialog(true)
  }

  //commented code may be used later
  // const shareOnTeams = () => {
  //   const script = document.createElement('script')
  //   script.src = "https://teams.microsoft.com/share/launcher.js"
  //   script.async = true
  //   script.defer = true

  //   document.body.appendChild(script)
  //   console.log("here")

  //   return () => {
  //     document.body.removeChild(script)
  //   }
  // }

  const getIcon = (mode) => {
    switch (mode) {
      case emulatorMode.emulate:
        return <SlChemistry className="emu-card-icon" />
      case emulatorMode.calculate:
        return <BsFillCalculatorFill className="emu-card-icon" />
      case emulatorMode.lookup:
        return <RiFileSearchLine className="emu-card-icon" />
      default:
        return null
    }
  }

  const getStyledIconWithBadge = (mode) => {
    return width > 480 ? (
      <StyledBadge
        badgeContent={<WarningAmberIcon style={{ fontSize: "initial" }} />}
        color="secondary"
      >
        {getIcon(mode)}
      </StyledBadge>
    ) : (
      <m.Badge color="secondary" variant="dot">
        {getIcon(mode)}
      </m.Badge>
    )
  }

  const renderIconButton = (type) => {
    const renderModeNotes = (mode) => {
      return `Click to try the ${mode ? mode + " " + "engine" : "Lookup"}`
    }

    return (
      <div>
        {emulator?.modes?.map((mode, i) => (
          <m.IconButton
            key={`${mode}${i}`}
            title={
              (mode === emulatorMode.emulate && renderModeNotes("Emulator")) ||
              (mode === emulatorMode.calculate &&
                renderModeNotes("Calculator")) ||
              (mode === emulatorMode.lookup && renderModeNotes())
            }
            className="zoom-hover-10"
            color="primary"
            onClick={(e) => tryItRouteChange(e, mode)}
          >
            {type === "styled" ? getStyledIconWithBadge(mode) : getIcon(mode)}
          </m.IconButton>
        ))}
      </div>
    )
  }

  return (
    <>
      <EmulationSelectionAlert
        title={"Alert"}
        severity="success"
        open={openGHAlert}
        setOpen={setOpenGHAlert}
      >
        <GrasshopperInstructions />
        <m.Typography variant="body2">
          This emulator is ready for use in Grasshopper. Open Grasshopper and
          install WPM Grasshopper Tools (ghWally) to use.
        </m.Typography>
      </EmulationSelectionAlert>
      {_.some(searchResultKeys, (value) => _.includes(value, emulator.id)) && (
        <m.Card
          className="emu-row__card cursor-point"
          onClick={investigateRouteChange}
        >
          <m.CardHeader
            className="emu-card-header"
            action={
              <m.IconButton className="zoom-hover-10" onClick={handleClick}>
                <MoreVertIcon
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                />
                <m.Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {links(
                    emulator.id,
                    emulator.status,
                    isCountZero,
                    siteWideRoleState,
                    emulatorSpecificRoleState,
                    disableCalc,
                    true,
                    disableCalc
                  ).map((link, index) => {
                    let menuItems = link.heading.props.children.replace(
                      /:$/,
                      ""
                    )
                    return (
                      link.heading.props.children !== "Training:" &&
                      !link.children[0].disable && (
                        <m.MenuItem
                          key={link.heading.key + index}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            menuItemClick(e, link.children[0].url)
                          }}
                        >
                          {menuItems}
                        </m.MenuItem>
                      )
                    )
                  })}
                  <Authorization processName="uploadEmFilesOrTrain">
                    <m.MenuItem onClick={cloneEmulator}>
                      Clone Emulator
                    </m.MenuItem>
                  </Authorization>
                  <m.MenuItem onClick={shareEmulator}>
                    Share Emulator
                  </m.MenuItem>
                  {mongoProfile.useModes.includes("admin") && (
                    <m.MenuItem onClick={handleDeleteDialogOpen}>
                      Delete Emulator
                    </m.MenuItem>
                  )}
                </m.Menu>
              </m.IconButton>
            }
            titleTypographyProps={{ variant: "body", fontWeight: "bold" }}
            title={
              <m.Typography
                variant="body1"
                fontWeight={650}
                color={theme.palette.common.black}
              >
                {emulator.name}
              </m.Typography>
            }
            subheader={
              width < 480
                ? isBeta
                  ? `V${betaVers}*`
                  : (latestVers !== undefined && `V${latestVers}`) || ""
                : ""
            }
          />
          <m.Container
            className="em-select-padding"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <span className={`${classes.backgroundWhite} selectioncard-chips`}>
              {Object.entries(emulator.tags).map(([id, tag]) => (
                <m.Chip
                  key={`${id}_${tag}`}
                  size="small"
                  label={tag}
                  color="default"
                  variant="filled"
                  sx={{ fontFamily: theme.palette.typography.fontFamily }}
                />
              ))}
            </span>
          </m.Container>
          <div className={`${classes.colorWhiteIcon} cards-positions`}>
            <img
              className="image_color_filter1"
              height="100%"
              width="100%"
              src={emulator.image}
              alt={emulator.name}
              onError={({ target }) => {
                target.src = "/assets/images/default_em_image.jpg"
              }}
            ></img>
          </div>
          <div
            className={`${classes.backgroundWhite} emu-card-content-div`}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <m.CardContent className="emu-card-content-col1">
              <m.Stack
                className="emu-card-stack-main"
                direction="column"
                divider={<core.Divider orientation="horizontal" flexItem />}
                spacing={5}
              >
                <m.Stack
                  direction="row"
                  divider={
                    <core.Divider
                      orientation="vertical"
                      flexItem
                      className="emu-divider"
                    />
                  }
                  justifyContent="space-around"
                  alignItems="center"
                  className="emu-card-content-stack2"
                >
                  <div
                    title={"Click to see emulator status"}
                    className={`grid-child-score zoom-hover-10 emu-status-icon`}
                    style={
                      width > 480
                        ? { width: 50, height: 50 }
                        : { width: 40, height: 40 }
                    }
                  >
                    <CircularProgressbarWithChildren
                      value={percentage}
                      strokeWidth={6}
                      styles={{
                        path: { stroke: `rgba(57, 194, 215, 0.7)` },
                        trail: { stroke: `rgba(57, 194, 215, 0.1)` },
                        text: { fill: "black", fontSize: "16px" },
                      }}
                    >
                      <m.IconButton
                        style={{ cursor: "pointer" }}
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          setShowPopUp(true)
                        }}
                      >
                        {(percentage === 2 && <TiLightbulb size="25px" />) ||
                          (percentage === 20 && <TiCogOutline size="25px" />) ||
                          (percentage === 40 && <TiMediaPause size="25px" />) ||
                          (percentage === 60 && (
                            <TiArrowSyncOutline
                              size={width > 480 ? "25px" : "20px"}
                            />
                          )) ||
                          (percentage === 100 && (
                            <div
                              style={{ marginLeft: "2px", marginTop: "6px" }}
                            >
                              <TiMediaPlayOutline size="30px" />
                            </div>
                          ))}
                      </m.IconButton>
                    </CircularProgressbarWithChildren>
                  </div>
                  <m.IconButton
                    className="grid-child-score zoom-hover-10"
                    aria-label="Number of times Used"
                    title="Number of times Used"
                  >
                    <m.Stack direction="column" alignItems="center">
                      <CompareArrowsIcon />
                      <m.Typography
                        variant="caption"
                        className={`${classes.themeTypo} emu-typo`}
                      >
                        {emulator.numUses}
                      </m.Typography>
                    </m.Stack>
                  </m.IconButton>
                  {!disableCalc &&
                    checkAuthorization(
                      emulator,
                      mongoProfile,
                      noOfReleases,
                      renderIconButton
                    )}
                </m.Stack>
                {
                  <m.Stack
                    direction="row"
                    divider={
                      <core.Divider
                        orientation="vertical"
                        flexItem
                        className="emu-divider"
                      />
                    }
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  ></m.Stack>
                }
              </m.Stack>
            </m.CardContent>
            <div
              className={`${width > 480 ? classes.bgLightPink : ""} emu-div2`}
            >
              <m.Stack
                direction="row"
                divider={
                  <core.Divider
                    orientation="vertical"
                    flexItem
                    className="emu-divider"
                  />
                }
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {emulator.status === allEmulatorStatus.deployed ? (
                  <m.IconButton
                    title="This emulator is ready for grasshopper, click to learn how to use it"
                    sx={{ display: { xs: "none", md: "flex" } }}
                    onClick={ghClickHandler}
                  >
                    <m.Avatar
                      alt="GH"
                      src={gh3dimage}
                      sx={{ width: 24, height: 24, margin: "3px" }}
                      variant="square"
                    />
                  </m.IconButton>
                ) : width > 650 ? (
                  ""
                ) : (
                  <></>
                )}
                <HeartnContribute emulator={emulator} />
              </m.Stack>
            </div>
          </div>
          {width > 480 && (
            <m.Stack id="version-card" className={classes.colorGraySec}>
              <m.IconButton title={isBeta ? "Beta Version" : "Latest version"}>
                <m.Typography
                  variant="body2"
                  className={`${classes.themeTypo} emu-typo`}
                >
                  {isBeta
                    ? `V${betaVers}*`
                    : (latestVers !== undefined && `V${latestVers}`) || ""}
                </m.Typography>
              </m.IconButton>
            </m.Stack>
          )}
        </m.Card>
      )}
      {showPopUp && (
        <core.Dialog
          onKeyDown={(e) => handleKeyDown(e, setShowPopUp)}
          open={showPopUp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          fullWidth
          maxWidth="md"
        >
          <core.DialogContent dividers>
            <div>
              <ErrorBoundary>
                <EmulatorStatus emulator={emulator} />
              </ErrorBoundary>
              <div className="statusClosebtn-div">
                <core.Button
                  color="primary"
                  className="ml-display-flex ml-float-right"
                  style={{ color: theme.palette.common.white }}
                  variant="contained"
                  type="submit"
                  onClick={closeStatus}
                >
                  <m.Typography variant="caption">Close</m.Typography>
                </core.Button>
              </div>
            </div>
          </core.DialogContent>
        </core.Dialog>
      )}
      <ErrorBoundary>
        <CloneEmulator
          open={editMeta}
          setEditMeta={setEditMeta}
          emulator={emulator}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <DeleteEmulator
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          name={emulator.name}
          emulatorId={emulator.id}
          fetchData={fetchData}
        />
      </ErrorBoundary>
    </>
  )
}
