import { CorrelationMatrix, ErrorBoundary } from "../../GlobalFileContainer"

const FeatureSelection = () => {
  return (
    <ErrorBoundary>
      <CorrelationMatrix />
    </ErrorBoundary>
  )
}

export default FeatureSelection
