import { useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { useScreenshot } from "use-react-screenshot"
import { EmulationLab } from "../../GlobalFileContainer"
import {
  exploreImagesState,
  getSingleEmulatorId,
} from "../../state/projectState"

export default function CapturableDiv({
  afterRenderFunc,
  children,
  filename,
  renderableref,
  setLoader,
  setGlobalLoader,
  heightVal,
  widthVal,
}) {
  const [image, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  })

  const [images, setImages] = useRecoilState(exploreImagesState)
  const emulatorId = useRecoilValue(getSingleEmulatorId)

  const postImage = `emulators/${emulatorId}/images?type=file&folder=explore_matrix`

  useEffect(() => {
    if (renderableref.current) {
      setTimeout(() => {
        try {
          takeScreenShot(renderableref.current)
            .then(upload)
            .then(() => afterRenderFunc())
        } catch (e) {
          console.error(`Issue generating screenshot for ${filename} ${e}`)
        }
      }, 1000)
    }
  }, [renderableref])

  const upload = async (image, { name = filename, extension = "png" } = {}) => {
    const createFileName = (extension, name) => `${name}.${extension}`

    const response = await fetch(image)
    const blob = await response.blob()
    const file = new File([blob], createFileName(extension, name), {
      type: `image/${extension}`,
    })

    const formData = new FormData()
    formData.append("file", file)

    try {
      const result = await EmulationLab.post(postImage, formData)
      if (result) {
        const resultData = result?.data
        const resultImgName = resultData.public_id?.split("/explore_matrix/")[1]
        const findImg = images?.find((image) => image.name === resultImgName)

        if (!findImg) {
          const newImage = {
            image: resultData.url || "",
            id: resultData.public_id,
            name: resultImgName || "unknown",
            height: resultData.height,
            width: resultData.width,
          }

          setImages((prevImg) => [newImage, ...prevImg])
        }
        setLoader({ generate: false, upload: false })
        setGlobalLoader(false)
      }
    } catch (error) {
      setLoader({ generate: false, upload: false })
      setGlobalLoader(false)
      console.error("Error uploading image", error)
    }
  }

  return (
    <>
      <div style={{ height: heightVal, width: widthVal }}>{children}</div>
    </>
  )
}
