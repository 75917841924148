import { useState } from "react"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { useRecoilValue } from "recoil"
import { returnMetadataValue } from "../state/projectState"
import { BsFillCalculatorFill } from "react-icons/bs"
import { RiFileSearchLine } from "react-icons/ri"
import { SlChemistry } from "react-icons/sl"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import "./CreateNewEmulator.css"
import { useWindowDimensions } from "../GlobalFileContainer"
import useStyles from "./card_style"
import {
  emulatorSoftwareType,
  fontWeight650,
  renderFormLabel,
} from "../state/StatusState"

export const modelCheckbox = (hasModel, changemodelSetting) => (
  <m.Grid
    item
    className="createEm-grid-item-input ml-display-flex ml-align-center"
  >
    <m.Checkbox
      sx={{ paddingLeft: "0px" }}
      color="primary"
      value={hasModel}
      checked={hasModel}
      disabled={!changemodelSetting}
      onChange={changemodelSetting}
    />
    &nbsp; <m.Typography variant="body2">Has Model</m.Typography>
  </m.Grid>
)

function CreateEmulator({
  newEmulator,
  handleInput,
  handleCategoryChange,
  handleTagChange,
  categoryValue,
  tagValue,
  tempCategoryAlert,
  fileType,
  textfieldError,
  source,
  setDataSource,
  setFileType,
  modes,
  handleModeChange,
  edit,
  hasModel,
  changemodelSetting,
  setHasModel,
  setTextfieldError,
}) {
  const { width } = useWindowDimensions()
  const classes = useStyles()

  const [displayTaskForm, setDisplayTaskForm] = useState(false)
  const [taskName, setTaskName] = useState("")
  const [taskType, setTaskType] = useState("")
  const [allTasks, setAllTasks] = useState(source?.tasks || [])

  const calc = ["excel", "mathcad", "grasshopper", "python"]

  const dataSources = [
    { value: "none", label: "None" },
    { value: "hosted_calculator", label: "Hosted Calculator" },
    { value: "excel", label: "Excel" },
    { value: "mathcad", label: "MathCad" },
    { value: "upload", label: "Upload" },
    { value: "grasshopper", label: "Grasshopper" },
    { value: "python", label: "Python" },
  ]

  const expectedModes = [
    {
      value: "Emulate",
      label: "Emulators are very fast ML models that can predict the values.",
    },
    {
      value: "Calculate",
      label:
        "Calculator will actually run the calc for exact solutions, but will be slow and only work with xxx.",
    },
    // {
    //   value: "Lookup",
    //   label: "Lookup requires a complete dataset,with no holes.",
    // },
    //we will release lookup later then this code will be uncommented
  ]

  const apiKeys = [{ value: "GET_WITH_PARAMS" }, { value: "POST_WITH_JSON" }]

  const hostedCalc = fileType === "hosted_calculator"

  const metadata = useRecoilValue(returnMetadataValue)

  const categoryMeta =
    metadata && metadata.category.filter((item) => item !== "")
  const sortedData = categoryMeta.sort((a, b) => a.localeCompare(b))

  const selectMenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  }

  const handleChange = (e) => {
    const value = e.target.value
    const isHosted = value === "hosted_calculator"
    const isGrasshopper = value === emulatorSoftwareType.grasshopper
    setFileType(value)
    setDataSource(
      value === calc[0]
        ? {
            ...source,
            type: "calculator",
            software: value,
            method: "",
          }
        : {
            type: calc?.includes(value) ? "calculator" : value,
            software:
              (calc?.includes(value) && value) || (isHosted && "python") || "",
            method: isHosted ? "GET_WITH_PARAMS" : "",
          }
    )
    setHasModel && setHasModel(isGrasshopper)
    setTextfieldError(false)
  }

  const handleMethod = (e) => {
    setDataSource({ ...source, method: e.target.value })
    setTextfieldError(false)
  }

  const handleInputUrl = (e) => {
    const name = e.target.name
    const value = e.target.value

    setDataSource({ ...source, [name]: value })
    setTextfieldError(false)
  }

  const handleTaskValues = (e) => {
    e.preventDefault()
    setAllTasks((prev) => [...prev, { Name: taskName, Type: taskType }])
    setTaskName("")
    setTaskType("")
    setDataSource({
      ...source,
      tasks: [...allTasks, { Name: taskName, Type: taskType }],
    })
    setTextfieldError(false)
  }

  const handleChipDelete = (value) => {
    const filteredTasks = allTasks.filter((task) => task.Name !== value)
    setAllTasks(filteredTasks)
    setDataSource({ ...source, tasks: filteredTasks })
    setTextfieldError(false)
  }

  const handleChipClick = (task) => {
    setDisplayTaskForm(true)
    const filteredTasks = allTasks.filter((item) => item.Name !== task.Name)
    setAllTasks(filteredTasks)
    setTaskName(task.Name)
    setTaskType(task.Type)
    setTextfieldError(false)
  }

  return (
    <core.Grid className="createEm-grid-main" container spacing={2}>
      <core.Grid container className="createEm-grid-sub ml-display-flex">
        <core.Grid item className="createEm-grid-item">
          {renderFormLabel("Name:", classes)}
        </core.Grid>
        <core.Grid item className="createEm-grid-item-input">
          <m.TextField
            className="createEm-input"
            autoComplete="off"
            value={newEmulator.name}
            sx={{
              "& .MuiFormHelperText-root": {
                textTransform: "none",
              },
              "& .MuiFormHelperText-contained": {
                margin: 0,
                fontSize: "0.9rem",
              },
            }}
            onChange={handleInput}
            error={
              textfieldError.name &&
              (newEmulator.name.length === 0 || newEmulator.name?.trim() === "")
            }
            helperText={
              textfieldError.name &&
              (newEmulator.name.length === 0 ||
                newEmulator.name?.trim() === "") &&
              "You must enter Name."
            }
            name="name"
            placeholder="Enter emulator name"
            variant="outlined"
            size="small"
          />
        </core.Grid>
      </core.Grid>
      <core.Grid container className="createEm-grid-sub ml-display-flex">
        <core.Grid item className="createEm-grid-item">
          <m.Typography
            variant="body1"
            fontWeight={fontWeight650}
            className={`${classes.colorBlack} boldLabel`}
          >
            Description:
          </m.Typography>
        </core.Grid>
        <core.Grid item className="createEm-grid-item-input">
          <core.TextField
            className="createEm-input"
            multiline
            minRows={4}
            name="description"
            value={newEmulator.description}
            onChange={handleInput}
            placeholder="Emulator description"
            variant="outlined"
          />
        </core.Grid>
      </core.Grid>
      <core.Grid container className="createEm-grid-sub ml-display-flex">
        <core.Grid item className="createEm-grid-item">
          <m.Typography
            variant="body1"
            fontWeight={fontWeight650}
            className={`${classes.colorBlack} boldLabel`}
          >
            Image URL:
          </m.Typography>
        </core.Grid>
        <core.Grid item className="createEm-grid-item-input">
          <core.TextField
            className="createEm-input"
            hiddenLabel
            value={newEmulator.image}
            onChange={handleInput}
            error={textfieldError.image}
            helperText={
              textfieldError.image && "Please Enter correct Image URL."
            }
            name="image"
            placeholder="Enter a public URL for image"
            variant="outlined"
            size="small"
            autoComplete="off"
          />
        </core.Grid>
      </core.Grid>
      <core.Grid container className="createEm-grid-sub ml-display-flex">
        <core.Grid item className="createEm-grid-item">
          <m.Typography
            variant="body1"
            fontWeight={fontWeight650}
            className={`${classes.colorBlack} boldLabel`}
          >
            Tags:
          </m.Typography>
        </core.Grid>
        <core.Grid item className="createEm-grid-item-input">
          <Autocomplete
            multiple
            size="small"
            value={tagValue}
            options={metadata ? metadata.tags : "Loading tags, Please wait."}
            onChange={handleTagChange}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <m.Chip
                  key={`${option}-${index}`}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <core.TextField
                {...params}
                variant="outlined"
                placeholder={
                  tagValue?.length > 0
                    ? ""
                    : "Type to create your own tag or click to select value"
                }
              />
            )}
          />
        </core.Grid>
      </core.Grid>
      <core.Grid container className="createEm-grid-sub ml-display-flex">
        <core.Grid item className="createEm-grid-item">
          {renderFormLabel("Category:", classes)}
        </core.Grid>
        <core.Grid item className="createEm-grid-item-input">
          <m.FormControl
            fullWidth
            error={textfieldError.category && categoryValue.length === 0}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              displayEmpty
              value={categoryValue}
              onChange={handleCategoryChange}
              error={textfieldError.category && categoryValue.length === 0}
              MenuProps={selectMenuProps}
            >
              <m.MenuItem disabled value={categoryValue}>
                <em>Select Category</em>
              </m.MenuItem>
              {sortedData &&
                sortedData?.map((item) => {
                  const labelWord = item
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                  return (
                    <m.MenuItem key={item} value={item}>
                      {labelWord}
                    </m.MenuItem>
                  )
                })}
            </Select>
          </m.FormControl>
          {textfieldError.category && categoryValue.length === 0 && (
            <m.Typography className={classes.colorDangerIcon} variant="body2">
              You must enter Category.
            </m.Typography>
          )}
          {tempCategoryAlert !== "" && (
            <m.Typography
              className={`${classes.colorGraySec} delete-dialog-note`}
            >
              <m.Typography variant="body2"> {tempCategoryAlert}</m.Typography>
            </m.Typography>
          )}
        </core.Grid>
      </core.Grid>
      <core.Grid container className="createEm-grid-sub ml-display-flex">
        <core.Grid item className="createEm-grid-item">
          {renderFormLabel("Expected Run Modes:", classes)}
        </core.Grid>
        <core.Grid item className="createEm-grid-item-input">
          <m.FormControl fullWidth size="small">
            <Select
              multiple
              value={modes}
              displayEmpty
              onChange={handleModeChange}
              error={textfieldError.mode && modes.length === 0}
              autoWidth={false}
              MenuProps={{
                selectMenuProps,
                style: {
                  maxWidth: width > 480 ? "40%" : "30%",
                  minWidth: "fit-content",
                  right: 0,
                },
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Expected Run Modes</em>
                }
                return selected.join(", ")
              }}
            >
              {expectedModes.map((mode) => (
                <m.MenuItem
                  key={mode.value}
                  value={mode.value}
                  style={{
                    display: "block",
                    whiteSpace: "normal",
                  }}
                >
                  <div className="ml-display-flex ml-flex-dir-row ml-align-center">
                    <div className="create-em-width">
                      <Checkbox checked={modes.indexOf(mode.value) > -1} />
                    </div>
                    <m.IconButton className="create-em-width" color="primary">
                      {(mode.value === "Calculate" && (
                        <BsFillCalculatorFill fontSize="30px" />
                      )) ||
                        (mode.value === "Lookup" && (
                          <RiFileSearchLine fontSize="30px" />
                        )) || <SlChemistry fontSize="30px" />}
                    </m.IconButton>
                    <div>
                      <m.Typography variant="body1">{mode.label}</m.Typography>
                    </div>
                  </div>
                </m.MenuItem>
              ))}
            </Select>
          </m.FormControl>
          {textfieldError.mode && modes.length === 0 && (
            <m.Typography className={classes.colorDangerIcon} variant="body2">
              You must enter Expected Run Modes.
            </m.Typography>
          )}
        </core.Grid>
      </core.Grid>
      <core.Grid container className="createEm-grid-sub ml-display-flex">
        <core.Grid item className="createEm-grid-item">
          <m.Typography
            variant="body1"
            fontWeight={fontWeight650}
            className={`${classes.colorBlack} boldLabel`}
          >
            Data Source:
          </m.Typography>
        </core.Grid>
        <core.Grid item className="createEm-grid-item-input">
          <m.FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              MenuProps={selectMenuProps}
              error={
                textfieldError.source &&
                source.type === emulatorSoftwareType.upload
              }
              displayEmpty
              value={fileType}
              onChange={(e) => handleChange(e)}
            >
              <m.MenuItem disabled value="none">
                <em>Select Data source</em>
              </m.MenuItem>
              {dataSources.map((dataSource) => (
                <m.MenuItem key={dataSource.value} value={dataSource.value}>
                  {dataSource.label}
                </m.MenuItem>
              ))}
            </Select>
          </m.FormControl>
          {textfieldError.source &&
            source.type === emulatorSoftwareType.upload && (
              <m.Typography className={classes.colorDangerIcon} variant="body2">
                You can't choose data source as upload if mode is calculate.
              </m.Typography>
            )}
        </core.Grid>
      </core.Grid>
      {edit && fileType === emulatorSoftwareType.grasshopper && (
        <m.Grid
          container
          alignItems="baseline"
          className="createEm-grid-sub ml-display-flex ml-align-center"
        >
          <m.Grid item className="createEm-grid-item"></m.Grid>
          {modelCheckbox(hasModel, changemodelSetting)}
        </m.Grid>
      )}
      {source.software === calc[0] && (
        <core.Grid container className="createEm-grid-sub ml-display-flex">
          <core.Grid item className="createEm-grid-item">
            <m.Typography
              variant="body1"
              fontWeight={fontWeight650}
              className={`${classes.colorBlack} boldLabel`}
            >
              Advanced Tasks:
            </m.Typography>
          </core.Grid>
          <core.Grid item className="createEm-grid-item-input">
            <core.Grid className="ml-display-flex ml-align-center ml-flex-dir-row box-border">
              <core.Grid item xs={11}>
                <core.Box className="member-manage-title">
                  {allTasks.length > 0 ? (
                    <m.Stack direction="row" flexWrap="wrap" spacing={1}>
                      {allTasks.map((task) => (
                        <m.Chip
                          key={task.Name}
                          label={task.Name}
                          variant="outlined"
                          onDelete={() => handleChipDelete(task.Name)}
                          onClick={() => handleChipClick(task)}
                        />
                      ))}
                    </m.Stack>
                  ) : (
                    <m.Typography
                      className={`${classes.colorGraySec} miniLabel`}
                    >
                      Click on Add icon to add new task
                    </m.Typography>
                  )}
                </core.Box>
              </core.Grid>
              <core.Grid item xs={1}>
                <m.IconButton
                  onClick={() => setDisplayTaskForm(!displayTaskForm)}
                  edge="end"
                >
                  {displayTaskForm ? (
                    <RemoveCircleOutlineOutlinedIcon />
                  ) : (
                    <AddCircleOutlineOutlinedIcon />
                  )}
                </m.IconButton>
              </core.Grid>
            </core.Grid>
            <core.Grid
              className={`text-field-container ml-display-flex ml-align-center ml-flex-dir-row ${
                displayTaskForm && "slide-in"
              }`}
            >
              {displayTaskForm && (
                <core.Grid container spacing={1}>
                  <core.Grid item xs={5}>
                    <m.FormControl fullWidth>
                      <Select
                        size="small"
                        variant="outlined"
                        MenuProps={selectMenuProps}
                        displayEmpty
                        value={taskType}
                        onChange={(e) => setTaskType(e.target.value)}
                      >
                        <m.MenuItem disabled value="">
                          <em className={classes.colorGraySec}>
                            Enter task type
                          </em>
                        </m.MenuItem>
                        <m.MenuItem value="ExcelMacro">ExcelMacro</m.MenuItem>
                      </Select>
                    </m.FormControl>
                  </core.Grid>
                  <core.Grid item xs={5}>
                    <core.TextField
                      size="small"
                      variant="outlined"
                      value={taskName}
                      placeholder="Enter macro name"
                      onChange={(e) => setTaskName(e.target.value)}
                    />
                  </core.Grid>
                  <core.Grid item sx={1}>
                    <m.IconButton
                      disabled={!(taskName && taskType)}
                      onClick={handleTaskValues}
                    >
                      <DoneOutlinedIcon />
                    </m.IconButton>
                  </core.Grid>
                </core.Grid>
              )}
            </core.Grid>
          </core.Grid>
        </core.Grid>
      )}
      {hostedCalc && (
        <>
          <core.Grid container className="createEm-grid-sub ml-display-flex">
            <core.Grid item className="createEm-grid-item">
              <m.Typography
                variant="body1"
                fontWeight={fontWeight650}
                className={`${classes.colorBlack} boldLabel`}
              >
                Methods:
              </m.Typography>
            </core.Grid>
            <core.Grid item className="createEm-grid-item-input">
              <m.FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  MenuProps={selectMenuProps}
                  displayEmpty
                  value={source.method}
                  onChange={(e) => handleMethod(e)}
                >
                  <m.MenuItem disabled value="none">
                    <em>Select Method</em>
                  </m.MenuItem>
                  {apiKeys.map((api) => (
                    <m.MenuItem key={api.value} value={api.value}>
                      {api.value}
                    </m.MenuItem>
                  ))}
                </Select>
              </m.FormControl>
            </core.Grid>
          </core.Grid>
          <core.Grid container className="createEm-grid-sub ml-display-flex">
            <core.Grid item className="createEm-grid-item">
              {renderFormLabel("Ref. URL:", classes)}
            </core.Grid>
            <core.Grid item className="createEm-grid-item-input">
              <m.TextField
                className="createEm-input"
                hiddenLabel
                value={source.ref}
                onChange={handleInputUrl}
                sx={{
                  "& .MuiFormHelperText-root": {
                    textTransform: "none",
                  },
                  "& .MuiFormHelperText-contained": {
                    margin: 0,
                    fontSize: "0.9rem",
                  },
                }}
                name="ref"
                placeholder="Enter a Reference URL for Hosted Calculator"
                variant="outlined"
                size="small"
                autoComplete="off"
                error={textfieldError.url}
                helperText={textfieldError.url && "Please Enter Correct URL."}
              />
            </core.Grid>
          </core.Grid>
        </>
      )}
    </core.Grid>
  )
}

export default CreateEmulator
