import { Stack, Typography } from "@mui/material"
import * as core from "@material-ui/core"
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone"
import "./CalculatorConfig.css"
import { useRecoilValue } from "recoil"
import useStyles from "../../card_style"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"

export default function VariablesStack(props) {
  const classes = useStyles()

  const emulatorStatusState = useRecoilValue(storedSates.emulatorStatus)
  const selectedVersions = useRecoilValue(storedSates.selectedVersion)
  const betVers = useRecoilValue(storedSates.getBeta)
  const versName = useRecoilValue(storedSates.versionName)

  return (
    <core.Grid item xs={12} md={props.width} height>
      <Stack direction="column">
        <core.Box
          height={"auto"}
          className={classes.backgroundWhite}
          style={{ borderBottom: "3px solid #39c2d759" }}
        >
          <core.Typography
            variant="body1"
            color="primary"
            align={"center"}
            id="inputoutputtitle"
          >
            {props.title}
          </core.Typography>
        </core.Box>
        <core.Box textAlign={"left"}>{props.children}</core.Box>
        {selectedVersions === betVers &&
          versName === storedSatus.breadCrumbsStrings.beta &&
          props.title !== "UNUSED COLUMNS" && (
            <file.Authorization processName="manageIO">
              <core.Button style={{ width: "100%" }}>
                <file.Authorization
                  role={emulatorStatusState}
                  forbidden={storedSatus.trainingDeployed}
                  callFunction={() => props.addTheVariable()}
                >
                  <Stack
                    alignItems="center"
                    justify="center"
                    direction="column"
                  >
                    <core.Typography variant="h1" color="primary">
                      <AddCircleTwoToneIcon />
                    </core.Typography>
                    <Typography variant="caption">
                      {props.buttonCaption}
                    </Typography>
                  </Stack>
                </file.Authorization>
              </core.Button>
            </file.Authorization>
          )}
      </Stack>
    </core.Grid>
  )
}
