import { useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import * as Status from "../../state/StatusState"
import * as projState from "../../state/projectState"
import * as file from "../../GlobalFileContainer"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { DataGrid } from "@mui/x-data-grid"
import useStyles from "../card_style"
import { ChipIcon } from "../project/CalculatorCard"
import { renderFilterButton } from "../navContent/ExploreNav"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { boldTitleHeader } from "../admin-dashboard/AgentHealthMain"

export const isAppliedFilter = (appliedFilters) => {
  return Object.values(appliedFilters).some((array) => array.length > 0)
}

export const toggleDrawer = (open, setState) => (event) => {
  if (
    !(
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
  ) {
    setState(open)
  }
}

export const tableHeadings = [
  {
    name: `Job Id`,
    width: "100px",
  },
  {
    name: "Status/progress",
    width: "50px",
  },
  {
    name: "#Generation",
    width: "50px",
  },
  {
    name: "#Runs",
    width: "50px",
  },
  {
    name: "Runtime",
    width: "50px",
  },
  {
    name: "Best",
    width: "50px",
  },
  {
    name: "User",
    width: "100px",
  },
  {
    name: "Actions",
    width: "200px",
  },
]

export const filterOptions = () => {
  return tableHeadings
    ?.map((tbHead) => tbHead.name)
    ?.filter((name) => !["Job Id", "Actions", "Runtime"].includes(name))
}

export const filterState = {
  status: ["complete"],
  generation: [],
  runs: [],
  best: [],
  user: [],
}

export const commonCss = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  m: 1,
}

const OptimizationHistory = ({ allOptHistory, setOpenOpt }) => {
  const theme = m.useTheme()
  const classes = useStyles()
  const itemsPerPage = 10

  const combineCss = {
    ...commonCss,
    width: "100%",
    flexWrap: "wrap",
    maxWidth: "100%",
  }

  const setJobId = useSetRecoilState(projState.resultJobIdState)
  const setOutputsParametersAndVariables = useSetRecoilState(
    projState.outputsParametersAndVariables
  )
  const emConfig = useRecoilValue(projState.getEmulatorData)

  const [contextHistory, setContextHistory] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [expanded, setExpanded] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [appliedFilters, setAppliedFilters] = useState(filterState)
  const [serachedCards, setSearchedCards] = useState(contextHistory)
  const [accordionKey, setAccordionKey] = useState(null)

  useEffect(() => {
    const filteredHistory = allOptHistory?.table
      ?.filter((table, i) => table.context == expanded)
      ?.sort((a, b) => {
        const valueA = a.best
        const valueB = b.best

        if (isNaN(valueA) && isNaN(valueB)) {
          return 0
        }
        if (isNaN(valueA)) {
          return 1
        }
        if (isNaN(valueB)) {
          return -1
        }
        return valueA - valueB
      })
    setContextHistory(filteredHistory)
    setSearchedCards(filteredHistory)
  }, [expanded])

  useEffect(() => {
    const filteredData = contextHistory?.filter(
      (item) =>
        (appliedFilters.best.length === 0 ||
          appliedFilters.best.includes(Status.roundValues(item.best))) &&
        (appliedFilters.generation.length === 0 ||
          appliedFilters.generation.includes(item.generation_count)) &&
        (appliedFilters.runs.length === 0 ||
          appliedFilters.runs.includes(item.iteration_count)) &&
        (appliedFilters.status.length === 0 ||
          appliedFilters.status.includes(item.status)) &&
        (appliedFilters.user.length === 0 ||
          appliedFilters.user.includes(item.requesting_user_email))
    )
    setSearchedCards(filteredData)
  }, [appliedFilters, contextHistory])

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    setAppliedFilters(accordionKey === panel ? appliedFilters : filterState)
    setAccordionKey(panel)
  }

  const handleOpenFilters = (e) => {
    setAnchorEl(e.currentTarget)
    setOpenFilter(true)
  }

  const resetFilter = () => {
    setAppliedFilters({
      ...filterState,
      status: [],
    })
  }

  const renderNoneBox = () => (
    <m.Box sx={commonCss}>
      <m.Typography variant="body2">None</m.Typography>
    </m.Box>
  )

  const renderActionBtn = (label, color, callBAckFunc, id, disable) => {
    return (
      <m.Button
        className="ml-align-center"
        disabled={disable}
        sx={{ p: 0, m: 1, height: "25px" }}
        size="small"
        variant="outlined"
        color={color}
        onClick={(e) => callBAckFunc(e, id)}
      >
        {label}
      </m.Button>
    )
  }

  const viewOldJob = async (e, id, open) => {
    if (e) {
      e.preventDefault()
    }
    if (open) {
      const optimisedData = await getInstructions(id)
      setOutputsParametersAndVariables({ ...optimisedData })
      setJobId(id)
      setOpenOpt(true)
    }
  }

  const instructionUrl = (id) => {
    return `/calc/job/${id}`
  }

  const getInstructions = async (id) => {
    try {
      const res = await file.EmulationLab.get(instructionUrl(id))
      const { solver, ...instructions } = res.data.instructions
      return { ...instructions, bestResult: res.data.best }
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const renderAccHeading = (label, obj) => {
    const isVariable = label === "Variables"
    const isObjective = label === "Objectives"
    const isParameter = label === "Parameters"
    const isConstraints = label === "Constraints"

    const objLabel = (obj) => {
      return emConfig?.calculator?.OutputVariables?.find(
        (otp) => otp?.ColumnLabel === obj.label
      )
    }

    const filteredArray =
      isVariable &&
      emConfig?.calculator?.InputVariables?.filter(
        (item) => !obj.includes(item.ColumnLabel)
      )

    const getNickname = (columnLabel) => {
      const matchedItem = emConfig?.calculator?.InputVariables.find(
        (item) => item.ColumnLabel === columnLabel
      )
      return matchedItem ? matchedItem.NickName : columnLabel
    }

    return (
      <m.Box
        sx={{
          width: "25%",
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <m.Typography variant="body1">
          <b>{label}</b>
        </m.Typography>
        {isObjective &&
          obj?.map((object, i) => (
            <m.Box key={object.label} sx={commonCss}>
              <m.Typography variant="body2">
                {Status.convertToUppercase(object.mode)} &nbsp;
              </m.Typography>
              <m.Chip
                size={"medium"}
                key={objLabel(object)?.NickName}
                className={classes.chipStyle}
                label={objLabel(object)?.NickName}
                icon={ChipIcon(objLabel(object)?.Type)}
                color={"secondary"}
                style={{
                  fontFamily: theme.palette.typography.fontFamily,
                }}
              />
            </m.Box>
          ))}
        {isParameter && (
          <m.Box sx={combineCss}>
            <m.Typography variant="body2">
              {obj.length > 0
                ? obj?.map(
                    (exObj, i) =>
                      `${getNickname(exObj.label)} = ${Status.roundValues(
                        exObj.value
                      )}${i === obj.length - 1 ? "" : ", "}`
                  )
                : "None"}
            </m.Typography>
          </m.Box>
        )}
        {isVariable && (
          <m.Box sx={combineCss}>
            {filteredArray.length
              ? filteredArray?.map((inpVar, i) => (
                  <>
                    <m.Chip
                      key={inpVar?.NickName}
                      size={"medium"}
                      className={classes.chipStyle}
                      label={inpVar?.NickName}
                      icon={ChipIcon(inpVar?.Type)}
                      color={"primary"}
                      style={{
                        fontFamily: theme.palette.typography.fontFamily,
                      }}
                    />
                    &nbsp;
                  </>
                ))
              : renderNoneBox()}
          </m.Box>
        )}
        {isConstraints && obj?.length > 0
          ? obj?.map((constr, i) => (
              <m.Box key={`${constr} ${i}`} sx={commonCss}>
                <m.Chip
                  size={"medium"}
                  key={objLabel(constr)?.NickName}
                  className={classes.chipStyle}
                  label={`${objLabel(constr)?.NickName} ${constr?.mode} ${
                    constr?.value
                  }`}
                  icon={ChipIcon(objLabel(constr)?.Type)}
                  style={{
                    fontFamily: theme.palette.typography.fontFamily,
                  }}
                />
              </m.Box>
            ))
          : isConstraints && renderNoneBox()}
      </m.Box>
    )
  }

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return Object.keys(allOptHistory?.contexts)
      ?.reverse()
      .slice(startIndex, endIndex)
      .reduce((result, key) => {
        result[key] = allOptHistory?.contexts[key]
        return result
      }, {})
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }

  const cancelExistngJob = (e, id) => {
    e.stopPropagation()
    try {
      file.EmulationLab.post(
        instructionUrl(id) + `/cancel`,
        { status: "canceled" },
        {
          headers: {
            severity: SeverityLevel.Warning,
          },
        }
      )
    } catch (err) {
      console.error(err)
    }
  }

  const columns = [
    {
      field: "job_id",
      headerName: "Job ID",
      width: 180,
      align: "center",
      headerAlign: "center",
      headerClassName: "bold-header",
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status/progress",
      width: 150,
      align: "center",
      renderCell: (params) => (
        <m.Chip
          size="medium"
          label={
            <m.Typography
              variant="body2"
              color={
                (params.row.status === Status.ALGO_STATUS.complete &&
                  theme.palette.status.success) ||
                (params.row.status === "incomplete" &&
                  theme.palette.secondary.light) ||
                (params.row.status === Status.ALGO_STATUS.failed &&
                  theme.palette.status.danger)
              }
            >
              {params.row.status === "incomplete"
                ? `In progress (${params.row.progress})`
                : Status.convertToUppercase(params.row.status)}
            </m.Typography>
          }
        />
      ),
      headerAlign: "center",
    },
    {
      field: "generation_count",
      headerName: "#Generation",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "iteration_count",
      headerName: "#Runs",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "duration",
      headerName: "Runtime",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "best",
      headerName: "Best",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "requesting_user_email",
      width: 200,
      align: "center",
      headerName: "User",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const recivedComplete =
          params.row.status === Status.ALGO_STATUS.received ||
          params.row.status === "incomplete"
        const timedOut = params.row.status === Status.ALGO_STATUS.timedOut
        return (
          <>
            {!timedOut &&
              renderActionBtn(
                recivedComplete ? "Stop" : "Cancel",
                "error",
                recivedComplete ? viewOldJob : cancelExistngJob,
                params.row.job_id,
                Status.passFailedArray.includes(params.row.status) ||
                  params.row.status === "canceled"
              )}
            {renderActionBtn("View", "primary", viewOldJob, params.row.job_id)}
          </>
        )
      },
      headerAlign: "center",
    },
  ]

  const rows = serachedCards.map((history) => ({
    id: history.job_id,
    job_id: history.job_id,
    status: history.status,
    generation_count: history.generation_count,
    iteration_count: history.iteration_count,
    duration: history.duration,
    best: Status.roundValues(history.best),
    requesting_user_email: history.requesting_user_email,
    progress: history?.progress === undefined ? "0%" : history?.progress,
  }))

  return (
    <>
      {allOptHistory?.contexts &&
        Object.keys(getCurrentPageData())?.map((keys, i) => {
          const extractedKey = getCurrentPageData()[keys]
          const extractedObjectives = extractedKey.objectives
          const extractedParameters = extractedKey.parameters
          const extractedConstrant = extractedKey.constraints
          const extractedParametersLabel = extractedParameters?.map(
            (parm) => parm.label
          )

          return (
            <m.Accordion
              key={keys}
              expanded={expanded === keys}
              onChange={handleAccordionChange(keys)}
              className="accordion-opt"
            >
              <m.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {renderAccHeading("Objectives", extractedObjectives)}
                {renderAccHeading("Constraints", extractedConstrant)}
                {renderAccHeading("Variables", extractedParametersLabel)}
                {renderAccHeading("Parameters", extractedParameters)}
              </m.AccordionSummary>
              <m.AccordionDetails>
                <m.Grid className="ml-display-flex ml-justify-end">
                  {renderFilterButton(
                    handleOpenFilters,
                    isAppliedFilter(appliedFilters)
                  )}
                </m.Grid>
                {expanded == keys ? (
                  <div className="gutter-top">
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      sx={boldTitleHeader}
                      onCellClick={(params) => {
                        viewOldJob(undefined, params.id, "open")
                      }}
                      density="compact"
                      autoHeight
                      slots={{
                        noRowsOverlay: () => (
                          <div className="ml-display-flex ml-justify-center ml-align-center ml-height">
                            No Jobs Available
                          </div>
                        ),
                      }}
                      hideFooter
                      disableColumnMenu
                      disableSelectionOnClick
                    />
                  </div>
                ) : (
                  <file.Loader />
                )}
              </m.AccordionDetails>
            </m.Accordion>
          )
        })}
      {allOptHistory?.contexts && (
        <m.Pagination
          count={Math.ceil(
            Object.keys(allOptHistory?.contexts)?.length / itemsPerPage
          )}
          className="ml-display-flex ml-justify-end"
          page={currentPage}
          onChange={handlePageChange}
        />
      )}
      <file.AdvanceSearchFilter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        anchorEl={anchorEl}
        isAppliedFilter={isAppliedFilter(appliedFilters)}
        resetFilter={resetFilter}
        cards={contextHistory}
        filterOptions={filterOptions(tableHeadings)}
        table={true}
      />
    </>
  )
}

export default OptimizationHistory
