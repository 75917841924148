import React, { useEffect, useRef, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import { useParams } from "react-router-dom"
import * as files from "../GlobalFileContainer"
import { getAllMatrixUrl } from "../state/StatusState"
import useStyles from "../components/card_style"
import {
  exploreImagesState,
  getSingleEmulatorId,
  globalDialogBox,
  startGeneratingImage,
} from "../state/projectState"
import { SeverityLevel } from "@microsoft/applicationinsights-web"

export const ScatterplotMatrixComplete = () => {
  let { emulatorId } = useParams()
  const { width } = files.useWindowDimensions()
  const [config] = files.useCalculatorConfig()

  const vars = config.InputVariables.concat(config.OutputVariables)

  const images = useRecoilValue(exploreImagesState)
  const setStartGenerating = useSetRecoilState(startGeneratingImage)

  const [variableName, setVariableName] = useState(vars[0]?.Name)
  const [globalLoader, setGlobalLoader] = useState(false)
  const [nameKey, setNameKey] = useState(0)
  const [currentI, setCurrentI] = useState(0)

  const varsTuples = vars.flatMap((inputVar, i) =>
    vars.map((outputVar, j) => ({ i, j }))
  )
  const nameSet = new Set(images?.map((image) => image.name))

  useEffect(() => {
    const findMissingIndices = (varsTuples) => {
      const missingIndices = []

      for (let index = 0; index < varsTuples.length; index++) {
        const { i, j } = varsTuples[index]
        const nameToFind = `${emulatorId}_${i}_${j}.png`
        if (!nameSet?.has(nameToFind)) {
          missingIndices.push(index)
        }
      }
      return missingIndices
    }

    const missingIndices = findMissingIndices(varsTuples)

    if (missingIndices.length > 0) {
      setCurrentI(missingIndices[0])
    }
  }, [images, emulatorId])

  const handleNameChange = (event, key) => {
    let Inpkey = key.key
    setNameKey(parseInt(Inpkey.replace(/^[.$]+/g, "")))
    setVariableName(event.target.value)
  }

  const iterator = () => {
    if (currentI < varsTuples.length - 1) {
      setCurrentI(currentI + 1)
    } else {
      setStartGenerating(false)
    }
  }

  return (
    <>
      {globalLoader && (
        <files.Loader linear={true} message="Uploading generated images." />
      )}
      {width > 600 ? (
        <div
          className="scatter-matrixdiv em-display-grid"
          style={{
            gridTemplateColumns:
              "auto " + `${width / 2 / vars.length}px `.repeat(vars.length),
            gridTemplateRows: "120px " + "1fr ".repeat(vars.length),
            margin: "5px",
          }}
        >
          <span></span>
          {vars.map((inputVar) => (
            <div
              key={`inputvar-${inputVar.Name}`}
              className="ml-display-flex ml-align-center ml-justify-center height-100"
              style={{
                whiteSpace: "pre-line",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <m.Typography
                variant="body1"
                className="scatterinputs ml-p-5"
                sx={{ width: "100px" }}
              >
                {inputVar.Name}
              </m.Typography>
            </div>
          ))}
          {vars.map((inputVar, i) => {
            return (
              <React.Fragment key={inputVar + i}>
                <m.Typography
                  variant="body1"
                  className="ml-display-flex ml-align-center"
                >
                  {inputVar.Name}{" "}
                </m.Typography>
                {vars.map((outputVar, j) => {
                  return (
                    <DrawChart
                      key={`input_${i}-ouput_${j}`}
                      i={i}
                      j={j}
                      title={`${inputVar.Name}-${outputVar.Name}`}
                      emid={emulatorId}
                      iterator={iterator}
                      setGlobalLoader={setGlobalLoader}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        </div>
      ) : (
        <div className="ml-display-flex ml-justify-center ml-flex-dir-col">
          <>
            <m.FormControl className="filter-div" sx={{ width: "90%" }}>
              <m.Select value={variableName} onChange={handleNameChange}>
                {vars.map((inputVar, index) => (
                  <m.MenuItem
                    key={`in_${inputVar}` + index}
                    value={inputVar.Name}
                  >
                    {inputVar.Name}
                  </m.MenuItem>
                ))}
              </m.Select>
            </m.FormControl>
          </>
          <div className="ml-display-flex ml-align-center main__title ">
            <div className="em-display-grid matrix-grid">
              <span></span>

              {vars.map((inputVar, i) => {
                return (
                  <DrawChart
                    key={`input_${i}-ouput_${nameKey}`}
                    i={i}
                    j={nameKey}
                    title={`${inputVar.Name}-${variableName}`}
                    emid={emulatorId}
                    iterator={iterator}
                    setGlobalLoader={setGlobalLoader}
                  />
                )
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const DrawChart = ({ i, j, emid, title, iterator, setGlobalLoader }) => {
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)

  const handleChartClick = (e) => {
    e.preventDefault()
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: <DrawTableChart i={i} j={j} />,
      title: title,
      style: style,
      maxWidth: "xl",
    }))
  }
  return (
    <div title className="userform-grid-item ml-heigth">
      <DrawChartPlacholderImage
        clickHandler={handleChartClick}
        i={i}
        j={j}
        title={title}
        emid={emid}
        iterator={iterator}
        setGlobalLoader={setGlobalLoader}
      />
    </div>
  )
}

const DrawChartPlacholderImage = ({
  title,
  i,
  j,
  emid,
  clickHandler,
  iterator,
  setGlobalLoader,
}) => {
  const classes = useStyles()
  const renderableref = useRef(null)

  const images = useRecoilValue(exploreImagesState)
  const startGenerating = useRecoilValue(startGeneratingImage)

  const [graph, setGraph] = useState()
  const [loader, setLoader] = useState({ generate: false, upload: false })

  const imageSrc = images?.find(
    (image) => image?.name === `${emid}_${i}_${j}.png`
  )?.image

  useEffect(() => {
    if (startGenerating && !imageSrc) {
      if (i !== undefined && j !== undefined) {
        setLoader({ generate: true, upload: false })
        files.EmulationLab.get(getAllMatrixUrl(emid, i, j), {
          headers: {
            severity: SeverityLevel.Error,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              return response.data
            }
            throw new Error("Something went wrong")
          })
          .then((thisGraph) => {
            setGraph(thisGraph)
            setLoader({ generate: false, upload: true })
            setGlobalLoader(true)
          })
          .catch((error) => {
            setLoader({ generate: false, upload: true })
            setGlobalLoader(true)
            console.error(
              `Error while fetching chart for scatterplot-matrix: ${error}`
            )
          })
      }
    }
  }, [startGenerating])

  return (
    <div
      onClick={!loader.generate && !loader.upload ? clickHandler : null}
      title={title}
      className={`${classes.backgroundWhite} userform-grid-item dropdown ml-heigth placeholder-image hover-styling`}
    >
      <div ref={renderableref}>
        {loader.generate && (
          <div style={{ position: "absolute", height: "100%", width: "100%" }}>
            <files.Loader margin="0px" />
          </div>
        )}
        {graph && loader.upload ? (
          <files.CapturableDiv
            renderableref={renderableref}
            filename={`${emid}_${i}_${j}`}
            afterRenderFunc={iterator}
            setLoader={setLoader}
            setGlobalLoader={setGlobalLoader}
            heightVal={renderableref?.current.offsetHeight}
            widthVal={renderableref?.current.offsetWidth}
          >
            {i === j ? (
              <files.Histogram graph={graph} capturVersion={true} />
            ) : (
              <files.ScatterPlot
                graph={graph}
                capturVersion={true}
                iterator={iterator}
              />
            )}
          </files.CapturableDiv>
        ) : (
          <img
            src={
              imageSrc ||
              (i === j
                ? "/assets/images/default_barchart.png"
                : "/assets/images/default_scatterplot.png")
            }
            alt={"chart"}
            className="scatterimgframe"
          ></img>
        )}
      </div>
      <span
        className={`${classes.colorGraySec} ${classes.backgroundWhite} userform-grid-item scatterimgbottomframe`}
      >
        <div className="userform-grid-item scatter-title">
          <m.Typography variant="body2" fontWeight={"600"}>
            {title.split("-")[0]}
          </m.Typography>
        </div>
        <m.Typography variant="body2" fontWeight={"600"}>
          {title.split("-")[1]}
        </m.Typography>
      </span>
    </div>
  )
}

export default DrawChartPlacholderImage

const style = {
  width: "95vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

const DrawTableChart = ({ i, j }) => {
  let emulatorId = useRecoilValue(getSingleEmulatorId)
  const getMatrixUrl = getAllMatrixUrl(emulatorId, i, j)

  const [graph, setGraph] = useState()

  useEffect(() => {
    files.EmulationLab.get(getMatrixUrl)
      .then((response) => {
        if (response.status === 200 && typeof response.data !== "string") {
          return response.data
        }
        throw new Error("Something went wrong")
      })
      .then((thisGraph) => {
        setGraph(thisGraph)
      })
      .catch((error) => {
        console.error(
          `Error while fetching chart for scatterplot-matrix: ${error}`
        )
        setGraph({})
      })
  }, [i, j, emulatorId])

  return (
    <div className="scattermatrixresponsive">
      {!graph ? (
        <files.Loader margin={"0px"} />
      ) : (
        <>
          {i === j ? (
            <files.Histogram graph={graph} />
          ) : (
            <files.ScatterPlot graph={graph} />
          )}
        </>
      )}
    </div>
  )
}
