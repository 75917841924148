import { useState } from "react"
import * as core from "@material-ui/core"
import { useDrop } from "react-dnd"
import * as file from "../../../GlobalFileContainer"
import { useRecoilValue } from "recoil"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"

function DragDropVariables({ variables, dropVarZone, accept }) {
  const betVers = useRecoilValue(storedSates.getBeta)
  const selectedVersions = useRecoilValue(storedSates.selectedVersion)
  const versName = useRecoilValue(storedSates.versionName)

  const firstVariableKey = Object.keys(variables || [])
  const [inputBoard, setInputBoard] = useState([])
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "button",
    drop: (item) => addBtnToBoard(item.id, item.name),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }))

  const addBtnToBoard = (id, name) => {
    const totalVarList =
      firstVariableKey.includes(name) &&
      firstVariableKey.filter((keys, i) => keys === name)
    setInputBoard((inputBoard) => [...inputBoard, totalVarList[0]])
  }

  return (
    <core.Grid item xs={12} className="drop-nonselect">
      {selectedVersions === betVers &&
        versName === storedSatus.breadCrumbsStrings.beta && (
          <file.Authorization processName="manageIO">
            {dropVarZone(drop, inputBoard, setInputBoard, accept)}
          </file.Authorization>
        )}
    </core.Grid>
  )
}

export default DragDropVariables
