import { Suspense } from "react"
import { useRecoilValue } from "recoil"
import * as core from "@material-ui/core"
import {
  Loader,
  ParallelCoordinatesNivo,
  EmulatorBreadCrumbs,
  pageHelpers,
  PageHelper,
} from "../../GlobalFileContainer"
import { projectDatasetState } from "../../state/projectState"
import { cleanReturnJson } from "../../state/axios"

const ExploreParallel = () => <TableCard />

const TableCard = () => {
  const data = cleanReturnJson(useRecoilValue(projectDatasetState))

  return (
    <Suspense fallback={<Loader />}>
      <EmulatorBreadCrumbs msg={"Explore/parallel"}>
        <PageHelper info={pageHelpers.exploreTable} />
      </EmulatorBreadCrumbs>
      <core.TableContainer component={core.Paper}>
        <ParallelCoordinatesNivo data={data} />
      </core.TableContainer>
    </Suspense>
  )
}

export default ExploreParallel
