import { useState, useEffect } from "react"
import { Typography } from "@material-ui/core"
import * as m from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { TiLightbulb, TiCogOutline } from "react-icons/ti"
import { GoDatabase } from "react-icons/go"
import { RiFileSearchLine } from "react-icons/ri"
import { FaScroll, FaCalculator } from "react-icons/fa"
import { SlCalculator, SlCloudUpload, SlChemistry } from "react-icons/sl"
import { ImTable } from "react-icons/im"
import { TbCloudComputing, TbTestPipe } from "react-icons/tb"
import { Stack } from "@mui/system"
import { allStatus, getFilteredSubStatusConfig } from "../state/StatusState"
import { useRecoilState } from "recoil"
import {
  emulatorProgressStore,
  getDataFiles,
  getValidatedSubStatus,
  updateProgressInConfig,
} from "../state/projectState"
import { EmulationLab, Loader } from "../GlobalFileContainer"
import { IconButton, styled } from "@mui/material"
import { NewStatusInfo } from "./project/NewStatusInfo"
import { EvaluateTooltip } from "./MLEvaluation/MLIcons"

const ShowButton = ({ title, callFunc }) => {
  return (
    <EvaluateTooltip
      title={"Release Calculator/Emulator feature coming soon."}
      placement="top"
    >
      <span>
        <m.Button
          variant="contained"
          color="primary"
          disabled
          sx={{
            color: "white",
            fontSize: "0.8rem",
            textTransform: "none",
            padding: "6px",
          }}
          // onClick={() => callFunc && callFunc()}
        >
          {`Release ${title}`}
        </m.Button>
      </span>
    </EvaluateTooltip>
  )
}

const RotatingContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "50px",
  position: "relative",
})

const getIconPosition = (index, angle) => {
  return {
    left: `calc(50% + ${Math.cos((angle * Math.PI) / 180) * 15}px)`,
    top: `calc(50% + ${Math.sin((angle * Math.PI) / 180) * 15}px)`,
  }
}

// Create a component for the rotating icons
const RotatingIcons = () => {
  const icons = [
    { icon: <SlCloudUpload size="20px" />, angle: 30 },
    { icon: <GoDatabase size="20px" />, angle: 170 },
    { icon: <TiCogOutline size="20px" />, angle: 270 },
  ]

  return (
    <RotatingContainer sx={{ margin: "0 30px 30px 0" }}>
      {icons.map((item, index) => (
        <IconButton
          key={index}
          style={{
            position: "absolute",
            ...getIconPosition(index, item.angle),
            transformOrigin: "center center",
            zIndex: 1,
          }}
        >
          {item.icon}
        </IconButton>
      ))}
    </RotatingContainer>
  )
}

const createProgressCompleteObject = (emulatorProgress) => {
  let progressComplete = {}
  for (const key in emulatorProgress) {
    for (const subKey in emulatorProgress[key]) {
      allStatus.map((value) => {
        const lowercaseString = value.replace(/\s/g, "").toLowerCase()
        if (lowercaseString === subKey.toLowerCase()) {
          progressComplete[value] = emulatorProgress[key][subKey]
        }
      })
    }
  }
  return progressComplete
}

const getDataUploadType = (emulatorCompletedSubStatusState) => {
  if (emulatorCompletedSubStatusState[allStatus[7]]) {
    if (emulatorCompletedSubStatusState[allStatus[4]]) {
      return allStatus[4]
    } else if (emulatorCompletedSubStatusState[allStatus[5]]) {
      return allStatus[5]
    } else {
      return allStatus[6]
    }
  }
  return null
}

const getKeysWithTrueValues = async (initialSubStatus) => {
  const keysWithTrueValue = Object.entries(initialSubStatus)
    .filter(([key, value]) => value === true)
    .map(([key]) => key)
  return keysWithTrueValue
}

function EmulatorStatus({ emulator }) {
  const [activeStep, setActiveStep] = useState(0)
  const [emulatorProgress, setEmulatorProgress] = useState({})
  const [loadingState, setLoadingState] = useState(true)
  const [emulatorCompletedSubStatusState, setEmulatorCompletedSubStatusState] =
    useState({})
  const [filterObjectOnUploadState, setFilterObjectOnUploadState] = useState([])

  const [emulatorProgressStoreState, setEmulatorProgressStoreState] =
    useRecoilState(emulatorProgressStore)

  const allEmulatorFilteredConfig = getFilteredSubStatusConfig(emulator?.modes)
  const dataUploadState = getDataUploadType(emulatorCompletedSubStatusState)
  const chooseDataMode = `${
    emulator?.modes.length > 1 ? "Generate/" : "Reference/"
  }Upload Data`
  const iconKey = dataUploadState ? dataUploadState : chooseDataMode
  const dataValues = ["Data Upload", "Data Synthesis", "Data Reference"]
  const pageURL = window.location.pathname

  const updateProgressState = async () => {
    const emulatorFiles = await getDataFiles(emulator.id)
    const initialSubStatus = await getValidatedSubStatus(
      emulator,
      emulatorFiles
    )
    const keysWithTrueValue = await getKeysWithTrueValues(initialSubStatus)
    let returnValue = ""

    if (keysWithTrueValue.length > 0) {
      returnValue = keysWithTrueValue[keysWithTrueValue.length - 1]
      setEmulatorProgressStoreState(returnValue)
      await updateProgressInConfig(
        keysWithTrueValue,
        emulator,
        setEmulatorProgress
      )
    }

    setLoadingState(false)
  }

  useEffect(() => {
    if (emulator?.progress) {
      updateProgressState()
    }
  }, [])

  const ColorlibConnector = styled(m.StepConnector)(({ theme }) => ({
    [`&.${m.stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${m.stepConnectorClasses.active}`]: {
      [`& .${m.stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( rgba(57, 194, 215, 0.7) 0%, rgba(57, 194, 215, 0.7) 50%, rgba(57, 194, 215, 0.7) 100%) !important",
      },
    },
    [`&.${m.stepConnectorClasses.completed}`]: {
      [`& .${m.stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( rgba(57, 194, 215, 0.7) 0%, rgba(57, 194, 215, 0.7) 50%, rgba(57, 194, 215, 0.7) 90%) !important",
      },
    },
    [`& .${m.stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }))

  const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
    zIndex: 1,
    color: "grey",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      color: "white",
      backgroundColor: "rgba(57, 194, 215)",
    }),
    ...(ownerState.completed && {
      color: "rgba(57, 194, 215, 0.7)",
    }),
  }))

  function ColorlibStepIcon(props) {
    const { active, completed } = props

    const returnIconBasedOnUpload = () => {
      switch (dataUploadState) {
        case allStatus[4]:
          return <SlCloudUpload size="25px" />
        case allStatus[5]:
          return <GoDatabase size="25px" />
        case allStatus[6]:
          return <TiCogOutline size="25px" />

        default:
          break
      }
    }

    const icons = {
      [allStatus[0]]: <TiLightbulb size="25px" />,
      [allStatus[1]]: <FaScroll size="25px" />,
      [allStatus[2]]: <SlCalculator size="25px" />,
      [allStatus[3]]: <FaCalculator size="25px" />,
      [iconKey]: dataUploadState ? (
        returnIconBasedOnUpload()
      ) : loadingState ? (
        <>
          <m.Fab>
            <RotatingIcons />
          </m.Fab>
          {loadingState && (
            <m.CircularProgress
              size={68}
              color="primary"
              sx={{
                position: "absolute",
                zIndex: 1,
              }}
            />
          )}
        </>
      ) : (
        <RotatingIcons />
      ),
      [allStatus[7]]: <ImTable size="25px" />,
      [allStatus[8]]: <RiFileSearchLine size="25px" />,
      [allStatus[9]]: <TbCloudComputing size="25px" />,
      [allStatus[10]]: <TbTestPipe size="25px" />,
      [allStatus[11]]: <SlChemistry size="25px" />,
    }

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }}>
        {completed ? (
          <m.Badge
            badgeContent={<CheckCircleIcon color="success" />}
          >
            {icons[String(props.icon)]}
          </m.Badge>
        ) : (
          icons[String(props.icon)]
        )}
      </ColorlibStepIconRoot>
    )
  }

  //commented for later use
  // const handleReleaseButton = async () => {
  //   let releaseUrl = `/emulators/${emulator.id}/releases`
  //   let apiBody = {
  //     status: "good",
  //     comments: "Initial",
  //     changelog: "No changes",
  //   }
  //   try {
  //     const response = await EmulationLab.put(releaseUrl, apiBody)
  //     if (response.status === 200) {
  //       var emulatorURL = `/emulators/${emulator.id}`
  //       const fetchData = await EmulationLab.get(emulatorURL)
  //       if (fetchData.status === 200) {
  //         setEmulatorData(fetchData.data)
  //         setMessage("calculator released successfully!")
  //         setAlertType("success")
  //         setAlert(true)
  //       }
  //     }
  //   } catch (error) {
  //     const errorMessage =
  //       error?.response?.data?.message || "Some error occurred."
  //     alert(errorMessage)
  //   }
  // }

  const getEmulatorProgress = async () => {
    let url = `/emulators/${emulator.id}/progress`
    const res = await EmulationLab.get(url)
    if (res.data === null) {
      updateProgressInConfig(["Idea Initiated"], emulator, setEmulatorProgress)
    } else {
      setEmulatorProgress(res.data)
    }
  }

  const returnLabelOrButton = (title) => {
    if (pageURL.includes("/em")) {
      if (
        emulatorCompletedSubStatusState[allStatus[2]] === true &&
        emulatorCompletedSubStatusState[allStatus[3]] === false &&
        title === allStatus[3]
      ) {
        return <ShowButton title="Calculator (WIP)" />
      } else if (
        emulatorCompletedSubStatusState[allStatus[10]] === true &&
        emulatorCompletedSubStatusState[allStatus[11]] === false &&
        title === allStatus[11]
      ) {
        return <ShowButton title="Emulator (WIP)" />
      } else return title
    }
    return title
  }

  useEffect(() => {
    getEmulatorProgress()
  }, [])

  useEffect(() => {
    const statusIndex = filterObjectOnUploadState.findIndex(
      (item) => item.title === emulatorProgressStoreState
    )
    if (statusIndex !== undefined) {
      setActiveStep(statusIndex)
    }
  }, [filterObjectOnUploadState, emulatorProgressStoreState])

  useEffect(() => {
    const filterObject = dataUploadState
      ? allEmulatorFilteredConfig.filter((status) => {
          let data = dataValues.filter(
            (datavalue) => datavalue !== dataUploadState
          )
          return !data.includes(status.title)
        })
      : allEmulatorFilteredConfig
    setFilterObjectOnUploadState(filterObject)
  }, [dataUploadState])

  useEffect(() => {
    if (emulatorProgress) {
      const getProgress = createProgressCompleteObject(emulatorProgress)
      setEmulatorCompletedSubStatusState(getProgress)
      const keysWithTrueValue = getKeysWithTrueValues(getProgress)
      const latestStatus = keysWithTrueValue.then(
        (data) => data[data.length - 1]
      )
      setEmulatorProgressStoreState(latestStatus)
      setLoadingState(false)
    }
  }, [emulatorProgress])

  return (
    <Stack spacing={2} padding={"10px"}>
      {Object.keys(emulatorCompletedSubStatusState)?.length > 0 &&
      filterObjectOnUploadState?.length > 0 &&
      !loadingState ? (
        <>
          <m.Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {filterObjectOnUploadState?.map((status, index) => {
              const statusCompleted =
                emulatorCompletedSubStatusState[status?.title] &&
                activeStep !== index
              const statusTitle =
                status.title === "Data Upload" ||
                status.title === "Data Synthesis"
                  ? iconKey
                  : status.title

              return (
                (status.title !== "Data Synthesis" ||
                  dataUploadState === "Data Synthesis") && (
                  <m.Step key={status.title}>
                    <m.StepLabel
                      StepIconComponent={({ active, completed }) => (
                        <ColorlibStepIcon
                          icon={statusTitle}
                          active={active}
                          completed={statusCompleted}
                        />
                      )}
                    >
                      {returnLabelOrButton(statusTitle)}
                    </m.StepLabel>
                  </m.Step>
                )
              )
            })}
          </m.Stepper>
          {filterObjectOnUploadState?.map((status, index) => {
            return (
              activeStep === index && (
                <Typography key={`${status.title}-${index}`} textalign={"left"}>
                  {NewStatusInfo[status.title]?.info}
                </Typography>
              )
            )
          })}
        </>
      ) : (
        <Loader linear={true} />
      )}
    </Stack>
  )
}

export default EmulatorStatus
