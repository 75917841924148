import { useEffect } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  getBeta,
  getEmulatorData,
  getLatestV,
  releaseIOState,
  selectedVersion,
  versionName,
} from "../state/projectState"
import {
  betaVersion,
  breadCrumbsStrings,
  latestVersion,
} from "../state/StatusState"

const useCalculatorConfig = () => {
  const activeEmulator = useRecoilValue(getEmulatorData)
  const selectedVersions = useRecoilValue(selectedVersion)
  const [betaVers, setBetaVersion] = useRecoilState(getBeta)
  const versName = useRecoilValue(versionName)
  const setLatestVersion = useSetRecoilState(getLatestV)
  const releaseWithIo = useRecoilValue(releaseIOState)

  useEffect(() => {
    const latest = latestVersion(activeEmulator)
    const beta = betaVersion(activeEmulator)
    setLatestVersion(latest)
    setBetaVersion(beta)
  }, [])

  const config =
    selectedVersions === betaVers && versName === breadCrumbsStrings.beta
      ? activeEmulator?.calculator
      : Object.keys(releaseWithIo)?.length > 0
      ? releaseWithIo
      : activeEmulator?.calculator

  return [config]
}

export default useCalculatorConfig

export const configInpOut = () => {
  const [config] = useCalculatorConfig()
  const inpVar = config["InputVariables"]?.length || 0
  const outVar = config["OutputVariables"]?.length || 0
  return { inpVar, outVar }
}
