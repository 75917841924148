import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import * as core from "@material-ui/core"
import { Badge, Dialog, IconButton, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { AlertBox, EmulationLab } from "../../GlobalFileContainer"
import { editAlertBoxState, handleKeyDown } from "../../state/projectState"
import useStyles from "../card_style"
import { confirmDelete } from "../../state/StatusState"

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    top: 15.5,
    border: `1px solid black`,
  },
}))

const AnnotationsCard = ({
  chipPosition,
  setChipPosition,
  disableComponent,
}) => {
  const classes = useStyles()
  const email = localStorage.getItem("email")

  const [editAlertBox, setEditAlertBox] = useRecoilState(editAlertBoxState)

  const [deleteId, setDeleteId] = useState()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  useEffect(() => {
    openDeleteDialog &&
      setEditAlertBox({
        heading: confirmDelete,
        content: `Are You Sure , You want to delete the annotation?`,
      })
  }, [openDeleteDialog])

  const handleDeleteAnnotation = async (id) => {
    try {
      const deleteURL = `/emulators/images/annotations/${id}`
      let targetObject = chipPosition.find((item) => item.id === id)
      let data = {
        type: targetObject.type,
        user: email,
        content: targetObject.content,
        u: targetObject.position.u,
        v: targetObject.position.v,
      }
      const res = await EmulationLab.delete(deleteURL, data)
      if (res) {
        const updatedChipPos = chipPosition.filter((item) => item.id !== id)
        setChipPosition(updatedChipPos)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <core.Typography>
        {chipPosition.length > 0 && (
          <core.Typography variant="h5">Annotations:</core.Typography>
        )}
        {chipPosition?.map((chip, index) => {
          return (
            <div
              className={`${classes.colorAnnotation} ml-display-flex annotate-card`}
              key={chip.id}
            >
              <StyledBadge
                badgeContent={index + 1}
                classes={{
                  badge:
                    chip.type === "input"
                      ? classes.inputBadge
                      : classes.outputBadge,
                }}
              />
              <core.Typography
                variant="body2"
                className="ml-display-flex ml-flex-dir-row ml-align-center annotate-label width-100"
              >
                <Stack
                  className="width-100"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {chip.content}
                  {!disableComponent && (
                    <IconButton
                      title={"Remove annotation"}
                      color="primary"
                      onClick={(e) => {
                        setDeleteId(e.target.closest("svg").id)
                        setOpenDeleteDialog(true)
                      }}
                    >
                      <DeleteOutlineOutlinedIcon
                        fontSize="small"
                        id={chip.id}
                        color="primary"
                      />
                    </IconButton>
                  )}
                </Stack>
              </core.Typography>
            </div>
          )
        })}
      </core.Typography>
      <Dialog
        onKeyDown={(e) => handleKeyDown(e, setOpenDeleteDialog)}
        open={openDeleteDialog}
      >
        <AlertBox
          heading={editAlertBox.heading}
          content={editAlertBox.content}
          closeDialog={() => setOpenDeleteDialog(false)}
          callFunction={() => handleDeleteAnnotation(deleteId)}
        />
      </Dialog>
    </>
  )
}

export default AnnotationsCard
