import { useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined"
import { debounce } from "lodash"
import { showTimer } from "../../state/StatusState"
import { CustomButton } from "../testing-calculator/MainTest"
import EmulatorBreadCrumbs from "../EmulatorBreadCrumbs"
import {
  ErrorBoundary,
  Authorization,
  PageHelper,
  pageHelpers,
} from "../../GlobalFileContainer"
import {
  getEmulatorData,
  openNewOptimiseState,
  searchedJobIdState,
  startGeneratingImage,
} from "../../state/projectState"

const MemberManagement = ({
  name,
  component,
  helper,
  navigateButton,
  version,
  fetched,
  time,
  timerInterval,
  gnTime,
  pageCss,
}) => {
  const theme = m.useTheme()

  const getSingleEmulatorValue = useRecoilValue(getEmulatorData)
  const setOpenNewOptimise = useSetRecoilState(openNewOptimiseState)
  const setSearchdJobId = useSetRecoilState(searchedJobIdState)
  const setStartGenerating = useSetRecoilState(startGeneratingImage)

  const getSingleEmulatorNameValue = getSingleEmulatorValue?.name

  const handleOpenOptimisation = () => {
    setOpenNewOptimise(true)
  }

  const changeHandler = debounce((a) => {
    setSearchdJobId(a.target.value.toLowerCase().trim())
  }, 300)

  return (
    <div style={pageCss ?? undefined}>
      <ErrorBoundary>
        <EmulatorBreadCrumbs
          msg={name}
          name={getSingleEmulatorNameValue}
          data={"data"}
          version={version}
        >
          {helper && (
            <core.Box display="flex" flexDirection="row" alignItems="center">
              {fetched && showTimer(time)}
              <PageHelper info={pageHelpers[helper]} />
            </core.Box>
          )}
          {timerInterval && (
            <m.Grid sx={{ display: { xs: "none", md: "flex" } }}>
              {showTimer(gnTime)}
            </m.Grid>
          )}
        </EmulatorBreadCrumbs>
      </ErrorBoundary>

      <core.Paper className="ml-p-5">
        <core.Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <core.Typography className="member-manage-title" variant="h5">
            {name}{" "}
          </core.Typography>
          {navigateButton && (
            <Authorization processName="manageIO">
              <core.Button
                align="center"
                variant="contained"
                color="primary"
                style={{ color: theme.palette.common.white }}
                onClick={() => setStartGenerating(true)}
              >
                <core.Typography
                  variant="caption"
                  margin="4px"
                  color={theme.palette.common.white}
                >
                  Generate Preview Images
                </core.Typography>
              </core.Button>
            </Authorization>
          )}
          {name === "Optimization" && (
            <m.Grid
              className="ml-display-flex ml-align-center"
              marginBottom={"10px"}
            >
              <m.TextField
                type="search"
                name="name"
                variant="outlined"
                placeholder="Search your job id here..."
                size="small"
                spellCheck="false"
                autoComplete="off"
                style={{
                  backgroundColor: theme.palette.common.white,
                }}
                onChange={changeHandler}
              />
              {/* <Authorization processName="manageIO">  */}
              <CustomButton
                text={"Add New Optimization"}
                icon={<AddCircleOutlineOutlined color="primary" />}
                callingFunction={handleOpenOptimisation}
              />
              {/* </Authorization> */}
            </m.Grid>
          )}
        </core.Box>
        <ErrorBoundary>{component}</ErrorBoundary>
      </core.Paper>
    </div>
  )
}

export default MemberManagement
