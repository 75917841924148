import { useState } from "react"
import _ from "lodash"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import Box from "@mui/material/Box"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import HistosliderContainer from "./SliderHistogram"
import { EmulationLab } from "../../GlobalFileContainer"
import "./MlSelectCriteria.css"
import { alertStates } from "../../state/vizState"
import { Typography, useTheme } from "@mui/material"
import useStyles from "../card_style"

function MlSelectionCriteria({
  setGlobalDialogOpen,
  variableName,
  size,
  speed,
  setSelectCrit,
  lComf,
  uComf,
  lBlunder,
  activeEmulator,
  uBlunder,
}) {
  let { emulatorId } = useParams()
  const theme = useTheme()
  const classes = useStyles()

  const [maxFileSize, setMaxFileSize] = useState(size.split(" ")[0] || "")
  const [maxQuTime, setMaxQuTime] = useState(speed.split(" ")[0] || "")
  const [cLowBound, setCLowBound] = useState(lComf.split("%")[0] || "")
  const [cUpBound, setCUpBound] = useState(uComf.split("%")[0] || "")
  const [blLowBound, setBlLowBound] = useState(lBlunder.split("%")[0] || "")
  const [blUpBound, setBlUpBound] = useState(uBlunder.split("%")[0] || "")
  const data = {
    max_file_size: `${maxFileSize} KB`,
    max_query_time: `${maxQuTime} ms`,
    severe_lower_bound: `${blLowBound}%`,
    severe_upper_bound: `${blUpBound}%`,
    comfort_lower_bound: `${cLowBound}%`,
    comfort_upper_bound: `${cUpBound}%`,
    auto_select_active: "True",
  }

  const setAlertState = useSetRecoilState(alertStates)
  const postUrl = `/engine/fit/${emulatorId}/evaluate?target=${variableName}`

  const getTimes = () => {
    const values = Object.values(
      activeEmulator.training[variableName].algorithms
    ).map((algo) => {
      const size = algo.mean_predict_time
      if (size) {
        return Number(size.split(" ")[0]) * 1000
      } else {
        return
      }
    })
    return values.filter((value) => value)
  }

  const getSizes = () => {
    const values = Object.values(
      activeEmulator.training[variableName].algorithms
    ).map((algo) => {
      const size = algo.pipeline_size
      if (size) {
        return Number(size.split(" ")[0]) / 1000
      } else {
        return
      }
    })
    return values.filter((value) => value)
  }

  const bucketNumbers = (numbers, bucketqty) => {
    const numbers2 = numbers
      .sort(function (a, b) {
        return a - b
      })
      .slice(0, -4)
    const minNum = 0
    const maxNum = Math.max.apply(Math, numbers2)
    const rangeNum = maxNum - minNum
    const groupSize = rangeNum / bucketqty
    const bucketsMin = _.range(minNum, maxNum, groupSize)
    const buckets = bucketsMin.map((bucketMin, i) => {
      const bucketMax = bucketMin + groupSize
      const countInBucket = numbers2.filter((numb) =>
        _.inRange(numb, bucketMin, bucketMax)
      ).length
      return {
        x0: bucketMin,
        x: bucketMax,
        y: countInBucket,
      }
    })
    return buckets
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setGlobalDialogOpen({
      isOpen: false,
      content: null,
      title: "",
      style: {},
      maxWidth: "md",
      actions: null,
    })
    try {
      const postData = await EmulationLab.post(postUrl, data, {
        headers: {
          severity: SeverityLevel.Error,
        },
      })
      if (postData.status == 200) {
        setAlertState({
          boolState: true,
          message: "Selection criteria updated successfully",
          severityState: "success",
        })
        setSelectCrit(true)
      }
    } catch (error) {
      alert("Data is not submitted..")
    }
  }

  const selectionCriteriamargin = {
    marginTop: "20px",
  }

  return (
    <Box>
      <Box className="ml-display-flex ml-flex-dir-row ml-p-5">
        <Box>
          <core.Typography variant="h6" gutterBottom>
            Adjust maximum file size {`(KB)`} :
          </core.Typography>

          <HistosliderContainer
            data={bucketNumbers(getSizes(), 10)}
            currentMax={maxFileSize}
            stateSetter={setMaxFileSize}
          />
        </Box>
        <Box className="para-1">
          <core.Typography variant="body1">
            As more people use emulators for their projects, we can suggest some
            settings based on what we've learned from other emulators that are
            already in use. However, since every project is different, these
            settings will need to be adjusted to fit each individual situation.
            Without knowing the specific details of a project, we can't offer
            much more guidance.
          </core.Typography>
        </Box>
      </Box>
      <Box className="ml-display-flex ml-flex-dir-row ml-p-5">
        <Box>
          <core.Typography variant="h6" gutterBottom>
            Adjust max prediction speed {`(ms)`}:
          </core.Typography>
          <HistosliderContainer
            data={bucketNumbers(getTimes(), 10)}
            currentMax={maxQuTime}
            stateSetter={setMaxQuTime}
          />
        </Box>
        <Box className="para-1">
          <Typography variant="body1">
            Processing time is required for each request. The maximum time taken
            by the server to obtain a solution is referred to as the max query
            time. It should be noted that this value can be substantially
            increased based on the emulator's condition. However, if multiple
            answers are requested, as frequently occurs in Grasshopper, they
            accumulate and have an impact on script execution times. The default
            setting for all emulators is 10ms.
          </Typography>
        </Box>
      </Box>

      <Box id="selectcriteria2" bgcolor={theme.palette.primary.light}>
        <core.Typography variant="h6" gutterBottom>
          Set acceptable error limits for the emulator:
        </core.Typography>
        <Box>
          <Box style={selectionCriteriamargin}>
            <Box className="ml-display-flex">
              <Box className="leftheading margin-top-50">
                <Box>
                  <Typography variant="body1">Comfort level{`(%)`}:</Typography>
                </Box>
              </Box>
              <Box className="ml-display-flex maininputdiv">
                <Box>
                  <Box className="sc-bound">
                    <Typography variant="body2" gutterBottom>
                      Lower
                      <br /> Bound
                    </Typography>
                  </Box>
                  <input
                    className={`${classes.bgPastelIcon} select-critInput`}
                    type="text"
                    name="comfort_lowlevel"
                    value={cLowBound}
                    onChange={(e) => setCLowBound(e.target.value)}
                  />
                </Box>
                <Box>
                  <Box className="sc-bound">
                    <Typography variant="body2">
                      Upper
                      <br /> Bound
                    </Typography>
                  </Box>
                  <input
                    className={`${classes.bgPastelIcon} select-critInput`}
                    type="text"
                    name="comfort-uplevel"
                    value={cUpBound}
                    onChange={(e) => setCUpBound(e.target.value)}
                  />
                </Box>
              </Box>
              <Box className="rightdiv margin-top-50">
                <Box className="rightbox">
                  <Typography variant="body1">
                    When you use the emulator, you should decide on the level of
                    accuracy you need. For instance, if you want most of the
                    answers to be within 5% of the correct answer, you should
                    set the accuracy level to +/-5% (which is the default
                    sitewide for emulation lab).
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box style={selectionCriteriamargin}>
              <Box className="ml-display-flex">
                <Box className="leftheading">
                  <Box>
                    <Typography variant="body2">
                      Blunder level{`(%)`}:
                      <br />
                      sever level
                    </Typography>
                  </Box>
                </Box>
                <Box className="ml-display-flex maininputdiv">
                  <Box>
                    <input
                      className={`${classes.bgPastelIcon} select-critInput`}
                      type="text"
                      name="blund_lowlevel"
                      value={blLowBound}
                      onChange={(e) => setBlLowBound(e.target.value)}
                    />
                  </Box>
                  <Box>
                    <input
                      className={`${classes.bgPastelIcon} select-critInput`}
                      type="text"
                      name="blund_uplevel"
                      value={blUpBound}
                      onChange={(e) => setBlUpBound(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box className="rightdiv">
                  <Box className="rightbox">
                    <Typography variant="body1">
                      Comfort level should be expected requirements you have for
                      the emulator. for example if you think the emulator should
                      return the majority of answers within 5%, set this to
                      +/-5%(Eml defaults)
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        className={`${classes.backgroundPrimaryMain} ml-display-flex ml-justify-center`}
      >
        <core.Button
          style={{ color: theme.palette.common.white }}
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          fullWidth
        >
          <Typography variant="caption">Continue</Typography>
        </core.Button>
      </Box>
    </Box>
  )
}

export default MlSelectionCriteria
