import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import useStyles from "../card_style"
import "./MainTest.css"
import {
  finalTestObject,
  getEmulatorData,
  getUnitList,
  matchUnit,
  outputsParametersAndVariables,
  saveBtnState,
} from "../../state/projectState"
import {
  ERROR_MESSAGE,
  allValuesFilled,
  isDataValid,
  testRule,
  textCatArray,
} from "../../state/StatusState"
import { CustomButton, processSubTest } from "./MainTest"
import { callCalculateToRun, validateInput } from "./AddTestForm"

const CustomTextField = m.styled(m.TextField)(({ theme }) => ({
  "& .MuiInputBase-multiline": {
    padding: "10px 14px",
  },
}))

function OutputsForm(props) {
  const classes = useStyles()
  let { emulatorId } = useParams()
  const theme = m.useTheme()
  const emConfig = useRecoilValue(getEmulatorData)
  const [finalTestObjectState, setFinalTestObjectState] =
    useRecoilState(finalTestObject)
  const [saveBtn, setSaveBtn] = useRecoilState(saveBtnState)
  const [selectedOutputs, setSelectedOutputs] = useRecoilState(
    outputsParametersAndVariables
  )
  const uniqueList = useRecoilValue(getUnitList)

  const [mappedOutputs, setMappedOutputs] = useState([])

  const calcConfig = emConfig.calculator
  const outputVars = calcConfig?.OutputVariables?.map(
    ({ ColumnLabel, Type, Units, ModelVisual, NickName }) => ({
      NickName,
      ColumnLabel,
      Type,
      Units,
      ModelVisual,
    })
  )
  const inputVars = calcConfig?.InputVariables?.map(
    ({ ColumnLabel, Type, Units, NickName }) => ({
      NickName,
      ColumnLabel,
      Type,
      Units,
    })
  )
  const numberConditions = [
    {
      value: testRule.equal,
      label: "Equal With Tolerance",
      constraints: false,
    },
    { value: testRule.between, label: "Between", constraints: false },
    { value: testRule.greater, label: "Greater Than", constraints: true },
    { value: testRule.lesser, label: "Less Than", constraints: true },
    {
      value: testRule.greaterOrEqual,
      label: "Greater Than Or Equal To",
      constraints: true,
    },
    {
      value: testRule.lesserOrEqual,
      label: "Less Than Or Equal To",
      constraints: true,
    },
    {
      value: testRule.equalTto,
      label: "Equal To",
      constraints: true,
    },
    {
      value: testRule.notEqual,
      label: "Not Equals",
      constraints: false,
      lookup: true,
    },
    {
      value: testRule.inSet,
      label: "In set",
      constraints: false,
      lookup: true,
    },
  ]

  const textCondition = [
    { value: testRule.equalTto, label: "Match", constraints: true },
    { value: testRule.contains, label: "Output Contains", constraints: true },
    {
      value: testRule.inSet,
      label: "In set",
      constraints: false,
      lookup: true,
    },
  ]

  const isDataValidState = props.postBody ? isDataValid(props.postBody) : true
  const allValuesFilledState = props.checkingRangeWarning
    ? allValuesFilled(props.checkingRangeWarning)
    : true

  useEffect(() => {
    props.setErrorFields(false)
    props.setValidationErrors({
      ruleError: false,
      expOutError: false,
      maxOutErr: false,
    })
    props.setSelectedLabel(false)
  }, [])

  useEffect(() => {
    if (props.lookup) {
      setMappedOutputs([...outputVars, ...inputVars])
    } else {
      setMappedOutputs(outputVars)
    }
  }, [props.lookup])

  useEffect(() => {
    if (saveBtn) {
      handleAddTest("save")
      setTimeout(() => {
        setSaveBtn(false)
      }, [500])
    }
  }, [saveBtn])

  const handleChangeRule = (e, key) => {
    const value = e.target.value
    props.setTestDetails({
      ...props.testDetails,
      [key]: value,
    })
    props.setValidationErrors({
      ...props.validationError,
      ruleError: false,
    })
  }

  const handleChange = (e, key) => {
    props.setTestDetails({
      ...props.testDetails,
      [key]: e.target.value,
      status: "draft",
    })
    props.setValidationErrors({
      ...props.validationError,
      expOutError: false,
      maxOutErr: false,
    })
  }

  const handleInsetChange = (e, key) => {
    const value = e.target.value
    const regex = /^[0-9,]*$/

    if (regex.test(value)) {
      const stringArray = value.split(",")
      const numberArray = stringArray.map(Number)

      props.setTestDetails({
        ...props.testDetails,
        [key]: numberArray,
        status: "draft",
      })
    }
  }

  const handleValidation = () => {
    let errorMessage = ""

    errorMessage = validateInput(
      "mode",
      props.testDetails.mode,
      props.validationError,
      props.setValidationErrors
    )
    if (errorMessage) return errorMessage

    errorMessage =
      props.testDetails.mode === testRule.between
        ? validateInput(
            "valueMin",
            props.testDetails.valueMin,
            props.validationError,
            props.setValidationErrors
          )
        : validateInput(
            "value",
            props.testDetails.value,
            props.validationError,
            props.setValidationErrors
          )
    if (errorMessage) return errorMessage

    errorMessage =
      props.testDetails.mode === testRule.equal &&
      validateInput(
        "tolerance",
        props.testDetails.tolerance,
        props.validationError,
        props.setValidationErrors
      )
    if (errorMessage) return errorMessage

    errorMessage =
      props.testDetails.mode === testRule.between &&
      validateInput(
        "maxValue",
        props.testDetails.valueMax,
        props.validationError,
        props.setValidationErrors
      )
    if (errorMessage) return errorMessage

    return ""
  }

  const RunCalculation = async (testObject, i) => {
    try {
      const data = await callCalculateToRun(props.postBody)
      const subTestResults = await processSubTest(
        testObject.subTests,
        false,
        data
      )

      const updateTest = {
        inputs: props.postBody.FeaturesData ?? [],
        subTests: subTestResults,
      }
      await handleSubmitTest(updateTest, i)
    } catch (error) {
      console.error("Error in callCalculateToRun:", error)
    }
  }

  const handleOutputChange = (e) => {
    const value = e.target.value
    props.setTestDetails({
      ...props.testDetails,
      mode: "",
      label: value,
    })
  }

  const isOut = (key) => key === "out"

  const isInp = (key) => key === "in"

  const handleEditRuleChange = (index, key, label, e) => {
    const value = e.target.value
    const newData = [...props.testList]
    const updatedItem = { ...newData[index] }
    if (key === "mode") {
      updatedItem[key] = value
      updatedItem
      updatedItem.valueMin = ""
      updatedItem.valueMax = ""
      updatedItem.tolerance = ""
    } else {
      updatedItem.label = value
      const matchingObject = mappedOutputs.find(
        (obj) => obj.ColumnLabel === label
      )
      const eTargetMatchingObject = mappedOutputs.find(
        (obj) => obj.ColumnLabel === value
      )
      if (matchingObject.Type !== eTargetMatchingObject.Type) {
        if (textCatArray?.includes(eTargetMatchingObject.Type)) {
          updatedItem.mode = testRule.equalTto
        } else {
          updatedItem.mode === testRule.greater
        }
        updatedItem.valueMin = ""
      }
    }
    updatedItem["status"] = "draft"
    newData[index] = updatedItem
    props.setTestList(newData)
  }

  const handleExpOutputChange = (index, key, e) => {
    const newData = [...props.testList]
    const updatedItem = { ...newData[index] }
    updatedItem[key] = [...updatedItem[key]]
    updatedItem[key] = e.target.value
    updatedItem["status"] = "draft"
    newData[index] = updatedItem
    props.setTestList(newData)
  }

  const isOutputANumber = (outputName) => {
    return mappedOutputs.some((item) => {
      const isNumericType = item.Type !== "text" && item.Type !== "categorical"
      const isMatchingName = item.ColumnLabel === outputName

      return isMatchingName && isNumericType
    })
  }

  const handleSubmitTest = (updatedFinalTestObject, i) => {
    if (props.updateTest) {
      const newData = JSON.parse(JSON.stringify(finalTestObjectState))
      newData.tests[i] = updatedFinalTestObject
      setFinalTestObjectState(newData)
      props.setSendTest && props.setSendTest(true)
    } else {
      if (Object.keys(finalTestObjectState).length > 0) {
        setFinalTestObjectState((prevTestObject) => ({
          ...prevTestObject,
          tests: [...prevTestObject.tests, updatedFinalTestObject],
        }))
        props.setSendTest && props.setSendTest(true)
      } else {
        setFinalTestObjectState({
          emulator_id: emulatorId,
          tests: [updatedFinalTestObject],
        })
        props.setSendTest && props.setSendTest(true)
      }
    }
    props.handleTestDialogClose()
  }

  const handleAddingTestValidation = () => {
    const validationError = handleValidation()
    if (validationError) return validationError
    handleAddTest("add")
  }

  const handleAddTest = (addState) => {
    const validationError = handleValidation()
    if (validationError) return validationError
    if (!isDataValidState) {
      props.setErrorFields(true)
    } else if (allValuesFilledState) {
      const updatedFinalTestObject =
        !props.updateTest || props.addForm
          ? {
              inputs: props.postBody?.FeaturesData ?? [],
              subTests: [...props.testList, props.testDetails],
            }
          : {
              inputs: props.postBody?.FeaturesData ?? [],
              subTests: [...props.testList],
            }
      props.setTestList(updatedFinalTestObject.subTests)
      props.setTestDetails(props.emptySubTestObject)
      if (addState === "save") {
        props.setAddForm(false)
        if (props.selectedLabel) {
          RunCalculation(updatedFinalTestObject, props.index)
        } else {
          handleSubmitTest(updatedFinalTestObject, props.index)
        }
        setSaveBtn(false)
      } else {
        props.setAddForm(true)
      }
    }
  }

  const renderOutputs = (key, outputName, output, i) => {
    const conditions = isOutputANumber(outputName)
      ? props.isConstraints
        ? numberConditions
        : numberConditions?.filter(
            (numberCond) => numberCond?.value !== testRule.equalTto
          )
      : textCondition
    // const width = "100%"
    const value = output
      ? output
      : isOut(key)
      ? props.testDetails.label
      : props.testDetails.mode
    const isFieldFilled = value.length > 0
    return (
      <m.Grid item xs={4}>
        <m.Typography variant="body2">
          {isOut(key) ? `${props.lookup ? "" : "Output "}Variable` : "Rule"}
        </m.Typography>
        <m.FormControl
          fullWidth
          error={
            (props.validationError.ruleError && !isFieldFilled) ||
            (props.validationError.labelError && !isFieldFilled)
          }
        >
          {!output && (
            <m.InputLabel
              shrink={true}
              id="demo-simple-select-label"
              variant="filled"
              style={{ top: "-4px" }}
            >
              {(isInp(key) &&
                props.validationError.ruleError &&
                !isFieldFilled) ||
              (props.validationError.labelError && !isFieldFilled)
                ? ERROR_MESSAGE.requiredField
                : ""}
            </m.InputLabel>
          )}
          <m.Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="outlined"
            SelectDisplayProps={{
              style: { padding: "10px" },
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            // style={{ minWidth: width }}
            value={value}
            onChange={(e) => {
              if (output) {
                handleEditRuleChange(
                  i,
                  isOut(key) ? "label" : "mode",
                  output,
                  e
                )
              } else if (isOut(key)) {
                handleOutputChange(e)
              } else {
                handleChangeRule(e, "mode")
              }
            }}
          >
            <m.MenuItem disabled value="none">
              {isInp(key)
                ? "Select Condition"
                : `Select ${props.lookup ? "Variable" : "Output"}`}
            </m.MenuItem>
            {isOut(key)
              ? mappedOutputs.map(
                  (outp) =>
                    !outp?.ModelVisual && (
                      <m.MenuItem
                        key={outp.ColumnLabel}
                        value={outp.ColumnLabel}
                      >
                        {outp.NickName}
                      </m.MenuItem>
                    )
                )
              : conditions.map(
                  (cndtn) =>
                    ((props.isConstraints && cndtn.constraints) ||
                      (props.lookup && cndtn.lookup) ||
                      !props.isConstraints) && (
                      <m.MenuItem key={cndtn.value} value={cndtn.value}>
                        {cndtn.label}
                      </m.MenuItem>
                    )
                )}
          </m.Select>
        </m.FormControl>
      </m.Grid>
    )
  }

  const renderExpOutput = (key, output, rule, refLabel, i, edit) => {
    const label =
      (rule === testRule.between && isInp(key)) || rule === testRule.greater
        ? "Min Value"
        : key === "key"
        ? "Tolerance"
        : isInp(key) && rule !== testRule.lesser
        ? "Expected Value"
        : "Max Value"
    const textFieldType =
      rule === testRule.equalTto ||
      rule === testRule.contains ||
      rule === testRule.inSet
        ? "text"
        : "number"
    const sendLabel =
      label === "Min Value" || label === "Expected Value"
        ? rule === testRule.between
          ? "valueMin"
          : "value"
        : label === "Max Value"
        ? rule === testRule.between
          ? "valueMax"
          : "value"
        : "tolerance"

    const findObjectForUnit = mappedOutputs?.find(
      (outVar) => outVar.ColumnLabel === refLabel
    )
    const unit = findObjectForUnit?.Units || ""
    const isOutputFilled = i === undefined || output?.length > 0
    return (
      <m.Grid item xs={props.lookup ? 4 : 2}>
        <m.Typography variant="body2">{label}</m.Typography>
        <CustomTextField
          className="leftheading form-padding-0"
          fullWidth
          multiline={rule === testRule.inSet}
          maxRows={2}
          value={output ?? ""}
          variant="outlined"
          autoComplete="off"
          inputProps={{
            style: {
              padding:
                !isOutputFilled &&
                (props.validationError.expOutError ||
                  props.validationError.maxOutErr)
                  ? "18px 14px 6px"
                  : rule === testRule.inSet
                  ? "0px"
                  : "10px 14px",
            },
          }}
          InputProps={{
            endAdornment: (
              <m.InputAdornment position="end">
                {key === "key" ? "%" : matchUnit(unit ?? "none", uniqueList)}
              </m.InputAdornment>
            ),
            // style: { minWidth: 130 },
          }}
          error={
            !output && isInp(key)
              ? !isOutputFilled && props.validationError.expOutError
              : !isOutputFilled && props.validationError.maxOutErr
          }
          InputLabelProps={{
            shrink: false,
            style: {
              fontSize: "11.5px",
              top: "-13px",
              letterSpacing: "0.5px",
            },
          }}
          label={
            !output &&
            !isOutputFilled &&
            (props.validationError.expOutError ||
              props.validationError.maxOutErr) &&
            ERROR_MESSAGE.requiredField
          }
          onChange={(e) => {
            if (i || edit) {
              handleExpOutputChange(i, sendLabel, e)
            } else if (rule === testRule.inSet) {
              handleInsetChange(e, sendLabel)
            } else {
              handleChange(e, sendLabel)
            }
          }}
          type={textFieldType}
        />
      </m.Grid>
    )
  }

  const handleRemoveSubTest = (index) => {
    const testArray = [
      ...props.testList.slice(0, index),
      ...props.testList.slice(index + 1),
    ]
    const isAllFieldFill =
      props?.testDetails?.mode?.length > 0 && props?.testDetails?.value

    const testArryWIthDetails = [...testArray, props?.testDetails]
    props.setTestList(testArray)
    setSelectedOutputs({
      ...selectedOutputs,
      constraints: isAllFieldFill ? testArryWIthDetails : testArray,
    })
  }

  const handleEmptyTestField = () => {
    props.setTestDetails({
      ...props.testDetails,
      label: props.isConstraints ? "" : props.testDetails.label,
      mode: "",
      value: "",
      valueMin: "",
      valueMax: "",
      tolerance: "",
    })
    props.setValidationErrors({
      ruleError: false,
      expOutError: false,
      maxOutErr: false,
    })
    props.setErrorFields(false)
    props.setSelectedLabel(false)
  }

  return (
    <>
      <core.Grid
        style={{
          paddingLeft: theme.spacing(3),
          paddingTop: theme.spacing(2),
          paddingRight: theme.spacing(1),
        }}
        item
        xl={props.isConstraints ? 10 : 8}
        md={props.isConstraints ? 10 : 9}
        xs={12}
      >
        {props.postBody && (
          <core.Typography
            variant="h5"
            style={{ marginBottom: "0px" }}
            className={classes.calculatorTypo}
          >
            Output Tests
          </core.Typography>
        )}
        <m.Stack direction={"column"} spacing={1} className="test-dialog">
          {props.testList?.map((testL, index) => (
            <m.Stack key={testL} direction={"column"} spacing={1}>
              <m.Grid
                container
                className="ml-display-flex ml-flex-dir-row ml-space-between MLCharts-imagelist"
              >
                <m.Grid item xs={11.5}>
                  <m.Grid
                    container
                    spacing={1}
                    className="ml-display-flex ml-flex-dir-row"
                  >
                    {renderOutputs("out", "name", testL.label, index)}
                    {renderOutputs("in", testL.label, testL.mode, index)}
                    {testL.mode === testRule.between
                      ? renderExpOutput(
                          "in",
                          testL.valueMin,
                          testL.mode,
                          testL.label,
                          index,
                          "edit"
                        )
                      : renderExpOutput(
                          "in",
                          testL.value,
                          testL.mode,
                          testL.label,
                          index,
                          "edit"
                        )}
                    {testL.mode === testRule.equal &&
                      renderExpOutput(
                        "key",
                        testL.tolerance,
                        testL.mode,
                        testL.label,
                        index,
                        "edit"
                      )}
                    {testL.mode === testRule.between &&
                      renderExpOutput(
                        "out",
                        testL.valueMax,
                        testL.mode,
                        testL.label,
                        index,
                        "edit"
                      )}
                  </m.Grid>
                </m.Grid>
                <m.Grid
                  item
                  xs={0.5}
                  sx={{ display: "flex", alignItems: "end" }}
                >
                  <m.IconButton
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    title="Click here to remove constraints"
                    color="primary"
                    onClick={() => handleRemoveSubTest(index)}
                  >
                    <RemoveCircleOutlineIcon />
                  </m.IconButton>
                </m.Grid>
              </m.Grid>
            </m.Stack>
          ))}

          {props.addForm && (
            <m.Grid
              container
              className="ml-display-flex ml-flex-dir-row ml-space-between MLCharts-imagelist"
            >
              <m.Grid item xs={11.5}>
                <m.Grid
                  container
                  spacing={1}
                  className="ml-display-flex ml-flex-dir-row"
                >
                  {renderOutputs("out", "name")}
                  {renderOutputs("in", props.testDetails.label)}
                  {props.testDetails.mode === testRule.between
                    ? renderExpOutput(
                        "in",
                        props.testDetails.valueMin,
                        props.testDetails.mode,
                        props.testDetails.label
                      )
                    : renderExpOutput(
                        "in",
                        props.testDetails.value,
                        props.testDetails.mode,
                        props.testDetails.label
                      )}
                  {props.testDetails.mode === testRule.equal &&
                    renderExpOutput(
                      "key",
                      props.testDetails.tolerance,
                      props.testDetails.mode,
                      props.testDetails.label
                    )}
                  {props.testDetails.mode === testRule.between &&
                    renderExpOutput(
                      "out",
                      props.testDetails.valueMax,
                      props.testDetails.mode,
                      props.testDetails.label
                    )}
                </m.Grid>
              </m.Grid>
              <m.Grid item xs={0.5} sx={{ display: "flex", alignItems: "end" }}>
                {(props.testDetails?.mode?.length > 0 ||
                  props.testDetails?.value ||
                  (props.isConstraints &&
                    props.testDetails.label?.length > 0)) && (
                  <m.IconButton
                    title="Click here to remove constraints"
                    color="primary"
                    onClick={handleEmptyTestField}
                  >
                    <RemoveCircleOutlineIcon />
                  </m.IconButton>
                )}
              </m.Grid>
            </m.Grid>
          )}
        </m.Stack>
        <core.Grid className="ml-display-flex ml-flex-dir-row ml-justify-center">
          <CustomButton
            text={"Add More"}
            icon={<AddCircleOutlineOutlinedIcon color="primary" />}
            callingFunction={handleAddingTestValidation}
          />
        </core.Grid>
      </core.Grid>
    </>
  )
}

export default OutputsForm
