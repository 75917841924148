// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/parallel-coordinates
import { Button, Typography } from "@mui/material"
import { ResponsiveParallelCoordinates } from "@nivo/parallel-coordinates"
import { useEffect, useState } from "react"

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

// function onlyUnique(value, index, self) {
//   return self.indexOf(value) === index
// }

function ParallelCoordinatesNivo(props) {
  const [dataSlice, setDataSlice] = useState([])
  // const [sliceLength, setSliceLength] = useState(100)
  const [domainStructure, setDomainStructure] = useState({})
  const sliceLength = 100

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      getRandomSlice(props.data, sliceLength)
      let tempDomainStructure = Object.keys(props.data[0]).map((name) => {
        if (Number(props.data[0][name])) {
          return {
            key: name,
            type: "linear",
            legend: name,
            legendPosition: "start",
            legendOffset: -20,
          }
        } else {
          return {
            key: name,
            type: "point",
            legend: name,
            legendPosition: "start",
            legendOffset: -20,
          }
        }
      })
      setDomainStructure(tempDomainStructure)
    }
  }, [props.data, sliceLength])

  function getRandomSlice() {
    // Shuffle array
    const indexes = props.data.map((el, i) => i)
    const shuffledIndexes = indexes.sort(() => 0.5 - Math.random())

    // Get sub-array of first n elements after shuffled
    const selectedIndexes = shuffledIndexes.slice(0, sliceLength)
    setDataSlice(selectedIndexes.map((i) => props.data[i]))
  }

  if (dataSlice.length === 0) return <></>

  // const domains = data.reduce((acc, row) => {
  //   return acc.map((d) => {
  //     let newArray = d.values
  //     newArray.push(row[d.key.toString()])
  //     newArray = newArray.filter(onlyUnique)
  //     newArray.sort()
  //     return { ...d, values: newArray }
  //   })
  // }, domainStructure)

  //   //console.log(domains)
  //

  return (
    <div className="randomslicebtn">
      <Button
        onClick={() => {
          getRandomSlice()
        }}
      >
        <Typography variant="caption"> Get New Random Slice of Data</Typography>
      </Button>

      <ResponsiveParallelCoordinates
        data={dataSlice}
        variables={domainStructure}
        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
        colors={{ scheme: "purple_blue" }}
        strokeWidth={1}
        lineOpacity={0.5}
        pixelRatio={2}
        animate={false}
      />
    </div>
  )
}
export default ParallelCoordinatesNivo
