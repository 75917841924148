import { Suspense } from "react"
import * as core from "@material-ui/core"
import { AddDialogTitle, ExploreTable, Loader } from "../../GlobalFileContainer"
import { handleKeyDown } from "../../state/projectState"

const ShowResult = ({ ViewResult, closeResult, refKey, title, optimise }) => {
  return (
    <core.Dialog
      onKeyDown={(e) => handleKeyDown(e, closeResult)}
      open={ViewResult}
      fullWidth={true}
      maxWidth="lg"
    >
      <AddDialogTitle closeCreate={closeResult} title={title} />
      <Suspense fallback={<Loader margin={"10%"} />}>
        <core.DialogContent dividers>
          <ExploreTable refKey={refKey} optimise={optimise} />
        </core.DialogContent>
      </Suspense>
    </core.Dialog>
  )
}

export default ShowResult
