import React from "react"
import ErrorBoundary from "../ErrorBoundary"
import OptimizationStepper from "../result-optimization/OptimizationStepper"

const LookupMain = () => {
  return (
    <ErrorBoundary>
      <OptimizationStepper lookup />
    </ErrorBoundary>
  )
}

export default LookupMain
