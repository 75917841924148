import * as core from "@material-ui/core"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import PsychologyIcon from "@mui/icons-material/Psychology"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import * as m from "@mui/material"
import useStyles from "./card_style"

const CollaboratorDialog = ({
  setShowCollaboratorDialog,
  setCollaboratorRole,
  director,
}) => {
  const classes = useStyles()
  const theme = m.useTheme()

  const tableContent = [
    {
      name: "Edit Metadata",
      director: true,
      manager: true,
      technician: false,
    },
    {
      name: "Manage I/O",
      director: true,
      manager: true,
      technician: true,
    },
    {
      name: "Upload/Download Data",
      director: true,
      manager: true,
      technician: true,
    },
    {
      name: "Train",
      director: true,
      manager: true,
      technician: true,
    },
    {
      name: "Upload/Download Pipelines",
      director: false,
      manager: false,
      technician: false,
    },
    {
      name: "Manage Collaborator",
      director: true,
      manager: true,
      technician: false,
    },
    {
      name: "Delete Emulator",
      director: true,
      manager: false,
      technician: false,
    },
  ]

  const returnIcon = (value) => {
    const iconClassName = value
      ? classes.colorSuccessIcon
      : classes.colorDangerIcon
    const IconComponent = value ? CheckCircleIcon : CancelIcon

    return (
      <m.TableCell sx={{ width: "400px", padding: "5px" }} align="center">
        <IconComponent fontSize="medium" className={iconClassName} />
      </m.TableCell>
    )
  }

  const returnButton = (role) => {
    return (
      <m.TableCell align="center">
        <m.Button
          align="center"
          variant="contained"
          color="primary"
          style={{ color: theme.palette.common.white }}
          onClick={() => {
            setCollaboratorRole(role)
            setShowCollaboratorDialog({
              isOpen: false,
              content: null,
              title: "",
              style: {},
              maxWidth: "md",
              actions: null
            })
          }}
        >
          <m.Typography variant="caption">Request</m.Typography>
        </m.Button>
      </m.TableCell>
    )
  }
  const renderTableHeader = (value) => (
    <m.TableCell align="center">
      <m.Typography variant="body2">
        <b>{value}</b>
      </m.Typography>
    </m.TableCell>
  )
  return (
    <>
      <core.Box>
        {director && (
          <m.Typography variant="h6">Emulator Permissions</m.Typography>
        )}
        <m.TableContainer component={m.Paper}>
          <m.Table sx={{ minWidth: 700 }} aria-label="spanning table">
            <m.TableHead>
              <m.TableRow>
                <m.TableCell width={"600px"}></m.TableCell>
                {director && renderTableHeader("Director")}
                {renderTableHeader("Manager")}
                {renderTableHeader("Technician")}
              </m.TableRow>
            </m.TableHead>
            <m.TableBody>
              {tableContent.map((content, id) => (
                <m.TableRow key={`${id}-${content.name}`}>
                  <m.TableCell sx={{ width: "600px", padding: "5px" }}>
                    <m.Typography variant="body2">{content.name}</m.Typography>
                  </m.TableCell>
                  {director && returnIcon(content.director)}
                  {returnIcon(content.manager)}
                  {returnIcon(content.technician)}
                </m.TableRow>
              ))}
              {!director && (
                <m.TableRow key={"buttons"}>
                  <m.TableCell
                    sx={{ width: "600px", padding: "5px" }}
                  ></m.TableCell>
                  {returnButton("manager")}
                  {returnButton("technician")}
                </m.TableRow>
              )}
            </m.TableBody>
          </m.Table>
        </m.TableContainer>
      </core.Box>
      <br />
      {director && (
        <m.Box>
          <m.Typography variant="h6">Sitewide Permissions</m.Typography>
          <m.Card className="ml-display-flex ml-flex-dir-row ml-align-center ml-space-around ml-p-5">
            <m.Box className="ml-display-flex ml-flex-dir-col ml-align-center">
              <PsychologyIcon color="primary" sx={{ fontSize: "xxx-large" }} />
              <m.Typography variant="h6">Machine Learning Experts</m.Typography>
            </m.Box>
            <m.Box className="ml-display-flex ml-flex-dir-col ml-align-center">
              <AdminPanelSettingsIcon
                color="primary"
                sx={{ fontSize: "xxx-large" }}
              />
              <m.Typography variant="h6">Site Admin</m.Typography>
            </m.Box>
          </m.Card>
        </m.Box>
      )}
    </>
  )
}

export default CollaboratorDialog
