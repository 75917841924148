import { useState, useEffect } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import { Tab, Tabs, Typography } from "@mui/material"
import useStyles from "../../card_style"
import {
  chipPositionState,
  fetchAnnotationsState,
  imageIndex,
} from "../../../state/projectState"
import {
  KonvaImageStage,
  GalleryDialogBox,
  ThreedCanvasControlled,
} from "../../../GlobalFileContainer"

const CalculatorGallery = ({
  mainLoader,
  isModalObject,
  showModal,
  imageLength,
  result,
  currentIndex,
  setOpenThreeModal,
  openThreeModal,
  emData,
  setDisableKeys,
  disableKeys,
  loader,
}) => {
  const classes = useStyles()

  const [index, setIndex] = useRecoilState(imageIndex)
  const setChipPosition = useSetRecoilState(chipPositionState)
  const setIsFetchingAnnotations = useSetRecoilState(fetchAnnotationsState)

  const [openImageDialog, setOpenImageDialog] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const tabTitles = []

  if (isModalObject && showModal) {
    tabTitles.push("3D Model")
  }
  if (imageLength !== 0) {
    tabTitles.push("Gallery")
  }

  useEffect(() => {
    setAssociatedStates()
  }, [])

  const setAssociatedStates = () => {
    setChipPosition([])
    setIsFetchingAnnotations(true)
    setIndex(0)
    setOpenImageDialog(false)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const tabStyle = {
    justifyContent: "flex-start",
    padding: "0px 16px",
  }

  return (
    tabTitles && (
      <core.Card className="ml-height">
        <core.CardContent
          style={{ height: "100%" }}
          className={classes.calcMainCard}
        >
          <Tabs value={tabValue} onChange={handleChange}>
            {tabTitles.map((title, index) => (
              <Tab
                key={`${title}-${index}`}
                sx={tabStyle}
                label={
                  <Typography variant="h5" className={classes.calculatorTypo}>
                    {title}
                  </Typography>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {tabTitles[tabValue] === "3D Model" ? (
            <ThreedCanvasControlled
              result={result}
              currentIndex={currentIndex}
              setOpenThreeModal={setOpenThreeModal}
              openThreeModal={openThreeModal}
              emData={emData}
              setDisableKeys={setDisableKeys}
              disableKeys={disableKeys}
              loader={loader}
            />
          ) : (
            <core.Box
              className="hover-styling"
              title="Click here to view gallery"
              onClick={() => setOpenImageDialog(true)}
            >
              <KonvaImageStage
                mainLoader={mainLoader}
                disableComponent={true}
                displayIn="calc"
              />
            </core.Box>
          )}
        </core.CardContent>
        <GalleryDialogBox
          setOpenImageDialog={setOpenImageDialog}
          disable={true}
          openImageDialog={openImageDialog}
          setAssociatedStates={setAssociatedStates}
        />
      </core.Card>
    )
  )
}

export default CalculatorGallery
