import { createTheme } from "@material-ui/core/styles"
import { commonFontFamily } from "./state/StatusState"

export const MainAppTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 980,
      lg: 1200,
      xl: 1450,
    },
  },
  typography: {
    cardHeading:{
      fontSize:"20px"
    },
    subHeading: {
      fontSize: "14px"
    },
    subtitle2: {
      fontSize: "small",
      lineHeight: "1",
    },
  },
  palette: {
    typography: {
      fontFamily: commonFontFamily,
    },
    status: {
      danger: "#FF0D19",
      success: "#3b9668",
    },
    primary: {
      main: "#39C2D7",
      light: "#e7f7fa",
    },
    secondary: {
      main: "#EBCE30",
      light: "#FA8128",
    },
    grey: {
      main: "#6C757D",
      secondaryLight: "#BEBEBE",
      light: "#F3F4F6",
    },
    common: {
      white: "#FFFFFF",
      black: "#000000",
      pink: "#EFADB1",
      pastel: "#DAF8EE",
      lightPink: "#FBF4DC",
      lightGrey: "#FAFAFA",
      blue: "#0000FF",
    },
    excel: {
      light: "rgba(230, 255, 230, 0.3)",
      main: "#006600",
    },
    python: {
      light: "rgba(230, 255, 255, 0.4)",
      main: "#00e6e6",
    },
    grasshopper: {
      light: "rgba(255, 240, 230, 0.3)",
      main: "#ff751a",
    },
    optimization: {
      light: "rgba(255, 230, 243, 0.3)",
      main: "#ff1a8c",
    },
    mathcad: {
      light: "rgba(242, 230, 255, 0.3)",
      main: "#4d004d",
    },
    all: {
      light: "rgb(128,0,0,0.1)",
      main: "#4d004d",
    },
  },
  spacing: 8,
  overrides: {
    MuiListItemIcon: {
      root: {
        "@media (max-width:480px)": {
          minWidth: "0%",
        },
      },
    },
    MuiListItem: {
      root: {
        padding: "0px !important",
      },
    },
    MuiTableCell: {
      root: {
        "@media (max-width:500px)": {
          padding: "5px",
          borderBottom: "none",
        },
      },
    },
    MuiButtonBase: {
      root: {
        "&.Mui-disabled": {
          pointerEvents: "auto",
          cursor: "pointer",
        },
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          cursor: "pointer",
          // transform: "scale(1.02)",
        },
        "& .MuiButton-label:hover": {
          cursor: "pointer",
        },
      },
    },
  },
})
const fontWeight500 = "500"

const createHeadingStyles = (fontSize, bold) => ({
  fontSize,
  fontFamily: commonFontFamily,
  fontWeight: bold,
  "@media (min-width:600px)": {
    fontSize: `calc(${fontSize} + 0.2rem)`,
  },
  [MainAppTheme.breakpoints.up("md")]: {
    fontSize: `calc(${fontSize} + 0.4rem)`,
  },
})

MainAppTheme.typography.h1 = createHeadingStyles("1.6rem", 650)
MainAppTheme.typography.h2 = createHeadingStyles("1.5rem", 650)
MainAppTheme.typography.h3 = createHeadingStyles("1.4rem", 650)
MainAppTheme.typography.h4 = {
  fontSize: "1.2rem",
  fontFamily: commonFontFamily,
  fontWeight: fontWeight500,
  "@media (min-width:600px)": {
    fontSize: "1.4rem",
  },
  [MainAppTheme.breakpoints.up("md")]: {
    fontSize: "1.6rem",
  },
}

const createBodyStyles = (fontSize, letterSpacing) => ({
  fontSize,
  "@media (min-width:600px)": {
    fontSize: `calc(${fontSize} + 0.1rem)`,
  },
  [MainAppTheme.breakpoints.up("md")]: {
    fontSize: `calc(${fontSize} + 0.3rem)`,
  },
  fontFamily: commonFontFamily,
  letterSpacing,
})

MainAppTheme.typography.h5 = createHeadingStyles("1.0rem", 500)
MainAppTheme.typography.h6 = createHeadingStyles("0.8rem", 500)
MainAppTheme.typography.button = {
  fontFamily: commonFontFamily,
  fontSize: "0.9rem",
  [MainAppTheme.breakpoints.up("md")]: {
    fontSize: "1.0rem",
  },
}
MainAppTheme.typography.caption = {
  textTransform: "uppercase",
  fontSize: "0.65rem",
  fontFamily: commonFontFamily,
  "@media (min-width:600px)": {
    fontSize: "0.8rem",
  },
}
MainAppTheme.typography.body1 = createBodyStyles("0.7rem", "0.3px")
MainAppTheme.typography.body2 = createBodyStyles("0.6rem", "0.20px")
