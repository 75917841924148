import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { Box } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import {
  columnNotMatch,
  downgradeStatusandAddDelVar,
} from "./InputVariableCards"
import { Typography } from "@mui/material"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"

export default function OutputVariableCards({
  setIndex,
  updateVariable,
  setEmulatorStatusState,
  dataColumnLabels,
  setDeleteInSuccess,
  setVariableAction,
  updateEmData,
}) {
  let { emulatorId } = useParams()
  const Software = useRecoilValue(storedSates.dataSourceType)
  const setUpdateData = useSetRecoilState(storedSates.addData)
  const emulatorStatusState = useRecoilValue(storedSates.emulatorStatus)
  const selectedVersions = useRecoilValue(storedSates.selectedVersion)
  const versName = useRecoilValue(storedSates.versionName)
  const betaVers = useRecoilValue(storedSates.getBeta)
  const [emData, setEmData] = useRecoilState(storedSates.getEmulatorData)
  const releaseWithIo = useRecoilValue(storedSates.releaseIOState)

  const outputVars =
    selectedVersions === betaVers &&
    versName === storedSatus.breadCrumbsStrings.beta
      ? emData?.calculator?.OutputVariables
      : Object.keys(releaseWithIo)?.length > 0 ? releaseWithIo.OutputVariables : emData?.calculator?.OutputVariables

  const deleteData = async (index, hasModel) => {
    const url = `/emulators/${emulatorId}/variables/output/${index}`
    try {
      const removeData = await file.EmulationLab.delete(url, {
        headers: {
          severity: SeverityLevel.Error,
        },
      })
      if (removeData.status === 200) {
        setVariableAction("deleted")
        setEmulatorStatusState(storedSatus.allEmulatorStatus.preparing)
        setDeleteInSuccess(true)
        if (hasModel?.ModelVisual) {
          const metaData = {
            name: emData?.name,
            description: emData?.description,
            image: emData?.image,
            tags: emData?.tags,
            category: emData?.category,
            modes: emData?.modes,
            source: {
              ...emData?.source,
              hasModel: false,
            },
          }

          const url = `/emulators/${emulatorId}/metadata`
          try {
            const upData = await file.EmulationLab.post(url, metaData, {
              headers: {
                severity: SeverityLevel.Error,
              },
            })
            updateEmData && updateEmData()
          } catch {
            console.error("Unable to update metadata")
          }
        } else if (emData?.calculator?.OutputVariables?.length > 0) {
          const temp = JSON.parse(JSON.stringify(emData))
          temp.calculator.OutputVariables.splice(index, 1)
          setEmData(temp)
        }
      }
    } catch (error) {
      alert("Variable is not deleted , please try again..")
    }
  }

  const handleRemoveOutput = (index, hasModel) => {
    deleteData(index, hasModel)
  }

  const handleDelete = (index, hasModel) => {
    setDeleteInSuccess(false)
    handleRemoveOutput(index, hasModel)
  }

  return (
    <>
      {outputVars?.map((data, index) => (
        <div key={index}>
          <file.VariableCardsLayout
            variable={data}
            version={selectedVersions === betaVers}
            editButtonFunction={() => {
              updateVariable()
              setIndex(index)
              setUpdateData(false)
            }}
            deleteButtonFunction={() =>
              emulatorStatusState === storedSatus.allEmulatorStatus.training
                ? downgradeStatusandAddDelVar(
                    emulatorId,
                    handleDelete,
                    index,
                    data
                  )
                : handleDelete(index, data)
            }
          >
            <file.VariableInfoLine
              variable={data}
              varKey={"Nickname"}
              require={true}
            >
              <Typography variant="body1">{data.NickName}</Typography>
            </file.VariableInfoLine>
            <file.VariableInfoLine
              variable={data}
              varKey={"Column Label"}
              require={true}
            >
              <Box className="ml-display-flex ml-flex-dir-row ml-align-center">
                <Typography variant="body1">
                  {dataColumnLabels?.length > 0 &&
                    !dataColumnLabels?.includes(data?.ColumnLabel) && (
                      <Box className="domainicon">{columnNotMatch()}</Box>
                    )}
                </Typography>
                <Box className="ml-display-flex ml-align-center">
                  <Typography variant="body1"> {data?.ColumnLabel}</Typography>
                </Box>
              </Box>
            </file.VariableInfoLine>
            {Software === storedSatus.emulatorSoftwareType.python && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Python Variable Name"}
                require={true}
              >
                <Typography variant="body1">
                  {data?.PythonVariableName}
                </Typography>
              </file.VariableInfoLine>
            )}
            {Software === storedSatus.emulatorSoftwareType.excel && (
              <>
                <file.VariableInfoLine
                  variable={data}
                  varKey={"Worksheet"}
                  require={false}
                />
                <file.VariableInfoLine
                  variable={data}
                  varKey={"Cell"}
                  require={false}
                />
              </>
            )}
            {Software === storedSatus.emulatorSoftwareType.hosted && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Api Label"}
                require={false}
              >
                <Typography variant="body1">{data?.ApiLabel}</Typography>
              </file.VariableInfoLine>
            )}
            {Software === storedSatus.emulatorSoftwareType.mathcad && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Alias"}
                require={false}
              />
            )}
            {Software === storedSatus.emulatorSoftwareType.grasshopper && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Param Name"}
                require={false}
              >
                <Typography variant="body1">{data?.ParamName}</Typography>
              </file.VariableInfoLine>
            )}
            <file.VariableInfoLine
              variable={data}
              varKey={"Type"}
              require={false}
            >
              <Typography variant="body1">
                {data.Type ? data.Type : ""}
                {data?.Units && data.Type == "quantity" && (
                  <>({`${data.Units}`})</>
                )}
              </Typography>
            </file.VariableInfoLine>
            {data?.ModelVisual && (
              <file.VariableInfoLine
                variable={data}
                varKey={"ModelVisual"}
                require={true}
              />
            )}
          </file.VariableCardsLayout>
        </div>
      ))}
    </>
  )
}
