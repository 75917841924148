import { useEffect, useState } from "react"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as m from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { VscJson } from "react-icons/vsc"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  CommonHeader,
  Loader,
  EmulationLab,
  useWindowDimensions,
} from "../../GlobalFileContainer"
import {
  agentQuedLoader,
  agentQueueData,
  refreshButtonPollingStates,
  jsonDialogOpenClose,
  getAgentQueue,
  getCurrentJob,
  getRecentJob,
  formatTimeBasedOnLocal,
  commonDateFormat,
} from "../../state/projectState"
import {
  SECONDS_IN_MINUTE,
  SECONDS_IN_ONEHALF_MIN,
  boldTitleHeader,
  renderIdenticalIcon,
  stringOperators,
  uniqueFiltersOwner,
} from "./AgentHealthMain"
import { alertStates } from "../../state/vizState"
import { mongoProfileState } from "../../state/userState"
import {
  ALGO_STATUS,
  convertToUppercase,
  pickStatusWithSoftwareIcons,
  seperateSoftwareAndSubType,
} from "../../state/StatusState"
import { commonRowStyle } from "./CapabilityMain"
import { fetchJobsData } from "./AdminDashboardMain"
import useStyles from "../card_style"
import { darken, lighten, styled } from "@mui/material/styles"

const POLLING_INTERVAL = 10000
export const jobQueue = "Job Queue"
export const currentJob = "Active Jobs"
export const recentJob = "Today's Job"

const singleSelectObj = {
  type: "singleSelect",
}

const headerAlign = {
  align: "center",
  headerAlign: "center",
}

const returnMessage = (isQueue) => {
  return isQueue ? "Awaiting Jobs" : "No jobs available"
}

const generateSubText = (type, data) => {
  const dataLength = data?.length
  const message = returnMessage(type === jobQueue)
  return dataLength ? (
    <m.Typography className="ed-small">{`(${dataLength} job${
      dataLength > 1 ? "s" : ""
    })`}</m.Typography>
  ) : (
    <m.Typography className="ed-small">{`(${message})`}</m.Typography>
  )
}

export const RenderTableAccordion = ({
  title,
  children,
  expandedAccordions,
  handleExpansion,
  data,
}) => {
  const isExpanded = expandedAccordions?.includes(title)
  return (
    <m.Accordion
      expanded={isExpanded}
      onChange={(e) => handleExpansion(e, title)}
      className="hoverGrey margin-to-10 ml-p-5"
      elevation={1}
    >
      <m.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <CommonHeader
          title={title}
          subText={isExpanded ? "" : generateSubText(title, data)}
        />
      </m.AccordionSummary>
      <m.AccordionDetails>{children}</m.AccordionDetails>
    </m.Accordion>
  )
}

export const hasData = (data) => data?.length > 0

export const statusColumn = (theme, minWidth) => {
  return {
    field: "status",
    headerName: "Status",
    minWidth: minWidth,
    width: 120,
    filterable: false,
    ...headerAlign,
    renderCell: (params) =>
      params?.value === ALGO_STATUS.failed ? (
        <m.Chip
          size="small"
          color="error"
          label={
            <m.Typography variant="body2">
              {convertToUppercase(params.value)}
            </m.Typography>
          }
        />
      ) : params?.value === ALGO_STATUS.canceled ? (
        <m.Chip
          size="small"
          color="secondary"
          label={
            <m.Typography variant="body2" color={theme.palette.common.white}>
              {convertToUppercase(params.value)}
            </m.Typography>
          }
        />
      ) : params?.value === ALGO_STATUS.complete ? (
        <m.Chip
          size="small"
          color="success"
          label={
            <m.Typography variant="body2" color={theme.palette.common.white}>
              {convertToUppercase(params.value)}
            </m.Typography>
          }
        />
      ) : params?.value === ALGO_STATUS.received ? (
        <m.Chip
          size="small"
          style={{ backgroundColor: theme.palette.mathcad.main }}
          label={
            <m.Typography variant="body2" color={theme.palette.common.white}>
              {convertToUppercase(params.value)}
            </m.Typography>
          }
        />
      ) : params?.value === "incomplete" ? (
        <m.Chip
          size="small"
          style={{ backgroundColor: theme.palette.common.blue }}
          label={
            <m.Typography variant="body2" color={theme.palette.common.white}>
              {convertToUppercase(params.value)}
            </m.Typography>
          }
        />
      ) : (
        <m.Chip
          size="small"
          color="primary"
          label={
            <m.Typography variant="body2" color={theme.palette.common.white}>
              {convertToUppercase(params.value)}
            </m.Typography>
          }
        />
      ),
  }
}

const formatTime = (val) => {
  const [hours, minutes, seconds] = val.split(":").map(Number)
  let time

  if (hours === 0 && minutes === 0) {
    time = `${seconds} sec${seconds > 1 ? "s" : ""}`
  } else if (hours === 0) {
    time = `${minutes} min${minutes > 1 ? "s" : ""}`
  } else {
    time = `${hours} hour${hours > 1 ? "s" : ""}`
  }
  return time
}

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7)

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6)

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5)

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4)

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .highlight": {
    backgroundColor: getBackgroundColor(
      theme.palette.grey[500],
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.grey[500],
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.grey[500],
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.grey[500],
          theme.palette.mode
        ),
      },
    },
  },
}))

const AgentQueueMain = ({ summary, gndata, emulatorId }) => {
  const theme = m.useTheme()
  const { width } = useWindowDimensions()
  const classes = useStyles()

  const [queueDataState, setQueueDataState] = useRecoilState(agentQueueData)
  const [queuedLoader, setQueuedLoader] = useRecoilState(agentQuedLoader)
  const setAlertState = useSetRecoilState(alertStates)
  const mongoProfile = useRecoilValue(mongoProfileState)
  const setJsonDialogOpen = useSetRecoilState(jsonDialogOpenClose)
  const [refreshPolling, setRefreshPolling] = useRecoilState(
    refreshButtonPollingStates
  )

  const reversedQueueData = gndata
    ? queueDataState?.queuedData?.filter(
        (queData) => queData?.emulator_id === emulatorId
      )
    : queueDataState.queuedData

  const currentJobData = gndata
    ? queueDataState.currentJobData?.filter(
        (currData) => currData?.emulator_id === emulatorId
      )
    : queueDataState.currentJobData

  const reversedCurrentData = currentJobData
    ? currentJobData.slice().reverse()
    : []

  const hasQueuedData = hasData(reversedQueueData)
  const hasCurrentData = hasData(currentJobData)

  const recentData = queueDataState.recentData ?? []

  const updatedQueuedData = summary
    ? reversedQueueData
      ? reversedQueueData?.slice(0, 2)
      : []
    : reversedQueueData

  const updatedCurrentData = summary
    ? reversedCurrentData
      ? hasQueuedData
        ? []
        : reversedCurrentData?.slice(0, 2)
      : []
    : reversedCurrentData

  const updatedRecentData = summary
    ? recentData
      ? hasQueuedData || hasCurrentData
        ? []
        : recentData?.slice(0, 2)
      : []
    : recentData

  const handleJsonViewerClick = (object) => {
    setJsonDialogOpen({
      state: true,
      obj: object,
    })
  }

  const typeColumn = (data, queue) => {
    return {
      field: "type",
      headerName: "Type",
      sortable: false,
      width: 100,
      filterable: !summary,
      ...singleSelectObj,
      valueOptions: uniqueFiltersOwner(data, "type"),
      filterOperators: stringOperators,
      minWidth: queue ? 50 : null,
    }
  }

  const jobIdColumn = (field, width) => {
    return {
      field: field,
      headerName: "Job Id",
      width: width,
      sortable: false,
      renderCell: (params) =>
        renderIdenticalIcon(
          params?.value,
          setAlertState,
          "Job ID",
          false,
          params
        ),
      filterable: false,
    }
  }

  const dependencyColumn = (width, data) => {
    return {
      field: "dependency_set",
      sortable: false,
      headerName: "Dependency_set",
      ...headerAlign,
      filterable: !summary,
      ...singleSelectObj,
      valueOptions: uniqueFiltersOwner(data, "dependency_set"),
      filterOperators: stringOperators,
      width: width,
      renderCell: (params) => {
        const [softwareType, softwareSubType, softwareStatus] =
          seperateSoftwareAndSubType(params.row.dependency_set)

        return params.row.dependency_set ? (
          <div style={commonRowStyle}>
            {pickStatusWithSoftwareIcons(
              softwareType,
              softwareSubType,
              softwareStatus,
              "30px",
              params.row.dependency_set
            )}
          </div>
        ) : (
          ""
        )
      },
    }
  }

  const refColumn = {
    field: "ref",
    headerName: "Ref",
    width: 200,
    filterable: false,
    ...headerAlign,
  }

  const countColumn = {
    field: "count",
    headerName: "Count",
    width: 70,
    ...headerAlign,
    filterable: false,
  }

  const removeColumn = (title, width, data, parameter, queue) => {
    return {
      field: "remove",
      headerName: title,
      width: width,
      filterable: false,
      ...headerAlign,
      sortable: false,
      renderCell: (params) => {
        const findAgentObj = data?.find(
          (queue) => queue[parameter] === params.row[parameter]
        )
        return (
          <m.Box className="ml-display-flex ml-flex-dir-row, ml-justify-center ml-align-center ml-height">
            {queue && (
              <>
                <m.Box
                  sx={{
                    display: showRemoveIcon(params.row?.requestor)
                      ? "inline"
                      : "none",
                  }}
                >
                  {renderDeleteButton(params, parameter)}
                </m.Box>
                &nbsp;
              </>
            )}
            <m.Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              title="Click to view job"
            >
              <VscJson
                fontSize={"large"}
                onClick={() => handleJsonViewerClick(findAgentObj)}
              />
            </m.Box>
          </m.Box>
        )
      },
    }
  }

  const agentColumn = {
    field: "agent",
    headerName: "Agent",
    sortable: false,
    filterable: false,
    width: 100,
    renderCell: (params) =>
      renderIdenticalIcon(
        params.value,
        setAlertState,
        "Agent ID",
        false,
        params
      ),
  }

  const requestor = {
    field: "requestor",
    headerName: "Requestor",
    filterable: false,
    width: 100,
    renderCell: (params) => (
      <m.Box title={params.value}>{params.value?.split("@")[0]}</m.Box>
    ),
  }

  const calcFileName = {
    field: "calc_name",
    headerName: "Calc Name",
    width: 180,
    filterable: false,
  }

  const estimatesColumn = {
    field: "estimate_s",
    headerName: "Estimates",
    width: 100,
    filterable: false,
    renderCell: (params) => {
      const inSecondsValue = showComputedDuration(params.value)
      const inHHMMSSValue = convertSeconds(inSecondsValue)

      return params.value === null ? "" : <>{inHHMMSSValue}</>
    },
    ...headerAlign,
  }

  const durationsColumn = {
    field: "duration_s",
    headerName: "Durations",
    width: 100,
    filterable: false,
    renderCell: (params) => {
      const inSecondsValue = showComputedDuration(params.value)
      const inHHMMSSValue = convertSeconds(inSecondsValue)

      return params.value === null ? "" : <>{inHHMMSSValue}</>
    },
    ...headerAlign,
  }

  const progressColumn = {
    field: "progress",
    headerName: "Progress",
    width: 100,
    filterable: false,
    ...headerAlign,
    renderCell: (params) => (
      <ProgressCell value={params.value} rowId={params.row.job_id} />
    ),
  }

  const versionsColumn = {
    field: "version",
    headerName: "Version",
    width: 100,
    filterable: false,
    ...headerAlign,
  }

  const columns = [
    jobIdColumn("id", gndata ? 100 : 200),
    typeColumn(updatedQueuedData, true),
    dependencyColumn(150, updatedQueuedData),
    {
      field: "ref",
      headerName: "Ref",
      width: summary ? 400 : 300,
      renderCell: (params) => params?.row?.ref?.split("/calculator/")[1],
      filterable: false,
    },
    {
      field: "position",
      headerName: "Position",
      width: 100,
      ...headerAlign,
      sortable: true,
      sortComparator: (v1, v2) => v2 - v1,
      filterable: false,
    },
    {
      field: "sec_ago",
      headerName: "Queued On",
      filterable: false,
      width: 150,
      renderCell: (params) =>
        params?.value > SECONDS_IN_ONEHALF_MIN ? (
          <m.Chip
            size="small"
            color="error"
            label={
              <m.Typography variant="body2">
                {params.row?.when_queued}
              </m.Typography>
            }
          />
        ) : (
          <m.Typography
            variant="body2"
            sx={{
              color:
                params.value > SECONDS_IN_MINUTE
                  ? theme.palette.secondary.main
                  : theme.palette.status.success,
            }}
          >
            {params.value < 1
              ? renderMiliSeconds(params.value)
              : params.row?.when_queued}
          </m.Typography>
        ),
    },
    countColumn,
    {
      field: "req_agent",
      headerName: "Assigned Agent",
      ...headerAlign,
      width: 140,
      filterable: false,
    },
    removeColumn("Remove/See Job", 150, updatedQueuedData, "id", true),
  ]

  const fixedToDecimal = (value) => {
    return typeof value === "number" ? value?.toFixed(1) : value
  }

  const initialRows = updatedQueuedData?.map(
    ({
      type,
      dependency_set,
      ref,
      position,
      id,
      when_queued,
      dependency_sets,
      count,
      sec_ago,
      req_agent,
      requestor,
    }) => ({
      type,
      dependency_set,
      ref,
      position,
      id,
      when_queued,
      dependency_sets,
      count,
      sec_ago,
      req_agent,
      requestor,
    })
  )

  const ProgressCell = ({ rowId, value }) => (
    <div className={classes.progressCell}>
      {typeof value === "number" ? (
        <div
          className={`progress-container ${
            Object.keys(queueDataState.updatedProgressCell)?.includes(rowId)
              ? "pulse2"
              : ""
          }`}
        >
          <div className="progress-bar" style={{ width: `${value}%` }}></div>
          <div className="progress-text">
            <m.Typography
              style={{ fontSize: "0.7rem", color: theme.palette.common.black }}
            >
              {`${Math.round(value)}%`}
            </m.Typography>
          </div>
        </div>
      ) : (
        value
      )}
    </div>
  )

  const currentColumns = [
    jobIdColumn("job_id", 100),
    agentColumn,
    typeColumn(updatedCurrentData),
    requestor,

    calcFileName,
    dependencyColumn(100, updatedCurrentData),
    countColumn,
    statusColumn(theme),
    {
      field: "required_agent",
      headerName: "Required Agent",
      filterable: false,
      width: 120,
    },
    {
      field: "received_at",
      headerName: "Received_at",
      width: 150,
      filterable: false,
      renderCell: (params) => {
        const exactTime = params?.value
        return formatTimeBasedOnLocal(exactTime, {
          year: "numeric",
          ...commonDateFormat,
        })
      },
    },
    estimatesColumn,
    {
      field: "delay_multiplier",
      headerName: "Delay_multiplier",
      width: 80,
      filterable: false,
      ...headerAlign,
      renderCell: (params) => {
        const formattedValue = fixedToDecimal(params?.value)

        return params?.row?.delayed ? (
          <m.Chip
            size="small"
            color="error"
            label={
              <m.Typography variant="body2">{formattedValue}</m.Typography>
            }
          />
        ) : (
          formattedValue
        )
      },
    },
    durationsColumn,
    progressColumn,
    versionsColumn,
    refColumn,
    removeColumn("See Job", 80, updatedCurrentData, "job_id"),
  ]

  const todaysColumn = [
    jobIdColumn("job_id", 100),
    agentColumn,
    typeColumn(updatedRecentData),
    requestor,
    calcFileName,
    dependencyColumn(100, updatedRecentData),
    countColumn,
    statusColumn(theme),
    estimatesColumn,
    durationsColumn,
    {
      field: "queue_duration_s",
      headerName: "Queue_duration's",
      width: 100,
      renderCell: (params) => {
        const inSecondsValue = showComputedDuration(params.value)
        const inHHMMSSValue = convertSeconds(inSecondsValue)

        return params.value === null ? "" : <>{inHHMMSSValue}</>
      },
      filterable: false,
      ...headerAlign,
    },
    progressColumn,
    versionsColumn,
    refColumn,
    {
      field: "timing_accuracy",
      headerName: "Timing accuracy",
      width: 100,
      filterable: false,
      ...headerAlign,
      renderCell: (params) => {
        const formattedValue = fixedToDecimal(params?.value)
        return <m.Typography variant="body2">{formattedValue}</m.Typography>
      },
    },
    removeColumn("See Job", 80, updatedRecentData, "job_id"),
  ]

  const convertSeconds = (val) => {
    const seconds = Math.round(val % 60)
    const minutes = Math.floor((val % 3600) / 60) + (seconds > 30 ? 1 : 0)
    const hours = Math.floor(val / 3600) + (minutes > 30 ? 1 : 0)
    const lessThanSec = val < 1 && val > 0

    const timeVal = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`

    return lessThanSec ? "<1 sec" : formatTime(timeVal)
  }

  const showComputedDuration = (value) => {
    const timeinSeconds =
      typeof value === "string" ? value?.split(" ")[1] === "seconds" : ""
    const timeParts = typeof value === "string" ? value?.split(" ") : ""
    const timeValue = timeParts ? parseFloat(timeParts[0]).toFixed(2) : ""

    return timeinSeconds
      ? `${timeValue} ${timeParts[1]}`
      : typeof value === "number"
      ? value?.toFixed(2)
      : value
  }

  const currentRows = updatedCurrentData?.map(
    ({
      job_id,
      agent,
      type,
      requestor,
      calc_name,
      dependency_set,
      count,
      status,
      received_at,
      progress,
      delayed,
      required_agent,
      estimate_s,
      duration_s,
      delay_multiplier,
      version,
      ref,
    }) => ({
      job_id,
      agent,
      type,
      requestor,
      calc_name,
      dependency_set,
      count,
      status,
      received_at,
      progress,
      id: job_id,
      delayed,
      required_agent,
      estimate_s,
      duration_s,
      delay_multiplier,
      version,
      ref,
    })
  )

  const recentRows = updatedRecentData?.map(
    ({
      job_id,
      agent,
      type,
      requestor,
      calc_name,
      dependency_set,
      count,
      status,
      progress,
      duration_s,
      queue_duration_s,
      estimate_s,
      version,
      ref,
      timing_accuracy,
    }) => ({
      job_id,
      agent,
      type,
      requestor,
      calc_name,
      dependency_set,
      count,
      status,
      progress,
      id: job_id,
      duration_s,
      queue_duration_s,
      estimate_s,
      version,
      ref,
      timing_accuracy,
    })
  )

  const [columnWidths, setColumnWidths] = useState(
    columns.map((col) => ({ field: col.field, width: col?.width ?? "" }))
  )
  const [columnCurrentWidths, setColumnCurrentWidths] = useState(
    currentColumns.map((col) => ({
      field: col.field,
      width: col?.width ?? "",
    }))
  )
  const [columnRecentWidths, setColumnRecentWidths] = useState(
    todaysColumn.map((col) => ({
      field: col.field,
      width: col?.width ?? "",
    }))
  )
  const [expandedAccordions, setExpanedAccordions] = useState([jobQueue])

  const handleExpansion = (e, title) => {
    expandedAccordions?.includes(title)
      ? setExpanedAccordions(
          expandedAccordions?.filter((accords) => accords !== title)
        )
      : setExpanedAccordions((prev) => [...prev, title])
  }

  const showRemoveIcon = (email) =>
    mongoProfile?.email === email || mongoProfile?.useModes?.includes("admin")

  const handleColumnResize = (
    newColumnWidths,
    columnWidths,
    setColumnWidths
  ) => {
    setColumnWidths(
      columnWidths?.map((col) =>
        col.field === newColumnWidths.colDef.field
          ? newColumnWidths.colDef
          : col
      )
    )
  }

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRefreshPolling((prevTime) => ({
        ...prevTime,
        time: prevTime.time > 1 ? prevTime.time - 250 / 1000 : 10,
      }))
    }, 250)

    return () => clearInterval(timerInterval)
  }, [refreshPolling.pollingState, refreshPolling.currentPollingState])

  useEffect(() => {
    if (refreshPolling.pollingState || refreshPolling.currentPollingState) {
      const percentComplete = ((11 - refreshPolling.time) * 100) / 10
      setRefreshPolling((prev) => ({
        ...prev,
        progress: percentComplete,
      }))
    } else {
      setRefreshPolling((prev) => ({
        ...prev,
        progress: 0,
      }))
    }
  }, [refreshPolling.time])

  useEffect(() => {
    const fetchData = async () => {
      await fetchJobsData(
        getAgentQueue,
        setQueueDataState,
        "queuedData",
        setRefreshPolling,
        "pollingState",
        0,
        10,
        setQueuedLoader,
        emulatorId
      )
      await fetchJobsData(
        getCurrentJob,
        setQueueDataState,
        "currentJobData",
        setRefreshPolling,
        "currentPollingState",
        refreshPolling.progress,
        refreshPolling.time,
        setQueuedLoader,
        emulatorId
      )
      !gndata &&
        (await fetchJobsData(
          getRecentJob,
          setQueueDataState,
          "recentData",
          setRefreshPolling,
          "recentPollingState",
          refreshPolling.progress,
          refreshPolling.time,
          setQueuedLoader,
          emulatorId
        ))
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (refreshPolling.pollingState || refreshPolling.currentPollingState) {
      const pollingID = setInterval(async () => {
        refreshPolling.pollingState &&
          (await fetchJobsData(
            getAgentQueue,
            setQueueDataState,
            "queuedData",
            setRefreshPolling,
            "pollingState",
            0,
            10,
            setQueuedLoader,
            emulatorId
          ))
        await fetchJobsData(
          getCurrentJob,
          setQueueDataState,
          "currentJobData",
          setRefreshPolling,
          "currentPollingState",
          0,
          10,
          setQueuedLoader,
          emulatorId
        )
        if (
          !gndata &&
          (refreshPolling.currentPollingState || refreshPolling.pollingState)
        ) {
          await fetchJobsData(
            getRecentJob,
            setQueueDataState,
            "recentData",
            setRefreshPolling,
            "recentPollingState",
            0,
            10,
            setQueuedLoader,
            emulatorId
          )
        }
      }, POLLING_INTERVAL)

      return () => clearInterval(pollingID)
    }
  }, [refreshPolling.pollingState, refreshPolling.currentPollingState])

  const renderDeleteButton = (jobParams, jobId) => (
    <m.IconButton
      disabled={jobId === "job_id"}
      onClick={(e) => handleCancelJob(e, jobParams, jobId)}
    >
      <DeleteOutlineOutlinedIcon
        color={jobId === "job_id" ? "default" : "primary"}
      />
    </m.IconButton>
  )

  const handleCancelJob = async (e, jobParams, jobId) => {
    e.preventDefault()

    const jobIdToCancel = jobParams?.row[jobId]
    const cancelJobURL = `calc/job/${jobIdToCancel}/cancel`
    try {
      await EmulationLab.post(
        cancelJobURL,
        {},
        {
          headers: {
            severity: SeverityLevel.Error,
          },
        }
      )
      setAlertState({
        boolState: true,
        message: `Job removed successfully!`,
        severityState: "success",
      })
      setQueueDataState((prevState) => ({
        ...prevState,
        queuedData: prevState.queuedData?.filter(
          (queueData) => queueData[jobId] !== jobIdToCancel
        ),
        currentJobData: prevState.currentJobData?.filter(
          (currentData) => currentData[jobId] !== jobIdToCancel
        ),
      }))
    } catch (error) {
      alert(error.response.data.message)
      return null
    }
  }

  const renderMiliSeconds = (seconds) => {
    const roundedMs = Math.round(seconds * 1000)
    return `${roundedMs}ms`
  }

  const MyCustomNoRowsOverlay = (isQueue) => {
    return (
      <div className="ml-display-flex ml-justify-center ml-align-center ml-height">
        {returnMessage(isQueue)}
      </div>
    )
  }

  const returnDatGrid = (rows, columns, queue) => {
    const isQueue = queue === jobQueue
    const isCurrent = queue === currentJob
    return (
      <div
        className={
          width > 650
            ? "adminmuigridtable"
            : ` ${summary ? "" : "adminmuigridtable margin-0-10"}`
        }
        style={{
          maxWidth:
            width < 650 ? `${width - 60}px` : summary ? "1360px" : "1380px",
        }}
      >
        <StyledDataGrid
          rows={rows}
          getRowClassName={(params) =>
            isCurrent &&
            queueDataState.newlyAddedRows.includes(params.row.job_id)
              ? "highlight"
              : ""
          }
          columns={
            isQueue
              ? columns.map((col, index) => ({
                  ...col,
                  width: columnWidths[index]?.width ?? "",
                }))
              : isCurrent
              ? currentColumns.map((col, index) => ({
                  ...col,
                  width: columnCurrentWidths[index]?.width ?? "",
                }))
              : todaysColumn.map((col, index) => ({
                  ...col,
                  width: columnRecentWidths[index]?.width ?? "",
                }))
          }
          sx={boldTitleHeader}
          onColumnResize={(newColumnWidths) =>
            isQueue
              ? handleColumnResize(
                  newColumnWidths,
                  columnWidths,
                  setColumnWidths
                )
              : isCurrent
              ? handleColumnResize(
                  newColumnWidths,
                  columnCurrentWidths,
                  setColumnCurrentWidths
                )
              : handleColumnResize(
                  newColumnWidths,
                  columnRecentWidths,
                  setColumnRecentWidths
                )
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                remove: !summary,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          autoHeight
          pageSizeOptions={[10]}
          density="compact"
          disableRowSelectionOnClick
          hideFooter={summary}
          slots={{
            noRowsOverlay: () => MyCustomNoRowsOverlay(isQueue),
          }}
        />
      </div>
    )
  }

  return (
    <>
      {queuedLoader && !gndata && (
        <m.Grid item xs={10}>
          <Loader margin={"5%"} />
        </m.Grid>
      )}
      {!queuedLoader && (
        <m.Box className={summary ? "noclass" : "para-1 queuegrid"}>
          {summary &&
            (hasQueuedData ||
              (!updatedCurrentData?.length && !updatedRecentData?.length)) && (
              <>{returnDatGrid(initialRows, columns, jobQueue)}</>
            )}

          {!summary && (
            <RenderTableAccordion
              title={jobQueue}
              expandedAccordions={expandedAccordions}
              handleExpansion={handleExpansion}
              data={updatedQueuedData}
            >
              {returnDatGrid(initialRows, columns, jobQueue)}
            </RenderTableAccordion>
          )}
          {summary ? (
            updatedCurrentData?.length > 0 &&
            returnDatGrid(currentRows, currentColumns, currentJob)
          ) : (
            <RenderTableAccordion
              title={currentJob}
              expandedAccordions={expandedAccordions}
              handleExpansion={handleExpansion}
              data={updatedCurrentData}
            >
              {returnDatGrid(currentRows, currentColumns, currentJob)}
            </RenderTableAccordion>
          )}
          {summary
            ? updatedRecentData?.length > 0 &&
              returnDatGrid(recentRows, todaysColumn, recentJob)
            : !gndata && (
                <RenderTableAccordion
                  title={recentJob}
                  expandedAccordions={expandedAccordions}
                  handleExpansion={handleExpansion}
                  data={updatedRecentData}
                >
                  {returnDatGrid(recentRows, todaysColumn, recentJob)}
                </RenderTableAccordion>
              )}
        </m.Box>
      )}
    </>
  )
}

export default AgentQueueMain
