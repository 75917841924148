export const areValuesEqualWithTolerancePercent = (
  value,
  tolerance,
  [compareValue]
) =>
  Math.abs(parseFloat(value) - parseFloat(compareValue)) <=
  (parseFloat(tolerance) / 100) * parseFloat(value)

export const areValuesInBetween = (lower, upper, [value]) =>
  parseFloat(value) >= parseFloat(lower) &&
  parseFloat(value) <= parseFloat(upper)

export const isValueMoreThan = (value1, [value2]) => {
  return Number(value1) < Number(value2)
}

export const isValueLess = (value1, [value2]) => {
  return Number(value1) > Number(value2)
}

export const isValueExactMatch = (value1, [value2]) =>
  value1.toLowerCase() === value2.toLowerCase()

export const stringContains = (mainInput, [subInput]) =>
  mainInput.toLowerCase().includes(subInput.toLowerCase())

export const isValueMoreOrEqual = (value1, [value2]) => {
  return Number(value1) < Number(value2) || Number(value1) === Number(value2)
}

export const isValueLessOrEqual = (value1, [value2]) => {
  return Number(value1) > Number(value2) || Number(value1) === Number(value2)
}

export const isValueNotEqual = (value1, [value2]) => {
  return Number(value1) !== Number(value2)
}

export const isValueInSet = (value1, [value2]) => {
  return value1.includes(value2)
}
