import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import GradingIcon from "@mui/icons-material/Grading"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import "./CalculatorConfig.css"
import * as storedSatus from "../../../state/StatusState"
import { returnDisableTestingVal } from "../../sidebar/EmulatorSidebar"
import { configInpOut } from "../../../hooks/useCalculatorConfig"
import { getIconBasedOnStatus } from "../../testing-calculator/MainTest"
import useStyles from "../../card_style"
import { runDefaultTest } from "../CalculatorCard"
import { alertStates } from "../../../state/vizState"

function CalcFileButtons({ setOpenImageDialog, openFiles, openImageDialog }) {
  const { width } = file.useWindowDimensions()
  const theme = m.useTheme()
  const { inpVar, outVar } = configInpOut()
  const classes = useStyles()

  const fileManagerStyle = {
    color: theme.palette.common.white,
    margin: "4px",
    width: width > 480 ? "180px" : "",
  }

  const setAlertState = useSetRecoilState(alertStates)
  const emulatorConfig = useRecoilValue(storedSates.getEmulatorData)
  const emulatorStatusState = useRecoilValue(storedSates.emulatorStatus)
  const [defaultTestStatusState, setDefaultTestStatusState] = useRecoilState(
    storedSates.defaultTestStatus
  )
  const fileType = useRecoilValue(storedSates.dataSourceType)
  const estimateData = useRecoilValue(storedSates.estimatedTimeState)

  const noConfigs = inpVar === 0 || outVar === 0
  const isCalculateMode = emulatorConfig?.modes?.includes(
    storedSatus.emulatorMode.calculate
  )
  const disableDefaultTest = returnDisableTestingVal(
    noConfigs,
    isCalculateMode,
    emulatorConfig,
    true
  )
  const testRunning = ["running", "draft"].includes(
    defaultTestStatusState.status
  )

  const closeImageDialog = () => setOpenImageDialog(false)

  const hostedUploadArray = [
    storedSatus.emulatorSoftwareType.upload,
    storedSatus.emulatorSoftwareType.hosted,
  ]

  return (
    <div>
      <core.Grid
        className={`fileconfig ml-align-center ${
          width > 600 ? "" : "box-view"
        }`}
        id={width > 1100 ? "allbtndirection" : "no-id"}
        container
        spacing={0}
      >
        {width > 600 && (
          <core.Box id="btnvalidatewrap" className="ml-align-center">
            <file.Authorization processName="uploadEmFilesOrTrain">
              <core.Button
                color="primary"
                variant="contained"
                fullWidth
                margin="0"
                style={fileManagerStyle}
                onClick={() => setOpenImageDialog(true)}
              >
                <m.Typography
                  variant="caption"
                  margin="4px"
                  textTransform="upperCase"
                >
                  View Images
                </m.Typography>
              </core.Button>
            </file.Authorization>
            {!hostedUploadArray?.includes(fileType) && (
              <file.Authorization
                role={emulatorStatusState}
                forbidden={storedSatus.trainingDeployed}
                callFunction={() => openFiles()}
                processName="manageIO"
              >
                <core.Button
                  color="primary"
                  variant="contained"
                  margin="4px"
                  style={fileManagerStyle}
                >
                  <m.Typography variant="caption" margin="4px">
                    Calc File manager
                  </m.Typography>
                </core.Button>
              </file.Authorization>
            )}
            <file.UpdateStatus style="true" />
            <file.Authorization processName="manageIO">
              <m.Button
                variant="contained"
                margin="4px"
                className="ml-display-flex ml-align-center ml-flex-dir-row"
                style={{
                  ...fileManagerStyle,
                  width: width > 480 ? "190px" : "",
                }}
                disabled={disableDefaultTest || testRunning}
                color="primary"
                onClick={() =>
                  runDefaultTest(
                    emulatorConfig,
                    setAlertState,
                    setDefaultTestStatusState,
                    "single",
                    estimateData
                  )
                }
              >
                {testRunning ? (
                  <m.Box
                    sx={{
                      width: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {getIconBasedOnStatus("test", "running", false, classes)}
                  </m.Box>
                ) : (
                  <GradingIcon fontSize="small" />
                )}{" "}
                &nbsp;
                <m.Typography variant="caption">Run Default Test</m.Typography>
              </m.Button>
            </file.Authorization>
          </core.Box>
        )}
      </core.Grid>
      <file.GalleryDialogBox
        setOpenImageDialog={setOpenImageDialog}
        disable={false}
        openImageDialog={openImageDialog}
        setAssociatedStates={closeImageDialog}
      />
    </div>
  )
}

export default CalcFileButtons
