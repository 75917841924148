import { useEffect, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useParams } from "react-router-dom"
import * as core from "@material-ui/core"
import { Button, Chip, Typography, useTheme } from "@mui/material"
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled"
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined"
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined"
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import ErrorIcon from "@mui/icons-material/Error"
import Edit from "@mui/icons-material/Edit"
import {
  EmulationLab,
  MLCharts,
  MLIcons,
  MLSideComponent,
  MlSelectionCriteria,
  Loader,
  Rankhelp,
  Authorization,
  CommonerrorPageHandle,
  ErrorBoundary,
} from "../../GlobalFileContainer"
import {
  dataSet,
  emulatorStatus,
  getEmulatorData,
  globalDialogBox,
} from "../../state/projectState"
import "./MLEvaluation.css"
import { alertStates } from "../../state/vizState"
import {
  EMPTY_STATES,
  ALGO_STATUS,
  startPollingData,
  uploadEmFilesOrTrain,
} from "../../state/StatusState"
import useStyles from "../card_style"

const intervalIds = []
const LOADER_TYPES = { main: "main", fit: "fit" }

export const MLEvaluation = ({ fetched, setFetched, setTime }) => {
  let { emulatorId } = useParams()
  const theme = useTheme()
  const classes = useStyles()

  const SetConfigs = useSetRecoilState(getEmulatorData)
  const [emulatorStatusState, setEmulatorStatusState] =
    useRecoilState(emulatorStatus)
  const [globalDialogOpen, setGlobalDialogOpen] =
    useRecoilState(globalDialogBox)

  const [singleEmData, setSingleEmData] = useState([])
  const [loader, setLoader] = useState(false)
  const [mainLoader, setMainLoader] = useState(false)
  const training = singleEmData?.training ? singleEmData?.training : ""
  const [showReset, setShowReset] = useState(false)
  const [rank, setRank] = useState(-1)
  const [status, setStatus] = useState({})
  const [algoName, setAlgoName] = useState("")
  const [newkey, setKey] = useState("")
  const [disableButton, setDisableButton] = useState(false)
  const [selectCrit, setSelectCrit] = useState(false)

  const [showActive, setShowActive] = useState(false)
  const [pollingIntervalId, setPollingIntervalId] = useState(null)
  const [runSingleAlgorithm, setRunSingleAlgorithm] = useState({
    target: "",
    algorithms: [""],
  })
  const [showRerun, setShowRerun] = useState(false)
  const [openWarnDialog, setOpenWarnDialog] = useState(false)
  const [makeAlgoActive, setMakeAlgoActive] = useState(false)
  const [failCount, setFailCount] = useState({})
  const [expanded, setExpanded] = useState(false)

  const setEmulatorData = useSetRecoilState(dataSet)
  const setAlertState = useSetRecoilState(alertStates)

  const postPrepUrl = `engine/fit/${emulatorId}/prep`
  const postResetUrl = `engine/fit/${emulatorId}/reset`
  const preppostUrl = `engine/fit/${emulatorId}`
  const postUrl = `/engine/fit/${emulatorId}/pipelines/active`
  const url = `emulators/${emulatorId}`
  const fitPerAlgo = `/engine/fit/${emulatorId}?target=${runSingleAlgorithm.target}`

  useEffect(() => {
    CallData_polling(LOADER_TYPES.main)
  }, [])

  useEffect(() => {
    refreshStatus()
    setEmulatorStatusState(singleEmData.status)
    setEmulatorData(singleEmData?.data?.total_row_count)
    if (
      Object.values(status).some(
        (value) =>
          value === ALGO_STATUS.pending || value === ALGO_STATUS.running
      )
    ) {
      setFetched(true)
      setDisableButton(false)
    }
  }, [singleEmData])

  useEffect(() => {
    startPollingData(
      fetched,
      CallData_polling,
      intervalIds,
      setPollingIntervalId,
      setTime
    )
  }, [fetched])

  useEffect(() => {
    if (selectCrit) {
      CallData_polling()
      setSelectCrit(false)
    }
  }, [selectCrit])

  useEffect(() => {
    if (pollingIntervalId) {
      return () => clearInterval(pollingIntervalId)
    }
  }, [pollingIntervalId])

  const CallData_polling = async (loader) => {
    try {
      if (loader === LOADER_TYPES.main) {
        setMainLoader(true)
      }

      const result = await EmulationLab.get(url)
      const responseData = result.data

      setSingleEmData(responseData)
      setEmulatorStatusState(responseData.status)
      setEmulatorData(responseData?.data?.total_row_count)
      SetConfigs(responseData)

      if (loader === LOADER_TYPES.fit) {
        setLoader(false)
        setFetched(true)
        setAlertState({
          boolState: true,
          message: "Data fetched successfully",
          severityState: "success",
        })
      } else {
        setMainLoader(false)
      }
    } catch (error) {
      console.error(error)
      setFetched(false)

      if (loader === LOADER_TYPES.fit) {
        setLoader(false)
      } else {
        setMainLoader(false)
      }
    }
  }

  const openHelpImg = () => {
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: (
        <Rankhelp
          height={420}
          color="black"
          thickness={2}
          dashWidth={4}
          dashGap={4}
        />
      ),
      title: "How are my algorithms ranked?",
    }))
  }

  const handleSelectCrit = (
    e,
    key,
    size,
    speed,
    lComf,
    uComf,
    lBlunder,
    uBlunder
  ) => {
    e.stopPropagation()
    openWarnDialog && setOpenWarnDialog(false)
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: (
        <MlSelectionCriteria
          setGlobalDialogOpen={setGlobalDialogOpen}
          variableName={key}
          size={size}
          speed={speed}
          setSelectCrit={setSelectCrit}
          lComf={lComf}
          uComf={uComf}
          lBlunder={lBlunder}
          uBlunder={uBlunder}
          activeEmulator={singleEmData}
        />
      ),
      title: "Adjust Selection Criteria",
    }))
  }

  const handleSubmitInput = async (e) => {
    e.stopPropagation()
    if (makeAlgoActive) {
      setMakeAlgoActive(false)
    }

    if (openWarnDialog) {
      setOpenWarnDialog(false)
    }
    try {
      const postData = await EmulationLab.post(postUrl, newkey)
      if (postData.status === 200) {
        CallData_polling()
        setAlertState({
          boolState: true,
          message: "Data fetched successfully",
          severityState: "success",
        })
      }
    } catch (error) {
      alert("Data is not submitted..")
    }
  }

  const handleRerunAlgorithm = async (e) => {
    e.stopPropagation()
    setAlertState({
      boolState: true,
      message: "Initiated ML-fitting for selected algorithm",
      severityState: "info",
    })
    if (runSingleAlgorithm.target) {
      delete runSingleAlgorithm.target
    }
    try {
      const postData = await EmulationLab.post(fitPerAlgo, runSingleAlgorithm)
    } catch (error) {
      alert("Data is not submitted..")
    }
  }

  const handlefitForAll = async (e) => {
    try {
      setDisableButton(false)
      setShowReset(false)
      setLoader(true)
      e.preventDefault()

      EmulationLab.post(preppostUrl, {})
        .then(() => {
          setAlertState({
            boolState: true,
            message: "The Emulator training has been queued successfully!",
            severityState: "success",
          })
          !fetched && setFetched(true)
          !loader && setLoader(false)
        })
        .catch(() => {
          alert("Error in running training, Please try again!..")
          setLoader(false)
        })

      await new Promise((resolve) => setTimeout(resolve, 2000))
      CallData_polling(LOADER_TYPES.fit)
    } catch (error) {
      alert("Error in fetching data, Please try again!..")
      setLoader(false)
    }
  }

  const getEmulatorConfig = async () => {
    try {
      const res = await EmulationLab.get(url)
      const responseData = res.data
      SetConfigs(responseData)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePrep = async (e) => {
    e.preventDefault()
    setLoader(true)
    try {
      const res = await EmulationLab.post(postPrepUrl, {})
      if (res.status == 200) {
        getEmulatorConfig()
        setSingleEmData(res.data)
        setShowReset(false)
        setAlertState({
          boolState: true,
          message: "Data fetched successfully",
          severityState: "success",
        })
        setDisableButton(true)
        setLoader(false)
      }
    } catch (error) {
      alert("error in fetching data..")
      setLoader(false)
    }
  }

  const handleReset = async (e) => {
    e.preventDefault()
    setLoader(true)
    try {
      const res = await EmulationLab.post(postResetUrl, {})
      if (res.status == 200) {
        setSingleEmData(res.data)
        setShowReset(false)
        setAlertState({
          boolState: true,
          message: "Data fetched successfully",
          severityState: "success",
        })
        setDisableButton(true)
        setLoader(false)
        setTime(10)
        setFetched(false)
        return () => clearInterval(pollingIntervalId)
      }
    } catch (error) {
      alert("error in fetching data..")
      setLoader(false)
    }
  }

  const handleRankClick = (e, i) => {
    e.stopPropagation()
    setRank(rank === i ? -1 : i)
    openHelpImg()
  }

  const refreshStatus = () => {
    const tempStatus = {}
    const failedCount = {}
    let isReady = true

    singleEmData?.training &&
      Object.keys(singleEmData.training).forEach((key) => {
        const algorithms = singleEmData.training[key].algorithms
        const executionStatuses = Object.values(algorithms).map(
          (algo) => algo.execution_status
        )

        if (executionStatuses.every((status) => status === ALGO_STATUS.ready)) {
          tempStatus[key] = ALGO_STATUS.ready
        } else if (executionStatuses.includes(ALGO_STATUS.running)) {
          tempStatus[key] = ALGO_STATUS.running
          setFetched(true)
          isReady = false
        } else if (executionStatuses.includes(ALGO_STATUS.pending)) {
          tempStatus[key] = ALGO_STATUS.pending
          setFetched(true)
          isReady = false
        } else if (executionStatuses.includes(ALGO_STATUS.done)) {
          tempStatus[key] = ALGO_STATUS.done
        }

        const failedStatusCount = executionStatuses.filter(
          (status) => status === ALGO_STATUS.failed
        ).length

        if (failedStatusCount > 0) {
          failedCount[key] = failedStatusCount
        }
      })

    if (isReady) {
      setFetched(false)
    }

    if (
      Object.values(tempStatus).every(
        (v) => v === ALGO_STATUS.done || v === ALGO_STATUS.failed
      )
    ) {
      setShowReset(true)
    }

    setStatus(tempStatus)
    setFailCount(failedCount)
  }

  // const zeroPad = (num) => {
  //   var str = String(num)
  //   if (str.length < 2) {
  //     return "0" + str
  //   }
  //   return str
  // }

  const totalTimeString = (timeStrings) => {
    const initialTime = { hours: 0, minutes: 0, seconds: 0 }

    if (!timeStrings) return formatTime(initialTime)

    const totals = timeStrings.reduce((accumulator, timeString) => {
      const parts = timeString.split(":")

      if (parts.length > 0) {
        accumulator.seconds += Number(parts.pop())

        if (parts.length > 0) {
          accumulator.minutes +=
            Number(parts.pop()) + Math.floor(accumulator.seconds / 60)
          accumulator.seconds %= 60

          if (parts.length > 0) {
            accumulator.hours +=
              Number(parts.pop()) + Math.floor(accumulator.minutes / 60)
            accumulator.minutes %= 60

            if (parts.length > 0) {
              accumulator.hours += Number(parts.pop())
            }
          }
        }
      }

      return accumulator
    }, initialTime)

    return formatTime(totals)
  }

  const formatTime = (time) => {
    const { hours, minutes, seconds } = time
    return [zeroPad(hours), zeroPad(minutes), zeroPad(seconds)].join(":")
  }

  const zeroPad = (value) => {
    return value.toString().padStart(2, "0")
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (!globalDialogOpen.isOpen) {
      setExpanded(isExpanded ? panel : false)
    }
  }

  const renderTraining = () => {
    return (
      <core.Box>
        <core.Box margin={"5px"}>{loader && <Loader margin="0" />}</core.Box>
        {singleEmData?.training && (
          <core.Box>
            {Object.keys(singleEmData?.training)?.map((key, index) => {
              const i = index
              const newItem = key
              const outputVariables =
                singleEmData.calculator.OutputVariables[index]
              const variableName =
                outputVariables?.ColumnLabel === key && outputVariables?.Name
              const trainingObject = singleEmData?.training[key]
              const formatedAlgo = trainingObject?.algorithms
              const active_algorithm = trainingObject.active_algorithm
              const speed = trainingObject?.evaluation_settings?.max_query_time
              const size = trainingObject?.evaluation_settings?.max_file_size
              const lComf =
                trainingObject?.evaluation_settings?.comfort_lower_bound
              const uComf =
                trainingObject.evaluation_settings?.comfort_upper_bound
              const lBlunder =
                trainingObject.evaluation_settings?.severe_lower_bound
              const uBlunder =
                trainingObject.evaluation_settings?.severe_upper_bound
              const value =
                formatedAlgo &&
                Object.values(formatedAlgo).map((v) => v.execution_status)
              const time =
                trainingObject.algorithms &&
                Object.values(formatedAlgo).map((v) =>
                  v.execution_status === ALGO_STATUS.done
                    ? v.training_time
                    : "0:00:00"
                )

              const totalTime = time && totalTimeString(time)
              let executedLength = {}
              const totalMin = +totalTime?.split(":")[1]
              const totalSec =
                +totalTime?.split(":")[2] > 30 ? totalMin + 1 : totalMin
              value &&
                value.forEach(function (i) {
                  executedLength[i] = (executedLength[i] || 0) + 1
                })
              const reRunButton = status[key] === ALGO_STATUS.ready

              const handleSingleFit = async (e) => {
                setLoader(true)
                setDisableButton(false)
                e.preventDefault()
                const Api = `engine/fit/${emulatorId}?target=${newItem}`
                EmulationLab.post(Api, {})
                  .then(() => {
                    setAlertState({
                      boolState: true,
                      message: "Data fetched successfully",
                      severityState: "success",
                    })
                    !fetched && setFetched(true)
                  })
                  .catch((error) => {
                    alert("error in fetching data..")
                  })
                await new Promise((resolve) => setTimeout(resolve, 2000))
                CallData_polling(LOADER_TYPES.fit)
              }

              const summaryDetails = () => {
                return (
                  <>
                    <div className="eval-main-grid-container margin-left-20 padding-top-5">
                      <core.Box
                        className={`ml-display-flex ml-flex-dir-col ml-p-5 ${
                          expanded === index ? "depth-view" : ""
                        }`}
                      >
                        <core.Box>
                          <core.Typography variant="h6" color="primary">
                            {variableName}
                          </core.Typography>
                        </core.Box>
                        <core.Box className="ml-display-flex ml-flex-dir-row ml-p-5 ml-space-between ml-align-center">
                          <core.Box
                            className={
                              expanded === index &&
                              "ml-justify-start ml-display-flex ml-flex-dir-col"
                            }
                          >
                            {status[key] === ALGO_STATUS.done && (
                              <core.Box
                                className="ml-status ml-display-flex ml-p-5 status-done"
                                color={theme.palette.status.success}
                              >
                                <CheckCircleOutlineOutlinedIcon
                                  color="success"
                                  fontSize="small"
                                />
                                &nbsp; Done
                                {failCount[key] && (
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    className="ml-failure-chip"
                                  >
                                    <Chip
                                      size="small"
                                      color="error"
                                      icon={
                                        <ErrorIcon
                                          fontSize="small"
                                          className={classes.colorWhiteIcon}
                                        />
                                      }
                                      label={
                                        <Typography
                                          variant="body2"
                                          className={classes.colorWhiteIcon}
                                        >
                                          {failCount[key]}
                                        </Typography>
                                      }
                                    />
                                  </Typography>
                                )}
                              </core.Box>
                            )}
                            {reRunButton && (
                              <Authorization processName={uploadEmFilesOrTrain}>
                                <Button
                                  id="status-fitting"
                                  style={{ color: theme.palette.primary.main }}
                                  onClick={handleSingleFit}
                                  className="ml-status ml-display-flex"
                                >
                                  <PlayCircleFilledIcon color="primary" />
                                  &nbsp;
                                  <Typography variant="caption">
                                    Initiate ML Fitting
                                  </Typography>
                                </Button>
                              </Authorization>
                            )}
                            {status[key] === ALGO_STATUS.pending && (
                              <core.Box
                                color={theme.palette.grey.main}
                                className="ml-status ml-display-flex ml-align-center ml-p-5 status-pending"
                              >
                                <AdjustOutlinedIcon
                                  color={theme.palette.grey.main}
                                />
                                &nbsp;
                                <Typography variant="body1">Pending</Typography>
                              </core.Box>
                            )}
                            {status[key] === ALGO_STATUS.failed && (
                              <core.Box
                                color="error"
                                className="ml-status ml-display-flex ml-align-center ml-p-5 status-error"
                              >
                                <CancelOutlinedIcon
                                  color="error"
                                  fontSize="small"
                                />
                                &nbsp;
                                <Typography variant="body1">Error</Typography>
                              </core.Box>
                            )}
                            {status[key] === ALGO_STATUS.running && (
                              <core.Box
                                color={theme.palette.primary.main}
                                className=" ml-status ml-display-flex ml-align-center ml-p-5 status-running"
                              >
                                <DonutLargeOutlinedIcon
                                  className={`${classes.colorPrimaryMain} mliconrunning`}
                                  fontSize="small"
                                />
                                &nbsp;
                                <Typography variant="body1">Running</Typography>
                              </core.Box>
                            )}
                            <core.Box>
                              <core.Typography
                                variant="body2"
                                className="ed-small"
                              >{`${
                                totalTime.split(":")[1] === "00"
                                  ? "0"
                                  : totalSec
                              }min`}</core.Typography>
                              <core.Typography
                                variant="body2"
                                style={{ marginLeft: "25px" }}
                                className="ed-small"
                                color={theme.palette.grey.main}
                              >
                                {executedLength
                                  ? `${executedLength.done || 0}/ ${
                                      value.length
                                    }`
                                  : 0}
                              </core.Typography>
                            </core.Box>
                          </core.Box>
                          <core.Box className="ml-justify-start ml-display-flex ml-flex-dir-col">
                            <core.Typography
                              variant="body2"
                              className="ed-small"
                            >
                              {training[key] && training[key].estimator_type}
                            </core.Typography>
                            {singleEmData?.calculator.OutputVariables?.map(
                              (key2, i) => {
                                return (
                                  key2["ColumnLabel"] === key && (
                                    <core.Typography
                                      variant="body2"
                                      className="ed-small"
                                      key={key2 + i}
                                    >
                                      {`${
                                        training[key] &&
                                        training[key]?.range_min_value
                                          ? (!isNaN(
                                              +training[key]?.range_min_value
                                            ) &&
                                              training[
                                                key
                                              ].range_min_value.toFixed(2)) ||
                                            0
                                          : 0
                                      } ${
                                        (key2["Units"] !== "none" &&
                                          key2["Units"]) ||
                                        ""
                                      } to ${
                                        training[key] &&
                                        training[key].range_max_value
                                          ? (!isNaN(
                                              +training[key].range_max_value
                                            ) &&
                                              training[
                                                key
                                              ].range_max_value.toFixed(2)) ||
                                            0
                                          : 0
                                      } ${
                                        (key2["Units"] !== "none" &&
                                          key2["Units"]) ||
                                        ""
                                      }`}
                                    </core.Typography>
                                  )
                                )
                              }
                            )}
                            &nbsp; &nbsp;
                          </core.Box>
                        </core.Box>
                      </core.Box>
                      <core.Box>
                        <core.Box className="ml-display-flex">
                          <core.Box
                            className={`ml-display-flex ml-flex-dir-col ml-p-5 ${
                              expanded === index ? "depth-view" : "box-view"
                            }`}
                          >
                            <core.Box
                              className={
                                expanded === index &&
                                "ml-justify-start ml-display-flex ml-flex-dir-col"
                              }
                            >
                              <core.Typography variant="body1" id="eval-p1">
                                Execution
                              </core.Typography>
                              <core.Typography variant="body1" id="eval-p1">
                                Rank &nbsp;
                                <core.IconButton
                                  size="small"
                                  onClick={(e) => handleRankClick(e, index)}
                                >
                                  <HelpOutlineIcon
                                    sx={{
                                      position: "absolute",
                                      marginLeft: "5px",
                                      marginBottom: "10px",
                                      fontSize: 16,
                                    }}
                                    color="primary"
                                  />
                                </core.IconButton>
                              </core.Typography>
                              <core.Typography variant="body1" id="eval-p1">
                                {expanded !== index ? "Note" : "Reviewer Note"}
                              </core.Typography>
                              {expanded === index && (
                                <core.Box className="ml-display-flex ml-align-center ml-flex-dir-row">
                                  <core.Box>
                                    <core.Typography
                                      variant="body1"
                                      id="eval-p1"
                                    >
                                      {"< "}
                                      {size}
                                    </core.Typography>
                                    <core.Typography
                                      variant="body1"
                                      id="eval-p1"
                                    >
                                      {"< "}
                                      {speed}
                                    </core.Typography>
                                  </core.Box>
                                  {!EMPTY_STATES.includes(active_algorithm) && (
                                    <Authorization
                                      role={emulatorStatusState}
                                      forbidden={["deployed"]}
                                      callFunction={(e) =>
                                        handleSelectCrit(
                                          e,
                                          key,
                                          size,
                                          speed,
                                          lComf,
                                          uComf,
                                          lBlunder,
                                          uBlunder
                                        )
                                      }
                                      processName={uploadEmFilesOrTrain}
                                    >
                                      <core.IconButton>
                                        <Edit color="primary" />
                                      </core.IconButton>
                                    </Authorization>
                                  )}
                                </core.Box>
                              )}
                            </core.Box>
                          </core.Box>
                          {formatedAlgo &&
                            Object.keys(formatedAlgo)?.map((key1, index) => {
                              const sizeforPipeline =
                                trainingObject.algorithms[
                                  key1
                                ]?.pipeline_size?.split(" ")[0] / 1000 || 0
                              const timeForpipeline =
                                trainingObject.algorithms[
                                  key1
                                ]?.training_time?.split(":")[2] * 1000 || 0

                              return (
                                <core.Box
                                  className="ml-display-flex"
                                  key={index}
                                >
                                  <ErrorBoundary>
                                    <MLIcons
                                      modifiedName={key1}
                                      show={expanded}
                                      key={key}
                                      trainingObject={trainingObject}
                                      size={size}
                                      sizeforPipeline={sizeforPipeline}
                                      speed={speed}
                                      timeForpipeline={timeForpipeline}
                                      i={i}
                                      algoName={algoName}
                                      setAlgoName={setAlgoName}
                                      setShowActive={setShowActive}
                                      setKey={setKey}
                                      setRunSingleAlgorithm={
                                        setRunSingleAlgorithm
                                      }
                                      runSingleAlgorithm={runSingleAlgorithm}
                                      setShowRerun={setShowRerun}
                                      reRunButton={reRunButton}
                                    />
                                  </ErrorBoundary>
                                </core.Box>
                              )
                            })}
                          <core.Box className="ml-display-flex ml-flex-dir-col ml-p-5 dv_m_3">
                            <ErrorBoundary sideComp>
                              <core.Box
                                className={
                                  expanded !== index && "min-width-active-algo"
                                }
                              >
                                <MLSideComponent
                                  show={expanded}
                                  index={index}
                                  showActive={showActive}
                                  handleSubmitInput={
                                    newkey !== "" && handleSubmitInput
                                  }
                                  handleSelectCrit={(e) =>
                                    handleSelectCrit(
                                      e,
                                      key,
                                      size,
                                      speed,
                                      lComf,
                                      uComf,
                                      lBlunder,
                                      uBlunder
                                    )
                                  }
                                  trainingKey={singleEmData?.training[key]}
                                  active_algorithm={active_algorithm}
                                  algoName={algoName}
                                  showRerun={showRerun}
                                  handleRerunAlgorithm={handleRerunAlgorithm}
                                  reRunButton={reRunButton}
                                />
                              </core.Box>
                            </ErrorBoundary>
                          </core.Box>
                        </core.Box>
                      </core.Box>
                    </div>
                  </>
                )
              }

              return (
                <core.Accordion
                  key={index}
                  expanded={expanded === index}
                  onChange={handleAccordionChange(index)}
                >
                  <core.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {summaryDetails()}
                  </core.AccordionSummary>
                  <core.AccordionDetails>
                    {expanded === index &&
                      !EMPTY_STATES.includes(active_algorithm) && (
                        <ErrorBoundary>
                          <core.Box className="margin-top-20 ml-justify-start ml-display-flex">
                            <MLCharts item={newItem} algoName={algoName} />
                          </core.Box>
                        </ErrorBoundary>
                      )}
                  </core.AccordionDetails>
                </core.Accordion>
              )
            })}
          </core.Box>
        )}
      </core.Box>
    )
  }

  const renderButton = () => {
    return (
      <core.Box>
        <core.Box>
          {!showReset && (
            <core.Button
              disabled={!disableButton}
              id="mlfittingbutton"
              onClick={singleEmData?.training ? handlefitForAll : handlePrep}
            >
              {singleEmData?.training && disableButton && (
                <>
                  <PlayCircleOutlineIcon color="primary" />
                  &nbsp;
                  <Typography variant="caption">
                    Initiate ML Fitting for All Output
                  </Typography>
                </>
              )}
              {singleEmData?.training && !disableButton && (
                <Typography variant="caption">
                  Emulator Training has been queued
                </Typography>
              )}
            </core.Button>
          )}
          {singleEmData?.training ? (
            showReset && (
              <core.Button id="mlfittingbutton" onClick={(e) => handleReset(e)}>
                <RestartAltIcon /> &nbsp;
                <Typography variant="caption">Reset</Typography>
              </core.Button>
            )
          ) : (
            <core.Button id="mlfittingbutton" onClick={(e) => handlePrep(e)}>
              <PlaylistAddCheckIcon color="primary" /> &nbsp;
              <Typography variant="caption">
                Prepare Emulator For Training
              </Typography>
            </core.Button>
          )}
        </core.Box>
        {singleEmData?.training && !showReset && (
          <core.Box>
            <core.Button id="mlfittingbutton" onClick={(e) => handleReset(e)}>
              <RestartAltIcon /> &nbsp;
              <Typography variant="caption">Reset</Typography>
            </core.Button>
          </core.Box>
        )}
      </core.Box>
    )
  }

  const renderName = () => {
    return (
      <core.Box>
        <core.Box>
          <core.Typography variant="h5" className={classes.colorGraySec}>
            {singleEmData?.name} | {singleEmData?.status} <br />
            <core.Box id="txt-d" className="ml-display-flex">
              <core.Typography variant="body2" className="p-p1">
                {`${singleEmData?.data?.total_row_count} Datapoints`} &nbsp;
              </core.Typography>
            </core.Box>
          </core.Typography>
        </core.Box>
      </core.Box>
    )
  }

  return (
    <core.Box>
      {mainLoader && <Loader />}
      {!mainLoader && (
        <CommonerrorPageHandle title="ML Evaluation">
          <core.Box className="ml-p-5" style={{ marginTop: "2%" }}>
            <core.Box className="ml-space-between ml-flex-dir-row ml-display-flex">
              {renderName()}
              <Authorization processName={uploadEmFilesOrTrain}>
                {renderButton()}
              </Authorization>
            </core.Box>
            {singleEmData?.training && renderTraining()}
          </core.Box>
        </CommonerrorPageHandle>
      )}
    </core.Box>
  )
}
