import React from "react"
import * as m from "@mui/material"

const CommonHeader = ({ children, title, subText }) => {
  const capabilityHealthArray = ["Agent Capability", "Agent Health"]
  const addBottomBorder = capabilityHealthArray?.includes(title)
  return (
    <m.Box
      sx={{
        borderBottom: addBottomBorder && 1,
        borderColor: addBottomBorder && "divider",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: addBottomBorder && "60px",
        marginBottom: addBottomBorder && "20px",
      }}
    >
      <m.Typography
        className="member-manage-title ml-display-flex ml-align-center"
        variant="h5"
      >
        {title}&nbsp;{subText}
      </m.Typography>
      {children}
    </m.Box>
  )
}

export default CommonHeader
