import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import * as core from "@material-ui/core"
import { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import { useParams } from "react-router-dom"
import "./CalculatorConfig.css"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { title } from "../pageHelpersConfig"
import { Typography } from "@mui/material"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"

export const EvaluateTooltip = styled(({ className, ...props }) => (
  <core.Tooltip
    {...props}
    placement="top-start"
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(13),
    border: "1px solid #dadde9",
  },
}))

export const columnNotMatch = () => (
  <EvaluateTooltip title="Column Label Mismatch! The defined column label are not matching with the column labels defined in manage data files.">
    <CancelIcon color="error" fontSize="small" />
  </EvaluateTooltip>
)
export const downgradeStatusandAddDelVar = async (
  emulatorId,
  handleDelete,
  index,
  hasModel
) => {
  try {
    await storedSates.updateTheStatus(emulatorId, "preparing")
    handleDelete(index, hasModel)
  } catch (err) {
    console.error(err)
  }
}

export default function InputVariableCards({
  updateVariable,
  setIndex,
  setEmulatorStatusState,
  dataColumnLabels,
  setDeleteInSuccess,
  setVariableAction,
}) {
  let { emulatorId } = useParams()
  const Software = useRecoilValue(storedSates.dataSourceType)
  const setUpdateData = useSetRecoilState(storedSates.addData)
  const emulatorStatusState = useRecoilValue(storedSates.emulatorStatus)
  const selectedVersions = useRecoilValue(storedSates.selectedVersion)
  const versName = useRecoilValue(storedSates.versionName)
  const betaVers = useRecoilValue(storedSates.getBeta)
  const [emData, setEmData] = useRecoilState(storedSates.getEmulatorData)
  const releaseWithIo = useRecoilValue(storedSates.releaseIOState)

  const inputVars =
    selectedVersions === betaVers &&
    versName === storedSatus.breadCrumbsStrings.beta
      ? emData?.calculator?.InputVariables
      : Object.keys(releaseWithIo)?.length > 0
      ? releaseWithIo.InputVariables
      : emData?.calculator?.InputVariables

  const dataVarSummary = emData?.data?.variables["summary"]

  const deleteData = async (index) => {
    const url = `/emulators/${emulatorId}/variables/input/${index}`
    try {
      const removeData = await file.EmulationLab.delete(url, {
        headers: {
          severity: SeverityLevel.Error,
        },
      })
      if (removeData.status === 200) {
        setVariableAction("deleted")
        setEmulatorStatusState(storedSatus.allEmulatorStatus.preparing)
        setDeleteInSuccess(true)
        if (emData?.calculator?.InputVariables?.length > 0) {
          const temp = JSON.parse(JSON.stringify(emData))
          temp.calculator.InputVariables.splice(index, 1)
          setEmData(temp)
        }
      }
    } catch (error) {
      alert("Variable is not deleted , please try again..")
    }
  }

  const handleRemoveInput = (index) => {
    deleteData(index)
  }

  const handleDelete = (index) => {
    setDeleteInSuccess(false)
    handleRemoveInput(index)
  }

  const editButtonFunction = (index) => {
    updateVariable()
    setIndex(index)
    setUpdateData(false)
  }

  const makeRndomNumber = (number) => {
    const integerPart = Math.floor(number)
    const decimalPart = number - integerPart

    return decimalPart > 0.9
      ? integerPart + 1
      : integerPart + Math.ceil(decimalPart * 10) / 10
  }

  const makeRndomNumber2 = (number) => {
    const integerPart = Math.floor(number)
    const decimalPart = number - integerPart

    return integerPart + Math.floor(decimalPart * 10) / 10
  }

  const validIcon = (data, label) => {
    const startData = data?.Domain?.Start
    const endData = data?.Domain?.End

    const start = parseFloat(startData?.trim().split(" ")[0]) || 0
    const end = parseFloat(endData?.trim().split(" ")[0]) || 0

    const { Min: minValue, Max: maxValue } = dataVarSummary[label] || {}

    const min = makeRndomNumber2(minValue)
    const max = makeRndomNumber(maxValue)

    let validRange

    if (
      minValue <= start &&
      start <= maxValue &&
      maxValue >= end &&
      end >= start
    ) {
      validRange = "good"
    } else if (min <= start && start <= max && max >= end && end >= start) {
      validRange = "Roundedgood"
    } else if (min > start && max >= end) {
      validRange = "avrg"
    } else {
      validRange = "error"
    }

    const isRangeGood = validRange === "good"
    const isRangeRounded = validRange === "Roundedgood"
    const isRnageAvrg = validRange === "avrg"

    return (
      <>
        {dataVarSummary &&
          dataVarSummary[label]?.domain_or_range_type === "continuous" &&
          (isRangeGood || isRangeRounded || isRnageAvrg) && (
            <EvaluateTooltip
              title={
                isRangeGood
                  ? title().good
                  : isRangeRounded
                  ? title(
                      `(${startData} to ${endData})`,
                      `(${minValue} to ${maxValue})`
                    ).round
                  : title().avg
              }
            >
              {isRangeRounded ? (
                <CheckCircleOutlinedIcon color={"secondary"} fontSize="small" />
              ) : (
                <CheckCircleIcon
                  color={isRangeGood ? "success" : "secondary"}
                  fontSize="small"
                />
              )}
            </EvaluateTooltip>
          )}
        {dataVarSummary &&
          dataVarSummary[label]?.domain_or_range_type === "continuous" &&
          validRange === "error" && (
            <EvaluateTooltip title={title().err}>
              <CancelIcon color="error" fontSize="small" />
            </EvaluateTooltip>
          )}
        {dataVarSummary &&
          dataVarSummary[label]?.domain_or_range_type === "discrete" && (
            <EvaluateTooltip title={title().discr}>
              <CheckCircleIcon
                color={isRangeGood ? "success" : "secondary"}
                fontSize="small"
              />
            </EvaluateTooltip>
          )}
      </>
    )
  }

  const discreetValidate = (op, label, type) => {
    const modifiedArray = [...op]
    const uniqueArr = Array.from(new Set(modifiedArray))
    const updateArray = dataVarSummary && dataVarSummary[label]
    const sumryOptions = Object.keys(updateArray || [])
    const filteredArr =
      sumryOptions &&
      sumryOptions.filter(
        (key) => key !== "sensed_type" && key !== "domain_or_range_type"
      )
    const sortedArr1 = uniqueArr?.sort()
    const sortedArr2 = filteredArr && filteredArr?.sort()
    const isEqual = JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)

    const range =
      (isEqual && "good") ||
      (uniqueArr?.length <= filteredArr?.length && "avrg") ||
      (uniqueArr?.length > filteredArr?.length && "error")

    return (
      <>
        {dataVarSummary &&
          dataVarSummary[label]?.domain_or_range_type === "discrete" &&
          range === "good" && (
            <EvaluateTooltip title={title().good}>
              <CheckCircleIcon color="success" fontSize="small" />
            </EvaluateTooltip>
          )}
        {dataVarSummary &&
          dataVarSummary[label]?.domain_or_range_type === "discrete" &&
          range === "avrg" && (
            <EvaluateTooltip title={title().avg}>
              <CheckCircleIcon color="secondary" fontSize="small" />
            </EvaluateTooltip>
          )}
        {dataVarSummary &&
          dataVarSummary[label]?.domain_or_range_type === "discrete" &&
          range === "error" && (
            <EvaluateTooltip title={title().err}>
              <CancelIcon color="error" fontSize="small" />
            </EvaluateTooltip>
          )}
        {dataVarSummary &&
          dataVarSummary[label]?.domain_or_range_type === "continuous" && (
            <EvaluateTooltip title={title().discr}>
              <CheckCircleIcon color="secondary" fontSize="small" />
            </EvaluateTooltip>
          )}
      </>
    )
  }

  return (
    <>
      {inputVars?.map((data, index) => (
        <div key={`inputs_${data}_${index}`}>
          <file.VariableCardsLayout
            variable={data}
            version={selectedVersions === betaVers}
            editButtonFunction={() => editButtonFunction(index)}
            deleteButtonFunction={() =>
              emulatorStatusState === storedSatus.allEmulatorStatus.training
                ? downgradeStatusandAddDelVar(emulatorId, handleDelete, index)
                : handleDelete(index)
            }
          >
            <file.VariableInfoLine
              variable={data}
              varKey={"Nickname"}
              require={true}
            >
              <Typography variant="body1">{data?.NickName}</Typography>
            </file.VariableInfoLine>
            <file.VariableInfoLine
              variable={data}
              varKey={"Column Label"}
              require={true}
            >
              <core.Box className="ml-display-flex ml-flex-dir-row ml-align-center">
                {dataColumnLabels?.length > 0 &&
                  !dataColumnLabels?.includes(data?.ColumnLabel) && (
                    <core.Box className="domainicon">
                      <Typography variant="body1">
                        {columnNotMatch()}
                      </Typography>
                    </core.Box>
                  )}
                <core.Box className="ml-display-flex ml-align-center">
                  <Typography variant="body1">{data?.ColumnLabel}</Typography>
                </core.Box>
              </core.Box>
            </file.VariableInfoLine>
            {Software === storedSatus.emulatorSoftwareType.python && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Python Variable Name"}
                require={true}
              >
                <Typography variant="body1">
                  {data?.PythonVariableName}
                </Typography>
              </file.VariableInfoLine>
            )}
            {Software === storedSatus.emulatorSoftwareType.excel && (
              <>
                <file.VariableInfoLine
                  variable={data}
                  varKey={"Worksheet"}
                  require={false}
                />
                <file.VariableInfoLine
                  variable={data}
                  varKey={"Cell"}
                  require={false}
                />
              </>
            )}
            {Software === storedSatus.emulatorSoftwareType.hosted && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Api Label"}
                require={false}
              >
                <Typography variant="body1">{data?.ApiLabel}</Typography>
              </file.VariableInfoLine>
            )}
            {Software === storedSatus.emulatorSoftwareType.mathcad && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Alias"}
                require={false}
              />
            )}
            {Software === storedSatus.emulatorSoftwareType.grasshopper && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Param Name"}
                require={false}
              >
                <Typography variant="body1">{data?.ParamName}</Typography>
              </file.VariableInfoLine>
            )}
            <file.VariableInfoLine
              variable={data}
              varKey={"Type"}
              require={false}
            >
              <Typography variant="body1">
                {data.Type ? data.Type : ""}
                {data?.Units && data.Type == "quantity" && (
                  <>({`${data.Units}`})</>
                )}
              </Typography>
            </file.VariableInfoLine>
            {data.Domain?.Type === "continuous" ? (
              <file.VariableInfoLine
                variable={data}
                varKey={"Domain"}
                require={true}
              >
                <core.Box className="ml-display-flex ml-flex-dir-row ml-align-center">
                  {dataVarSummary && (
                    <core.Box className="domainicon">
                      <Typography variant="body1">
                        {validIcon(data, data.ColumnLabel)}
                      </Typography>
                    </core.Box>
                  )}
                  <core.Box className="ml-display-flex ml-align-center">
                    <Typography variant="body1">{`${data?.Domain?.Start} to ${data?.Domain?.End}`}</Typography>
                  </core.Box>
                </core.Box>
              </file.VariableInfoLine>
            ) : (
              <file.VariableInfoLine
                variable={data}
                varKey={"Domain"}
                require={true}
              >
                <core.Box className="ml-display-flex ml-flex-dir-row ml-align-center">
                  {dataVarSummary && (
                    <core.Box className="domainicon">
                      <Typography variant="body1">
                        {discreetValidate(
                          data?.Domain?.Options,
                          data.ColumnLabel,
                          data.Domain?.Type
                        )}
                      </Typography>
                    </core.Box>
                  )}
                  <core.Box className="ml-display-flex ml-align-center">
                    <Typography variant="body1">{`${
                      data?.Domain?.Options?.join(", ") || ""
                    }`}</Typography>
                  </core.Box>
                </core.Box>
              </file.VariableInfoLine>
            )}
            {data.DefaultValue !== undefined && data.DefaultValue !== null && (
              <file.VariableInfoLine
                variable={data}
                varKey={"Default Value"}
                require={false}
              >
                <Typography variant="body1">{data.DefaultValue}</Typography>
              </file.VariableInfoLine>
            )}
          </file.VariableCardsLayout>
        </div>
      ))}
    </>
  )
}
