import { useEffect, useState } from "react"
import * as core from "@material-ui/core"
import { Loader, useWindowDimensions } from "../../../GlobalFileContainer"
import { RiFileSearchLine } from "react-icons/ri"
import { FaCalculator } from "react-icons/fa"
import { SlChemistry } from "react-icons/sl"
import { VscJson } from "react-icons/vsc"
import {
  getEmulatorData,
  jobResponsesState,
  jsonDialogOpenClose,
} from "../../../state/projectState"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { emulatorMode } from "../../../state/StatusState"
import useStyles from "../../card_style"
import { Box, LinearProgress, Stack, Typography } from "@mui/material"
import { renderIdenticalIcon } from "../../admin-dashboard/AgentHealthMain"
import { alertStates } from "../../../state/vizState"

const InputOutputCard = ({
  index,
  name,
  data,
  version,
  width,
  id,
  status,
  progress,
}) => {
  const classes = useStyles()
  const setAlertState = useSetRecoilState(alertStates)
  const isOutputs = name === "Outputs"
  const showVersion = (width < 480 || isOutputs) && version
  const progressConditions = ["timed-out", "failed"]

  useEffect(() => {
    if (progress === 100) {
      const timeout = setTimeout(() => {
        const element = document.getElementById("jobProgress")
        if (element) {
          element.style.display = "none"
        }
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [progress])

  return (
    <core.Card
      key={index}
      className="width-100 ml-display-flex ml-flex-dir-col margin-right-5"
    >
      <core.Box
        className={`ml-display-flex ml-flex-dir-row ml-align-center tryit-${name}`}
      >
        <core.Typography
          variant="body1"
          className={`${classes.colorWhiteIcon} align-vertical`}
        >
          {name}
        </core.Typography>
        {showVersion && (
          <core.Typography
            variant="body1"
            className={`${classes.colorWhiteIcon} createEm-close-icon`}
          >
            (V{version})
          </core.Typography>
        )}
      </core.Box>
      {isOutputs && (
        <>
          {id && (
            <Typography
              variant="body1"
              className="outputscard-result ml-display-flex"
            >
              JobId-&nbsp;
              <p style={{ display: "flex", flexWrap: "wrap" }}>
                <b>
                  {renderIdenticalIcon(id, setAlertState, "Job ID", false, {
                    colDef: { width: 300 },
                    isCalcResult: true,
                  })}
                </b>
                &nbsp;
                {status === "queued" ||
                status === "received" ||
                status === "incomplete"
                  ? "in processing stage."
                  : ""}
              </p>
            </Typography>
          )}
          {progress !== undefined && !progressConditions.includes(status) && (
            <Stack
              id="jobProgress"
              sx={{ width: "100%", alignItems: "center" }}
            >
              <Typography
                variant="body2"
                className="ed-small"
                textAlign={"center"}
              >
                Job progress
              </Typography>
              <LinearProgress
                sx={{ width: "90%" }}
                variant="determinate"
                value={progress ?? 0}
              />
              <br />
            </Stack>
          )}
        </>
      )}
      {data ? (
        <Typography variant="body1">{data}</Typography>
      ) : (
        <Loader margin={"0px"} />
      )}
    </core.Card>
  )
}

const returnIcon = (buttonName) => {
  switch (buttonName) {
    case emulatorMode.emulate:
      return <SlChemistry fontSize={30} />
    case emulatorMode.calculate:
      return <FaCalculator fontSize={25} />
    default:
      return <RiFileSearchLine fontSize={30} />
  }
}

const OutputsCard = ({
  finalRes,
  currentIndex,
  fullView,
  threeRes,
  isModalObject,
  lookup,
}) => {
  const { width } = useWindowDimensions()
  const [showCard1, setShowCard1] = useState([])
  const emulatorConfig = useRecoilValue(getEmulatorData)
  const jobResponses = useRecoilValue(jobResponsesState)
  const setJsonDialogOpen = useSetRecoilState(jsonDialogOpenClose)
  const calcConfig = emulatorConfig?.calculator

  const handleToggle = (index) => {
    const newToggleStates = [...showCard1]
    newToggleStates[index] = !newToggleStates[index]
    setShowCard1(newToggleStates)
  }

  const compareLabels = function (singleRd, expectedOutput) {
    return expectedOutput.ColumnLabel === singleRd
  }

  const checkStringType = (str) => {
    const numberPattern = /^-?\d+(\.\d+)?$/
    const textPattern = /^[a-zA-Z0-9]+$/
    if (numberPattern.test(str)) {
      return "number"
    } else if (textPattern.test(str)) {
      return "string"
    } else {
      return "string"
    }
  }

  const getInputVariableName = (inp) => {
    const configInputs = lookup
      ? [...calcConfig.InputVariables, ...calcConfig.OutputVariables]
      : calcConfig?.InputVariables || []
    const matchingInput = configInputs.find(
      (obj) => obj.ColumnLabel === inp.Label
    )
    return matchingInput ? matchingInput : ""
  }

  const dataToString = (data, unit) => {
    unit = unit ? unit.replace("none", "") : ""
    let numbers = data.map((val) => {
      if (checkStringType(val) === "string") {
        return val
      } else {
        const valString = val.toString()
        const decimalIndex = valString.indexOf(".")
        const roundedNumber =
          decimalIndex !== -1 && valString.length - decimalIndex > 4
            ? Number(val).toFixed(3)
            : Number(val)
        const isWholeNumber = Math.floor(val) === val
        return `${
          isWholeNumber ? parseFloat(val.toFixed(10)) : roundedNumber
        } ${unit}`
      }
    })
    return numbers.join(", ")
  }

  const findMatchingOutputVar = (label) => {
    return (
      (lookup
        ? [...calcConfig.InputVariables, ...calcConfig.OutputVariables]
        : calcConfig?.OutputVariables || []
      ).find((outputVar) => compareLabels(label, outputVar)) || {}
    )
  }

  const convertOutputLabels = (element, type) => {
    const matchingOutVar =
      type === "input"
        ? getInputVariableName(element)
        : findMatchingOutputVar(lookup ? element?.name : element?.Ref?.Label)
    const value = dataToString(
      lookup && type === "output" ? [element?.value] : element?.Data,
      matchingOutVar?.Units
    )
    return {
      name: matchingOutVar?.Name,
      value: value,
    }
  }

  const displayData = (input, status, type) => {
    return input?.map((element, i) => {
      const values =
        type === "Inputs"
          ? convertOutputLabels(element, "input")
          : status === "complete"
          ? convertOutputLabels(element, "output")
          : { name: element.name, value: element.value }
      return (
        <Typography
          className="outputscard-result"
          key={`index-${i}-${element.name}`}
        >
          <b>{`${values.name}: `}</b>
          {`${values.value}`}
        </Typography>
      )
    })
  }

  const handleJsonViewerClick = (object) => {
    setJsonDialogOpen({
      state: true,
      obj: object,
    })
  }

  const wholeCard = (index, resultItem) => {
    const filteredData = filterObjectForModal(resultItem)

    return (
      <>
        <InputOutputCard
          index={index}
          name={"Inputs"}
          version={resultItem.resultVersion}
          data={displayData(
            resultItem.inputValues,
            resultItem.resultStatus,
            "Inputs"
          )}
          width={width}
          id={resultItem.jobId}
          status={resultItem.resultStatus}
        />
        <InputOutputCard
          index={index}
          name={"Outputs"}
          version={resultItem?.resultVersion}
          data={displayData(
            isModalObject ? filteredData : resultItem.outputValues,
            resultItem.resultStatus,
            "Outputs"
          )}
          width={width}
          id={resultItem.jobId}
          status={resultItem.resultStatus}
          progress={jobResponses[resultItem.jobId]?.job?.progress}
        />
        {!threeRes && (
          <core.Card className="ml-display-flex ml-flex-dir-col height-max">
            {jobResponses[resultItem.jobId] &&
              resultItem.resultMode === "calculate" && (
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  title="Click to view job"
                >
                  <VscJson
                    fontSize={"x-large"}
                    onClick={() =>
                      handleJsonViewerClick(jobResponses[resultItem.jobId]?.job)
                    }
                  />
                </Box>
              )}
            {returnIcon(resultItem?.resultMode)}
          </core.Card>
        )}
      </>
    )
  }

  const filterObjectForModal = (resultItem) => {
    const updatedArray = resultItem?.outputValues?.filter((item) => {
      try {
        const parsedData = JSON.parse(item.Data[0])
        return typeof parsedData !== "object"
      } catch (error) {
        return true
      }
    })

    return updatedArray
  }

  const mobileViewCard = (index, resultItem) => {
    const filteredData = filterObjectForModal(resultItem)

    return (
      <>
        <core.Box
          sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
        >
          {!threeRes && returnIcon(resultItem?.resultMode)}
          <core.FormControlLabel
            value="top"
            control={
              <core.Switch
                color="primary"
                checked={showCard1[index]}
                onChange={() => handleToggle(index)}
              />
            }
            label={showCard1[index] ? "Inputs" : "Outputs"}
            labelPlacement="start"
          />
        </core.Box>
        {showCard1[index] ? (
          <InputOutputCard
            index={index}
            name={"Inputs"}
            version={resultItem?.resultVersion}
            data={displayData(
              resultItem.inputValues,
              resultItem.resultStatus,
              "Inputs"
            )}
            width={width}
            id={resultItem.jobId}
            status={resultItem.resultStatus}
          />
        ) : (
          <InputOutputCard
            index={index}
            name={"Outputs"}
            version={resultItem?.resultVersion}
            data={displayData(
              isModalObject ? filteredData : resultItem.outputValues,
              resultItem.resultStatus,
              "Outputs"
            )}
            width={width}
            id={resultItem.jobId}
            status={resultItem.resultStatus}
          />
        )}
      </>
    )
  }

  return (
    <>
      {finalRes?.map((resultItem, index) => (
        <core.Grid
          key={`${index}-input/output`}
          className={`ml-display-flex ${
            threeRes ? "ml-flex-dir-col" : "ml-flex-dir-row"
          } my-result-card ${index !== 0 && !lookup ? "grey-out" : ""}`}
        >
          {width > 480
            ? fullView === "Open"
              ? index === currentIndex && wholeCard(index, resultItem)
              : wholeCard(index, resultItem)
            : fullView === "Open"
            ? index === currentIndex && mobileViewCard(index, resultItem)
            : mobileViewCard(index, resultItem)}
        </core.Grid>
      ))}
    </>
  )
}

export default OutputsCard
