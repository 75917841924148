import * as THREE from "three"

export const getMouseXY = (event, container) => {
  const rect = container.getBoundingClientRect()
  const mouse = new THREE.Vector2()
  mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1
  mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1
  return mouse
}

export const getLabelPosition = (index) => {
  // Function to calculate cube label position based on side index
  const position = new THREE.Vector3()
  switch (index) {
    case 0: // Front
      position.set(0.8, 1.5, 0)
      break
    case 1: // Back
      position.set(0.8, -1.5, 0)
      break
    case 2: // Top
      position.set(0.6, 0, 1.5)
      break
    case 3: // Bottom
      position.set(1.1, 0, -1.5)
      break
    case 4: // Left
      position.set(1.5, 0, -0.6)
      break
    case 5: // Right
      position.set(-1.5, 0, 0.8)
      break
  }
  return position
}

export const getSidePosition = (index) => {
  // Function to calculate cube sides position based on side index
  const position = new THREE.Vector3()
  switch (index) {
    case 0: // Front
      position.set(0, 1.5, 0)
      break
    case 1: // Back
      position.set(0, -1.5, 0)
      break
    case 2: // Top
      position.set(0, 0, 1.5)
      break
    case 3: // Bottom
      position.set(0, 0, -1.5)
      break
    case 4: // Left
      position.set(1.5, 0, 0)
      break
    case 5: // Right
      position.set(-1.5, 0, 0)
      break
  }
  return position
}
