import { Suspense, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"

function AddMetaData({
  newEmulator,
  setTagValue,
  categoryValue,
  tagValue,
  fileType,
  source,
  setDataSource,
  setFileType,
  modes,
  handleSubmitMeta,
  setNewMeta,
  setCategoryValue,
  setModes,
  hasModel,
  changemodelSetting,
  setHasModel,
}) {
  const theme = m.useTheme()

  const emData = useRecoilValue(storedSates.getEmulatorData)
  const [textfieldError, setTextfieldError] = useRecoilState(
    storedSates.textFieldState
  )
  const [editMeta, setEditMeta] = useRecoilState(storedSates.isEditMeta)
  const [tempCategoryAlert, setTempCategoryAlert] = useState("")

  const sourceRefs = storedSatus.sourceRef(emData)
  const sourceMethods = storedSatus.sourceMethod(emData)
  const sourceTypes = storedSatus.sourceType(emData)
  const softwareType = storedSatus.sourceSoftware(emData)

  const closeMeta = () => {
    setEditMeta(false)
    setDataSource({
      ...emData.source,
      type: sourceTypes || "",
      software: softwareType || "",
      ref:
        sourceTypes === storedSatus.emulatorSoftwareType.hosted
          ? sourceRefs
          : "",
      method: sourceMethods || "",
    })
    setFileType(
      sourceTypes === "calculator" ? softwareType : sourceTypes || "none"
    )
    setTextfieldError(false)
  }

  const handleInput = (e) => {
    const name = e.target.name
    const value = e.target.value
    setNewMeta({ ...newEmulator, [name]: value })
    setTextfieldError(false)
  }

  const handleCategoryChange = (e) => {
    const category = e.target.value
    setTempCategoryAlert(storedSates.commonCategoryChangeHandler(category))
    setCategoryValue(e.target.value)
    setTextfieldError(false)
  }

  const handleModeChange = (event) => {
    setModes(event.target.value)
    setTextfieldError(false)
  }

  const handleTagChange = (event, value) => {
    setTagValue(value)
    setTextfieldError(false)
  }

  return (
    <core.Dialog
      onKeyDown={(e) => storedSates.handleKeyDown(e, closeMeta)}
      open={editMeta}
      fullWidth={true}
    >
      <file.AddDialogTitle
        closeCreate={closeMeta}
        title={"Edit Emulator Info"}
      />
      <core.DialogContent dividers>
        <Suspense fallback={<file.Loader />}>
          <div>
            <core.Box sx={{ flexGrow: 1 }}>
              <file.CreateEmulator
                newEmulator={newEmulator}
                handleInput={handleInput}
                handleCategoryChange={handleCategoryChange}
                tempCategoryAlert={tempCategoryAlert}
                handleTagChange={handleTagChange}
                categoryValue={categoryValue}
                tagValue={tagValue}
                textfieldError={textfieldError}
                fileType={fileType}
                source={source}
                setDataSource={setDataSource}
                setFileType={setFileType}
                modes={modes}
                handleModeChange={handleModeChange}
                edit={true}
                hasModel={hasModel}
                changemodelSetting={changemodelSetting}
                setHasModel={setHasModel}
                setTextfieldError={setTextfieldError}
              />
              <core.Grid container className="mui-grids">
                <core.Button
                  variant="contained"
                  type="submit"
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  }}
                  className="createEm-submit"
                  onClick={handleSubmitMeta}
                >
                  Update
                </core.Button>
              </core.Grid>
            </core.Box>
          </div>
        </Suspense>
      </core.DialogContent>
    </core.Dialog>
  )
}
export default AddMetaData
