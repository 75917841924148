import React from "react"
import { useRecoilValue } from "recoil"
import * as m from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import useStyles from "../../card_style"
import { dataSourceType } from "../../../state/projectState"
import { emulatorSoftwareType } from "../../../state/StatusState"

function VariableHelperText() {
  const classes = useStyles()
  const software = useRecoilValue(dataSourceType)

  const variableData = [
    {
      name: "Variable Name",
      description:
        "Full long form of input/output name, (e.g.‘Section Size’, ‘Unbraced Length in Major Axis’).",
    },
    {
      name: "Nickname",
      description:
        "Short form or abbreviation of input/output (e.g. Section, Lb, B, D, L).",
    },
    {
      name: "Column Label",
      description:
        "Used in the data file, if uploading data this must match the columns in the uploaded data.  If generating data, this can be left as the default value.",
    },
    {
      name: "Python Variable Name",
      description:
        "A variable name must start with a letter or the underscore character. A variable name cannot start with a number. A variable name can only contain alpha-numeric characters and underscores (A-z, 0-9, and _ ).A variable name cannot be any of the Python keywords.",
      condition: software === emulatorSoftwareType.python,
    },
    {
      name: "Worksheet",
      description: "Excel sheet tab name here (e.g. Sheet1, Sheet2).",
      condition: software === emulatorSoftwareType.excel,
    },
    {
      name: "Cell",
      description:
        "Cell name or reference within the Excel calc. file (e.g. A4, B11).",
      condition: software === emulatorSoftwareType.excel,
    },
    {
      name: "Api Label",
      description:
        "APIs often require custom names, place the web key/label, etc.",
      condition: software === emulatorSoftwareType.hosted,
    },
    {
      name: "Alias",
      description:
        "Modified name of param for your inputs (EmL: Span, EmL: Width, etc) and outputs (RH_OUT: Weight, etc).",
      condition: software === emulatorSoftwareType.mathcad,
    },
    {
      name: "Param Name",
      description:
        "Name of the parameter as indicated in your calculator source.",
      condition: software === emulatorSoftwareType.grasshopper,
    },
    {
      name: "Description",
      description:
        "Write a short description to assist users in understanding the key aspects of this specific output.",
    },
  ]

  const showVariable = (variable) => {
    return variable.condition === undefined || variable.condition ? (
      <>
        <m.ListItem alignItems="flex-start" key={variable.name}>
          <m.ListItemText
            primary={
              <m.Typography variant="body2">{variable.name}:</m.Typography>
            }
            secondary={
              <m.Typography
                variant="caption"
                textTransform={"none"}
                className={classes.colorGraySec}
              >
                {variable.description}
              </m.Typography>
            }
          />
        </m.ListItem>
        <m.Divider />
      </>
    ) : null
  }

  return (
    <m.Grid padding="1rem" sx={{ maxWidth: { xs: "25rem", md: "200px" } }}>
      <m.List>
        <m.Grid container alignItems={"center"}>
          <InfoOutlinedIcon
            color="primary"
            fontSize="small"
            className="margin-right-10"
          />
          <m.Typography variant="body1">Information</m.Typography>
        </m.Grid>
        {variableData.map((variable) => showVariable(variable))}
      </m.List>
    </m.Grid>
  )
}

export default VariableHelperText
