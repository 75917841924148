import { useState, useEffect, useCallback } from "react"
import { DndProvider } from "react-dnd"
import { TouchBackend } from "react-dnd-touch-backend"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useNavigate, useParams } from "react-router-dom"
import * as core from "@material-ui/core"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import * as m from "@mui/material"
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp"
import AddIcon from "@mui/icons-material/Add"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import PageHelper from "../../PageHelper"
import { pageHelpers } from "../pageHelpersConfig"
import "./CalculatorConfig.css"
import { urlPattern } from "../../CreateNewEmulator"
import * as storedSatus from "../../../state/StatusState"
import useStyles from "../../card_style"
import { alertStates } from "../../../state/vizState"
import useCustomDropzone from "../../../hooks/useCustomDropzone"
import { metadataPost } from "../../../state/requests"

export const varFontSize = {
  fontSize: "0.9rem",
}

export const headFontSize = {
  fontSize: "16",
}

export const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
export const base64Regex = /^data:image\/(png|jpg|jpeg);base64,/

export default function DefineCalcualtorConfig({ fileList, setFileList }) {
  const { emulatorId } = useParams()
  const navigate = useNavigate()
  const { width } = file.useWindowDimensions()
  const classes = useStyles()
  const theme = m.useTheme()

  const [emData, setEmData] = useRecoilState(storedSates.getEmulatorData)
  const [fileType, setFileType] = useRecoilState(storedSates.dataSourceType)
  const setUpdateInputOutput = useSetRecoilState(storedSates.updateVar)
  const setEmulatorStatusState = useSetRecoilState(storedSates.emulatorStatus)
  const uniqueList = useRecoilValue(storedSates.getUnitList)
  const setUpdateData = useSetRecoilState(storedSates.addData)
  const setAllNames = useSetRecoilState(storedSates.manageConfigsNames)
  const setAlertState = useSetRecoilState(alertStates)
  const setEditMeta = useSetRecoilState(storedSates.isEditMeta)
  const setTextfieldError = useSetRecoilState(storedSates.textFieldState)
  const setUploadEmFilesState = useSetRecoilState(storedSates.uploadEmFiles)
  const setShowNotification = useSetRecoilState(storedSates.notificationState)

  const sourceTypes = storedSatus.sourceType(emData)
  const softwareType = storedSatus.sourceSoftware(emData)
  const sourceRefs = storedSatus.sourceRef(emData)
  const sourceMethods = storedSatus.sourceMethod(emData)
  const supportRef = storedSatus.sourceSupRef(emData)

  const columnLabelButtonStyle = {
    color: theme.palette.common.white,
    fontSize: "0.8rem",
    margin: "4px 0",
  }

  const btnStyle = {
    color: theme.palette.common.white,
    width: "150px",
  }

  const [emSoftware, setEmSoftware] = useState(softwareType)
  const [loader, setLoader] = useState(false)
  const [mainLoader, setMainLoader] = useState(true)
  const [success, setSuccess] = useState(false)
  const [variableAction, setVariableAction] = useState("")
  const [inputDialogVisible, setInputDialogVisible] = useState(false)
  const [outputDialogVisible, setOutputDialogVisible] = useState(false)
  const [uploadOpen, setUploadOpen] = useState(false)
  const [index, setIndex] = useState()
  const [categoryValue, setCategoryValue] = useState(emData.category)
  const [tagValue, setTagValue] = useState(emData.tags)
  const [domainData, setDomainData] = useState({})
  const [columnLabelListOpen, setColumnLabelListOpen] = useState(false)
  const [acceptIO, setAcceptIO] = useState("")
  const setImageIndex = useSetRecoilState(storedSates.imageIndex)
  const setFiles = useSetRecoilState(storedSates.filesFromDropzone)
  const [newMeta, setNewMeta] = useState({
    name: emData?.name,
    description: emData?.description,
    image: emData?.image,
  })
  const [dataSource, setDataSource] = useState({
    ...emData.source,
    type: sourceTypes || "",
    software: emSoftware || "",
    ref:
      sourceTypes === storedSatus.emulatorSoftwareType.hosted ? sourceRefs : "",
    method: sourceMethods || "",
  })
  const [modes, setModes] = useState(
    emData?.modes?.map((m) => storedSatus.convertToUppercase(m)) || []
  )
  const [tempfileType, setTempFileType] = useState(
    sourceTypes === "calculator" ? emSoftware : sourceTypes || "none"
  )
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const [hasModel, setHasModel] = useState(emData?.source?.hasModel)
  const [calcTabIndex, setCalcTabIndex] = useState(-1)

  const variables = emData?.data?.variables?.summary
  const emulatorManageConfig = emData?.calculator
  const inpLabels =
    emulatorManageConfig?.InputVariables?.map(
      (inputVar) => inputVar?.ColumnLabel
    ) ?? []

  const outpLabels =
    emulatorManageConfig?.OutputVariables?.map(
      (outputVar) => outputVar?.ColumnLabel
    ) ?? []

  const columnLabels = inpLabels && outpLabels && [...inpLabels, ...outpLabels]
  const dataColumnLabels =
    variables !== "Not Validated" && Object.keys(variables || [])
  const equalityCheck =
    dataColumnLabels &&
    dataColumnLabels?.every((value) => columnLabels.includes(value))

  const filteredArray =
    dataColumnLabels &&
    dataColumnLabels?.filter((item) => !columnLabels.includes(item))

  const inputNames =
    emData?.calculator?.InputVariables?.map((inp) => inp.Name) || []
  const outputNames =
    emData?.calculator?.OutputVariables?.map((out) => out.Name) || []
  const inputNicknames =
    emData?.calculator?.InputVariables?.map((inpNick) => inpNick.NickName) || []
  const outputNicknames =
    emData?.calculator?.OutputVariables?.map((outNick) => outNick.NickName) ||
    []
  const mode = modes?.map((m) => m?.toLowerCase())

  useEffect(() => {
    setTimeout(() => {
      setMainLoader(false)
      setSuccess(false)
    }, [800])
    setAlertState((prevState) => ({
      ...prevState,
      severityState: "success",
    }))
  }, [success])

  useEffect(() => {
    setImageIndex(-1)
    setFiles([])
  }, [])

  useEffect(() => {
    const names = [...inputNames, ...outputNames]
    const nickNames = [...inputNicknames, ...outputNicknames]
    const updatedSoftware = storedSatus.sourceSoftware(emData)
    setAllNames({
      names,
      nickNames,
      columnLabels,
    })
    setEmSoftware(updatedSoftware)
    setNewMeta({
      name: emData?.name,
      description: emData?.description,
      image: emData?.image,
    })
    setTagValue(emData?.tags)
    setCategoryValue(emData.category)
    setTempFileType(
      emData?.source?.type === "calculator"
        ? emData?.source?.software
        : emData?.source?.type || "none"
    )
    setDataSource({
      ...emData.source,
      type: emData?.source?.type || "",
      software: emData?.source?.software || "",
      ref:
        emData?.source?.type === storedSatus.emulatorSoftwareType.hosted
          ? emData?.source?.ref
          : "",
      method: emData?.source?.method || "",
    })
    setModes(emData?.modes?.map((m) => storedSatus.convertToUppercase(m)) || [])
    setHasModel(emData?.source?.hasModel)
    setFileList(
      fileList.map((item) => ({
        ...item,
        ref: emData?.source?.ref?.includes(item.name),
        supporting_refs: emData?.source?.supporting_refs?.includes(
          `${emulatorId}/supporting/${item.name}`
        ),
      }))
    )
  }, [emData])

  useEffect(() => {
    if (calcTabIndex > -1) {
      getFiles(calcTabIndex)
    }
  }, [calcTabIndex])

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    const mappedAccepted = acceptedFiles.map((file) => ({
      file,
      errors: [],
    }))
    setFiles((curr) => [...curr, ...mappedAccepted, ...rejectedFiles])
    navigate(`/em/${emulatorId}/uploaddata`)
  }, [])

  const { getRootProps, getInputProps } = useCustomDropzone(onDrop, "csv")

  const handleAddInput = () => {
    setUpdateInputOutput(false)
    setInputDialogVisible(true)
    setUpdateData(false)
  }

  const handleAddOutput = () => {
    setUpdateInputOutput(false)
    setOutputDialogVisible(true)
    setUpdateData(false)
  }

  const updatingVar = async (variableType, data, hasModel, i, position) => {
    variableType === "input"
      ? setInputDialogVisible(false)
      : setOutputDialogVisible(false)
    const newData = hasModel
      ? data
      : {
          ...data,
          Units:
            data?.Type === "text" || data?.Type === "number"
              ? "none"
              : data?.Units,
        }
    const newIndex = i ?? index
    const url = `/emulators/${emulatorId}/variables/${variableType}/${newIndex}`

    try {
      const upData = await file.EmulationLab.post(url, newData)
      if (upData.status === 200) {
        setVariableAction("updated")
        setSuccess(true)
        setEmulatorStatusState("preparing")
        position && updateEmData()
      }
    } catch (error) {
      alert("Failed to update, please try again..")
    }
  }

  const addVar = async (variableType, data, modelData) => {
    variableType === "input"
      ? setInputDialogVisible(false)
      : setOutputDialogVisible(false)
    const newData = data && {
      ...data,
      Units:
        data?.Type === "text" || data?.Type === "number" ? "none" : data?.Units,
    }
    const url = `/emulators/${emulatorId}/variables/${variableType}/new`
    try {
      const upData = await file.EmulationLab.put(
        url,
        modelData ? modelData : newData
      )
      if (upData.status === 200) {
        setVariableAction("added")
        setSuccess(true)
        setEmulatorStatusState(storedSatus.allEmulatorStatus.preparing)
        if (variableType === "input") {
          setInputDialogVisible(false)
        } else {
          setOutputDialogVisible(false)
        }
      }
    } catch (error) {
      alert("Failed to update, please try again..")
    }
  }

  const handleUpdateOutput = () => {
    setUpdateInputOutput(true)
    setOutputDialogVisible(true)
  }

  const handleUpdateInput = () => {
    setUpdateInputOutput(true)
    setInputDialogVisible(true)
  }

  const updateEmData = () => {
    let configs = storedSates.fetchEmulator(emulatorId)
    configs
      .then((data) => {
        setEmData(data)
        setHasModel(data?.source?.hasModel)
      })
      .catch((error) => alert("Error while updating emulator config-", error))
  }

  const isSameSource = () => {
    const emulatorTypes = Object.values(storedSatus.emulatorSoftwareType)

    return emulatorTypes.some((type) => {
      if (fileType === type) {
        if (
          fileType === storedSatus.emulatorSoftwareType.hosted ||
          fileType === storedSatus.emulatorSoftwareType.upload
        ) {
          return dataSource?.type !== type
        } else {
          return (
            dataSource?.type !== "calculator" || dataSource?.software !== type
          )
        }
      }
      return false
    })
  }

  const isDeleteOrsource = (name) => {
    return name === "delete" || isSameSource()
  }

  const createMetaData = (dataSources, name) => ({
    name: newMeta.name,
    description: newMeta.description,
    image: newMeta.image,
    tags: tagValue,
    category: categoryValue,
    modes: mode,
    source:
      dataSources ||
      (dataSource.type === storedSatus.emulatorSoftwareType.hosted
        ? {
            ...dataSource,
            ref: name === "delete" ? null : dataSource.ref,
          }
        : {
            ...dataSource,
            ref: isDeleteOrsource(name) ? null : sourceRefs,
            hasModel:
              tempfileType === storedSatus.emulatorSoftwareType.grasshopper
                ? hasModel
                : false,
            supporting_refs: emData?.source?.supporting_refs,
          }),
  })

  const validateInputs = () => {
    if (newMeta.name?.length === 0) {
      setTextfieldError({ name: true })
      return false
    } else if (categoryValue?.length === 0) {
      setTextfieldError({ category: true })
      return false
    } else if (modes?.length === 0) {
      setTextfieldError({ mode: true })
      return false
    } else if (
      modes?.includes(
        storedSatus.convertToUppercase(storedSatus.emulatorMode.calculate)
      ) &&
      dataSource.type === storedSatus.emulatorSoftwareType.upload
    ) {
      setTextfieldError({ source: true })
      return false
    } else if (
      newMeta?.image?.length > 0 &&
      !(urlRegex.test(newMeta?.image) || base64Regex.test(newMeta?.image))
    ) {
      setTextfieldError({ image: true })
      return false
    } else if (
      dataSource.type === storedSatus.emulatorSoftwareType.hosted &&
      (!urlPattern.test(dataSource.ref) || dataSource.type.length === 0)
    ) {
      setTextfieldError({ url: true })
      return false
    }
    return true
  }

  const sendMetadataPostRequest = async (metaData) => {
    try {
      const upData = await file.EmulationLab.post(
        metadataPost(emulatorId),
        metaData,
        {
          headers: {
            severity: SeverityLevel.Error,
          },
        }
      )
      return upData
    } catch (error) {
      setLoader(false)
      alert(error.response.data.message)
      return null
    }
  }

  const updateOrDeleteVariable = async (dataSource, name) => {
    const modelPosition = storedSates.sendModelWithRotation("Front")
    const objectIndex = emData?.calculator?.OutputVariables?.findIndex(
      (item) => item?.ModelVisual && item?.orientation
    )

    const isGrasshopper = dataSource
      ? dataSource.software === storedSatus.emulatorSoftwareType.grasshopper
      : tempfileType === storedSatus.emulatorSoftwareType.grasshopper ||
        emSoftware === storedSatus.emulatorSoftwareType.grasshopper

    if (hasModel && isGrasshopper && name !== "delete") {
      if (objectIndex >= 0) {
        updatingVar("output", modelPosition, true, objectIndex, true)
      } else {
        addVar("output", undefined, modelPosition)
        setTimeout(() => {
          updateEmData()
        }, 1000)
      }
    } else if (!hasModel || !isGrasshopper) {
      if (objectIndex >= 0) {
        const url = `/emulators/${emulatorId}/variables/output/${objectIndex}`
        const removeData = await file.EmulationLab.delete(url)
      }
      updateEmData()
    } else {
      updateEmData()
    }
  }

  const updateStateOnSuccess = (dataSources, name, metaData, type) => {
    !type &&
      setFileList(
        dataSources
          ? fileList.map((item) => ({ ...item, ref: item.name === name }))
          : fileList.map((item) => ({ ...item, ref: false }))
      )
    setDataSource(dataSources ? dataSources : metaData.source)
    setVariableAction("meta")
    setEditMeta(false)
    setSuccess(true)
    setLoader(false)
    setFileType(dataSources ? dataSources.software : tempfileType)
    setTempFileType(dataSources ? dataSources.software : tempfileType)
    updateOrDeleteVariable(dataSources, name)
  }

  const submitData = async (dataSources, name, file, type) => {
    const metaData = createMetaData(dataSources, name, file)
    if (!validateInputs()) {
      return
    }
    setLoader(true)
    setTextfieldError(false)
    const upData = await sendMetadataPostRequest(metaData)
    if (upData && upData.status === 200) {
      await updateEmData()
      await updateStateOnSuccess(dataSources, name, metaData, type)
    }
  }

  const handleSubmitMeta = async (e) => {
    e.preventDefault()
    await submitData()
  }

  const openFiles = (callfunction) => {
    callfunction && callfunction()
    setUploadOpen(true)
    setCalcTabIndex(0)
  }

  const closeCalcManager = () => {
    setFiles([])
    setUploadOpen(false)
    setCalcTabIndex(-1)
  }

  const handleLabelClick = (dataColumn, data, value) => {
    setUpdateInputOutput(false)
    setColumnLabelListOpen(false)
    setDomainData({
      [dataColumn]: variables[dataColumn],
    })
    if (value ? data : acceptIO === "input") {
      setInputDialogVisible(true)
    } else {
      setOutputDialogVisible(true)
    }
    setUpdateData(true)
  }

  const variableButtons = (data) => {
    return filteredArray?.length > 0 ? (
      filteredArray?.map((variable, i) => (
        <file.VariableList
          key={`${variable}_${i}`}
          btnName={variable}
          id={i}
          columnLabels={columnLabels}
          acceptIO={acceptIO}
          setAcceptIO={setAcceptIO}
          handleLabelClick={handleLabelClick}
          data={data}
        />
      ))
    ) : (
      <core.Typography variant="body2">
        No unused column labels.
      </core.Typography>
    )
  }

  const openReferenceDialog = (inputBoard, setInputBoard, accept) => {
    inputBoard.map((variable, i) => {
      variable !== undefined && inputBoard.includes(variable)
        ? accept === "input"
          ? setInputDialogVisible(true)
          : setOutputDialogVisible(true)
        : "",
        setInputBoard([]),
        setUpdateInputOutput(false),
        setUpdateData(true),
        variable !== undefined &&
          inputBoard.includes(variable) &&
          setDomainData({
            [variable]: variables[variable],
          })
    })
  }

  const dropVarZone = (drop, inputBoard, setInputBoard, accept) => {
    return (
      <core.Grid
        ref={drop}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.uploadButton}
      >
        <div style={{ padding: "10px" }}>
          <AddIcon sx={{ color: theme.palette.grey.secondaryLight }} />
        </div>
        <div>
          <m.Typography variant="body2" color={theme.palette.grey.main}>
            Drag 'n' drop the Column Labels here.
          </m.Typography>
          <m.Typography variant="body2" className={classes.colorGraySec}>
            Only gray ones column labels are allowed.
          </m.Typography>
          {openReferenceDialog(inputBoard, setInputBoard, accept)}
        </div>
      </core.Grid>
    )
  }

  const uploadFilesButton = (calc) => {
    return (
      <m.Stack
        alignItems="center"
        justify="center"
        direction={calc ? "row" : "column"}
      >
        <core.Typography variant="h1" color="primary">
          <ArrowCircleUpIcon className="domainicon" />
        </core.Typography>
        <core.Typography variant="caption">Upload Data</core.Typography>
      </m.Stack>
    )
  }

  const routeToManageData = (calc) => {
    return (
      <file.Authorization processName="manageIO">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <core.Button
            style={{ width: calc ? undefined : "100%" }}
            id={calc ? "boxmainwrap" : null}
          >
            {uploadFilesButton()}
          </core.Button>
        </div>
      </file.Authorization>
    )
  }

  const responsiveColumnButtonList = (inoutParam) => (
    <file.Authorization processName="manageIO">
      <core.Button
        color="primary"
        size="small"
        variant="contained"
        fullWidth
        margin="0"
        style={columnLabelButtonStyle}
        onClick={() => {
          setColumnLabelListOpen(true)
          setAcceptIO(inoutParam)
        }}
      >
        <m.Typography variant="caption" margin="4px">
          Choose from column label list
        </m.Typography>
      </core.Button>
    </file.Authorization>
  )

  const dragDropFunctions = (acceptParams) => (
    <file.DragDropVariables
      accept={acceptParams}
      variables={variables}
      dropVarZone={dropVarZone}
      config={emData?.calculator}
    />
  )

  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0

  const getBackend = () => {
    return isTouchDevice ? TouchBackend : HTML5Backend
  }

  const backendOptions = {
    enableMouseEvents: true,
    enableTouchEvents: true,
  }

  const backendOptions1 = {
    enableTouchEvents: true,
  }

  const backend = getBackend()

  const changemodelSetting = () => {
    setHasModel(!hasModel)
  }
  const getFiles = async (index) => {
    const getEmulatorFiles = (type) => {
      return `/emulators/${emulatorId}/${
        type === 1 ? "supporting" : "calculator"
      }/files`
    }
    setLoader(true)
    try {
      const response = await file.EmulationLab.get(
        getEmulatorFiles(index ?? calcTabIndex)
      )
      const updatedFileList = response.data?.map((item) => {
        return {
          ...item,
          ref: item.name === sourceRefs?.split("/")[2],
          supporting_refs: supportRef?.includes(
            `${emulatorId}/supporting/${item.name}`
          ),
        }
      })
      setFileList(updatedFileList)
      setLoader(false)
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        listStatus: true,
      }))
    } catch (error) {
      setUploadEmFilesState((prevState) => ({
        ...prevState,
        listStatus: true,
      }))
      setLoader(false)
      setShowNotification(true)
      console.error(error.response)
    }
  }

  return (
    <DndProvider
      backend={backend}
      options={isTouchDevice ? backendOptions : backendOptions1}
    >
      {mainLoader && <file.Loader />}
      {!mainLoader && (
        <>
          <file.ErrorBoundary>
            <file.EmulatorBreadCrumbs
              msg={"Manage Configs"}
              name={emData.name}
              version={"true"}
              padding="none"
            >
              {width > 600 ? (
                <PageHelper info={pageHelpers.manageConfigs} />
              ) : (
                <file.MobileMenu
                  openFiles={openFiles}
                  setOpenImageDialog={setOpenImageDialog}
                />
              )}
            </file.EmulatorBreadCrumbs>
          </file.ErrorBoundary>
          <core.Paper className="ml-p-5">
            <core.Box
              sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
            >
              <core.Typography className="member-manage-title" variant="h5">
                Manage Configs
              </core.Typography>
            </core.Box>
            <file.CalcFileButtons
              setOpenImageDialog={setOpenImageDialog}
              openFiles={openFiles}
              openImageDialog={openImageDialog}
            />
            <file.ErrorBoundary>
              <file.MetaDataSection
                fileList={fileList}
                openFiles={openFiles}
                hasModel={hasModel}
                emSoftware={emSoftware}
              />
            </file.ErrorBoundary>
            {success && (
              <file.SnackBar
                message={
                  variableAction === "meta"
                    ? "Emulator information updated successfully!"
                    : `Variable ${variableAction} successfully!`
                }
                open={success}
              />
            )}
            <form>
              <m.Stack direction="column" sx={{ paddingTop: "0.5rem" }}>
                <core.Grid
                  className="mconfig-variable-grid"
                  container
                  spacing={2}
                >
                  {width < 930 && !variables && routeToManageData()}
                  <file.VariablesStack
                    title="INPUTS"
                    buttonCaption="Add Input"
                    width={width > 1680 ? 5 : 4}
                    addTheVariable={handleAddInput}
                  >
                    <file.ErrorBoundary>
                      {!equalityCheck &&
                        (width > 930
                          ? !openImageDialog && dragDropFunctions("input")
                          : responsiveColumnButtonList("input"))}
                      <file.InputVariableCards
                        setIndex={setIndex}
                        updateVariable={handleUpdateInput}
                        setEmulatorStatusState={setEmulatorStatusState}
                        dataColumnLabels={dataColumnLabels}
                        setDeleteInSuccess={setSuccess}
                        setVariableAction={setVariableAction}
                      />
                    </file.ErrorBoundary>
                    <file.VariableDialog
                      visible={inputDialogVisible}
                      setVisible={setInputDialogVisible}
                      givClass="ml-display-flex vdInput"
                    >
                      <file.ErrorBoundary>
                        <file.InputDefiner
                          setInputDialogVisible={setInputDialogVisible}
                          listIndex={index}
                          domainData={domainData}
                          visible={inputDialogVisible}
                          unique1={uniqueList}
                          btnStyle={btnStyle}
                          updateVariable={updatingVar}
                          addVar={addVar}
                          dataColumnLabels={dataColumnLabels}
                        />
                      </file.ErrorBoundary>
                    </file.VariableDialog>
                  </file.VariablesStack>
                  <file.VariablesStack
                    title="OUTPUTS"
                    buttonCaption="Add Output"
                    width={width > 1680 ? 5 : 4}
                    addTheVariable={handleAddOutput}
                  >
                    <file.ErrorBoundary>
                      {!equalityCheck &&
                        (width > 930
                          ? !openImageDialog && dragDropFunctions("output")
                          : responsiveColumnButtonList("output"))}
                      <file.OutputVariableCards
                        setIndex={setIndex}
                        updateVariable={handleUpdateOutput}
                        setEmulatorStatusState={setEmulatorStatusState}
                        dataColumnLabels={dataColumnLabels}
                        setDeleteInSuccess={setSuccess}
                        setVariableAction={setVariableAction}
                        updateEmData={updateEmData}
                      />
                    </file.ErrorBoundary>
                  </file.VariablesStack>
                  {width > 930 && !openImageDialog && (
                    <file.VariablesStack
                      title="UNUSED COLUMNS"
                      width={width > 1680 ? 2 : 4}
                    >
                      <core.Grid className="columnlbel-overflow">
                        <div className="ml-display-flex ml-flex-dir-col ml-align-center">
                          {variables
                            ? variableButtons("data")
                            : routeToManageData()}
                        </div>
                      </core.Grid>
                    </file.VariablesStack>
                  )}
                  <file.VariableDialog
                    visible={outputDialogVisible}
                    givClass="ml-display-flex vdOutput"
                    setVisible={setOutputDialogVisible}
                  >
                    <file.ErrorBoundary>
                      <file.OutputDefiner
                        setOutputDialogVisible={setOutputDialogVisible}
                        listIndex={index}
                        visible={outputDialogVisible}
                        unique1={uniqueList}
                        btnStyle={btnStyle}
                        updateVariable={updatingVar}
                        addVar={addVar}
                        domainData={domainData}
                        dataColumnLabels={dataColumnLabels}
                      />
                    </file.ErrorBoundary>
                  </file.VariableDialog>
                </core.Grid>
                <core.Dialog
                  onKeyDown={(e) =>
                    storedSates.handleKeyDown(e, setColumnLabelListOpen)
                  }
                  open={columnLabelListOpen}
                  maxWidth="md"
                >
                  <file.ErrorBoundary>
                    <file.AddDialogTitle
                      closeCreate={setColumnLabelListOpen}
                      title={"Unused Column Labels"}
                    />
                    <core.DialogContent dividers>
                      <core.Grid className="columnlbel-overflow">
                        <div className="ml-display-flex ml-flex-dir-col ml-align-center">
                          {variableButtons()}
                        </div>
                      </core.Grid>
                    </core.DialogContent>
                  </file.ErrorBoundary>
                </core.Dialog>
              </m.Stack>
            </form>
          </core.Paper>
        </>
      )}
      <file.ErrorBoundary>
        <file.CalcFileUpload
          setLoader={setLoader}
          setFileList={setFileList}
          uploadOpen={uploadOpen}
          closeCalcManager={closeCalcManager}
          loader={loader}
          fileList={fileList}
          submitData={submitData}
          calcTabIndex={calcTabIndex}
          setCalcTabIndex={setCalcTabIndex}
          getFiles={getFiles}
        />
      </file.ErrorBoundary>{" "}
      <file.ErrorBoundary>
        <file.AddMetaData
          newEmulator={newMeta}
          setNewMeta={setNewMeta}
          setTagValue={setTagValue}
          categoryValue={categoryValue}
          tagValue={tagValue}
          fileType={tempfileType}
          source={dataSource}
          setDataSource={setDataSource}
          setFileType={setTempFileType}
          modes={modes}
          setModes={setModes}
          handleSubmitMeta={handleSubmitMeta}
          setCategoryValue={setCategoryValue}
          hasModel={hasModel}
          changemodelSetting={changemodelSetting}
          setHasModel={setHasModel}
        />
      </file.ErrorBoundary>
    </DndProvider>
  )
}
