import CAc from "./Calculator_Active.svg"
import CIn from "./Calculator_Inactive.svg"
import CUn from "./Calculator_Unavailable.svg"
import EAc from "./Emulator_Active.svg"
import EIn from "./Emulator_Inactive.svg"
import EUn from "./Emulator_Unavailable.svg"
import LAc from "./Lookup_Active.svg"
import LIn from "./Lookup_Inactive.svg"
import LUn from "./Lookup_Unavailable.svg"

const calculatorImages = {
  calcActive: CAc,
  calcInactive: CIn,
  calcUnavail: CUn,
  emuActive: EAc,
  emuInactive: EIn,
  emuUnavail: EUn,
  lookupActive: LAc,
  lookupInactive: LIn,
  lookupUnavail: LUn,
}

export default calculatorImages
