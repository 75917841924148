import * as m from "@mui/material"
import { useRecoilState } from "recoil"
import { globalDialogBox, handleKeyDown } from "../../../state/projectState"
import AddDialogTitle from "../DialogTitle/AddDialogTitle"

const StyledDialog = m.styled(m.Dialog)(({ theme }) => ({
  "& .MuiDialog-paperWidthMd": {
    maxWidth: "980px"
  }
}))

const DialogBox = () => {
  const [globalDialogOpen, setGlobalDialogOpen] =
    useRecoilState(globalDialogBox)

  const closeDialog = () =>
    setGlobalDialogOpen({
      isOpen: false,
      content: null,
      title: "",
      style: {},
      maxWidth: "md",
      actions: null,
    })

  return (
    <StyledDialog
      onKeyDown={(e) => handleKeyDown(e, closeDialog)}
      open={globalDialogOpen.isOpen}
      style={globalDialogOpen.style ?? {}}
      maxWidth={globalDialogOpen.maxWidth}
    >
      <AddDialogTitle
        closeCreate={closeDialog}
        title={globalDialogOpen.title}
      />
      <m.DialogContent dividers>{globalDialogOpen.content}</m.DialogContent>
      {globalDialogOpen.actions}
    </StyledDialog>
  )
}

export default DialogBox
