import { useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import Button from "@material-ui/core/Button"
import { Box, Stack, Typography, useTheme } from "@mui/material"
import Edit from "@mui/icons-material/Edit"
import Delete from "@mui/icons-material/Delete"
import * as core from "@material-ui/core"
import useStyles from "../../card_style"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"

function VariableCardsLayout(props) {
  const theme = useTheme()
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const emulatorStatusState = useRecoilValue(storedSates.emulatorStatus)
  const versName = useRecoilValue(storedSates.versionName)
  const [editAlertBox, setEditAlertBox] = useRecoilState(
    storedSates.editAlertBoxState
  )

  const handleOpen = () => {
    setEditAlertBox({
      heading: storedSatus.confirmDelete,
      content: "Are You sure, You want to delete?",
    })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box
      className="hoverGrey"
      sx={{
        width: "auto",
        minWidth: "200px",
        textWrap: "true",
        textAlign: "left",
        contentAlign: "left",
        backgroundColor: theme.palette.common.white,
        paddingBottom: "20px",
        borderBottom: "3px solid #39c2d759",
      }}
      elevation={1}
    >
      <Stack id="stackAlign">
        <Stack spacing={0.5}>
          <div className={`${classes.colorPrimaryMain} variablename`}>
            <Typography variant="h6">{props.variable.Name}</Typography>
          </div>
          {props.children}
        </Stack>
        {props.version && versName === storedSatus.breadCrumbsStrings.beta && (
          <file.Authorization processName="manageIO">
            <Stack>
              <file.Authorization
                role={emulatorStatusState}
                forbidden={storedSatus.trainingDeployed}
                callFunction={() => props.editButtonFunction()}
              >
                <Button className="hover-styling" color="primary">
                  <Edit />
                </Button>
              </file.Authorization>
              <file.Authorization
                role={emulatorStatusState}
                forbidden={storedSatus.trainingDeployed}
                callFunction={() => handleOpen()}
              >
                <Button className="hover-styling" color="primary">
                  <Delete />
                </Button>
              </file.Authorization>
            </Stack>
          </file.Authorization>
        )}
      </Stack>
      <core.Dialog
        onKeyDown={(e) => storedSates.handleKeyDown(e, handleClose)}
        open={open}
        maxWidth="md"
      >
        <file.AlertBox
          heading={editAlertBox.heading}
          content={editAlertBox.content}
          closeDialog={handleClose}
          callFunction={() => props.deleteButtonFunction()}
        />
      </core.Dialog>
    </Box>
  )
}

export default VariableCardsLayout
