import { useCalculatorConfig } from "../GlobalFileContainer"

const useEmulatorVariablesByColumnLabel = (activeConfig) => {
  const [configs] = useCalculatorConfig()
  const config = activeConfig || configs
  var count = Object.keys(config).length
  let vars =
    count !== 0 ? config.InputVariables.concat(config.OutputVariables) : {}
  vars =
    count !== 0
      ? vars.reduce((combinedDict, thisParam) => {
          combinedDict[thisParam["ColumnLabel"]] = thisParam
          return combinedDict
        }, {})
      : {}

  return [vars]
}

export default useEmulatorVariablesByColumnLabel
