import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"

const syncRequest = (url) => {
  const xhr = new XMLHttpRequest()
  xhr.open("GET", url, false)
  xhr.onload = (e) => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        console.log(xhr.responseText)
      } else {
        console.error(xhr.statusText)
      }
    }
  }
  xhr.onerror = (e) => {
    console.error(xhr.statusText)
  }
  xhr.send(null)
  return JSON.parse(xhr.responseText)
}

export default function MetaTags({ url }) {
  //need to fetch the meta from the back end
  // const meta = {
  const [fetchResult, setFetchResult] = useState({})
  const hrefURL = new URL(window.location.href)
  const path =
    hrefURL.pathname.startsWith("/em") &&
    hrefURL.pathname.split("/").slice(3).join("-")

  useEffect(() => {
    if (hrefURL.pathname.startsWith("/em") || (url && url.startsWith("/em"))) {
      let emId = ""
      if (hrefURL.pathname.startsWith("/em")) {
        emId = hrefURL.pathname.split("/")[2]
      } else {
        emId = url.split("/")[2]
      }
      const newFetchResult = syncRequest(
        `${process.env.REACT_APP_API_URL}/emulators/${emId}/snippet`
      )
      if (newFetchResult) {
        setFetchResult(newFetchResult)
      }
    } else {
      setFetchResult({
        title: "Emulation Lab 6.7.2023 | Share Your Expertise",
        description: "Democratizing ML for the AEC industry",
        image: "%PUBLIC_URL%/logolargewhite.png",
      })
    }
  }, [])

  return (
    <Helmet>
      <title>{`${
        fetchResult.name
          ? "EmL | " + fetchResult.name + " | " + path
          : "Emulation Lab"
      }`}</title>
      <meta
        property="title"
        content={`${fetchResult?.name || fetchResult.title} - `}
      />
      <meta property="description" content={fetchResult.description} />
      <meta property="image" content={fetchResult.image} />
      <meta
        property="og:title"
        content={`${fetchResult?.name || fetchResult.title} - `}
      />
      <meta property="og:description" content={fetchResult.description} />
      <meta property="og:image" content={fetchResult.image} />
    </Helmet>
  )
}
