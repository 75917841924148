import { useState } from "react"
import { useRecoilValue } from "recoil"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import PageHelper from "../../PageHelper"
import { pageHelpers } from "../pageHelpersConfig"
import "./CalculatorConfig.css"
import * as storedSatus from "../../../state/StatusState"

function MobileMenu({ openFiles, setOpenImageDialog }) {
  const emulatorStatusState = useRecoilValue(storedSates.emulatorStatus)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleOpenImage = () => {
    handleMenuClose()
    setOpenImageDialog(true)
  }

  return (
    <div>
      <core.IconButton
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <MoreVertIcon />
      </core.IconButton>
      <m.Menu
        id="mobile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <m.MenuItem onClick={handleOpenImage}>View Images</m.MenuItem>
        <file.Authorization
          role={emulatorStatusState}
          forbidden={storedSatus.trainingDeployed}
          callFunction={() => openFiles(handleMenuClose)}
          processName="manageIO"
        >
          <m.MenuItem>Calc File manager</m.MenuItem>
        </file.Authorization>
        <file.UpdateStatus
          displayButton={false}
          callBackFunc={handleMenuClose}
        />
        <PageHelper
          info={pageHelpers.manageConfigs}
          displayIcon={false}
          callBackFunc={handleMenuClose}
        />
      </m.Menu>
    </div>
  )
}

export default MobileMenu
