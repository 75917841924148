import React, { useState } from "react"
import MuiAlert from "@mui/material/Alert"
import { Snackbar, Stack, Typography } from "@mui/material"
import { useRecoilState } from "recoil"
import { alertStates } from "../state/vizState"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function SnackBar({ message, open }) {
  const [alertState, setAlertState] = useRecoilState(alertStates)

  const [openSnackbar, setOpenSnackbar] = useState(open)

  const handleClose = (event, reason) => {
    if (event && event.stopPropagation) {
      event.stopPropagation()
    }
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
    setAlertState((prevState) => ({
      ...prevState,
      boolState: false,
    }))
  }

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alertState.severityState}
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">{message}</Typography>
        </Alert>
      </Snackbar>
    </Stack>
  )
}
