import { useEffect, useState } from "react"
import * as m from "@mui/material"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import {
  Authorization,
  CollaboratorDialog,
  EmulationLab,
} from "../../GlobalFileContainer"
import {
  emulatorSpecificRole,
  fetchEmulator,
  globalDialogBox,
  returnUserList,
  siteWideRole,
} from "../../state/projectState"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { SeverityLevel } from "@microsoft/applicationinsights-web"
import { alertStates } from "../../state/vizState"
import { mongoProfileState } from "../../state/userState"

const UserForm = ({ members, director, setTeam, emulatorId }) => {
  const theme = m.useTheme()
  const filter = createFilterOptions()

  const [searchValue, setSearchValue] = useState("")
  const [dropdownValue, setDropdownValue] = useState("")
  const [nameError, setNameError] = useState({ name: false, dropdown: false })

  const emulatorSpecificRoleState = useRecoilValue(emulatorSpecificRole)
  const siteWideRoleState = useRecoilValue(siteWideRole)
  const setAlertState = useSetRecoilState(alertStates)
  const userList = useRecoilValue(returnUserList)
  const mongoProfile = useRecoilValue(mongoProfileState)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)

  const reqUrl = `/emulators/${emulatorId}/team/members`
  const presentAsMember =
    members.some((member) => member.name === mongoProfile.name) ||
    director.name === mongoProfile.name
  const isNonMember = !presentAsMember && siteWideRoleState !== "admin"
  const alreadyRequested = members.find(
    (obj) => obj.email === mongoProfile.email
  )

  useEffect(() => {
    if (isNonMember) {
      setSearchValue(mongoProfile.email)
    }
  }, [])

  useEffect(() => {
    if (isNonMember && dropdownValue) {
      searchValueValidation()
    }
  }, [dropdownValue])

  const submitHandler = (e) => {
    e.preventDefault()
    searchValueValidation()
  }

  const searchValueValidation = () => {
    // e.preventDefault()
    const foundEmail =
      director.email === searchValue
        ? director
        : members.find((obj) => obj.email === searchValue)
    const isEmail = searchValue.includes("@")
    if (searchValue.length === 0 || !isEmail) {
      setNameError({ name: true, dropdown: false })
      return ""
    }
    if (dropdownValue.length === 0) {
      setNameError({ name: false, dropdown: true })
      return ""
    }
    if (foundEmail) {
      alert(
        `${foundEmail.name} already present in ${
          foundEmail?.status === "pending"
            ? "pending requests."
            : "approved members."
        }`
      )
      setDropdownValue("")
      setSearchValue("")
    } else {
      handleSubmit()
    }
  }

  const getEmailId = async (name) => {
    if (!name) {
      setSearchValue("")
      return
    }

    const url = `/users/name/${name}`

    try {
      const res = await EmulationLab.get(url)
      const userEmail = res.data.email
      setSearchValue(userEmail)
    } catch (error) {
      console.error("Error while fetching user email:", error)
      setSearchValue("")
    }
  }

  const handleSubmit = async () => {
    const submitValue = {
      email: searchValue,
      approved_role: dropdownValue,
    }
    try {
      const addUser = await EmulationLab.put(reqUrl, submitValue, {
        headers: {
          severity: SeverityLevel.Error,
        },
      })
      if (addUser.status === 200) {
        setAlertState({
          boolState: true,
          message: isNonMember
            ? "Successfully sent request!"
            : "Successfully invited user!",
          severityState: "success",
        })
        let newConfig = fetchEmulator(emulatorId)
        newConfig
          .then((data) => setTeam(data.team))
          .catch((error) =>
            alert("Error while updating emulator config-", error)
          )
        setDropdownValue("")
        setSearchValue("")
      }
    } catch (error) {
      const message = error.response?.data?.message || "Data is not submitted."
      const modifiedMessage = `${message.slice(
        0,
        message.indexOf(". Use the POST request to update.")
      )}. Try refreshing the page.`

      if (error?.response?.status === 400) {
        alert(modifiedMessage)
      } else {
        alert(message)
      }
      setDropdownValue("")
      setSearchValue("")
    }
  }

  return (
    <m.Box>
      <form onSubmit={submitHandler}>
        <m.Grid
          container
          spacing={1}
          justifyContent={isNonMember ? "flex-end" : undefined}
          className="userform-main-grid"
        >
          {isNonMember ? (
            <m.Grid item>
              <m.Button
                style={{ color: theme.palette.common.white, height: "90%" }}
                className="userform-grid-item"
                variant="contained"
                color="primary"
                disabled={alreadyRequested}
                onClick={() =>
                  setGlobalDialogOpen((prev) => ({
                    ...prev,
                    isOpen: true,
                    content: (
                      <CollaboratorDialog
                        setShowCollaboratorDialog={setGlobalDialogOpen}
                        setCollaboratorRole={setDropdownValue}
                      />
                    ),
                    title: "Manager/Technician Permissions",
                    maxWidth: "md",
                  }))
                }
              >
                <m.Typography variant="caption" margin="4px">
                  Request to Join
                </m.Typography>
              </m.Button>
            </m.Grid>
          ) : (
            siteWideRoleState !== "consumer" && (
              <>
                <m.Grid item xs={12} md={7}>
                  <Autocomplete
                    size="small"
                    value={searchValue}
                    onSelect={(event) => {
                      setNameError({})
                      setSearchValue(event.target.value)
                    }}
                    onChange={(event, newValue) => {
                      getEmailId(newValue)
                    }}
                    options={
                      userList ? userList : "Loading names, Please wait."
                    }
                    freeSolo
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params)
                      return filtered
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option}</li>
                    )}
                    renderInput={(params) => (
                      <m.TextField
                        {...params}
                        variant="outlined"
                        // sx={{'& .MuiInputBase-root': {padding: "4px"}}}
                        size="small"
                        placeholder="Enter email address of user you want to add."
                        error={nameError.name}
                        helperText={
                          nameError.name && searchValue.length === 0
                            ? "Email address is required to add member."
                            : nameError.name && !searchValue.includes("@")
                            ? "Type a correct email id."
                            : ""
                        }
                      />
                    )}
                  />
                </m.Grid>
                <m.Grid item xs={9} md={3}>
                  <m.FormControl className="userform-grid-item" size="small">
                    <m.Select
                      variant="outlined"
                      displayEmpty
                      value={dropdownValue}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={(event) => {
                        setNameError({})
                        setDropdownValue(event.target.value)
                      }}
                      error={nameError.dropdown}
                    >
                      <m.MenuItem disabled value="">
                        <p style={{ color: theme.palette.grey.main }}>
                          Select role
                        </p>
                      </m.MenuItem>
                      {siteWideRoleState === "admin" ||
                      emulatorSpecificRoleState !== "technician" ? (
                        <m.MenuItem value="manager">Manager</m.MenuItem>
                      ) : (
                        ""
                      )}
                      <m.MenuItem value="technician">Technician</m.MenuItem>
                    </m.Select>
                    <m.FormHelperText sx={{ color: "#FF0D19" }}>
                      {nameError.dropdown && "Please select a role."}
                    </m.FormHelperText>
                  </m.FormControl>
                </m.Grid>
                <m.Grid item xs={2}>
                  <m.Button
                    size="small"
                    disabled={isNonMember}
                    style={{ color: theme.palette.common.white }}
                    type="submit"
                    className="userform-grid-item"
                    variant="contained"
                    color="primary"
                  >
                    <m.Typography variant="caption" margin="8px">
                      Add
                    </m.Typography>
                  </m.Button>
                </m.Grid>
              </>
            )
          )}
        </m.Grid>
      </form>
    </m.Box>
  )
}

export default UserForm
