import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react"
import { InteractionStatus } from "@azure/msal-browser"
import EmulationLab, { testJWTToken } from "./state/axios"
import { mongoProfileState, msalUserState } from "./state/userState"
import SSOLogin from "./components/SSOLogin"
import MetaTags from "./components/MetaTags"

var loaded = false

export const fetchMongoProfile = async (email, msalToken, setMongoProfile) => {
  if (!email || !msalToken) {
    return null
  }

  const bodyParameters = {
    email: email,
    token: msalToken,
  }

  try {
    localStorage.removeItem("JWTToken")
    const response = await EmulationLab.post("/users/auth", bodyParameters)
    const accessToken = response.data.access_token
    localStorage.setItem("JWTToken", accessToken)
    await testJWTToken(setMongoProfile, accessToken)
    loaded = true
  } catch (error) {
    console.error(error)
  }
}

export function Authentication() {
  const { instance, inProgress, accounts } = useMsal()
  const [runCount, setRunCount] = useState(0)
  const [msalUser, setMsalUser] = useRecoilState(msalUserState)
  const [mongoProfile, setMongoProfile] = useRecoilState(mongoProfileState)

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0],
    }
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken
          setMsalUser(accounts[0])
          let thisEmail = accounts[0].username.toLowerCase()
          localStorage.setItem("email", thisEmail)

          // Call your API with token
          if (runCount == 0) {
            setRunCount((num) => {
              num + 1
            })
            fetchMongoProfile(
              accounts[0].username.toLowerCase(),
              accessToken,
              setMongoProfile
            )
          }
        })
    }
  }, [instance, accounts, inProgress])
}

function ProtectedComponent(props) {
  Authentication()
  if (loaded) return <>{props.children}</>
  return <></>
}

export function AuthEntryPoint(props) {
  return (
    <>
      <AuthenticatedTemplate>
        <ProtectedComponent>{props.children}</ProtectedComponent>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <MetaTags />
        <SSOLogin />
      </UnauthenticatedTemplate>
    </>
  )
}
