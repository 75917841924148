import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { EmulationLab, ReturnMemberCell } from "../../GlobalFileContainer"

const TransferRoleDialogContent = ({
  members,
  emulatorId,
  setMessage,
  setUpdateSuccess,
  setUpdateEmulatorData,
  setGlobalDialogOpen,
  tabulatorMargin,
}) => {
  const setAsDirectorFunctionCall = async (memberEmail) => {
    setUpdateSuccess(false)
    setGlobalDialogOpen({
      isOpen: false,
      content: null,
      title: "",
      style: {},
      maxWidth: "md",
      actions: null,
    })
    setUpdateEmulatorData(false)
    let transferURL = `/emulators/${emulatorId}/team/members`
    let apiBody = {
      email: memberEmail,
      approved_role: "director",
    }
    const response = await EmulationLab.post(transferURL, apiBody)
    setUpdateEmulatorData(true)
    setMessage("role updated")
    setUpdateSuccess(true)
  }

  const setAsDirectorButton = (memberEmail) => {
    return (
      <core.TableCell>
        <m.Button
          align="center"
          size="small"
          variant="outlined"
          color="primary"
          style={tabulatorMargin}
          onClick={() => setAsDirectorFunctionCall(memberEmail)}
        >
          Set As Director
        </m.Button>
      </core.TableCell>
    )
  }

  const renderCellContent = (member) => {
    return (
      <>
        <m.Box
          sx={{ display: { xs: "flex", md: "none" } }}
          className="ml-flex-dir-col"
        >
          <ReturnMemberCell
            name={member.name}
            email={member.email}
            onlyNames={true}
          />
          <m.Box className="ml-display-flex ml-justify-center">
            {setAsDirectorButton(member.email)}
          </m.Box>
        </m.Box>
        <m.Box sx={{ display: { xs: "none", md: "contents" } }}>
          <ReturnMemberCell
            name={member.name}
            email={member.email}
            onlyNames={true}
          />
          {setAsDirectorButton(member.email)}
        </m.Box>
      </>
    )
  }

  return (
    <m.TableContainer>
      <m.Table stickyHeader>
        <m.TableHead>
          <m.TableRow>
            <core.TableCell>
              <b>Members</b>
            </core.TableCell>
            <core.TableCell></core.TableCell>
          </m.TableRow>
        </m.TableHead>
        <m.TableBody style={{ overflowX: "auto" }}>
          {members.map(
            (member, id) =>
              member.approved_role && (
                <m.TableRow key={`${id}-${member.name}`}>
                  {renderCellContent(member)}
                </m.TableRow>
              )
          )}
        </m.TableBody>
      </m.Table>
    </m.TableContainer>
  )
}

export default TransferRoleDialogContent
