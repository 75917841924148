import * as m from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { allVersionUpdates } from "./VersionUpdates"
import "./Row.css"

function VersionHistory() {
  const renderList = (bug, issue) => {
    return (
      <div className="list-divrelease">
        {bug.map((b, i) => (
          <ul key={i} className="ullistrelease">
            <li>
              <m.Typography variant="body1">
                {issue && `${issue} `}
                {b}.
              </m.Typography>
            </li>
          </ul>
        ))}
      </div>
    )
  }

  return (
    <>
      {allVersionUpdates?.map((vers, i) => {
        const key = Object.keys(vers)[0]
        const tasks = vers[key]["Task"]
        const bugFixes = vers[key]["Bug"]

        return (
          <div key={i}>
            <m.Accordion defaultExpanded={i === 0}>
              <m.AccordionSummary
                sx={{ margin: 0 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <m.Typography variant="h6">
                  <b>V {key}</b>
                </m.Typography>
              </m.AccordionSummary>
              <m.AccordionDetails>
                {tasks?.length > 0 && renderList(tasks)}
                {bugFixes?.length > 0 && (
                  <div className="bugrel-fix">
                    <m.Typography variant="h6">
                      <b>Bug Fixes</b>
                    </m.Typography>
                    {renderList(bugFixes, "Fixed issue related to")}
                  </div>
                )}
              </m.AccordionDetails>
            </m.Accordion>
          </div>
        )
      })}
    </>
  )
}

export default VersionHistory
