import * as core from "@material-ui/core"
import { Grid, IconButton, Typography, useTheme } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import useStyles from "../card_style"

function SummaryElementCard({ image, title, desc, children, openStatusHelp }) {
  const classes = useStyles()
  const theme = useTheme()

  const helpIcon = () => {
    return (
      <IconButton
        title={"Help?"}
        size="medium"
        className="ml-float-right hover-styling"
        onClick={openStatusHelp}
      >
        <HelpOutlineIcon color="primary" />
      </IconButton>
    )
  }

  return (
    <core.Card className={classes.card}>
      <Grid container justifyContent={"center"}>
        <Grid item xs>
          <core.CardContent className={classes.cardContent}>
            <div
              className={`${classes.colorPrimaryMain} ml-display-flex ml-space-between blockwidths padding-left-10`}
            >
              <Typography variant="h6">{title}</Typography>
              {openStatusHelp && helpIcon()}
            </div>
            <core.Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
              {desc}
            </core.Typography>
            {children}
          </core.CardContent>
        </Grid>
        {image ? (
          <Grid
            sx={{
              background: theme.palette.primary.light,
            }}
            className="ml-display-flex ml-align-center ml-justify-center"
            item
            sm={4}
            md={4}
            lg={4}
          >
            <img src={image} alt="emulator image" className="img-main" />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </core.Card>
  )
}
export default SummaryElementCard
