import * as m from "@mui/material"
import { MdPattern } from "react-icons/md"
import { FaDna } from "react-icons/fa"
import { IoMdHelpCircleOutline } from "react-icons/io"
import {
  OPTIMIZE_MODES,
  optimiseModesStrings,
} from "../../../state/StatusState"
import { useRecoilState } from "recoil"
import { outputsParametersAndVariables } from "../../../state/projectState"

const AlgorithmButton = ({ selectedAlgorithm, setSelectedAlgorithm }) => {
  const [
    outputsParametersAndVariablesState,
    setOutputsParametersAndVariablesState,
  ] = useRecoilState(outputsParametersAndVariables)

  const getToggleIcon = (type) => {
    switch (type) {
      case optimiseModesStrings.genetic:
        return <FaDna fontSize={"22px"} />
      case optimiseModesStrings.differential:
        return <MdPattern fontSize={"22px"} />
      case optimiseModesStrings.nsg:
        return <IoMdHelpCircleOutline fontSize={"22px"} />
      default:
        return
    }
  }

  const handleButtonName = (name) => {
    setSelectedAlgorithm(name)
    setOutputsParametersAndVariablesState((prev) => ({
      ...prev,
      solver: name,
    }))
  }

  return (
    <m.Grid sx={{ display: "flex", flexDirection: "column" }}>
      <m.ToggleButtonGroup
        color="primary"
        value={selectedAlgorithm}
        exclusive
        aria-label="text alignment"
      >
        {OPTIMIZE_MODES.map((type) => (
          <m.ToggleButton
            key={type}
            value={type}
            disabled={
              outputsParametersAndVariablesState?.variables?.find(
                (vars) => vars.domain.type === "discrete"
              ) && type !== optimiseModesStrings.genetic
            }
            title={`Select mode ${type}`}
            onClick={() => handleButtonName(type)}
          >
            {getToggleIcon(type)}
          </m.ToggleButton>
        ))}
      </m.ToggleButtonGroup>
      <m.Typography variant="body2" className="ed-small gutter-top">
        <b>Algorithm: </b>
        {selectedAlgorithm}
      </m.Typography>
    </m.Grid>
  )
}

export default AlgorithmButton
