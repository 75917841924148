import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react"
import MenuIcon from "@mui/icons-material/Menu"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import * as core from "@material-ui/core"
import * as m from "@mui/material"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import logo from "../assets/logo.png"
import newIcons from "../assets/newIcons"
import { msalUserState } from "../state/userState"
import BackgroundLetterAvatars from "../StringAvatar"
import { SignOutButton } from "./SignOutButton"
import {
  apiNotificationData,
  cardFilterSearchStringState,
  globalDialogBox,
  siteWideRole,
} from "../state/projectState"
import { fetchTheNotifications } from "./navContent/ExploreNav"
import {
  Authorization,
  ShareIconButton,
  useWindowDimensions,
  VersionHistory,
} from "../GlobalFileContainer"
import "../App.css"
import useStyles from "./card_style"
import UserModeBox from "./UserModeBox"

const NAV_PAGES = [
  { title: "Discover Emulators", url: "/" },
  { title: "My Emulators", url: "/my/all" },
  { title: "Agent Dashboard", url: "/agent/summary" },
]

const NAV_PAGES_CONSUMER = [
  { title: "Discover Emulators", url: "/" },
  // { title: "My Emulators" },
  // { title: "To Contribute" },
]

const Navbar = () => {
  const theme = m.useTheme()
  const navigate = useNavigate()
  const classes = useStyles()

  const avtarStyle = {
    height: "50px",
    width: "auto",
  }

  const [msalUser] = useRecoilState(msalUserState)
  const notificationCount = useRecoilValue(apiNotificationData)
  const setNotificationCount = useSetRecoilState(apiNotificationData)
  const setCardsNameFilter = useSetRecoilState(cardFilterSearchStringState)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)
  const siteWideRoleState = useRecoilValue(siteWideRole)

  const [open, setOpen] = useState(false)
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const { width } = useWindowDimensions()
  const isActive = window.location.pathname

  useEffect(() => {
    fetchTheNotifications(setNotificationCount)
  }, [])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const openModal = () => {
    handleCloseNavMenu()
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: <VersionHistory />,
      title: "Release Log",
      maxWidth: "lg",
    }))
  }

  const releaseButton = () => {
    return (
      <m.Button
        sx={{
          my: 2,
          color: theme.palette.common.white,
          display: { xs: "none", md: "flex" },
          borderRadius: "6px",
        }}
        onClick={openModal}
      >
        <m.Typography
          variant="subHeading"
          margin="4px"
          fontFamily={"Helvetica"}
          fontWeight={"bold"}
          sx={{ cursor: "pointer" }}
        >
          What's New?
        </m.Typography>
      </m.Button>
    )
  }

  const helpButton = () => {
    return (
      <m.Button
        sx={{
          color: theme.palette.common.white,
          borderRadius: "6px",
          "& .MuiButton-startIcon": { mr: 0 },
          "&:hover": { backgroundColor: "#7bd7e5" },
        }}
        startIcon={<HelpOutlineIcon />}
      >
        <m.Typography
          variant="subHeading"
          margin="4px"
          fontFamily={"Helvetica"}
          sx={{ cursor: "pointer" }}
        >
          Help
        </m.Typography>
      </m.Button>
    )
  }

  const handleNavigate = (page) => {
    handleCloseNavMenu()
    navigatePage(page.url)
  }
  const resetSearchedFunction = () => {
    setCardsNameFilter("")
  }
  const navigatePage = (path) => {
    resetSearchedFunction()
    navigate(path)
  }
  return (
    <>
      <m.AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: width,
          right: "auto",
          padding: "0px",
        }}
      >
        <core.Paper />
        <m.Container maxWidth={false} style={{ maxHeight: "150px" }}>
          <m.Toolbar disableGutters sx={{ display: "block" }}>
            <m.Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "65px",
              }}
            >
              <m.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <m.Avatar
                  src={logo}
                  alt="EmL"
                  variant="square"
                  className="emulation-main-avatar"
                  style={avtarStyle}
                  sx={{ display: { xs: "none", md: "flex" }, mr: 0.5 }}
                />
                <m.Box
                  onClick={() => navigatePage("/")}
                  sx={{
                    mr: "12px",
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <m.Typography
                    variant="h6"
                    letterSpacing={"0.3rem"}
                    fontWeight={700}
                    noWrap
                    sx={{ cursor: "pointer" }}
                    className={`hover-styling ${classes.themeTypo} ${classes.colorWhiteIcon} main-heading-emu`}
                  >
                    EmulationLab
                  </m.Typography>
                </m.Box>
                <m.Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <m.IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </m.IconButton>
                  <m.Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {NAV_PAGES.map((page, i) => (
                      <m.MenuItem
                        key={`${page.title}_${i}_menu`}
                        onClick={() => handleNavigate(page)}
                      >
                        <m.Typography variant="body1">
                          {page.title}
                        </m.Typography>
                      </m.MenuItem>
                    ))}
                    <m.MenuItem onClick={openModal}>
                      <m.Typography variant="body1">What's New</m.Typography>
                    </m.MenuItem>
                    <SignOutButton />
                  </m.Menu>
                </m.Box>
                <m.Box
                  onClick={() => navigatePage("/")}
                  sx={{
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  <m.Avatar
                    src={logo}
                    alt=""
                    variant="square"
                    style={avtarStyle}
                    sx={{ mr: 1 }}
                  />
                  <m.Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <m.Typography
                      variant="h5"
                      letterSpacing={"0.3rem"}
                      fontWeight={700}
                      noWrap
                      className={`${classes.themeTypo} ${classes.colorWhiteIcon} main-heading-emu`}
                    >
                      EmL
                    </m.Typography>
                  </m.Box>
                </m.Box>
                <m.Box
                  sx={{
                    flexGrow: 1,
                    gap: "12px",
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  {siteWideRoleState === "consumer"
                    ? NAV_PAGES_CONSUMER.map((page, i) => (
                        <m.Button
                          key={page.title}
                          onClick={() => page?.url && navigate(page?.url)}
                          sx={{
                            my: 2,
                            color: theme.palette.common.white,
                            display: "flex",
                            borderRadius: "6px",
                            backgroundColor:
                              isActive === page?.url ? "#7bd7e5" : "inherit",
                            "&:hover": {
                              backgroundColor:
                                isActive === page?.url ? "#7bd7e5" : "inherit",
                            },
                          }}
                        >
                          <m.Typography
                            variant="subHeading"
                            margin="4px"
                            fontFamily={"Helvetica"}
                            fontWeight={"bold"}
                            sx={{ cursor: "pointer" }}
                          >
                            {page.title}
                          </m.Typography>
                        </m.Button>
                      ))
                    : NAV_PAGES.map((page, i) => (
                        <m.Button
                          key={page.title}
                          onClick={() => navigate(page.url)}
                          sx={{
                            my: 2,
                            color: theme.palette.common.white,
                            display: "flex",
                            borderRadius: "6px",
                            backgroundColor:
                              isActive === page?.url ? "#7bd7e5" : "inherit",
                            "&:hover": {
                              backgroundColor:
                                isActive === page?.url ? "#7bd7e5" : "inherit",
                            },
                          }}
                        >
                          <m.Typography
                            variant="subHeading"
                            margin="4px"
                            fontFamily={"Helvetica"}
                            fontWeight={"bold"}
                            sx={{ cursor: "pointer" }}
                          >
                            {page.title}
                          </m.Typography>
                        </m.Button>
                      ))}
                  {releaseButton()}
                </m.Box>
              </m.Box>
              <m.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {helpButton()}
                {window.location.pathname.includes("/em") && (
                  <ShareIconButton />
                )}
                <m.Box sx={{ display: "flex", mr: "12px" }}>
                  <AuthenticatedTemplate>
                    <m.Button onClick={handleClick}>
                      <m.Badge
                        badgeContent={notificationCount?.length}
                        color="secondary"
                      >
                        <BackgroundLetterAvatars
                          name={msalUser ? msalUser.name : "? ?"}
                        />
                      </m.Badge>
                    </m.Button>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate></UnauthenticatedTemplate>
                </m.Box>
                <m.Avatar
                  src={newIcons["wpmLogo"]}
                  alt="WPM"
                  variant="square"
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 0.5,
                    height: "40px",
                    width: "auto",
                  }}
                />
              </m.Box>
            </m.Box>
          </m.Toolbar>
        </m.Container>
        <UserModeBox
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      </m.AppBar>
    </>
  )
}
export default Navbar
