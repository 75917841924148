import * as core from "@material-ui/core"
import AddDialogTitle from "../DialogTitle/AddDialogTitle"
import { Typography } from "@mui/material"

const AlertBox = ({ heading, content, closeDialog, callFunction }) => {
  return (
    <>
      <AddDialogTitle callFunction={closeDialog} title={heading} />
      <core.DialogContent>
        <core.DialogContentText>
          <Typography variant="body1">{content}</Typography>
        </core.DialogContentText>
      </core.DialogContent>
      <core.DialogActions>
        <core.Button
          id="imgurlbtn"
          color="primary"
          variant="contained"
          onClick={() => {
            closeDialog()
            callFunction()
          }}
        >
          <Typography variant="caption">Yes</Typography>
        </core.Button>
        <core.Button
          id="imgurlbtn"
          color="primary"
          variant="contained"
          onClick={closeDialog}
        >
          <Typography variant="caption">No</Typography>
        </core.Button>
      </core.DialogActions>
    </>
  )
}

export default AlertBox
