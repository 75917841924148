// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { ResponsiveBar } from "@nivo/bar"
import { nivoTheme } from "./nivoTheme"
import { getRandomSlice } from "./Scatterplot"
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const Histogram = ({ graph, capturVersion }) => {
  var data = graph.data
  if (data.length > 2000) {
    data = getRandomSlice(data, 2000)
  }
  if (capturVersion) {
    return (
      <ResponsiveBar
        data={data}
        keys={["count"]}
        indexBy="bin"
        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band" }}
        valueFormat={{ format: "", enabled: false }}
        colors={{ scheme: "nivo" }}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        label={false}
      />
    )
  } else {
    return (
      <ResponsiveBar
        data={data}
        theme={nivoTheme}
        keys={["count"]}
        indexBy="bin"
        margin={{ top: 30, right: 10, bottom: 120, left: 80 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band" }}
        valueFormat={{ format: "", enabled: false }}
        colors={{ scheme: "nivo" }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: "fries",
            },
            id: "dots",
          },
          {
            match: {
              id: "sandwich",
            },
            id: "lines",
          },
        ]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -65,
          legend: graph.xLabel,
          legendPosition: "middle",
          legendOffset: 60,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "count",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      />
    )
  }
}

export default Histogram
