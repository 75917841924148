import imageNone from "./0.png"
import imageNoneBr from "./0bo.png"
import imageNoneBu from "./0bu.png"
import imageNoneI from "./0i.png"
import imageNoneOf from "./0o.png"
import imageNull from "./2.png"
import imageNullBr from "./2bo.png"
import imageNullBu from "./2bu.png"
import imageNullI from "./2i.png"
import imageNullOf from "./2o.png"

const mathcadImages = {
  "0no": imageNone,
  "0br": imageNoneBr,
  "0bu": imageNoneBu,
  "0id": imageNoneI,
  "0of": imageNoneOf,
  "2no": imageNull,
  "2br": imageNullBr,
  "2bu": imageNullBu,
  "2id": imageNullI,
  "2of": imageNullOf,
}

export default mathcadImages
