import { useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import * as core from "@material-ui/core"
import { Stack, Typography } from "@mui/material"
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined"
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined"
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import FlagSharpIcon from "@mui/icons-material/FlagSharp"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import Edit from "@mui/icons-material/Edit"
import "./MLEvaluation.css"
import { Authorization } from "../../GlobalFileContainer"
import { emulatorStatus, globalDialogBox } from "../../state/projectState"
import {
  EMPTY_STATES,
  trainingDeployed,
  uploadEmFilesOrTrain,
} from "../../state/StatusState"
import useStyles from "../card_style"

const infoDialogContent = (
  getSevereText,
  lComf,
  uComf,
  comfErrProb,
  comfort_remaing_prob
) => {
  return (
    <core.Box>
      <core.Typography variant="body1">
        Each algorithm is tested on a holdout set, a portion (typically 20-30%)
        of the dataset reserved for testing the performance on new observations,
        where the ground truth is known. The distribution of percentage errors
        (the difference between observed and true value, divided by the true
        value and multiplied by 100) inform how much we should trust its
        estimations. Contributors help us interpreting these errors by setting
        the comfort zone and server limits.
      </core.Typography>
      <br />
      <core.Typography variant="h6">
        Comfort Zone ({lComf} to {uComf}):
      </core.Typography>
      <core.Typography variant="body1">
        There is a <b>{comfErrProb}</b> chance that errors are within a
        comfortable range. In other words, <b>{comfort_remaing_prob}%</b>
        of the time you will get errors that make the contributors
        uncomfortable.
      </core.Typography>
      <br />
      {getSevereText()}
    </core.Box>
  )
}

function MLSideComponent({
  show,
  index,
  showActive,
  handleSubmitInput,
  handleSelectCrit,
  trainingKey,
  active_algorithm,
  algoName,
  showRerun,
  handleRerunAlgorithm,
  reRunButton,
}) {
  const classes = useStyles()
  const emulatorStatusState = useRecoilValue(emulatorStatus)
  const setGlobalDialogOpen = useSetRecoilState(globalDialogBox)

  const [algName, setAlgName] = useState("")

  const lComf = trainingKey?.evaluation_settings?.comfort_lower_bound
  const uComf = trainingKey.evaluation_settings?.comfort_upper_bound
  const lBlunder = trainingKey.evaluation_settings?.severe_lower_bound
  const uBlunder = trainingKey.evaluation_settings?.severe_upper_bound

  const evaluationDataForActiveAlgo = trainingKey.evaluation
    ? trainingKey?.evaluation[algName]
    : ""
  const severeError = evaluationDataForActiveAlgo
    ? evaluationDataForActiveAlgo?.severe_errors
    : false
  const comfErrProb = evaluationDataForActiveAlgo
    ? evaluationDataForActiveAlgo?.comfort_error_probability
    : "0%"
  const severeErrProb = evaluationDataForActiveAlgo
    ? evaluationDataForActiveAlgo?.severe_error_probability
    : "0%"
  const maxPercentError = evaluationDataForActiveAlgo
    ? evaluationDataForActiveAlgo?.max_percent_error
    : "0%"

  const comfort_remaing_prob = (
    100 - Number(comfErrProb.replace("%", ""))
  ).toFixed(3)
  const severe_remaing_prob =
    100 - Number(severeErrProb.replace("%", "")).toFixed(3)

  const getSevereText = () => {
    const header = (
      <core.Typography variant="h6">
        Severe Limits ({`<${lBlunder}`}, {`>${uBlunder}`}):
      </core.Typography>
    )

    if (severeError === "True") {
      return (
        <>
          {header}
          <core.Typography variant="body1">
            The maximum observed error is <b>{maxPercentError}</b>, which
            exceeds the severe limits, so this algorithm is considered RISKY.
            There is a <b>{severe_remaing_prob}%</b> chance of getting a severe
            error.
          </core.Typography>
        </>
      )
    } else {
      return (
        <>
          {header}
          <core.Typography variant="body1">
            The maximum observed error is <b>{maxPercentError}</b>,and that does
            not exceed the severe limits, so it is deemed ACCEPTABLE.
          </core.Typography>
        </>
      )
    }
  }

  const openHelpIcon = (e, algName, num) => {
    e.stopPropagation()
    setAlgName(algName)
    setGlobalDialogOpen((prev) => ({
      ...prev,
      isOpen: true,
      content: infoDialogContent(
        getSevereText,
        lComf,
        uComf,
        comfErrProb,
        comfort_remaing_prob
      ),
      title: `${num === 1 ? "Active" : "Highlighted"} Emulator Information`,
    }))
  }

  return (
    <core.Box style={{ marginRight: "20px" }}>
      {show === index && (
        <core.Box className="eval-m-1-2 ml-display-flex ml-flex-dir-col ml-p-top-10 ml-space-between">
          <core.Box className="eval-m-1-4-0 ml-p-5">
            <core.Typography variant="body1">Legend:</core.Typography>
            <core.Typography variant="body2" className="p-legend">
              <Stack direction="row" spacing={2} alignItems="center">
                <AdjustOutlinedIcon color="grey" fontSize="small" />
                Pending
                <CheckCircleOutlineOutlinedIcon
                  className={classes.colorSuccessIcon}
                  fontSize="small"
                />
                Finished
                <DonutLargeOutlinedIcon
                  className={classes.colorPrimaryMain}
                  fontSize="small"
                />
                In Progress
                <CancelOutlinedIcon
                  className={classes.colorDangerIcon}
                  fontSize="small"
                />
                Run Failed
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <ThumbUpAltOutlinedIcon fontSize="small" />
                Verified
                <FlagSharpIcon
                  className={classes.colorDangerIcon}
                  fontSize="small"
                />
                Flagged (hover for comment)
                <FiberManualRecordIcon
                  className={classes.colorGraySec}
                  fontSize="small"
                />
                No Comment
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <CircleOutlinedIcon
                  className={classes.colorGraySec}
                  fontSize="small"
                />
                Good
              </Stack>
            </core.Typography>
          </core.Box>

          <core.Box className="eval-m-1-4-0 ml-p-5">
            <core.Box className="ml-display-flex ml-flex-dir-row ml-p-5 ml-space-between ml-align-center">
              <core.Typography variant="body1">
                Emulator Selection Criteria:
              </core.Typography>
              {!EMPTY_STATES.includes(active_algorithm) && (
                <Authorization
                  role={emulatorStatusState}
                  forbidden={["deployed"]}
                  callFunction={(e) => handleSelectCrit(e)}
                  processName={uploadEmFilesOrTrain}
                >
                  <core.IconButton size="small">
                    <Edit color="primary" />
                  </core.IconButton>
                </Authorization>
              )}
            </core.Box>
            <core.Box className="ml-display-flex ml-flex-dir-col ml-p-5">
              <core.Typography variant="body2" className="ed-small">
                Error Comfort Zone = {uComf} / {lComf}
              </core.Typography>
              <core.Typography variant="body2" className="ed-small">
                Severe Error Limits = {uBlunder} / {lBlunder}
              </core.Typography>
            </core.Box>
          </core.Box>
        </core.Box>
      )}

      <core.Box paddingTop={1}>
        <core.Box
          className={`ml-p-5 ${
            !EMPTY_STATES.includes(active_algorithm) ? "eval-m-1-4-0" : ""
          }`}
        >
          {!EMPTY_STATES.includes(active_algorithm) && (
            <core.Box className={`ml-display-flex ml-flex-dir-col ml-p-5`}>
              <core.Typography variant="body1">
                Active Emulator Information:
                <core.IconButton
                  size="small"
                  onClick={(e) => openHelpIcon(e, active_algorithm, 1)}
                >
                  <HelpOutlineIcon
                    sx={{
                      position: "absolute",
                      marginLeft: "20px",
                      marginBottom: "15px",
                      fontSize: 16,
                    }}
                    color="primary"
                  />
                </core.IconButton>
              </core.Typography>
              <core.Typography variant="body2" className="ed-small">
                {active_algorithm}
              </core.Typography>
              <core.Typography variant="body2" className="ed-small">
                {trainingKey.evaluation?.[active_algorithm]
                  ? trainingKey?.evaluation?.[active_algorithm]?.synopsis
                  : ""}
              </core.Typography>
            </core.Box>
          )}

          {show === index && (
            <>
              {!EMPTY_STATES.includes(algoName) && (
                <core.Box className="ml-display-flex ml-flex-dir-col ml-p-5">
                  <core.Typography variant="body1">
                    Highlighted Emulator Information:
                    <core.IconButton
                      size="small"
                      onClick={(e) => openHelpIcon(e, algoName, 2)}
                    >
                      <HelpOutlineIcon
                        sx={{
                          position: "absolute",
                          marginLeft: "20px",
                          marginBottom: "15px",
                          fontSize: 16,
                        }}
                        color="primary"
                      />
                    </core.IconButton>
                  </core.Typography>
                  <core.Typography variant="body2" className="ed-small">
                    {algoName}
                  </core.Typography>
                  <core.Typography variant="body2" className="ed-small">
                    {trainingKey.evaluation
                      ? trainingKey?.evaluation[algoName]?.synopsis
                      : ""}
                  </core.Typography>
                </core.Box>
              )}
              <core.Box className="`ml-display-flex ml-flex-dir-col ml-p-5 ml-justify-center ml-text-center btn-selectiondiv">
                {!EMPTY_STATES.includes(active_algorithm) && (
                  <core.Box margin={"2px"}>
                    <Authorization
                      role={emulatorStatusState}
                      forbidden={["deployed"]}
                      callFunction={(e) => handleSubmitInput(e)}
                      processName={uploadEmFilesOrTrain}
                    >
                      <core.Button
                        variant="outlined"
                        color="primary"
                        disabled={
                          (!showActive &&
                            trainingDeployed?.includes(emulatorStatusState)) ||
                          reRunButton
                        }
                      >
                        <Typography variant="caption">
                          Make highlighted algorithm active
                        </Typography>
                      </core.Button>
                    </Authorization>
                  </core.Box>
                )}
                {trainingDeployed?.includes(emulatorStatusState) && (
                  <Authorization processName={uploadEmFilesOrTrain}>
                    <core.Box margin={"2px"}>
                      <core.Button
                        variant="outlined"
                        color="primary"
                        disabled={!showRerun || reRunButton}
                        onClick={handleRerunAlgorithm}
                      >
                        <Typography variant="caption">
                          Re-run highlighted algorithm
                        </Typography>
                      </core.Button>
                    </core.Box>
                  </Authorization>
                )}
              </core.Box>
            </>
          )}
        </core.Box>
      </core.Box>
    </core.Box>
  )
}

export default MLSideComponent
