import * as core from "@material-ui/core"
import { useState } from "react"
import gh1 from "../assets/login.mp4"
import gh2 from "../assets/using.mp4"

function GrasshopperInstructions() {
  const [activeStep, setActiveStep] = useState(0)

  const handleStep = (i) => {
    setActiveStep(i)
  }
  return (
    <>
      <core.Container>
        <core.Stepper orientation="vertical">
          <core.Step>
            <core.StepLabel>
              <core.StepButton color="inherit" onClick={() => handleStep(0)}>
                Install ghWally using instructions on Sharepoint
              </core.StepButton>
            </core.StepLabel>
            <core.Typography variant="body2">
              Search for "ghwally installation" on Sharepoint
            </core.Typography>
          </core.Step>
          <core.Step>
            <core.StepLabel>
              <core.StepButton color="inherit" onClick={() => handleStep(1)}>
                Sign in
              </core.StepButton>
            </core.StepLabel>
            <core.Typography  variant="body2">
              Grasshopper will allow you to sign in and use any of the
              components that are marked with the grasshopper badge.
            </core.Typography>
            {activeStep === 1 ? (
              <>
                <video
                  width="640"
                  height="480"
                  controls
                  autoPlay
                  loop
                  muted
                  poster="https://assets.codepen.io/6093409/river.jpg"
                >
                  <source src={gh1} type="video/mp4" />
                </video>
                <br />
              </>
            ) : null}
          </core.Step>
          <core.Step>
            <core.StepLabel>
              <core.StepButton color="inherit" onClick={() => handleStep(2)}>
                Use an Emulator
              </core.StepButton>
            </core.StepLabel>
            <core.Typography  variant="body2">
              Emulation Lab with one or a list of inputs
            </core.Typography>
            {activeStep === 2 ? (
              <>
                <br />
                <video
                  width="640"
                  height="480"
                  controls
                  autoPlay
                  loop
                  muted
                  poster="https://assets.codepen.io/6093409/river.jpg"
                >
                  <source src={gh2} type="video/mp4" />
                </video>
                <br />
              </>
            ) : null}
          </core.Step>
        </core.Stepper>
      </core.Container>
    </>
  )
}

export default GrasshopperInstructions
