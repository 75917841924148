import * as core from "@material-ui/core"
import { useParams } from "react-router-dom"
import { useRecoilState, useSetRecoilState } from "recoil"
import { alertStates } from "../../../state/vizState"
import { MenuItem, Typography, useTheme } from "@mui/material"
import * as file from "../../../GlobalFileContainer"
import * as storedSates from "../../../state/projectState"
import * as storedSatus from "../../../state/StatusState"
import { configInpOut } from "../../../hooks/useCalculatorConfig"

export default function UpdateStatus({ style, displayButton, callBackFunc }) {
  const { emulatorId } = useParams()
  const theme = useTheme()
  const { inpVar, outVar } = configInpOut()

  const noConfigs = inpVar === 0 || outVar === 0

  const [emulatorCofigStatus, setEmulatorCofigStatus] = useRecoilState(
    storedSates.emulatorStatus
  )
  const setEmulatorData = useSetRecoilState(storedSates.getEmulatorData)
  const setAlertState = useSetRecoilState(alertStates)
  const [emulatorDataCount, setEmulatorDataCount] = useRecoilState(
    storedSates.dataSet
  )

  const fetchEmulatorData = async () => {
    const url = `emulators/${emulatorId}`
    try {
      const response = await file.EmulationLab.get(url)
      const fetchedData = response.data
      setEmulatorData(fetchedData)
      setEmulatorCofigStatus(fetchedData.status)
      setEmulatorDataCount(fetchedData.data.total_row_count)
    } catch (error) {
      alert("error in fetching data...")
    }
  }

  const upgradeEmulatorStatus = async (newStatus, callBackFunc) => {
    callBackFunc && callBackFunc()
    storedSates
      .updateTheStatus(emulatorId, newStatus)
      .then(() => {
        setAlertState({
          boolState: true,
          message: "Status updated successfully!",
          severityState: "success",
        })
        fetchEmulatorData()
      })
      .catch(() => {
        alert("data is not submitted..")
      })
  }

  return (
    <>
      <file.Authorization
        role={emulatorCofigStatus}
        forbidden={storedSatus.trainingDeployed}
        callFunction={() => upgradeEmulatorStatus("prepared", callBackFunc)}
        processName={storedSatus.uploadEmFilesOrTrain}
      >
        {displayButton === false ? (
          <MenuItem>Prepare For Training</MenuItem>
        ) : (
          <core.Button
            color="primary"
            variant="contained"
            fullWidth={style && true}
            style={{
              color: theme.palette.common.white,
              margin: "4px",
              width: "200px",
            }}
            disabled={emulatorDataCount === 0 || noConfigs}
          >
            <Typography variant="caption" margin="4px">
              Prepare For Training
            </Typography>
          </core.Button>
        )}
      </file.Authorization>
    </>
  )
}
